import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { RouterLink, Router, PRIMARY_OUTLET } from '@angular/router';
import { NgbAlertModule, NgbProgressbarModule, NgbTooltipModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription, first } from 'rxjs';
import { ProjectDetails } from 'src/app/core/models-two/ProjectDetails';
import { IpGeoService } from 'src/app/core/service/ip-geo.service';
import { PetroleumProductsService } from 'src/app/core/service/petroleum-products.service';
import { ProjectService } from 'src/app/core/service/project.service';
import { FooterComponent } from 'src/app/shared/footer/footer.component';
import { GiftSecondNavbarComponent } from 'src/app/shared/gift-second-navbar/gift-second-navbar.component';
import { NavbarComponent } from 'src/app/shared/navbar/navbar.component';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { CompanySummary } from 'src/app/core/models-two/CompanySummary';
import { CompanyFinancing } from 'src/app/core/models-two/CompanyFinancing';
import { CompanyFeesReceived } from 'src/app/core/models-two/CompanyFeesReceived';
import { WorkingCapital } from 'src/app/core/models-two/WorkingCapital';
import { FuelLubricantGasProductTotal } from 'src/app/core/models-two/FuelLubricantGasProductTotal';
import { SensitivityAnalysis } from 'src/app/core/models-two/SensitivityAnalysis';

@Component({
  selector: 'app-result',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WidgetModule,
    NgbAlertModule,
    NavbarComponent,
    FooterComponent,
    RouterLink,
    GiftSecondNavbarComponent,
    NgbProgressbarModule,
    NgbTooltipModule,
  ],
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();
  show_alert: boolean;
  alert_message: string;
  alert_type: string;
  tree: any;
  g: any;
  s: any;
  projectId: any;
  loading: boolean = false;
  yearlyEscalationDefault: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  companySummaryNetFuelMarginArray: number[] = this.yearlyEscalationDefault;
  companySummaryDiversificationMarginArray: number[] = this.yearlyEscalationDefault;
  companySummaryOtherRevenueArray: number[] = this.yearlyEscalationDefault;
  companySummaryOperatingExpensesArray: number[] = this.yearlyEscalationDefault;
  companySummaryRentalsAndFeesDueArray: number[] = this.yearlyEscalationDefault;
  companySummaryEBITDAArray: number[] = this.yearlyEscalationDefault;
  companySummaryInvestmentArray: number[] = this.yearlyEscalationDefault;
  companySummaryChangeInWorkingCapitalArray: number[] = this.yearlyEscalationDefault;
  companySummaryFinancingInterestPaidArray: number[] = this.yearlyEscalationDefault;
  companySummaryTaxesArray: number[] = this.yearlyEscalationDefault;
  companySummaryOtherCashItemsArray: number[] = this.yearlyEscalationDefault;
  companySummaryNetCashAfterTaxArray: number[] = this.yearlyEscalationDefault;
  companySummaryFinancialResultArray: number[] = this.yearlyEscalationDefault;
  companySummaryInflationRateArray: number[] = this.yearlyEscalationDefault;
  companyInterestReceivedArray: number[] = this.yearlyEscalationDefault;
  companySummaryEBITDARealArray: number[] = this.yearlyEscalationDefault;
  companySummaryOtherCashItemsRealArray: number[] = this.yearlyEscalationDefault;
  companySummaryInvestmentRealArray: number[] = this.yearlyEscalationDefault;
  companySummaryTaxesRealArray: number[] = this.yearlyEscalationDefault;
  companySummaryChangeInWorkingCapitalRealArray: number[] = this.yearlyEscalationDefault;
  companySummaryNetCashAfterTaxRealArray: number[] = this.yearlyEscalationDefault;
  companySummaryRealCompanyCashBalanceArray: number[] = this.yearlyEscalationDefault;
  companySummaryDiscountedNetCashAfterTaxRealArray: number[] = this.yearlyEscalationDefault;
  companySummaryNetCashAfterTaxDiscountedArray: number[] = this.yearlyEscalationDefault;
  companySummaryTerminalValueNominalTermsArray: number[] = this.yearlyEscalationDefault;
  companySummaryEBITDABeforeOverheadCostsArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedInflationRateArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedInflationFactorArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedFuelVolumeGrowthInPotentialArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedFuelVolumeGrowthFactorArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedFuelVolumeGrowthFactorWithRampUpArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedVariableFeesFuelArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedVariableFeesDiversificationArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedFixedFeesFuelArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedFixedFeesDiversificationArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedVariableFeesCardCommissionArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedVariableFeesDueArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedFixedFeesDueArray: number[] = this.yearlyEscalationDefault;
  fuelVolumeArray: number[] = this.yearlyEscalationDefault;
  fuelMarginArray: number[] = this.yearlyEscalationDefault;
  companySummaryDiversificationShopMarginArray: number[] = this.yearlyEscalationDefault;
  companySummaryDiversificationCarWashMarginArray: number[] = this.yearlyEscalationDefault;
  companySummaryDiversificationMarginOthersArray: number[] = this.yearlyEscalationDefault;
  companyOperatingExpensesTaxOnFuelTurnoverArray: number[] = this.yearlyEscalationDefault;
  companyOperatingExpensesOtherEarningsArray: number[] = this.yearlyEscalationDefault;
  companyOperatingExpensesEarningsFrom3rdPartyContractsArray: number[] = this.yearlyEscalationDefault;
  companyOperatingExpensesYearlyRentOfEquipmentArray: number[] = this.yearlyEscalationDefault;
  companyOperatingExpensesLandLeaseRentsArray: number[] = this.yearlyEscalationDefault;
  companyOperatingExpensesOperatingOtherExpensesArray: number[] = this.yearlyEscalationDefault;
  companyOperatingExpensesOperatingExpensesFromInvestmentsArray: number[] = this.yearlyEscalationDefault;
  companyOperatingExpensesStaffCostsPartlyVariableArray: number[] = this.yearlyEscalationDefault;
  companyOperatingExpensesElectricityAndMiscellaneousOperatingExpensesArray: number[] = this.yearlyEscalationDefault;
  companyOperatingExpensesOtherCostsArray: number[] = this.yearlyEscalationDefault;
  companyOperatingExpensesOperatingLossesVariableArray: number[] = this.yearlyEscalationDefault;

  projectArray: ProjectDetails[];

  countryFXRateLocalToUsdActual: number = 0;
  countryFXRateLocalToUsd: number = 0;
  monthsOfWorkBeforeOperations: number = 0;
  investmentStartDate: number = 0;
  periodOfOperation: number = 0;
  typeOfInvestment: string = "";

  internalRateOfReturn: number = 0;
  paybackPeriod: number = 0;
  netPresentValue: number = 0;
  capitalizedLandLeaseAndFees: number = 0;
  capitalizedLandLeaseAndFeesUSD: number = 0;
  capitalExpenditure: number = 0;
  capitalExpenditureUSD: number = 0;
  otherInitialCosts: number = 0;
  otherInitialCostsUSD: number = 0;
  workingCapitalYear3: number = 0;
  workingCapitalYear3USD: number = 0;
  loanToDealer: number = 0;
  loanToDealerUSD: number = 0;
  estimatedGroupCommitment: number = 0;
  estimatedGroupCommitmentUSD: number = 0;

  lOverCInVolume: number = 0;
  investmentPerM3: number = 0;
  investmentIncludingCapitalizedLandLeasePerM3: number = 0;
  shopTurnoverPerM2: number = 0;
  turnoverPerM3: number = 0;
  rentalLeasePerM2: number = 0;
  rentalLeasePerM3: number = 0;
  fuelProductTotalYearlyVolume: number = 0;
  lubricantProductTotalYearlyVolume: number = 0;

  investmentMinus10PercentChangeInWorkingCapitalArray: number[];
  investmentMinus10PercentChangeInWorkingCapitalRealArray: number[];
  investmentMinus10PercentCompanyDiversificationMarginArray: number[];
  investmentMinus10PercentCompanyInterestReceivedArray: number[];
  investmentMinus10PercentCompanyInvestmentArray: number[];
  investmentMinus10PercentCompanyInvestmentRealArray: number[];
  investmentMinus10PercentCompanyOperatingExpensesArray: number[];
  investmentMinus10PercentCompanyOtherRevenueArray: number[];
  investmentMinus10PercentCompanyRentalsAndFeesDueArray: number[];
  investmentMinus10PercentDealerLoanInterestAmountArray: number[];
  investmentMinus10PercentDiscountedNetCashAfterTaxRealArray: number[];
  investmentMinus10PercentEbitdaArray: number[];
  investmentMinus10PercentEbitdaRealArray: number[];
  investmentMinus10PercentFinancialResultArray: number[];
  investmentMinus10PercentFinancingInterestPaidArray: number[];
  investmentMinus10PercentInflationRateArray: number[];
  investmentMinus10PercentNetCashAfterTaxArray: number[];
  investmentMinus10PercentNetCashAfterTaxDiscountedArray: number[];
  investmentMinus10PercentNetCashAfterTaxRealArray: number[];
  investmentMinus10PercentNetFuelMarginArray: number[];
  investmentMinus10PercentOtherCashItemsArray: number[];
  investmentMinus10PercentOtherCashItemsRealArray: number[];
  investmentMinus10PercentRealCompanyCashBalanceArray: number[];
  investmentMinus10PercentTaxAmountArray: number[];
  investmentMinus10PercentTaxAmountRealArray: number[];
  investmentMinus10PercentWorkingCapitalTerminalValueNominalTermsArray: number[];
  investmentPlus10PercentChangeInWorkingCapitalArray: number[];
  investmentPlus10PercentChangeInWorkingCapitalRealArray: number[];
  investmentPlus10PercentCompanyDiversificationMarginArray: number[];
  investmentPlus10PercentCompanyInterestReceivedArray: number[];
  investmentPlus10PercentCompanyInvestmentArray: number[];
  investmentPlus10PercentCompanyInvestmentRealArray: number[];
  investmentPlus10PercentCompanyOperatingExpensesArray: number[];
  investmentPlus10PercentCompanyOtherRevenueArray: number[];
  investmentPlus10PercentCompanyRentalsAndFeesDueArray: number[];
  investmentPlus10PercentDealerLoanInterestAmountArray: number[];
  investmentPlus10PercentDiscountedNetCashAfterTaxRealArray: number[];
  investmentPlus10PercentEbitdaArray: number[];
  investmentPlus10PercentEbitdaRealArray: number[];
  investmentPlus10PercentFinancialResultArray: number[];
  investmentPlus10PercentFinancingInterestPaidArray: number[];
  investmentPlus10PercentInflationRateArray: number[];
  investmentPlus10PercentNetCashAfterTaxArray: number[];
  investmentPlus10PercentNetCashAfterTaxDiscountedArray: number[];
  investmentPlus10PercentNetCashAfterTaxRealArray: number[];
  investmentPlus10PercentNetFuelMarginArray: number[];
  investmentPlus10PercentOtherCashItemsArray: number[];
  investmentPlus10PercentOtherCashItemsRealArray: number[];
  investmentPlus10PercentRealCompanyCashBalanceArray: number[];
  investmentPlus10PercentTaxAmountArray: number[];
  investmentPlus10PercentTaxAmountRealArray: number[];
  investmentPlus10PercentWorkingCapitalTerminalValueNominalTermsArray: number[];
  marginMinus20PercentChangeInWorkingCapitalArray: number[];
  marginMinus20PercentChangeInWorkingCapitalRealArray: number[];
  marginMinus20PercentCompanyDiversificationMarginArray: number[];
  marginMinus20PercentCompanyInterestReceivedArray: number[];
  marginMinus20PercentCompanyInvestmentArray: number[];
  marginMinus20PercentCompanyInvestmentRealArray: number[];
  marginMinus20PercentCompanyOperatingExpensesArray: number[];
  marginMinus20PercentCompanyOtherRevenueArray: number[];
  marginMinus20PercentCompanyRentalsAndFeesDueArray: number[];
  marginMinus20PercentDealerLoanInterestAmountArray: number[];
  marginMinus20PercentDiscountedNetCashAfterTaxRealArray: number[];
  marginMinus20PercentEbitdaArray: number[];
  marginMinus20PercentEbitdaRealArray: number[];
  marginMinus20PercentFinancialResultArray: number[];
  marginMinus20PercentFinancingInterestPaidArray: number[];
  marginMinus20PercentInflationRateArray: number[];
  marginMinus20PercentNetCashAfterTaxArray: number[];
  marginMinus20PercentNetCashAfterTaxDiscountedArray: number[];
  marginMinus20PercentNetCashAfterTaxRealArray: number[];
  marginMinus20PercentNetFuelMarginArray: number[];
  marginMinus20PercentOtherCashItemsArray: number[];
  marginMinus20PercentOtherCashItemsRealArray: number[];
  marginMinus20PercentRealCompanyCashBalanceArray: number[];
  marginMinus20PercentTaxAmountArray: number[];
  marginMinus20PercentTaxAmountRealArray: number[];
  marginMinus20PercentWorkingCapitalTerminalValueNominalTermsArray: number[];
  marginPlus20PercentChangeInWorkingCapitalArray: number[];
  marginPlus20PercentChangeInWorkingCapitalRealArray: number[];
  marginPlus20PercentCompanyDiversificationMarginArray: number[];
  marginPlus20PercentCompanyInterestReceivedArray: number[];
  marginPlus20PercentCompanyInvestmentArray: number[];
  marginPlus20PercentCompanyInvestmentRealArray: number[];
  marginPlus20PercentCompanyOperatingExpensesArray: number[];
  marginPlus20PercentCompanyOtherRevenueArray: number[];
  marginPlus20PercentCompanyRentalsAndFeesDueArray: number[];
  marginPlus20PercentDealerLoanInterestAmountArray: number[];
  marginPlus20PercentDiscountedNetCashAfterTaxRealArray: number[];
  marginPlus20PercentEbitdaArray: number[];
  marginPlus20PercentEbitdaRealArray: number[];
  marginPlus20PercentFinancialResultArray: number[];
  marginPlus20PercentFinancingInterestPaidArray: number[];
  marginPlus20PercentInflationRateArray: number[];
  marginPlus20PercentNetCashAfterTaxArray: number[];
  marginPlus20PercentNetCashAfterTaxDiscountedArray: number[];
  marginPlus20PercentNetCashAfterTaxRealArray: number[];
  marginPlus20PercentNetFuelMarginArray: number[];
  marginPlus20PercentOtherCashItemsArray: number[];
  marginPlus20PercentOtherCashItemsRealArray: number[];
  marginPlus20PercentRealCompanyCashBalanceArray: number[];
  marginPlus20PercentTaxAmountArray: number[];
  marginPlus20PercentTaxAmountRealArray: number[];
  marginPlus20PercentWorkingCapitalTerminalValueNominalTermsArray: number[];
  operatingCostMinus10PercentChangeInWorkingCapitalArray: number[];
  operatingCostMinus10PercentChangeInWorkingCapitalRealArray: number[];
  operatingCostMinus10PercentCompanyDiversificationMarginArray: number[];
  operatingCostMinus10PercentCompanyInterestReceivedArray: number[];
  operatingCostMinus10PercentCompanyInvestmentArray: number[];
  operatingCostMinus10PercentCompanyInvestmentRealArray: number[];
  operatingCostMinus10PercentCompanyOperatingExpensesArray: number[];
  operatingCostMinus10PercentCompanyOtherRevenueArray: number[];
  operatingCostMinus10PercentCompanyRentalsAndFeesDueArray: number[];
  operatingCostMinus10PercentDealerLoanInterestAmountArray: number[];
  operatingCostMinus10PercentDiscountedNetCashAfterTaxRealArray: number[];
  operatingCostMinus10PercentEbitdaArray: number[];
  operatingCostMinus10PercentEbitdaRealArray: number[];
  operatingCostMinus10PercentFinancialResultArray: number[];
  operatingCostMinus10PercentFinancingInterestPaidArray: number[];
  operatingCostMinus10PercentInflationRateArray: number[];
  operatingCostMinus10PercentNetCashAfterTaxArray: number[];
  operatingCostMinus10PercentNetCashAfterTaxDiscountedArray: number[];
  operatingCostMinus10PercentNetCashAfterTaxRealArray: number[];
  operatingCostMinus10PercentNetFuelMarginArray: number[];
  operatingCostMinus10PercentOtherCashItemsArray: number[];
  operatingCostMinus10PercentOtherCashItemsRealArray: number[];
  operatingCostMinus10PercentRealCompanyCashBalanceArray: number[];
  operatingCostMinus10PercentTaxAmountArray: number[];
  operatingCostMinus10PercentTaxAmountRealArray: number[];
  operatingCostMinus10PercentWorkingCapitalTerminalValueNominalTermsArray: number[];
  operatingCostPlus10PercentChangeInWorkingCapitalArray: number[];
  operatingCostPlus10PercentChangeInWorkingCapitalRealArray: number[];
  operatingCostPlus10PercentCompanyDiversificationMarginArray: number[];
  operatingCostPlus10PercentCompanyInterestReceivedArray: number[];
  operatingCostPlus10PercentCompanyInvestmentArray: number[];
  operatingCostPlus10PercentCompanyInvestmentRealArray: number[];
  operatingCostPlus10PercentCompanyOperatingExpensesArray: number[];
  operatingCostPlus10PercentCompanyOtherRevenueArray: number[];
  operatingCostPlus10PercentCompanyRentalsAndFeesDueArray: number[];
  operatingCostPlus10PercentDealerLoanInterestAmountArray: number[];
  operatingCostPlus10PercentDiscountedNetCashAfterTaxRealArray: number[];
  operatingCostPlus10PercentEbitdaArray: number[];
  operatingCostPlus10PercentEbitdaRealArray: number[];
  operatingCostPlus10PercentFinancialResultArray: number[];
  operatingCostPlus10PercentFinancingInterestPaidArray: number[];
  operatingCostPlus10PercentInflationRateArray: number[];
  operatingCostPlus10PercentNetCashAfterTaxArray: number[];
  operatingCostPlus10PercentNetCashAfterTaxDiscountedArray: number[];
  operatingCostPlus10PercentNetCashAfterTaxRealArray: number[];
  operatingCostPlus10PercentNetFuelMarginArray: number[];
  operatingCostPlus10PercentOtherCashItemsArray: number[];
  operatingCostPlus10PercentOtherCashItemsRealArray: number[];
  operatingCostPlus10PercentRealCompanyCashBalanceArray: number[];
  operatingCostPlus10PercentTaxAmountArray: number[];
  operatingCostPlus10PercentTaxAmountRealArray: number[];
  operatingCostPlus10PercentWorkingCapitalTerminalValueNominalTermsArray: number[];
  volumeMinus15PercentChangeInWorkingCapitalArray: number[];
  volumeMinus15PercentChangeInWorkingCapitalRealArray: number[];
  volumeMinus15PercentCompanyDiversificationMarginArray: number[];
  volumeMinus15PercentCompanyInterestReceivedArray: number[];
  volumeMinus15PercentCompanyInvestmentArray: number[];
  volumeMinus15PercentCompanyInvestmentRealArray: number[];
  volumeMinus15PercentCompanyOperatingExpensesArray: number[];
  volumeMinus15PercentCompanyOtherRevenueArray: number[];
  volumeMinus15PercentCompanyRentalsAndFeesDueArray: number[];
  volumeMinus15PercentDealerLoanInterestAmountArray: number[];
  volumeMinus15PercentDiscountedNetCashAfterTaxRealArray: number[];
  volumeMinus15PercentEbitdaArray: number[];
  volumeMinus15PercentEbitdaRealArray: number[];
  volumeMinus15PercentFinancialResultArray: number[];
  volumeMinus15PercentFinancingInterestPaidArray: number[];
  volumeMinus15PercentInflationRateArray: number[];
  volumeMinus15PercentNetCashAfterTaxArray: number[];
  volumeMinus15PercentNetCashAfterTaxDiscountedArray: number[];
  volumeMinus15PercentNetCashAfterTaxRealArray: number[];
  volumeMinus15PercentNetFuelMarginArray: number[];
  volumeMinus15PercentOtherCashItemsArray: number[];
  volumeMinus15PercentOtherCashItemsRealArray: number[];
  volumeMinus15PercentRealCompanyCashBalanceArray: number[];
  volumeMinus15PercentTaxAmountArray: number[];
  volumeMinus15PercentTaxAmountRealArray: number[];
  volumeMinus15PercentWorkingCapitalTerminalValueNominalTermsArray: number[];
  volumePlus15PercentChangeInWorkingCapitalArray: number[];
  volumePlus15PercentChangeInWorkingCapitalRealArray: number[];
  volumePlus15PercentCompanyDiversificationMarginArray: number[];
  volumePlus15PercentCompanyInterestReceivedArray: number[];
  volumePlus15PercentCompanyInvestmentArray: number[];
  volumePlus15PercentCompanyInvestmentRealArray: number[];
  volumePlus15PercentCompanyOperatingExpensesArray: number[];
  volumePlus15PercentCompanyOtherRevenueArray: number[];
  volumePlus15PercentCompanyRentalsAndFeesDueArray: number[];
  volumePlus15PercentDealerLoanInterestAmountArray: number[];
  volumePlus15PercentDiscountedNetCashAfterTaxRealArray: number[];
  volumePlus15PercentEbitdaArray: number[];
  volumePlus15PercentEbitdaRealArray: number[];
  volumePlus15PercentFinancialResultArray: number[];
  volumePlus15PercentFinancingInterestPaidArray: number[];
  volumePlus15PercentInflationRateArray: number[];
  volumePlus15PercentNetCashAfterTaxArray: number[];
  volumePlus15PercentNetCashAfterTaxDiscountedArray: number[];
  volumePlus15PercentNetCashAfterTaxRealArray: number[];
  volumePlus15PercentNetFuelMarginArray: number[];
  volumePlus15PercentOtherCashItemsArray: number[];
  volumePlus15PercentOtherCashItemsRealArray: number[];
  volumePlus15PercentRealCompanyCashBalanceArray: number[];
  volumePlus15PercentTaxAmountArray: number[];
  volumePlus15PercentTaxAmountRealArray: number[];
  volumePlus15PercentWorkingCapitalTerminalValueNominalTermsArray: number[];
  workingCapitalMinus10PercentChangeInWorkingCapitalArray: number[];
  workingCapitalMinus10PercentChangeInWorkingCapitalRealArray: number[];
  workingCapitalMinus10PercentCompanyDiversificationMarginArray: number[];
  workingCapitalMinus10PercentCompanyInterestReceivedArray: number[];
  workingCapitalMinus10PercentCompanyInvestmentArray: number[];
  workingCapitalMinus10PercentCompanyInvestmentRealArray: number[];
  workingCapitalMinus10PercentCompanyOperatingExpensesArray: number[];
  workingCapitalMinus10PercentCompanyOtherRevenueArray: number[];
  workingCapitalMinus10PercentCompanyRentalsAndFeesDueArray: number[];
  workingCapitalMinus10PercentDealerLoanInterestAmountArray: number[];
  workingCapitalMinus10PercentDiscountedNetCashAfterTaxRealArray: number[];
  workingCapitalMinus10PercentEbitdaArray: number[];
  workingCapitalMinus10PercentEbitdaRealArray: number[];
  workingCapitalMinus10PercentFinancialResultArray: number[];
  workingCapitalMinus10PercentFinancingInterestPaidArray: number[];
  workingCapitalMinus10PercentInflationRateArray: number[];
  workingCapitalMinus10PercentNetCashAfterTaxArray: number[];
  workingCapitalMinus10PercentNetCashAfterTaxDiscountedArray: number[];
  workingCapitalMinus10PercentNetCashAfterTaxRealArray: number[];
  workingCapitalMinus10PercentNetFuelMarginArray: number[];
  workingCapitalMinus10PercentOtherCashItemsArray: number[];
  workingCapitalMinus10PercentOtherCashItemsRealArray: number[];
  workingCapitalMinus10PercentRealCompanyCashBalanceArray: number[];
  workingCapitalMinus10PercentTaxAmountArray: number[];
  workingCapitalMinus10PercentTaxAmountRealArray: number[];
  workingCapitalMinus10PercentWorkingCapitalTerminalValueNominalTermsArray: number[];
  workingCapitalPlus10PercentChangeInWorkingCapitalArray: number[];
  workingCapitalPlus10PercentChangeInWorkingCapitalRealArray: number[];
  workingCapitalPlus10PercentCompanyDiversificationMarginArray: number[];
  workingCapitalPlus10PercentCompanyInterestReceivedArray: number[];
  workingCapitalPlus10PercentCompanyInvestmentArray: number[];
  workingCapitalPlus10PercentCompanyInvestmentRealArray: number[];
  workingCapitalPlus10PercentCompanyOperatingExpensesArray: number[];
  workingCapitalPlus10PercentCompanyOtherRevenueArray: number[];
  workingCapitalPlus10PercentCompanyRentalsAndFeesDueArray: number[];
  workingCapitalPlus10PercentDealerLoanInterestAmountArray: number[];
  workingCapitalPlus10PercentDiscountedNetCashAfterTaxRealArray: number[];
  workingCapitalPlus10PercentEbitdaArray: number[];
  workingCapitalPlus10PercentEbitdaRealArray: number[];
  workingCapitalPlus10PercentFinancialResultArray: number[];
  workingCapitalPlus10PercentFinancingInterestPaidArray: number[];
  workingCapitalPlus10PercentInflationRateArray: number[];
  workingCapitalPlus10PercentNetCashAfterTaxArray: number[];
  workingCapitalPlus10PercentNetCashAfterTaxDiscountedArray: number[];
  workingCapitalPlus10PercentNetCashAfterTaxRealArray: number[];
  workingCapitalPlus10PercentNetFuelMarginArray: number[];
  workingCapitalPlus10PercentOtherCashItemsArray: number[];
  workingCapitalPlus10PercentOtherCashItemsRealArray: number[];
  workingCapitalPlus10PercentRealCompanyCashBalanceArray: number[];
  workingCapitalPlus10PercentTaxAmountArray: number[];
  workingCapitalPlus10PercentTaxAmountRealArray: number[];
  workingCapitalPlus10PercentWorkingCapitalTerminalValueNominalTermsArray: number[];
  inflationRateArray: any;
  investmentMinus10FuelVolumeArray: number[];
  investmentMinus10FuelMarginArray: number[];
  investmentMinus10GasMarginArray: number[];
  investmentMinus10GasVolumeArray: number[];
  investmentMinus10FuelAndLubricantMarginByVolume: number[];
  investmentMinus10GasMarginByVolumeArray: number[];
  investmentPlus10FuelVolumeArray: number[];
  investmentPlus10FuelMarginArray: number[];
  investmentPlus10GasMarginArray: number[];
  investmentPlus10GasVolumeArray: number[];
  investmentPlus10FuelAndLubricantMarginByVolume: number[];
  investmentPlus10GasMarginByVolumeArray: number[];
  marginMinus20FuelVolumeArray: number[];
  marginMinus20FuelMarginArray: number[];
  marginMinus20GasMarginArray: number[];
  marginMinus20GasVolumeArray: number[];
  marginMinus20FuelAndLubricantMarginByVolume: number[];
  marginMinus20GasMarginByVolumeArray: number[];
  marginPlus20FuelVolumeArray: number[];
  marginPlus20FuelMarginArray: number[];
  marginPlus20GasMarginArray: number[];
  marginPlus20GasVolumeArray: number[];
  marginPlus20FuelAndLubricantMarginByVolume: number[];
  marginPlus20GasMarginByVolumeArray: number[];
  operatingCostMinus10FuelVolumeArray: number[];
  operatingCostMinus10FuelMarginArray: number[];
  operatingCostMinus10GasMarginArray: number[];
  operatingCostMinus10GasVolumeArray: number[];
  operatingCostMinus10FuelAndLubricantMarginByVolume: number[];
  operatingCostMinus10GasMarginByVolumeArray: number[];
  operatingCostPlus10FuelVolumeArray: number[];
  operatingCostPlus10FuelMarginArray: number[];
  operatingCostPlus10GasMarginArray: number[];
  operatingCostPlus10GasVolumeArray: number[];
  operatingCostPlus10FuelAndLubricantMarginByVolume: number[];
  operatingCostPlus10GasMarginByVolumeArray: number[];
  volumeMinus15FuelVolumeArray: number[];
  volumeMinus15FuelMarginArray: number[];
  volumeMinus15GasMarginArray: number[];
  volumeMinus15GasVolumeArray: number[];
  volumeMinus15FuelAndLubricantMarginByVolume: number[];
  volumeMinus15GasMarginByVolumeArray: number[];
  volumePlus15FuelVolumeArray: number[];
  volumePlus15FuelMarginArray: number[];
  volumePlus15GasMarginArray: number[];
  volumePlus15GasVolumeArray: number[];
  volumePlus15FuelAndLubricantMarginByVolume: number[];
  volumePlus15GasMarginByVolumeArray: number[];
  workingCapitalMinus10FuelVolumeArray: number[];
  workingCapitalMinus10FuelMarginArray: number[];
  workingCapitalMinus10GasMarginArray: number[];
  workingCapitalMinus10GasVolumeArray: number[];
  workingCapitalMinus10FuelAndLubricantMarginByVolume: number[];
  workingCapitalMinus10GasMarginByVolumeArray: number[];
  workingCapitalPlus10FuelVolumeArray: number[];
  workingCapitalPlus10FuelMarginArray: number[];
  workingCapitalPlus10GasMarginArray: number[];
  workingCapitalPlus10GasVolumeArray: number[];
  workingCapitalPlus10FuelAndLubricantMarginByVolume: number[];
  workingCapitalPlus10GasMarginByVolumeArray: number[];
  discountRateForNetPresentValue: number = 0;

  ebitdaMultiple0xIRR: number = 0;
  ebitdaMultiple0xNetPresentValue: number = 0;
  ebitdaMultiple0xTerminalValue: number = 0;
  ebitdaMultiple0xPaybackPeriod: number = 0;
  ebitdaMultiple0xWealthINCR: number = 0;

  gordonShapiroIRR: number = 0;
  gordonShapiroNetPresentValue: number = 0;
  gordonShapiroTerminalValue: number = 0;
  gordonShapiroPaybackPeriod: number = 0;
  gordonShapiroWealthINCR: number = 0;

  terminalValueAsInputIRR: number = 0;
  terminalValueAsInputNetPresentValue: number = 0;
  terminalValueAsInputTerminalValue: number = 0;
  terminalValueAsInputPaybackPeriod: number = 0;
  terminalValueAsInputWealthINCR: number = 0;

  capitalEmployedNBVIRR: number = 0;
  capitalEmployedNBVNetPresentValue: number = 0;
  capitalEmployedNBVTerminalValue: number = 0;
  capitalEmployedNBVPaybackPeriod: number = 0;
  capitalEmployedNBVWealthINCR: number = 0;

  investmentMinus10IRR: number = 0;
  investmentMinus10NPV: number = 0;
  investmentMinus10PaybackPeriodDiscounted: number = 0;
  investmentPlus10IRR: number = 0;
  investmentPlus10NPV: number = 0;
  investmentPlus10PaybackPeriodDiscounted: number = 0;
  marginMinus20IRR: number = 0;
  marginMinus20NPV: number = 0;
  marginMinus20PaybackPeriodDiscounted: number = 0;
  marginPlus20IRR: number = 0;
  marginPlus20NPV: number = 0;
  marginPlus20PaybackPeriodDiscounted: number = 0;
  operatingCostMinus10IRR: number = 0;
  operatingCostMinus10NPV: number = 0;
  operatingCostMinus10PaybackPeriodDiscounted: number = 0;
  operatingCostPlus10IRR: number = 0;
  operatingCostPlus10NPV: number = 0;
  operatingCostPlus10PaybackPeriodDiscounted: number = 0;
  volumeMinus15IRR: number = 0;
  volumeMinus15NPV: number = 0;
  volumeMinus15PaybackPeriodDiscounted: number = 0;
  volumePlus15IRR: number = 0;
  volumePlus15NPV: number = 0;
  volumePlus15PaybackPeriodDiscounted: number = 0;
  workingCapitalMinus10IRR: number = 0;
  workingCapitalMinus10NPV: number = 0;
  workingCapitalMinus10PaybackPeriodDiscounted: number = 0;
  workingCapitalPlus10IRR: number = 0;
  workingCapitalPlus10NPV: number = 0;
  workingCapitalPlus10PaybackPeriodDiscounted: number = 0;
  volumeMinus15PercentIrr: number = 0;
  volumePlus15PercentIrr: number = 0;
  marginMinus20PercentIrr: number = 0;
  marginPlus20PercentIrr: number = 0;
  investmentMinus10PercentIrr: number = 0;
  investmentPlus10PercentIrr: number = 0;
  operatingCostMinus10PercentIrr: number = 0;
  operatingCostPlus10PercentIrr: number = 0;
  workingCapitalMinus10PercentIrr: number = 0;
  workingCapitalPlus10PercentIrr: number = 0;

  investmentMinus10PaybackPeriod: number = 0;
  investmentPlus10PaybackPeriod: number = 0;
  marginMinus20PaybackPeriod: number = 0;
  marginPlus20PaybackPeriod: number = 0;
  operatingCostMinus10PaybackPeriod: number = 0;
  operatingCostPlus10PaybackPeriod: number = 0;
  volumeMinus15PaybackPeriod: number = 0;
  volumePlus15PaybackPeriod: number = 0;
  workingCapitalMinus10PaybackPeriod: number = 0;
  workingCapitalPlus10PaybackPeriod: number = 0;
  countryFXRateUsdToLocal: number;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private scroller: ViewportScroller,
    private ipGeoService: IpGeoService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private petroleumProductsService: PetroleumProductsService,
  ) {
    this.tree = router.parseUrl(router.url);
    this.g = this.tree.root.children[PRIMARY_OUTLET];
    this.s = this.g.segments;

    this.loadingAnimationFourSeconds();

    if (this.s[2].path) {
      if ((this.s[2].path.toString()).length === 22) {
        this.projectId = this.s[2].path.toString();

        this.subscription.add(
          this.projectService.getProjectArrayItem(this.projectId).pipe().subscribe((res: ProjectDetails[]) => {
            if (res) {
              this.projectArray = res;
              if (res[0]) {
                if (res[0].fuelVolumeAndMarginGrowth) {
                  if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolume) {
                    this.fuelVolumeArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolume;
                  }
                  if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMargin) {
                    this.fuelMarginArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMargin;
                  }
                }
                if (res[0].periodOfOperation) {
                  this.periodOfOperation = res[0].periodOfOperation
                };
                if (res[0].typeOfInvestment) {
                  this.typeOfInvestment = res[0].typeOfInvestment;
                }
                if (res[0].monthsBeforeOperation) {
                  this.monthsOfWorkBeforeOperations = res[0].monthsBeforeOperation;
                }
                if (res[0].startDate) {
                  // Format date
                  this.investmentStartDate = new Date(res[0].startDate)[Symbol.toPrimitive]('number');
                }
                if (res[0].country) {
                  if (res[0].country.usdFxRate) {

                    this.countryFXRateLocalToUsd = res[0].country.usdFxRate;
                    this.countryFXRateUsdToLocal = ((1) / Number(this.countryFXRateLocalToUsd));
                    if (!(this.countryFXRateUsdToLocal === 0)) {
                      if (this.estimatedGroupCommitment) { this.estimatedGroupCommitmentUSD = (this.estimatedGroupCommitment / Number(this.countryFXRateLocalToUsd)); }
                      if (this.capitalExpenditure) { this.capitalExpenditureUSD = (this.capitalExpenditure / Number(this.countryFXRateLocalToUsd)); }
                      if (this.workingCapitalYear3) { this.workingCapitalYear3USD = (this.workingCapitalYear3 / Number(this.countryFXRateLocalToUsd)); }
                      if (this.estimatedGroupCommitment) { this.estimatedGroupCommitmentUSD = (this.estimatedGroupCommitment / Number(this.countryFXRateLocalToUsd)); }
                      if (this.loanToDealer) { this.loanToDealerUSD = (this.loanToDealer / Number(this.countryFXRateLocalToUsd)); }
                    }
                  }
                  if (res[0].country.discountRateForNetPresentValue) {
                    this.discountRateForNetPresentValue = res[0].country.discountRateForNetPresentValue;
                  }
                }
                if (res[0].netPresentValue) {
                  this.netPresentValue = res[0].netPresentValue;
                }
                if (res[0].paybackPeriodDiscounted) {
                  this.paybackPeriod = res[0].paybackPeriodDiscounted;
                }
                if (res[0].internalRateOfReturn) {
                  this.internalRateOfReturn = res[0].internalRateOfReturn;
                }
                if (res[0].economicEnvironment) {
                  if (res[0].economicEnvironment.yearlyEscalation) {
                    if (res[0].economicEnvironment.yearlyEscalation.inflationRate) {
                      this.inflationRateArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                      let indexA = 0;
                      for (let index = 0; index < 16; index++) {
                        this.inflationRateArray[indexA] = ((res[0].economicEnvironment.yearlyEscalation.inflationRate[indexA]));
                        indexA += 1;
                      }
                    }
                  }
                }

                if (res[0].valuationSummary) {
                  if (res[0].valuationSummary.ebitdaMultiple0xIRR) { this.ebitdaMultiple0xIRR = res[0].valuationSummary.ebitdaMultiple0xIRR; };
                  if (res[0].valuationSummary.ebitdaMultiple0xNetPresentValue) { this.ebitdaMultiple0xNetPresentValue = res[0].valuationSummary.ebitdaMultiple0xNetPresentValue; };
                  if (res[0].valuationSummary.ebitdaMultiple0xTerminalValue) { this.ebitdaMultiple0xTerminalValue = res[0].valuationSummary.ebitdaMultiple0xTerminalValue; };
                  if (res[0].valuationSummary.ebitdaMultiple0xPaybackPeriod) { this.ebitdaMultiple0xPaybackPeriod = res[0].valuationSummary.ebitdaMultiple0xPaybackPeriod; };
                  if (res[0].valuationSummary.ebitdaMultiple0xWealthINCR) { this.ebitdaMultiple0xWealthINCR = res[0].valuationSummary.ebitdaMultiple0xWealthINCR; };

                  if (res[0].valuationSummary.gordonShapiroIRR) { this.gordonShapiroIRR = res[0].valuationSummary.gordonShapiroIRR; };
                  if (res[0].valuationSummary.gordonShapiroNetPresentValue) { this.gordonShapiroNetPresentValue = res[0].valuationSummary.gordonShapiroNetPresentValue; };
                  if (res[0].valuationSummary.gordonShapiroTerminalValue) { this.gordonShapiroTerminalValue = res[0].valuationSummary.gordonShapiroTerminalValue; };
                  if (res[0].valuationSummary.gordonShapiroPaybackPeriod) { this.gordonShapiroPaybackPeriod = res[0].valuationSummary.gordonShapiroPaybackPeriod; };
                  if (res[0].valuationSummary.gordonShapiroWealthINCR) { this.gordonShapiroWealthINCR = res[0].valuationSummary.gordonShapiroWealthINCR; };

                  if (res[0].valuationSummary.terminalValueAsInputIRR) { this.terminalValueAsInputIRR = res[0].valuationSummary.terminalValueAsInputIRR; };
                  if (res[0].valuationSummary.terminalValueAsInputNetPresentValue) { this.terminalValueAsInputNetPresentValue = res[0].valuationSummary.terminalValueAsInputNetPresentValue; };
                  if (res[0].valuationSummary.terminalValueAsInputTerminalValue) { this.terminalValueAsInputTerminalValue = res[0].valuationSummary.terminalValueAsInputTerminalValue; };
                  if (res[0].valuationSummary.terminalValueAsInputPaybackPeriod) { this.terminalValueAsInputPaybackPeriod = res[0].valuationSummary.terminalValueAsInputPaybackPeriod; };
                  if (res[0].valuationSummary.terminalValueAsInputWealthINCR) { this.terminalValueAsInputWealthINCR = res[0].valuationSummary.terminalValueAsInputWealthINCR; };

                  if (res[0].valuationSummary.capitalEmployedNBVIRR) { this.capitalEmployedNBVIRR = res[0].valuationSummary.capitalEmployedNBVIRR; };
                  if (res[0].valuationSummary.capitalEmployedNBVNetPresentValue) { this.capitalEmployedNBVNetPresentValue = res[0].valuationSummary.capitalEmployedNBVNetPresentValue; };
                  if (res[0].valuationSummary.capitalEmployedNBVTerminalValue) { this.capitalEmployedNBVTerminalValue = res[0].valuationSummary.capitalEmployedNBVTerminalValue; };
                  if (res[0].valuationSummary.capitalEmployedNBVPaybackPeriod) { this.capitalEmployedNBVPaybackPeriod = res[0].valuationSummary.capitalEmployedNBVPaybackPeriod; };
                  if (res[0].valuationSummary.capitalEmployedNBVWealthINCR) { this.capitalEmployedNBVWealthINCR = res[0].valuationSummary.capitalEmployedNBVWealthINCR; };
                }
              }
            }
          }));

      } else {
        this.router.navigate([`/portal/project`]);
      }

      this.subscription.add(
        this.projectService.getProjectCompanySummaryObject(this.projectId).pipe().subscribe((res: CompanySummary) => {
          if (res) {
            if (res.yearlyEscalation) {
              if (res.yearlyEscalation.netFuelMargin) { this.companySummaryNetFuelMarginArray = res.yearlyEscalation.netFuelMargin };
              if (res.yearlyEscalation.companyDiversificationMargin) { this.companySummaryDiversificationMarginArray = res.yearlyEscalation.companyDiversificationMargin };
              if (res.yearlyEscalation.companyOtherRevenue) { this.companySummaryOtherRevenueArray = res.yearlyEscalation.companyOtherRevenue };
              if (res.yearlyEscalation.companyOperatingExpenses) { this.companySummaryOperatingExpensesArray = res.yearlyEscalation.companyOperatingExpenses };
              if (res.yearlyEscalation.companyRentalsAndFeesDue) { this.companySummaryRentalsAndFeesDueArray = res.yearlyEscalation.companyRentalsAndFeesDue };
              if (res.yearlyEscalation.ebitda) { this.companySummaryEBITDAArray = res.yearlyEscalation.ebitda };
              if (res.yearlyEscalation.companyInvestment) {
                this.companySummaryInvestmentArray = res.yearlyEscalation.companyInvestment;
                this.capitalExpenditure = (res.yearlyEscalation.companyInvestment)[0];
                console.log("this.capitalExpenditure:", this.capitalExpenditure);
                this.estimatedGroupCommitment = ((this.loanToDealer) + (this.workingCapitalYear3) + (this.capitalExpenditure));
                console.log("this.countryFXRateLocalToUsd:", this.countryFXRateLocalToUsd);
                if (!(Number(this.countryFXRateLocalToUsd) === 0)) {
                  this.estimatedGroupCommitmentUSD = (this.estimatedGroupCommitment / Number(this.countryFXRateLocalToUsd));
                  this.capitalExpenditureUSD = (this.capitalExpenditure / Number(this.countryFXRateLocalToUsd));
                  console.log("this.capitalExpenditureUSD:", this.capitalExpenditureUSD);
                }
                // Calculate
                if ((this.fuelProductTotalYearlyVolume > 0) && (this.capitalExpenditure > 0)) {
                  this.investmentPerM3 = (((this.capitalExpenditure) / (this.fuelProductTotalYearlyVolume)) * (0.0086));
                }
              };
              if (res.yearlyEscalation.changeInWorkingCapital) { this.companySummaryChangeInWorkingCapitalArray = res.yearlyEscalation.changeInWorkingCapital };
              if (res.yearlyEscalation.financingInterestPaid) { this.companySummaryFinancingInterestPaidArray = res.yearlyEscalation.financingInterestPaid };
              if (res.yearlyEscalation.taxAmount) { this.companySummaryTaxesArray = res.yearlyEscalation.taxAmount };
              if (res.yearlyEscalation.otherCashItems) { this.companySummaryOtherCashItemsArray = res.yearlyEscalation.otherCashItems };
              if (res.yearlyEscalation.netCashAfterTax) { this.companySummaryNetCashAfterTaxArray = res.yearlyEscalation.netCashAfterTax };
              if (res.yearlyEscalation.financialResult) { this.companySummaryFinancialResultArray = res.yearlyEscalation.financialResult };
              if (res.yearlyEscalation.inflationRate) { this.companySummaryInflationRateArray = res.yearlyEscalation.inflationRate };
              if (res.yearlyEscalation.companyInterestReceived) { this.companyInterestReceivedArray = res.yearlyEscalation.companyInterestReceived };

              if (res.yearlyEscalation.ebitdaReal) { this.companySummaryEBITDARealArray = res.yearlyEscalation.ebitdaReal };
              if (res.yearlyEscalation.otherCashItemsReal) { this.companySummaryOtherCashItemsRealArray = res.yearlyEscalation.otherCashItemsReal };
              if (res.yearlyEscalation.companyInvestmentReal) { this.companySummaryInvestmentRealArray = res.yearlyEscalation.companyInvestmentReal };
              if (res.yearlyEscalation.taxAmountReal) { this.companySummaryTaxesRealArray = res.yearlyEscalation.taxAmountReal };
              if (res.yearlyEscalation.changeInWorkingCapitalReal) { this.companySummaryChangeInWorkingCapitalRealArray = res.yearlyEscalation.changeInWorkingCapitalReal };
              if (res.yearlyEscalation.netCashAfterTaxReal) { this.companySummaryNetCashAfterTaxRealArray = res.yearlyEscalation.netCashAfterTaxReal };
              if (res.yearlyEscalation.realCompanyCashBalance) { this.companySummaryRealCompanyCashBalanceArray = res.yearlyEscalation.realCompanyCashBalance };

              if (res.yearlyEscalation.discountedNetCashAfterTaxReal) { this.companySummaryDiscountedNetCashAfterTaxRealArray = res.yearlyEscalation.discountedNetCashAfterTaxReal };
              if (res.yearlyEscalation.netCashAfterTaxDiscounted) { this.companySummaryNetCashAfterTaxDiscountedArray = res.yearlyEscalation.netCashAfterTaxDiscounted };

              if (res.yearlyEscalation.workingCapitalTerminalValueNominalTerms) { this.companySummaryTerminalValueNominalTermsArray = res.yearlyEscalation.workingCapitalTerminalValueNominalTerms };
            };
          }
        }));

      this.subscription.add(
        this.projectService.getProjectCompanyFeesReceivedObject(this.projectId).pipe().subscribe((res: CompanyFeesReceived) => {
          if (res.yearlyEscalation) {
            if (res.yearlyEscalation.inflationRate) { this.companyFeesReceivedInflationRateArray = res.yearlyEscalation.inflationRate; };
            if (res.yearlyEscalation.inflationFactor) { this.companyFeesReceivedInflationFactorArray = res.yearlyEscalation.inflationFactor; };
            if (res.yearlyEscalation.fuelVolumeGrowthInPotential) { this.companyFeesReceivedFuelVolumeGrowthInPotentialArray = res.yearlyEscalation.fuelVolumeGrowthInPotential; };
            if (res.yearlyEscalation.fuelVolumeGrowthFactor) { this.companyFeesReceivedFuelVolumeGrowthFactorArray = res.yearlyEscalation.fuelVolumeGrowthFactor; };
            if (res.yearlyEscalation.fuelVolumeGrowthFactorWithRampUp) { this.companyFeesReceivedFuelVolumeGrowthFactorWithRampUpArray = res.yearlyEscalation.fuelVolumeGrowthFactorWithRampUp; };
            if (res.yearlyEscalation.variableFeesFuel) { this.companyFeesReceivedVariableFeesFuelArray = res.yearlyEscalation.variableFeesFuel; };
            if (res.yearlyEscalation.variableFeesDiversification) { this.companyFeesReceivedVariableFeesDiversificationArray = res.yearlyEscalation.variableFeesDiversification; };
            if (res.yearlyEscalation.fixedFeesFuel) { this.companyFeesReceivedFixedFeesFuelArray = res.yearlyEscalation.fixedFeesFuel; };
            if (res.yearlyEscalation.fixedFeesDiversification) { this.companyFeesReceivedFixedFeesDiversificationArray = res.yearlyEscalation.fixedFeesDiversification; };
            if (res.yearlyEscalation.variableFeesCardCommission) { this.companyFeesReceivedVariableFeesCardCommissionArray = res.yearlyEscalation.variableFeesCardCommission; };
          };
        }));

      this.subscription.add(
        this.projectService.getProjectWorkingCapitalObject(this.projectId).pipe().subscribe((res: WorkingCapital) => {

          if (res) {
            if (res.yearlyEscalation) {
              if (res.yearlyEscalation.workingCapital) {
                this.workingCapitalYear3 = (res.yearlyEscalation.workingCapital)[2];
                this.estimatedGroupCommitment = ((this.loanToDealer) + (this.workingCapitalYear3) + (this.capitalExpenditure));
              }
            }
          }
        }));

      this.subscription.add(
        this.projectService.getProjectFinancingObject(this.projectId).pipe().subscribe((res: CompanyFinancing) => {
          if (res) {
            if (res.loanAmount) {
              this.loanToDealer = res.loanAmount;
              this.estimatedGroupCommitment = ((this.loanToDealer) + (this.workingCapitalYear3) + (this.capitalExpenditure));
            };
          };
        }));

      this.subscription.add(
        this.projectService.getProjectFuelProductTotalObject(this.projectId).pipe().subscribe((res: FuelLubricantGasProductTotal) => {
          if (res) {
            if (res.yearlyVolume) {
              this.fuelProductTotalYearlyVolume = res.yearlyVolume;
              if ((this.lubricantProductTotalYearlyVolume > 0) && (this.fuelProductTotalYearlyVolume > 0)) {
                this.lOverCInVolume = (((this.lubricantProductTotalYearlyVolume) / (this.fuelProductTotalYearlyVolume)) * (100));
              };

              // Calculate
              if ((this.fuelProductTotalYearlyVolume > 0) && (this.capitalExpenditure > 0)) {
                this.investmentPerM3 = (((this.capitalExpenditure) / (this.fuelProductTotalYearlyVolume)) * (0.0086));
              }

            }
          }
        }));

      this.subscription.add(
        this.projectService.getProjectLubricantProductTotalObject(this.projectId).pipe().subscribe((res: FuelLubricantGasProductTotal) => {
          if (res) {
            if (res.yearlyVolume) {
              this.lubricantProductTotalYearlyVolume = res.yearlyVolume;
              if ((this.lubricantProductTotalYearlyVolume > 0) && (this.fuelProductTotalYearlyVolume > 0)) {
                this.lOverCInVolume = (((this.lubricantProductTotalYearlyVolume) / (this.fuelProductTotalYearlyVolume)) * (100));
              }
            }
          }
        }));


      // Sensitivity
      this.subscription.add(
        this.projectService.getProjectSensitivityObject(this.projectId).pipe().subscribe((res: SensitivityAnalysis) => {
          if (res) {
            if (res.investmentMinus10Percent) {
              if (res.investmentMinus10Percent.companySummary) {
                if (res.investmentMinus10Percent.companySummary.yearlyEscalation) {
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.changeInWorkingCapital) {
                    this.investmentMinus10PercentChangeInWorkingCapitalArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.changeInWorkingCapital;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal) {
                    this.investmentMinus10PercentChangeInWorkingCapitalRealArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.companyDiversificationMargin) {
                    this.investmentMinus10PercentCompanyDiversificationMarginArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.companyDiversificationMargin;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.companyInterestReceived) {
                    this.investmentMinus10PercentCompanyInterestReceivedArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.companyInterestReceived;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.companyInvestment) {
                    this.investmentMinus10PercentCompanyInvestmentArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.companyInvestment;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.companyInvestmentReal) {
                    this.investmentMinus10PercentCompanyInvestmentRealArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.companyInvestmentReal;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.companyOperatingExpenses) {
                    this.investmentMinus10PercentCompanyOperatingExpensesArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.companyOperatingExpenses;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.companyOtherRevenue) {
                    this.investmentMinus10PercentCompanyOtherRevenueArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.companyOtherRevenue;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue) {
                    this.investmentMinus10PercentCompanyRentalsAndFeesDueArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount) {
                    this.investmentMinus10PercentDealerLoanInterestAmountArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal) {
                    this.investmentMinus10PercentDiscountedNetCashAfterTaxRealArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.ebitda) {
                    this.investmentMinus10PercentEbitdaArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.ebitda;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.ebitdaReal) {
                    this.investmentMinus10PercentEbitdaRealArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.ebitdaReal;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.financialResult) {
                    this.investmentMinus10PercentFinancialResultArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.financialResult;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.financingInterestPaid) {
                    this.investmentMinus10PercentFinancingInterestPaidArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.financingInterestPaid;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.inflationRate) {
                    this.investmentMinus10PercentInflationRateArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.inflationRate;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.netCashAfterTax) {
                    this.investmentMinus10PercentNetCashAfterTaxArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.netCashAfterTax;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted) {
                    this.investmentMinus10PercentNetCashAfterTaxDiscountedArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.netCashAfterTaxReal) {
                    this.investmentMinus10PercentNetCashAfterTaxRealArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.netCashAfterTaxReal;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.netFuelMargin) {
                    this.investmentMinus10PercentNetFuelMarginArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.netFuelMargin;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.otherCashItems) {
                    this.investmentMinus10PercentOtherCashItemsArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.otherCashItems;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.otherCashItemsReal) {
                    this.investmentMinus10PercentOtherCashItemsRealArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.otherCashItemsReal;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.realCompanyCashBalance) {
                    this.investmentMinus10PercentRealCompanyCashBalanceArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.realCompanyCashBalance;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.taxAmount) {
                    this.investmentMinus10PercentTaxAmountArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.taxAmount;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.taxAmountReal) {
                    this.investmentMinus10PercentTaxAmountRealArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.taxAmountReal;
                  };
                  if (res.investmentMinus10Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms) {
                    this.investmentMinus10PercentWorkingCapitalTerminalValueNominalTermsArray = res.investmentMinus10Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms;
                  };
                };

              };
              if (res.investmentMinus10Percent.fuelVolumeAndMarginGrowth) {
                if (res.investmentMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume) {
                  this.investmentMinus10FuelVolumeArray = res.investmentMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume;
                }
                if (res.investmentMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin) {
                  this.investmentMinus10FuelMarginArray = res.investmentMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin;
                }
                if (res.investmentMinus10Percent.fuelVolumeAndMarginGrowth.gasMargin) {
                  this.investmentMinus10GasMarginArray = res.investmentMinus10Percent.fuelVolumeAndMarginGrowth.gasMargin;
                }
                if (res.investmentMinus10Percent.fuelVolumeAndMarginGrowth.gasVolume) {
                  this.investmentMinus10GasVolumeArray = res.investmentMinus10Percent.fuelVolumeAndMarginGrowth.gasVolume;
                }
                if (res.investmentMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume) {
                  this.investmentMinus10FuelAndLubricantMarginByVolume = res.investmentMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume;
                }
                if (res.investmentMinus10Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume) {
                  this.investmentMinus10GasMarginByVolumeArray = res.investmentMinus10Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume;
                }

              }

              if (res.investmentMinus10Percent.internalRateOfReturn) {
                this.investmentMinus10IRR = res.investmentMinus10Percent.internalRateOfReturn;
              }
              if (res.investmentMinus10Percent.netPresentValue) {
                this.investmentMinus10NPV = res.investmentMinus10Percent.netPresentValue;
              }
              if (res.investmentMinus10Percent.paybackPeriod) {
                this.investmentMinus10PaybackPeriod = res.investmentMinus10Percent.paybackPeriod;
              }
              if (res.investmentMinus10Percent.paybackPeriodDiscounted) {
                this.investmentMinus10PaybackPeriodDiscounted = res.investmentMinus10Percent.paybackPeriodDiscounted;
              }
            };
            if (res.investmentPlus10Percent) {
              if (res.investmentPlus10Percent.companySummary) {
                if (res.investmentPlus10Percent.companySummary.yearlyEscalation) {
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.changeInWorkingCapital) {
                    this.investmentPlus10PercentChangeInWorkingCapitalArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.changeInWorkingCapital;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal) {
                    this.investmentPlus10PercentChangeInWorkingCapitalRealArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.companyDiversificationMargin) {
                    this.investmentPlus10PercentCompanyDiversificationMarginArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.companyDiversificationMargin;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.companyInterestReceived) {
                    this.investmentPlus10PercentCompanyInterestReceivedArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.companyInterestReceived;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.companyInvestment) {
                    this.investmentPlus10PercentCompanyInvestmentArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.companyInvestment;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.companyInvestmentReal) {
                    this.investmentPlus10PercentCompanyInvestmentRealArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.companyInvestmentReal;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.companyOperatingExpenses) {
                    this.investmentPlus10PercentCompanyOperatingExpensesArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.companyOperatingExpenses;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.companyOtherRevenue) {
                    this.investmentPlus10PercentCompanyOtherRevenueArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.companyOtherRevenue;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue) {
                    this.investmentPlus10PercentCompanyRentalsAndFeesDueArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount) {
                    this.investmentPlus10PercentDealerLoanInterestAmountArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal) {
                    this.investmentPlus10PercentDiscountedNetCashAfterTaxRealArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.ebitda) {
                    this.investmentPlus10PercentEbitdaArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.ebitda;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.ebitdaReal) {
                    this.investmentPlus10PercentEbitdaRealArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.ebitdaReal;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.financialResult) {
                    this.investmentPlus10PercentFinancialResultArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.financialResult;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.financingInterestPaid) {
                    this.investmentPlus10PercentFinancingInterestPaidArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.financingInterestPaid;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.inflationRate) {
                    this.investmentPlus10PercentInflationRateArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.inflationRate;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.netCashAfterTax) {
                    this.investmentPlus10PercentNetCashAfterTaxArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.netCashAfterTax;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted) {
                    this.investmentPlus10PercentNetCashAfterTaxDiscountedArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.netCashAfterTaxReal) {
                    this.investmentPlus10PercentNetCashAfterTaxRealArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.netCashAfterTaxReal;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.netFuelMargin) {
                    this.investmentPlus10PercentNetFuelMarginArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.netFuelMargin;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.otherCashItems) {
                    this.investmentPlus10PercentOtherCashItemsArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.otherCashItems;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.otherCashItemsReal) {
                    this.investmentPlus10PercentOtherCashItemsRealArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.otherCashItemsReal;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.realCompanyCashBalance) {
                    this.investmentPlus10PercentRealCompanyCashBalanceArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.realCompanyCashBalance;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.taxAmount) {
                    this.investmentPlus10PercentTaxAmountArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.taxAmount;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.taxAmountReal) {
                    this.investmentPlus10PercentTaxAmountRealArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.taxAmountReal;
                  };
                  if (res.investmentPlus10Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms) {
                    this.investmentPlus10PercentWorkingCapitalTerminalValueNominalTermsArray = res.investmentPlus10Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms;
                  };
                };
              };
              if (res.investmentPlus10Percent.fuelVolumeAndMarginGrowth) {
                if (res.investmentPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume) {
                  this.investmentPlus10FuelVolumeArray = res.investmentPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume;
                }
                if (res.investmentPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin) {
                  this.investmentPlus10FuelMarginArray = res.investmentPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin;
                }
                if (res.investmentPlus10Percent.fuelVolumeAndMarginGrowth.gasMargin) {
                  this.investmentPlus10GasMarginArray = res.investmentPlus10Percent.fuelVolumeAndMarginGrowth.gasMargin;
                }
                if (res.investmentPlus10Percent.fuelVolumeAndMarginGrowth.gasVolume) {
                  this.investmentPlus10GasVolumeArray = res.investmentPlus10Percent.fuelVolumeAndMarginGrowth.gasVolume;
                }
                if (res.investmentPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume) {
                  this.investmentPlus10FuelAndLubricantMarginByVolume = res.investmentPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume;
                }
                if (res.investmentPlus10Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume) {
                  this.investmentPlus10GasMarginByVolumeArray = res.investmentPlus10Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume;
                }
              }
              if (res.investmentPlus10Percent.internalRateOfReturn) {
                this.investmentPlus10IRR = res.investmentPlus10Percent.internalRateOfReturn;
              }
              if (res.investmentPlus10Percent.netPresentValue) {
                this.investmentPlus10NPV = res.investmentPlus10Percent.netPresentValue;
              }
              if (res.investmentPlus10Percent.paybackPeriod) {
                this.investmentPlus10PaybackPeriod = res.investmentPlus10Percent.paybackPeriod;
              }
              if (res.investmentPlus10Percent.paybackPeriodDiscounted) {
                this.investmentPlus10PaybackPeriodDiscounted = res.investmentPlus10Percent.paybackPeriodDiscounted;
              }

            };
            if (res.marginMinus20Percent) {
              if (res.marginMinus20Percent.companySummary) {
                if (res.marginMinus20Percent.companySummary.yearlyEscalation) {
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.changeInWorkingCapital) {
                    this.marginMinus20PercentChangeInWorkingCapitalArray = res.marginMinus20Percent.companySummary.yearlyEscalation.changeInWorkingCapital;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal) {
                    this.marginMinus20PercentChangeInWorkingCapitalRealArray = res.marginMinus20Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.companyDiversificationMargin) {
                    this.marginMinus20PercentCompanyDiversificationMarginArray = res.marginMinus20Percent.companySummary.yearlyEscalation.companyDiversificationMargin;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.companyInterestReceived) {
                    this.marginMinus20PercentCompanyInterestReceivedArray = res.marginMinus20Percent.companySummary.yearlyEscalation.companyInterestReceived;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.companyInvestment) {
                    this.marginMinus20PercentCompanyInvestmentArray = res.marginMinus20Percent.companySummary.yearlyEscalation.companyInvestment;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.companyInvestmentReal) {
                    this.marginMinus20PercentCompanyInvestmentRealArray = res.marginMinus20Percent.companySummary.yearlyEscalation.companyInvestmentReal;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.companyOperatingExpenses) {
                    this.marginMinus20PercentCompanyOperatingExpensesArray = res.marginMinus20Percent.companySummary.yearlyEscalation.companyOperatingExpenses;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.companyOtherRevenue) {
                    this.marginMinus20PercentCompanyOtherRevenueArray = res.marginMinus20Percent.companySummary.yearlyEscalation.companyOtherRevenue;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue) {
                    this.marginMinus20PercentCompanyRentalsAndFeesDueArray = res.marginMinus20Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount) {
                    this.marginMinus20PercentDealerLoanInterestAmountArray = res.marginMinus20Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal) {
                    this.marginMinus20PercentDiscountedNetCashAfterTaxRealArray = res.marginMinus20Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.ebitda) {
                    this.marginMinus20PercentEbitdaArray = res.marginMinus20Percent.companySummary.yearlyEscalation.ebitda;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.ebitdaReal) {
                    this.marginMinus20PercentEbitdaRealArray = res.marginMinus20Percent.companySummary.yearlyEscalation.ebitdaReal;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.financialResult) {
                    this.marginMinus20PercentFinancialResultArray = res.marginMinus20Percent.companySummary.yearlyEscalation.financialResult;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.financingInterestPaid) {
                    this.marginMinus20PercentFinancingInterestPaidArray = res.marginMinus20Percent.companySummary.yearlyEscalation.financingInterestPaid;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.inflationRate) {
                    this.marginMinus20PercentInflationRateArray = res.marginMinus20Percent.companySummary.yearlyEscalation.inflationRate;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.netCashAfterTax) {
                    this.marginMinus20PercentNetCashAfterTaxArray = res.marginMinus20Percent.companySummary.yearlyEscalation.netCashAfterTax;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted) {
                    this.marginMinus20PercentNetCashAfterTaxDiscountedArray = res.marginMinus20Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.netCashAfterTaxReal) {
                    this.marginMinus20PercentNetCashAfterTaxRealArray = res.marginMinus20Percent.companySummary.yearlyEscalation.netCashAfterTaxReal;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.netFuelMargin) {
                    this.marginMinus20PercentNetFuelMarginArray = res.marginMinus20Percent.companySummary.yearlyEscalation.netFuelMargin;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.otherCashItems) {
                    this.marginMinus20PercentOtherCashItemsArray = res.marginMinus20Percent.companySummary.yearlyEscalation.otherCashItems;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.otherCashItemsReal) {
                    this.marginMinus20PercentOtherCashItemsRealArray = res.marginMinus20Percent.companySummary.yearlyEscalation.otherCashItemsReal;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.realCompanyCashBalance) {
                    this.marginMinus20PercentRealCompanyCashBalanceArray = res.marginMinus20Percent.companySummary.yearlyEscalation.realCompanyCashBalance;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.taxAmount) {
                    this.marginMinus20PercentTaxAmountArray = res.marginMinus20Percent.companySummary.yearlyEscalation.taxAmount;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.taxAmountReal) {
                    this.marginMinus20PercentTaxAmountRealArray = res.marginMinus20Percent.companySummary.yearlyEscalation.taxAmountReal;
                  };
                  if (res.marginMinus20Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms) {
                    this.marginMinus20PercentWorkingCapitalTerminalValueNominalTermsArray = res.marginMinus20Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms;
                  };
                };
              };
              if (res.marginMinus20Percent.fuelVolumeAndMarginGrowth) {
                if (res.marginMinus20Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume) {
                  this.marginMinus20FuelVolumeArray = res.marginMinus20Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume;
                }
                if (res.marginMinus20Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin) {
                  this.marginMinus20FuelMarginArray = res.marginMinus20Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin;
                }
                if (res.marginMinus20Percent.fuelVolumeAndMarginGrowth.gasMargin) {
                  this.marginMinus20GasMarginArray = res.marginMinus20Percent.fuelVolumeAndMarginGrowth.gasMargin;
                }
                if (res.marginMinus20Percent.fuelVolumeAndMarginGrowth.gasVolume) {
                  this.marginMinus20GasVolumeArray = res.marginMinus20Percent.fuelVolumeAndMarginGrowth.gasVolume;
                }
                if (res.marginMinus20Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume) {
                  this.marginMinus20FuelAndLubricantMarginByVolume = res.marginMinus20Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume;
                }
                if (res.marginMinus20Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume) {
                  this.marginMinus20GasMarginByVolumeArray = res.marginMinus20Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume;
                }
              }
              if (res.marginMinus20Percent.internalRateOfReturn) {
                this.marginMinus20IRR = res.marginMinus20Percent.internalRateOfReturn;
              }
              if (res.marginMinus20Percent.netPresentValue) {
                this.marginMinus20NPV = res.marginMinus20Percent.netPresentValue;
              }
              if (res.marginMinus20Percent.paybackPeriod) {
                this.marginMinus20PaybackPeriod = res.marginMinus20Percent.paybackPeriod;
              }
              if (res.marginMinus20Percent.paybackPeriodDiscounted) {
                this.marginMinus20PaybackPeriodDiscounted = res.marginMinus20Percent.paybackPeriodDiscounted;
              }
            };
            if (res.marginPlus20Percent) {
              if (res.marginPlus20Percent.companySummary) {
                if (res.marginPlus20Percent.companySummary.yearlyEscalation) {
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.changeInWorkingCapital) {
                    this.marginPlus20PercentChangeInWorkingCapitalArray = res.marginPlus20Percent.companySummary.yearlyEscalation.changeInWorkingCapital;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal) {
                    this.marginPlus20PercentChangeInWorkingCapitalRealArray = res.marginPlus20Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.companyDiversificationMargin) {
                    this.marginPlus20PercentCompanyDiversificationMarginArray = res.marginPlus20Percent.companySummary.yearlyEscalation.companyDiversificationMargin;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.companyInterestReceived) {
                    this.marginPlus20PercentCompanyInterestReceivedArray = res.marginPlus20Percent.companySummary.yearlyEscalation.companyInterestReceived;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.companyInvestment) {
                    this.marginPlus20PercentCompanyInvestmentArray = res.marginPlus20Percent.companySummary.yearlyEscalation.companyInvestment;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.companyInvestmentReal) {
                    this.marginPlus20PercentCompanyInvestmentRealArray = res.marginPlus20Percent.companySummary.yearlyEscalation.companyInvestmentReal;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.companyOperatingExpenses) {
                    this.marginPlus20PercentCompanyOperatingExpensesArray = res.marginPlus20Percent.companySummary.yearlyEscalation.companyOperatingExpenses;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.companyOtherRevenue) {
                    this.marginPlus20PercentCompanyOtherRevenueArray = res.marginPlus20Percent.companySummary.yearlyEscalation.companyOtherRevenue;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue) {
                    this.marginPlus20PercentCompanyRentalsAndFeesDueArray = res.marginPlus20Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount) {
                    this.marginPlus20PercentDealerLoanInterestAmountArray = res.marginPlus20Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal) {
                    this.marginPlus20PercentDiscountedNetCashAfterTaxRealArray = res.marginPlus20Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.ebitda) {
                    this.marginPlus20PercentEbitdaArray = res.marginPlus20Percent.companySummary.yearlyEscalation.ebitda;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.ebitdaReal) {
                    this.marginPlus20PercentEbitdaRealArray = res.marginPlus20Percent.companySummary.yearlyEscalation.ebitdaReal;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.financialResult) {
                    this.marginPlus20PercentFinancialResultArray = res.marginPlus20Percent.companySummary.yearlyEscalation.financialResult;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.financingInterestPaid) {
                    this.marginPlus20PercentFinancingInterestPaidArray = res.marginPlus20Percent.companySummary.yearlyEscalation.financingInterestPaid;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.inflationRate) {
                    this.marginPlus20PercentInflationRateArray = res.marginPlus20Percent.companySummary.yearlyEscalation.inflationRate;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.netCashAfterTax) {
                    this.marginPlus20PercentNetCashAfterTaxArray = res.marginPlus20Percent.companySummary.yearlyEscalation.netCashAfterTax;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted) {
                    this.marginPlus20PercentNetCashAfterTaxDiscountedArray = res.marginPlus20Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.netCashAfterTaxReal) {
                    this.marginPlus20PercentNetCashAfterTaxRealArray = res.marginPlus20Percent.companySummary.yearlyEscalation.netCashAfterTaxReal;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.netFuelMargin) {
                    this.marginPlus20PercentNetFuelMarginArray = res.marginPlus20Percent.companySummary.yearlyEscalation.netFuelMargin;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.otherCashItems) {
                    this.marginPlus20PercentOtherCashItemsArray = res.marginPlus20Percent.companySummary.yearlyEscalation.otherCashItems;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.otherCashItemsReal) {
                    this.marginPlus20PercentOtherCashItemsRealArray = res.marginPlus20Percent.companySummary.yearlyEscalation.otherCashItemsReal;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.realCompanyCashBalance) {
                    this.marginPlus20PercentRealCompanyCashBalanceArray = res.marginPlus20Percent.companySummary.yearlyEscalation.realCompanyCashBalance;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.taxAmount) {
                    this.marginPlus20PercentTaxAmountArray = res.marginPlus20Percent.companySummary.yearlyEscalation.taxAmount;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.taxAmountReal) {
                    this.marginPlus20PercentTaxAmountRealArray = res.marginPlus20Percent.companySummary.yearlyEscalation.taxAmountReal;
                  };
                  if (res.marginPlus20Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms) {
                    this.marginPlus20PercentWorkingCapitalTerminalValueNominalTermsArray = res.marginPlus20Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms;
                  };
                };
              };
              if (res.marginPlus20Percent.fuelVolumeAndMarginGrowth) {
                if (res.marginPlus20Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume) {
                  this.marginPlus20FuelVolumeArray = res.marginPlus20Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume;
                }
                if (res.marginPlus20Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin) {
                  this.marginPlus20FuelMarginArray = res.marginPlus20Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin;
                }
                if (res.marginPlus20Percent.fuelVolumeAndMarginGrowth.gasMargin) {
                  this.marginPlus20GasMarginArray = res.marginPlus20Percent.fuelVolumeAndMarginGrowth.gasMargin;
                }
                if (res.marginPlus20Percent.fuelVolumeAndMarginGrowth.gasVolume) {
                  this.marginPlus20GasVolumeArray = res.marginPlus20Percent.fuelVolumeAndMarginGrowth.gasVolume;
                }
                if (res.marginPlus20Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume) {
                  this.marginPlus20FuelAndLubricantMarginByVolume = res.marginPlus20Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume;
                }
                if (res.marginPlus20Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume) {
                  this.marginPlus20GasMarginByVolumeArray = res.marginPlus20Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume;
                }
              }
              if (res.marginPlus20Percent.internalRateOfReturn) {
                this.marginPlus20IRR = res.marginPlus20Percent.internalRateOfReturn;
              }
              if (res.marginPlus20Percent.netPresentValue) {
                this.marginPlus20NPV = res.marginPlus20Percent.netPresentValue;
              }
              if (res.marginPlus20Percent.paybackPeriod) {
                this.marginPlus20PaybackPeriod = res.marginPlus20Percent.paybackPeriod;
              }
              if (res.marginPlus20Percent.paybackPeriodDiscounted) {
                this.marginPlus20PaybackPeriodDiscounted = res.marginPlus20Percent.paybackPeriodDiscounted;
              }
            };
            if (res.operatingCostMinus10Percent) {
              if (res.operatingCostMinus10Percent.companySummary) {
                if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation) {
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.changeInWorkingCapital) {
                    this.operatingCostMinus10PercentChangeInWorkingCapitalArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.changeInWorkingCapital;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal) {
                    this.operatingCostMinus10PercentChangeInWorkingCapitalRealArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.companyDiversificationMargin) {
                    this.operatingCostMinus10PercentCompanyDiversificationMarginArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.companyDiversificationMargin;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.companyInterestReceived) {
                    this.operatingCostMinus10PercentCompanyInterestReceivedArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.companyInterestReceived;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.companyInvestment) {
                    this.operatingCostMinus10PercentCompanyInvestmentArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.companyInvestment;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.companyInvestmentReal) {
                    this.operatingCostMinus10PercentCompanyInvestmentRealArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.companyInvestmentReal;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.companyOperatingExpenses) {
                    this.operatingCostMinus10PercentCompanyOperatingExpensesArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.companyOperatingExpenses;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.companyOtherRevenue) {
                    this.operatingCostMinus10PercentCompanyOtherRevenueArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.companyOtherRevenue;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue) {
                    this.operatingCostMinus10PercentCompanyRentalsAndFeesDueArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount) {
                    this.operatingCostMinus10PercentDealerLoanInterestAmountArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal) {
                    this.operatingCostMinus10PercentDiscountedNetCashAfterTaxRealArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.ebitda) {
                    this.operatingCostMinus10PercentEbitdaArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.ebitda;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.ebitdaReal) {
                    this.operatingCostMinus10PercentEbitdaRealArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.ebitdaReal;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.financialResult) {
                    this.operatingCostMinus10PercentFinancialResultArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.financialResult;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.financingInterestPaid) {
                    this.operatingCostMinus10PercentFinancingInterestPaidArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.financingInterestPaid;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.inflationRate) {
                    this.operatingCostMinus10PercentInflationRateArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.inflationRate;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.netCashAfterTax) {
                    this.operatingCostMinus10PercentNetCashAfterTaxArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.netCashAfterTax;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted) {
                    this.operatingCostMinus10PercentNetCashAfterTaxDiscountedArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.netCashAfterTaxReal) {
                    this.operatingCostMinus10PercentNetCashAfterTaxRealArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.netCashAfterTaxReal;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.netFuelMargin) {
                    this.operatingCostMinus10PercentNetFuelMarginArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.netFuelMargin;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.otherCashItems) {
                    this.operatingCostMinus10PercentOtherCashItemsArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.otherCashItems;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.otherCashItemsReal) {
                    this.operatingCostMinus10PercentOtherCashItemsRealArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.otherCashItemsReal;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.realCompanyCashBalance) {
                    this.operatingCostMinus10PercentRealCompanyCashBalanceArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.realCompanyCashBalance;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.taxAmount) {
                    this.operatingCostMinus10PercentTaxAmountArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.taxAmount;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.taxAmountReal) {
                    this.operatingCostMinus10PercentTaxAmountRealArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.taxAmountReal;
                  };
                  if (res.operatingCostMinus10Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms) {
                    this.operatingCostMinus10PercentWorkingCapitalTerminalValueNominalTermsArray = res.operatingCostMinus10Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms;
                  };
                };
              };
              if (res.operatingCostMinus10Percent.fuelVolumeAndMarginGrowth) {
                if (res.operatingCostMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume) {
                  this.operatingCostMinus10FuelVolumeArray = res.operatingCostMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume;
                }
                if (res.operatingCostMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin) {
                  this.operatingCostMinus10FuelMarginArray = res.operatingCostMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin;
                }
                if (res.operatingCostMinus10Percent.fuelVolumeAndMarginGrowth.gasMargin) {
                  this.operatingCostMinus10GasMarginArray = res.operatingCostMinus10Percent.fuelVolumeAndMarginGrowth.gasMargin;
                }
                if (res.operatingCostMinus10Percent.fuelVolumeAndMarginGrowth.gasVolume) {
                  this.operatingCostMinus10GasVolumeArray = res.operatingCostMinus10Percent.fuelVolumeAndMarginGrowth.gasVolume;
                }
                if (res.operatingCostMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume) {
                  this.operatingCostMinus10FuelAndLubricantMarginByVolume = res.operatingCostMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume;
                }
                if (res.operatingCostMinus10Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume) {
                  this.operatingCostMinus10GasMarginByVolumeArray = res.operatingCostMinus10Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume;
                }
              }
              if (res.operatingCostMinus10Percent.internalRateOfReturn) {
                this.operatingCostMinus10IRR = res.operatingCostMinus10Percent.internalRateOfReturn;
              }
              if (res.operatingCostMinus10Percent.netPresentValue) {
                this.operatingCostMinus10NPV = res.operatingCostMinus10Percent.netPresentValue;
              }
              if (res.operatingCostMinus10Percent.paybackPeriod) {
                this.operatingCostMinus10PaybackPeriod = res.operatingCostMinus10Percent.paybackPeriod;
              }
              if (res.operatingCostMinus10Percent.paybackPeriodDiscounted) {
                this.operatingCostMinus10PaybackPeriodDiscounted = res.operatingCostMinus10Percent.paybackPeriodDiscounted;
              }
            };
            if (res.operatingCostPlus10Percent) {
              if (res.operatingCostPlus10Percent.companySummary) {
                if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation) {
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.changeInWorkingCapital) {
                    this.operatingCostPlus10PercentChangeInWorkingCapitalArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.changeInWorkingCapital;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal) {
                    this.operatingCostPlus10PercentChangeInWorkingCapitalRealArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.companyDiversificationMargin) {
                    this.operatingCostPlus10PercentCompanyDiversificationMarginArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.companyDiversificationMargin;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.companyInterestReceived) {
                    this.operatingCostPlus10PercentCompanyInterestReceivedArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.companyInterestReceived;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.companyInvestment) {
                    this.operatingCostPlus10PercentCompanyInvestmentArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.companyInvestment;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.companyInvestmentReal) {
                    this.operatingCostPlus10PercentCompanyInvestmentRealArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.companyInvestmentReal;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.companyOperatingExpenses) {
                    this.operatingCostPlus10PercentCompanyOperatingExpensesArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.companyOperatingExpenses;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.companyOtherRevenue) {
                    this.operatingCostPlus10PercentCompanyOtherRevenueArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.companyOtherRevenue;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue) {
                    this.operatingCostPlus10PercentCompanyRentalsAndFeesDueArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount) {
                    this.operatingCostPlus10PercentDealerLoanInterestAmountArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal) {
                    this.operatingCostPlus10PercentDiscountedNetCashAfterTaxRealArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.ebitda) {
                    this.operatingCostPlus10PercentEbitdaArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.ebitda;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.ebitdaReal) {
                    this.operatingCostPlus10PercentEbitdaRealArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.ebitdaReal;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.financialResult) {
                    this.operatingCostPlus10PercentFinancialResultArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.financialResult;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.financingInterestPaid) {
                    this.operatingCostPlus10PercentFinancingInterestPaidArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.financingInterestPaid;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.inflationRate) {
                    this.operatingCostPlus10PercentInflationRateArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.inflationRate;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.netCashAfterTax) {
                    this.operatingCostPlus10PercentNetCashAfterTaxArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.netCashAfterTax;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted) {
                    this.operatingCostPlus10PercentNetCashAfterTaxDiscountedArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.netCashAfterTaxReal) {
                    this.operatingCostPlus10PercentNetCashAfterTaxRealArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.netCashAfterTaxReal;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.netFuelMargin) {
                    this.operatingCostPlus10PercentNetFuelMarginArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.netFuelMargin;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.otherCashItems) {
                    this.operatingCostPlus10PercentOtherCashItemsArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.otherCashItems;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.otherCashItemsReal) {
                    this.operatingCostPlus10PercentOtherCashItemsRealArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.otherCashItemsReal;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.realCompanyCashBalance) {
                    this.operatingCostPlus10PercentRealCompanyCashBalanceArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.realCompanyCashBalance;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.taxAmount) {
                    this.operatingCostPlus10PercentTaxAmountArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.taxAmount;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.taxAmountReal) {
                    this.operatingCostPlus10PercentTaxAmountRealArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.taxAmountReal;
                  };
                  if (res.operatingCostPlus10Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms) {
                    this.operatingCostPlus10PercentWorkingCapitalTerminalValueNominalTermsArray = res.operatingCostPlus10Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms;
                  };
                };
              };
              if (res.operatingCostPlus10Percent.fuelVolumeAndMarginGrowth) {
                if (res.operatingCostPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume) {
                  this.operatingCostPlus10FuelVolumeArray = res.operatingCostPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume;
                }
                if (res.operatingCostPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin) {
                  this.operatingCostPlus10FuelMarginArray = res.operatingCostPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin;
                }
                if (res.operatingCostPlus10Percent.fuelVolumeAndMarginGrowth.gasMargin) {
                  this.operatingCostPlus10GasMarginArray = res.operatingCostPlus10Percent.fuelVolumeAndMarginGrowth.gasMargin;
                }
                if (res.operatingCostPlus10Percent.fuelVolumeAndMarginGrowth.gasVolume) {
                  this.operatingCostPlus10GasVolumeArray = res.operatingCostPlus10Percent.fuelVolumeAndMarginGrowth.gasVolume;
                }
                if (res.operatingCostPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume) {
                  this.operatingCostPlus10FuelAndLubricantMarginByVolume = res.operatingCostPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume;
                }
                if (res.operatingCostPlus10Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume) {
                  this.operatingCostPlus10GasMarginByVolumeArray = res.operatingCostPlus10Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume;
                }
              }
              if (res.operatingCostPlus10Percent.internalRateOfReturn) {
                this.operatingCostPlus10IRR = res.operatingCostPlus10Percent.internalRateOfReturn;
              }
              if (res.operatingCostPlus10Percent.netPresentValue) {
                this.operatingCostPlus10NPV = res.operatingCostPlus10Percent.netPresentValue;
              }
              if (res.operatingCostPlus10Percent.paybackPeriod) {
                this.operatingCostPlus10PaybackPeriod = res.operatingCostPlus10Percent.paybackPeriod;
              }
              if (res.operatingCostPlus10Percent.paybackPeriodDiscounted) {
                this.operatingCostPlus10PaybackPeriodDiscounted = res.operatingCostPlus10Percent.paybackPeriodDiscounted;
              }
            };
            if (res.volumeMinus15Percent) {
              if (res.volumeMinus15Percent.companySummary) {
                if (res.volumeMinus15Percent.companySummary.yearlyEscalation) {
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.changeInWorkingCapital) {
                    this.volumeMinus15PercentChangeInWorkingCapitalArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.changeInWorkingCapital;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal) {
                    this.volumeMinus15PercentChangeInWorkingCapitalRealArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.companyDiversificationMargin) {
                    this.volumeMinus15PercentCompanyDiversificationMarginArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.companyDiversificationMargin;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.companyInterestReceived) {
                    this.volumeMinus15PercentCompanyInterestReceivedArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.companyInterestReceived;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.companyInvestment) {
                    this.volumeMinus15PercentCompanyInvestmentArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.companyInvestment;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.companyInvestmentReal) {
                    this.volumeMinus15PercentCompanyInvestmentRealArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.companyInvestmentReal;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.companyOperatingExpenses) {
                    this.volumeMinus15PercentCompanyOperatingExpensesArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.companyOperatingExpenses;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.companyOtherRevenue) {
                    this.volumeMinus15PercentCompanyOtherRevenueArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.companyOtherRevenue;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue) {
                    this.volumeMinus15PercentCompanyRentalsAndFeesDueArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount) {
                    this.volumeMinus15PercentDealerLoanInterestAmountArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal) {
                    this.volumeMinus15PercentDiscountedNetCashAfterTaxRealArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.ebitda) {
                    this.volumeMinus15PercentEbitdaArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.ebitda;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.ebitdaReal) {
                    this.volumeMinus15PercentEbitdaRealArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.ebitdaReal;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.financialResult) {
                    this.volumeMinus15PercentFinancialResultArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.financialResult;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.financingInterestPaid) {
                    this.volumeMinus15PercentFinancingInterestPaidArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.financingInterestPaid;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.inflationRate) {
                    this.volumeMinus15PercentInflationRateArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.inflationRate;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.netCashAfterTax) {
                    this.volumeMinus15PercentNetCashAfterTaxArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.netCashAfterTax;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted) {
                    this.volumeMinus15PercentNetCashAfterTaxDiscountedArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.netCashAfterTaxReal) {
                    this.volumeMinus15PercentNetCashAfterTaxRealArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.netCashAfterTaxReal;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.netFuelMargin) {
                    this.volumeMinus15PercentNetFuelMarginArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.netFuelMargin;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.otherCashItems) {
                    this.volumeMinus15PercentOtherCashItemsArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.otherCashItems;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.otherCashItemsReal) {
                    this.volumeMinus15PercentOtherCashItemsRealArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.otherCashItemsReal;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.realCompanyCashBalance) {
                    this.volumeMinus15PercentRealCompanyCashBalanceArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.realCompanyCashBalance;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.taxAmount) {
                    this.volumeMinus15PercentTaxAmountArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.taxAmount;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.taxAmountReal) {
                    this.volumeMinus15PercentTaxAmountRealArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.taxAmountReal;
                  };
                  if (res.volumeMinus15Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms) {
                    this.volumeMinus15PercentWorkingCapitalTerminalValueNominalTermsArray = res.volumeMinus15Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms;
                  };
                };
              };
              if (res.volumeMinus15Percent.fuelVolumeAndMarginGrowth) {
                if (res.volumeMinus15Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume) {
                  this.volumeMinus15FuelVolumeArray = res.volumeMinus15Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume;
                }
                if (res.volumeMinus15Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin) {
                  this.volumeMinus15FuelMarginArray = res.volumeMinus15Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin;
                }
                if (res.volumeMinus15Percent.fuelVolumeAndMarginGrowth.gasMargin) {
                  this.volumeMinus15GasMarginArray = res.volumeMinus15Percent.fuelVolumeAndMarginGrowth.gasMargin;
                }
                if (res.volumeMinus15Percent.fuelVolumeAndMarginGrowth.gasVolume) {
                  this.volumeMinus15GasVolumeArray = res.volumeMinus15Percent.fuelVolumeAndMarginGrowth.gasVolume;
                }
                if (res.volumeMinus15Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume) {
                  this.volumeMinus15FuelAndLubricantMarginByVolume = res.volumeMinus15Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume;
                }
                if (res.volumeMinus15Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume) {
                  this.volumeMinus15GasMarginByVolumeArray = res.volumeMinus15Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume;
                }
              }
              if (res.volumeMinus15Percent.internalRateOfReturn) {
                this.volumeMinus15IRR = res.volumeMinus15Percent.internalRateOfReturn;
              }
              if (res.volumeMinus15Percent.netPresentValue) {
                this.volumeMinus15NPV = res.volumeMinus15Percent.netPresentValue;
              }
              if (res.volumeMinus15Percent.paybackPeriod) {
                this.volumeMinus15PaybackPeriod = res.volumeMinus15Percent.paybackPeriod;
              }
              if (res.volumeMinus15Percent.paybackPeriodDiscounted) {
                this.volumeMinus15PaybackPeriodDiscounted = res.volumeMinus15Percent.paybackPeriodDiscounted;
              }
            };
            if (res.volumePlus15Percent) {
              if (res.volumePlus15Percent.companySummary) {
                if (res.volumePlus15Percent.companySummary.yearlyEscalation) {
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.changeInWorkingCapital) {
                    this.volumePlus15PercentChangeInWorkingCapitalArray = res.volumePlus15Percent.companySummary.yearlyEscalation.changeInWorkingCapital;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal) {
                    this.volumePlus15PercentChangeInWorkingCapitalRealArray = res.volumePlus15Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.companyDiversificationMargin) {
                    this.volumePlus15PercentCompanyDiversificationMarginArray = res.volumePlus15Percent.companySummary.yearlyEscalation.companyDiversificationMargin;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.companyInterestReceived) {
                    this.volumePlus15PercentCompanyInterestReceivedArray = res.volumePlus15Percent.companySummary.yearlyEscalation.companyInterestReceived;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.companyInvestment) {
                    this.volumePlus15PercentCompanyInvestmentArray = res.volumePlus15Percent.companySummary.yearlyEscalation.companyInvestment;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.companyInvestmentReal) {
                    this.volumePlus15PercentCompanyInvestmentRealArray = res.volumePlus15Percent.companySummary.yearlyEscalation.companyInvestmentReal;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.companyOperatingExpenses) {
                    this.volumePlus15PercentCompanyOperatingExpensesArray = res.volumePlus15Percent.companySummary.yearlyEscalation.companyOperatingExpenses;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.companyOtherRevenue) {
                    this.volumePlus15PercentCompanyOtherRevenueArray = res.volumePlus15Percent.companySummary.yearlyEscalation.companyOtherRevenue;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue) {
                    this.volumePlus15PercentCompanyRentalsAndFeesDueArray = res.volumePlus15Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount) {
                    this.volumePlus15PercentDealerLoanInterestAmountArray = res.volumePlus15Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal) {
                    this.volumePlus15PercentDiscountedNetCashAfterTaxRealArray = res.volumePlus15Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.ebitda) {
                    this.volumePlus15PercentEbitdaArray = res.volumePlus15Percent.companySummary.yearlyEscalation.ebitda;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.ebitdaReal) {
                    this.volumePlus15PercentEbitdaRealArray = res.volumePlus15Percent.companySummary.yearlyEscalation.ebitdaReal;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.financialResult) {
                    this.volumePlus15PercentFinancialResultArray = res.volumePlus15Percent.companySummary.yearlyEscalation.financialResult;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.financingInterestPaid) {
                    this.volumePlus15PercentFinancingInterestPaidArray = res.volumePlus15Percent.companySummary.yearlyEscalation.financingInterestPaid;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.inflationRate) {
                    this.volumePlus15PercentInflationRateArray = res.volumePlus15Percent.companySummary.yearlyEscalation.inflationRate;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.netCashAfterTax) {
                    this.volumePlus15PercentNetCashAfterTaxArray = res.volumePlus15Percent.companySummary.yearlyEscalation.netCashAfterTax;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted) {
                    this.volumePlus15PercentNetCashAfterTaxDiscountedArray = res.volumePlus15Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.netCashAfterTaxReal) {
                    this.volumePlus15PercentNetCashAfterTaxRealArray = res.volumePlus15Percent.companySummary.yearlyEscalation.netCashAfterTaxReal;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.netFuelMargin) {
                    this.volumePlus15PercentNetFuelMarginArray = res.volumePlus15Percent.companySummary.yearlyEscalation.netFuelMargin;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.otherCashItems) {
                    this.volumePlus15PercentOtherCashItemsArray = res.volumePlus15Percent.companySummary.yearlyEscalation.otherCashItems;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.otherCashItemsReal) {
                    this.volumePlus15PercentOtherCashItemsRealArray = res.volumePlus15Percent.companySummary.yearlyEscalation.otherCashItemsReal;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.realCompanyCashBalance) {
                    this.volumePlus15PercentRealCompanyCashBalanceArray = res.volumePlus15Percent.companySummary.yearlyEscalation.realCompanyCashBalance;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.taxAmount) {
                    this.volumePlus15PercentTaxAmountArray = res.volumePlus15Percent.companySummary.yearlyEscalation.taxAmount;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.taxAmountReal) {
                    this.volumePlus15PercentTaxAmountRealArray = res.volumePlus15Percent.companySummary.yearlyEscalation.taxAmountReal;
                  };
                  if (res.volumePlus15Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms) {
                    this.volumePlus15PercentWorkingCapitalTerminalValueNominalTermsArray = res.volumePlus15Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms;
                  };
                };
              };
              if (res.volumePlus15Percent.fuelVolumeAndMarginGrowth) {
                if (res.volumePlus15Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume) {
                  this.volumePlus15FuelVolumeArray = res.volumePlus15Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume;
                }
                if (res.volumePlus15Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin) {
                  this.volumePlus15FuelMarginArray = res.volumePlus15Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin;
                }
                if (res.volumePlus15Percent.fuelVolumeAndMarginGrowth.gasMargin) {
                  this.volumePlus15GasMarginArray = res.volumePlus15Percent.fuelVolumeAndMarginGrowth.gasMargin;
                }
                if (res.volumePlus15Percent.fuelVolumeAndMarginGrowth.gasVolume) {
                  this.volumePlus15GasVolumeArray = res.volumePlus15Percent.fuelVolumeAndMarginGrowth.gasVolume;
                }
                if (res.volumePlus15Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume) {
                  this.volumePlus15FuelAndLubricantMarginByVolume = res.volumePlus15Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume;
                }
                if (res.volumePlus15Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume) {
                  this.volumePlus15GasMarginByVolumeArray = res.volumePlus15Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume;
                }
              }
              if (res.volumePlus15Percent.internalRateOfReturn) {
                this.volumePlus15IRR = res.volumePlus15Percent.internalRateOfReturn;
              }
              if (res.volumePlus15Percent.netPresentValue) {
                this.volumePlus15NPV = res.volumePlus15Percent.netPresentValue;
              }
              if (res.volumePlus15Percent.paybackPeriod) {
                this.volumePlus15PaybackPeriod = res.volumePlus15Percent.paybackPeriod;
              }
              if (res.volumePlus15Percent.paybackPeriodDiscounted) {
                this.volumePlus15PaybackPeriodDiscounted = res.volumePlus15Percent.paybackPeriodDiscounted;
              }
            };
            if (res.workingCapitalMinus10Percent) {
              if (res.workingCapitalMinus10Percent.companySummary) {
                if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation) {
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.changeInWorkingCapital) {
                    this.workingCapitalMinus10PercentChangeInWorkingCapitalArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.changeInWorkingCapital;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal) {
                    this.workingCapitalMinus10PercentChangeInWorkingCapitalRealArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.companyDiversificationMargin) {
                    this.workingCapitalMinus10PercentCompanyDiversificationMarginArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.companyDiversificationMargin;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.companyInterestReceived) {
                    this.workingCapitalMinus10PercentCompanyInterestReceivedArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.companyInterestReceived;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.companyInvestment) {
                    this.workingCapitalMinus10PercentCompanyInvestmentArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.companyInvestment;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.companyInvestmentReal) {
                    this.workingCapitalMinus10PercentCompanyInvestmentRealArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.companyInvestmentReal;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.companyOperatingExpenses) {
                    this.workingCapitalMinus10PercentCompanyOperatingExpensesArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.companyOperatingExpenses;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.companyOtherRevenue) {
                    this.workingCapitalMinus10PercentCompanyOtherRevenueArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.companyOtherRevenue;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue) {
                    this.workingCapitalMinus10PercentCompanyRentalsAndFeesDueArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount) {
                    this.workingCapitalMinus10PercentDealerLoanInterestAmountArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal) {
                    this.workingCapitalMinus10PercentDiscountedNetCashAfterTaxRealArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.ebitda) {
                    this.workingCapitalMinus10PercentEbitdaArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.ebitda;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.ebitdaReal) {
                    this.workingCapitalMinus10PercentEbitdaRealArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.ebitdaReal;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.financialResult) {
                    this.workingCapitalMinus10PercentFinancialResultArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.financialResult;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.financingInterestPaid) {
                    this.workingCapitalMinus10PercentFinancingInterestPaidArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.financingInterestPaid;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.inflationRate) {
                    this.workingCapitalMinus10PercentInflationRateArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.inflationRate;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.netCashAfterTax) {
                    this.workingCapitalMinus10PercentNetCashAfterTaxArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.netCashAfterTax;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted) {
                    this.workingCapitalMinus10PercentNetCashAfterTaxDiscountedArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.netCashAfterTaxReal) {
                    this.workingCapitalMinus10PercentNetCashAfterTaxRealArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.netCashAfterTaxReal;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.netFuelMargin) {
                    this.workingCapitalMinus10PercentNetFuelMarginArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.netFuelMargin;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.otherCashItems) {
                    this.workingCapitalMinus10PercentOtherCashItemsArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.otherCashItems;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.otherCashItemsReal) {
                    this.workingCapitalMinus10PercentOtherCashItemsRealArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.otherCashItemsReal;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.realCompanyCashBalance) {
                    this.workingCapitalMinus10PercentRealCompanyCashBalanceArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.realCompanyCashBalance;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.taxAmount) {
                    this.workingCapitalMinus10PercentTaxAmountArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.taxAmount;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.taxAmountReal) {
                    this.workingCapitalMinus10PercentTaxAmountRealArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.taxAmountReal;
                  };
                  if (res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms) {
                    this.workingCapitalMinus10PercentWorkingCapitalTerminalValueNominalTermsArray = res.workingCapitalMinus10Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms;
                  };
                };
              };
              if (res.workingCapitalMinus10Percent.fuelVolumeAndMarginGrowth) {
                if (res.workingCapitalMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume) {
                  this.workingCapitalMinus10FuelVolumeArray = res.workingCapitalMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume;
                }
                if (res.workingCapitalMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin) {
                  this.workingCapitalMinus10FuelMarginArray = res.workingCapitalMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin;
                }
                if (res.workingCapitalMinus10Percent.fuelVolumeAndMarginGrowth.gasMargin) {
                  this.workingCapitalMinus10GasMarginArray = res.workingCapitalMinus10Percent.fuelVolumeAndMarginGrowth.gasMargin;
                }
                if (res.workingCapitalMinus10Percent.fuelVolumeAndMarginGrowth.gasVolume) {
                  this.workingCapitalMinus10GasVolumeArray = res.workingCapitalMinus10Percent.fuelVolumeAndMarginGrowth.gasVolume;
                }
                if (res.workingCapitalMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume) {
                  this.workingCapitalMinus10FuelAndLubricantMarginByVolume = res.workingCapitalMinus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume;
                }
                if (res.workingCapitalMinus10Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume) {
                  this.workingCapitalMinus10GasMarginByVolumeArray = res.workingCapitalMinus10Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume;
                }
              }
              if (res.workingCapitalMinus10Percent.internalRateOfReturn) {
                this.workingCapitalMinus10IRR = res.workingCapitalMinus10Percent.internalRateOfReturn;
              }
              if (res.workingCapitalMinus10Percent.netPresentValue) {
                this.workingCapitalMinus10NPV = res.workingCapitalMinus10Percent.netPresentValue;
              }
              if (res.workingCapitalMinus10Percent.paybackPeriod) {
                this.workingCapitalMinus10PaybackPeriod = res.workingCapitalMinus10Percent.paybackPeriod;
              }
              if (res.workingCapitalMinus10Percent.paybackPeriodDiscounted) {
                this.workingCapitalMinus10PaybackPeriodDiscounted = res.workingCapitalMinus10Percent.paybackPeriodDiscounted;
              }
            };
            if (res.workingCapitalPlus10Percent) {
              if (res.workingCapitalPlus10Percent.companySummary) {
                if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation) {
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.changeInWorkingCapital) {
                    this.workingCapitalPlus10PercentChangeInWorkingCapitalArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.changeInWorkingCapital;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal) {
                    this.workingCapitalPlus10PercentChangeInWorkingCapitalRealArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.changeInWorkingCapitalReal;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.companyDiversificationMargin) {
                    this.workingCapitalPlus10PercentCompanyDiversificationMarginArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.companyDiversificationMargin;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.companyInterestReceived) {
                    this.workingCapitalPlus10PercentCompanyInterestReceivedArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.companyInterestReceived;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.companyInvestment) {
                    this.workingCapitalPlus10PercentCompanyInvestmentArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.companyInvestment;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.companyInvestmentReal) {
                    this.workingCapitalPlus10PercentCompanyInvestmentRealArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.companyInvestmentReal;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.companyOperatingExpenses) {
                    this.workingCapitalPlus10PercentCompanyOperatingExpensesArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.companyOperatingExpenses;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.companyOtherRevenue) {
                    this.workingCapitalPlus10PercentCompanyOtherRevenueArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.companyOtherRevenue;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue) {
                    this.workingCapitalPlus10PercentCompanyRentalsAndFeesDueArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.companyRentalsAndFeesDue;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount) {
                    this.workingCapitalPlus10PercentDealerLoanInterestAmountArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.dealerLoanInterestAmount;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal) {
                    this.workingCapitalPlus10PercentDiscountedNetCashAfterTaxRealArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.discountedNetCashAfterTaxReal;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.ebitda) {
                    this.workingCapitalPlus10PercentEbitdaArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.ebitda;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.ebitdaReal) {
                    this.workingCapitalPlus10PercentEbitdaRealArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.ebitdaReal;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.financialResult) {
                    this.workingCapitalPlus10PercentFinancialResultArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.financialResult;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.financingInterestPaid) {
                    this.workingCapitalPlus10PercentFinancingInterestPaidArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.financingInterestPaid;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.inflationRate) {
                    this.workingCapitalPlus10PercentInflationRateArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.inflationRate;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.netCashAfterTax) {
                    this.workingCapitalPlus10PercentNetCashAfterTaxArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.netCashAfterTax;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted) {
                    this.workingCapitalPlus10PercentNetCashAfterTaxDiscountedArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.netCashAfterTaxDiscounted;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.netCashAfterTaxReal) {
                    this.workingCapitalPlus10PercentNetCashAfterTaxRealArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.netCashAfterTaxReal;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.netFuelMargin) {
                    this.workingCapitalPlus10PercentNetFuelMarginArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.netFuelMargin;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.otherCashItems) {
                    this.workingCapitalPlus10PercentOtherCashItemsArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.otherCashItems;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.otherCashItemsReal) {
                    this.workingCapitalPlus10PercentOtherCashItemsRealArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.otherCashItemsReal;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.realCompanyCashBalance) {
                    this.workingCapitalPlus10PercentRealCompanyCashBalanceArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.realCompanyCashBalance;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.taxAmount) {
                    this.workingCapitalPlus10PercentTaxAmountArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.taxAmount;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.taxAmountReal) {
                    this.workingCapitalPlus10PercentTaxAmountRealArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.taxAmountReal;
                  };
                  if (res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms) {
                    this.workingCapitalPlus10PercentWorkingCapitalTerminalValueNominalTermsArray = res.workingCapitalPlus10Percent.companySummary.yearlyEscalation.workingCapitalTerminalValueNominalTerms;
                  };
                };
              };
              if (res.workingCapitalPlus10Percent.fuelVolumeAndMarginGrowth) {
                if (res.workingCapitalPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume) {
                  this.workingCapitalPlus10FuelVolumeArray = res.workingCapitalPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantVolume;
                }
                if (res.workingCapitalPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin) {
                  this.workingCapitalPlus10FuelMarginArray = res.workingCapitalPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMargin;
                }
                if (res.workingCapitalPlus10Percent.fuelVolumeAndMarginGrowth.gasMargin) {
                  this.workingCapitalPlus10GasMarginArray = res.workingCapitalPlus10Percent.fuelVolumeAndMarginGrowth.gasMargin;
                }
                if (res.workingCapitalPlus10Percent.fuelVolumeAndMarginGrowth.gasVolume) {
                  this.workingCapitalPlus10GasVolumeArray = res.workingCapitalPlus10Percent.fuelVolumeAndMarginGrowth.gasVolume;
                }
                if (res.workingCapitalPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume) {
                  this.workingCapitalPlus10FuelAndLubricantMarginByVolume = res.workingCapitalPlus10Percent.fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume;
                }
                if (res.workingCapitalPlus10Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume) {
                  this.workingCapitalPlus10GasMarginByVolumeArray = res.workingCapitalPlus10Percent.fuelVolumeAndMarginGrowth.gasMarginByVolume;
                }
              }
              if (res.workingCapitalPlus10Percent.internalRateOfReturn) {
                this.workingCapitalPlus10IRR = res.workingCapitalPlus10Percent.internalRateOfReturn;
              }
              if (res.workingCapitalPlus10Percent.netPresentValue) {
                this.workingCapitalPlus10NPV = res.workingCapitalPlus10Percent.netPresentValue;
              }
              if (res.workingCapitalPlus10Percent.paybackPeriod) {
                this.workingCapitalPlus10PaybackPeriod = res.workingCapitalPlus10Percent.paybackPeriod;
              }
              if (res.workingCapitalPlus10Percent.paybackPeriodDiscounted) {
                this.workingCapitalPlus10PaybackPeriodDiscounted = res.workingCapitalPlus10Percent.paybackPeriodDiscounted;
              }
            };

            if (res.irrSummary) {
              if (res.irrSummary.volumeMinus15Percent) { this.volumeMinus15PercentIrr = res.irrSummary.volumeMinus15Percent };
              if (res.irrSummary.volumePlus15Percent) { this.volumePlus15PercentIrr = res.irrSummary.volumePlus15Percent };
              if (res.irrSummary.marginMinus20Percent) { this.marginMinus20PercentIrr = res.irrSummary.marginMinus20Percent };
              if (res.irrSummary.marginPlus20Percent) { this.marginPlus20PercentIrr = res.irrSummary.marginPlus20Percent };
              if (res.irrSummary.investmentMinus10Percent) { this.investmentMinus10PercentIrr = res.irrSummary.investmentMinus10Percent };
              if (res.irrSummary.investmentPlus10Percent) { this.investmentPlus10PercentIrr = res.irrSummary.investmentPlus10Percent };
              if (res.irrSummary.operatingCostMinus10Percent) { this.operatingCostMinus10PercentIrr = res.irrSummary.operatingCostMinus10Percent };
              if (res.irrSummary.operatingCostPlus10Percent) { this.operatingCostPlus10PercentIrr = res.irrSummary.operatingCostPlus10Percent };
              if (res.irrSummary.workingCapitalMinus10Percent) { this.workingCapitalMinus10PercentIrr = res.irrSummary.workingCapitalMinus10Percent };
              if (res.irrSummary.workingCapitalPlus10Percent) { this.workingCapitalPlus10PercentIrr = res.irrSummary.workingCapitalPlus10Percent };
            };
          };

          if (res) {
            if (res.yearlyEscalation) {
              if (res.yearlyEscalation.netFuelMargin) { this.companySummaryNetFuelMarginArray = res.yearlyEscalation.netFuelMargin };
              if (res.yearlyEscalation.companyDiversificationMargin) { this.companySummaryDiversificationMarginArray = res.yearlyEscalation.companyDiversificationMargin };
              if (res.yearlyEscalation.companyOtherRevenue) { this.companySummaryOtherRevenueArray = res.yearlyEscalation.companyOtherRevenue };
              if (res.yearlyEscalation.companyOperatingExpenses) { this.companySummaryOperatingExpensesArray = res.yearlyEscalation.companyOperatingExpenses };
              if (res.yearlyEscalation.companyRentalsAndFeesDue) { this.companySummaryRentalsAndFeesDueArray = res.yearlyEscalation.companyRentalsAndFeesDue };
              if (res.yearlyEscalation.ebitda) { this.companySummaryEBITDAArray = res.yearlyEscalation.ebitda };
              if (res.yearlyEscalation.companyInvestment) {
                this.companySummaryInvestmentArray = res.yearlyEscalation.companyInvestment;
                this.capitalExpenditure = (res.yearlyEscalation.companyInvestment)[0];
                this.estimatedGroupCommitment = ((this.loanToDealer) + (this.workingCapitalYear3) + (this.capitalExpenditure));

                // Calculate
                if ((this.fuelProductTotalYearlyVolume > 0) && (this.capitalExpenditure > 0)) {
                  this.investmentPerM3 = (((this.capitalExpenditure) / (this.fuelProductTotalYearlyVolume)) * (0.0086));
                }
              };
              if (res.yearlyEscalation.changeInWorkingCapital) { this.companySummaryChangeInWorkingCapitalArray = res.yearlyEscalation.changeInWorkingCapital };
              if (res.yearlyEscalation.financingInterestPaid) { this.companySummaryFinancingInterestPaidArray = res.yearlyEscalation.financingInterestPaid };
              if (res.yearlyEscalation.taxAmount) { this.companySummaryTaxesArray = res.yearlyEscalation.taxAmount };
              if (res.yearlyEscalation.otherCashItems) { this.companySummaryOtherCashItemsArray = res.yearlyEscalation.otherCashItems };
              if (res.yearlyEscalation.netCashAfterTax) { this.companySummaryNetCashAfterTaxArray = res.yearlyEscalation.netCashAfterTax };
              if (res.yearlyEscalation.financialResult) { this.companySummaryFinancialResultArray = res.yearlyEscalation.financialResult };
              if (res.yearlyEscalation.inflationRate) { this.companySummaryInflationRateArray = res.yearlyEscalation.inflationRate };
              if (res.yearlyEscalation.companyInterestReceived) { this.companyInterestReceivedArray = res.yearlyEscalation.companyInterestReceived };

              if (res.yearlyEscalation.ebitdaReal) { this.companySummaryEBITDARealArray = res.yearlyEscalation.ebitdaReal };
              if (res.yearlyEscalation.otherCashItemsReal) { this.companySummaryOtherCashItemsRealArray = res.yearlyEscalation.otherCashItemsReal };
              if (res.yearlyEscalation.companyInvestmentReal) { this.companySummaryInvestmentRealArray = res.yearlyEscalation.companyInvestmentReal };
              if (res.yearlyEscalation.taxAmountReal) { this.companySummaryTaxesRealArray = res.yearlyEscalation.taxAmountReal };
              if (res.yearlyEscalation.changeInWorkingCapitalReal) { this.companySummaryChangeInWorkingCapitalRealArray = res.yearlyEscalation.changeInWorkingCapitalReal };
              if (res.yearlyEscalation.netCashAfterTaxReal) { this.companySummaryNetCashAfterTaxRealArray = res.yearlyEscalation.netCashAfterTaxReal };
              if (res.yearlyEscalation.realCompanyCashBalance) { this.companySummaryRealCompanyCashBalanceArray = res.yearlyEscalation.realCompanyCashBalance };

              if (res.yearlyEscalation.discountedNetCashAfterTaxReal) { this.companySummaryDiscountedNetCashAfterTaxRealArray = res.yearlyEscalation.discountedNetCashAfterTaxReal };
              if (res.yearlyEscalation.netCashAfterTaxDiscounted) { this.companySummaryNetCashAfterTaxDiscountedArray = res.yearlyEscalation.netCashAfterTaxDiscounted };

              if (res.yearlyEscalation.workingCapitalTerminalValueNominalTerms) { this.companySummaryTerminalValueNominalTermsArray = res.yearlyEscalation.workingCapitalTerminalValueNominalTerms };
            };
          }
        }));
    };
  }

  ngOnInit(): void {

  };

  navigateTo(type: string) {

    if (type === "report") {
      this.router.navigate([`/portal/report/${this.projectId}`]);
      this.toastr.info(`View/Edit Report`, "Info!");
    }

    if (type === "projectAppraisal") {
      this.router.navigate([`/portal/project/${this.projectId}`]);
      this.toastr.info(`View/Edit Project Appraisal`, "Info!");
    }

    if (type === "resultPDF") {
      // this.toastr.info(`PDF Download Feature under development`, "Info!");
      this.printResultSummaryPDF();
    }

  }
  
  printResultSummaryPDF() {
    throw new Error('Method not implemented.');
  }

  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  loadingAnimationStart() {
    this.loading = true;
  }

  loadingAnimationStop() {
    this.loading = false;
  }

  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
