import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NgbCollapseModule, NgbDropdown, NgbModule } from '@ng-bootstrap/ng-bootstrap';
// types
import { User } from 'src/app/core/models/User';
// service
import { AuthenticationService } from 'src/app/core/service/auth.service';

interface Language {
  id?: number;
  name?: string;
  flag?: string;
}

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    CommonModule,
    NgbModule,
    NgbCollapseModule,
    RouterLink,
  ],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  subscription: any;
  mobileMenuOpen: boolean = false;
  loggedInUser: any | User | null = null;
  languages: Language[] = [];
  selectedLanguage?: Language;

  @Input() layoutType: string = '';

  constructor (
    private authService: AuthenticationService,
    public router: Router
    ) { }

  ngOnInit(): void {
    //this.loggedInUser = this.authService.currentUser();
    this.subscription = this.authService.currentUser.subscribe(appUser => {
      this.loggedInUser = appUser;
    });

    this._fetchLanguages();
  }

  /**
   * Fetches supported languages
   */
  _fetchLanguages(): void {
    this.languages = [{
      id: 1,
      name: 'English',
      flag: 'assets/images/flags/us.jpg',
    },
    {
      id: 2,
      name: 'German',
      flag: 'assets/images/flags/germany.jpg',
    },
    {
      id: 3,
      name: 'Italian',
      flag: 'assets/images/flags/italy.jpg',
    },
    {
      id: 4,
      name: 'Spanish',
      flag: 'assets/images/flags/spain.jpg',
    },
    {
      id: 5,
      name: 'Russian',
      flag: 'assets/images/flags/russia.jpg',
    }];
    this.selectedLanguage = this.languages[0];
  }

  /**
  * Change the language
  * @param language selected language from dropdown
  */
  changeLanguage(language: Language) {
    this.selectedLanguage = language;
  }

  onOptionClick(event: any, route?: string): void {
    if (route) {
      if (route === '.') {
        event.preventDefault();
      } else {
        this.router.navigateByUrl(route);
      }
    }
  }

  over(drop:NgbDropdown){
    drop.open()
  }
  
  out(drop:NgbDropdown){
    drop.close()
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
