<app-ui-preloader [display]="loading"></app-ui-preloader>

<div class="row">
    <div class="col-12">
        <div class="page-title-box">
            <div class="page-title-right">
                <form class="d-flex">

                </form>
            </div>
            <h4 class="page-title" i18n>Dashboard</h4>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="card widget-inline">
            <div class="card-body p-0">
                <div class="row g-0">
                    <div class="col-sm-6 col-lg-3">
                        <div class="card shadow-none m-0">
                            <div class="card-body text-center">
                                <i class="mdi mdi-file-multiple-outline text-muted" style="font-size: 24px;"></i>
                                <h3><span>2</span></h3>
                                <p class="text-muted font-15 mb-0">Total Projects</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-3">
                        <div class="card shadow-none m-0 border-start">
                            <div class="card-body text-center">
                                <i class="mdi mdi-file-sign text-muted" style="font-size: 24px;"></i>
                                <h3><span>0</span></h3>
                                <p class="text-muted font-15 mb-0">Approved Projects</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-3">
                        <div class="card shadow-none m-0 border-start">
                            <div class="card-body text-center">
                                <i class="mdi mdi-account-group text-muted" style="font-size: 24px;"></i>
                                <h3><span>14</span></h3>
                                <p class="text-muted font-15 mb-0">App Users</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-3">
                        <div class="card shadow-none m-0 border-start">
                            <div class="card-body text-center">
                                <i class="dripicons-graph-line text-muted" style="font-size: 24px;"></i>
                                <h3><span>93%</span> <i class="mdi mdi-arrow-up text-success"></i></h3>
                                <p class="text-muted font-15 mb-0">Productivity</p>
                            </div>
                        </div>
                    </div>

                </div> <!-- end row -->
            </div>
        </div> <!-- end card-box-->
    </div> <!-- end col-->
</div>

<div class="row">
    <div class="col-xl-3 col-lg-4">

        <div class="card tilebox-one" *ngFor="let m of statWidget">
            <div class="card-body">
                <i class="{{m.icon}} float-end"></i>
                <h6 class="text-uppercase mt-0" i18n>{{m.title}}</h6>
                <h2 class="my-2">{{m.value}}</h2>
                <p class="mb-0 text-muted">
                    <span class="text-success me-2" *ngIf="m.arrow=='up'"><span
                            class="mdi mdi-arrow-up-bold"></span>{{m.change}}</span>
                    <span class="text-danger me-2" *ngIf="m.arrow=='down'"><span
                            class="mdi mdi-arrow-down-bold"></span>{{m.change}}</span>
                    <span class="text-nowrap" i18n>{{m.subtitle}}</span>
                </p>
            </div> <!-- end card-body-->
        </div>

    </div>

    <div class="col-xl-9 col-lg-8">
        <div class="card">
            <div class="card-body">
                <app-card-title cardTitle="Money History" [dropdownOptions]="dropdownOptions" containerClass="mb-2">
                </app-card-title>

                <div class="border border-light p-3 rounded" *ngFor="let item of moneyHistory;let i=index"
                    [class.mb-3]="i<moneyHistory.length-1">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <p class="font-18 mb-1">{{item.title}}</p>
                            <h3 class="text-{{item.variant}} my-0">{{item.amount | currency: 'USD' : 'symbol' :
                                '2.0-0'}}</h3>
                        </div>
                        <div class="avatar-sm">
                            <span class="avatar-title bg-{{item.variant}} rounded-circle h3 my-0">
                                <i [class]="item.icon"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div id="tooltip-container">
                <h5>Team Members:</h5>
                <a href="javascript:void(0);" data-bs-container="#tooltip-container" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="Mat Helme" class="d-inline-block">
                    <img src="assets/images/Male-Avatar.jpg" class="rounded-circle img-thumbnail avatar-sm"
                        alt="friend">
                </a>

                <a href="javascript:void(0);" data-bs-container="#tooltip-container" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="Michael Zenaty" class="d-inline-block">
                    <img src="assets/images/Male-Avatar.jpg" class="rounded-circle img-thumbnail avatar-sm"
                        alt="friend">
                </a>

                <a href="javascript:void(0);" data-bs-container="#tooltip-container" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="James Anderson" class="d-inline-block">
                    <img src="assets/images/Male-Avatar.jpg" class="rounded-circle img-thumbnail avatar-sm"
                        alt="friend">
                </a>

                <a href="javascript:void(0);" data-bs-container="#tooltip-container" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="Mat Helme" class="d-inline-block">
                    <img src="assets/images/Male-Avatar.jpg" class="rounded-circle img-thumbnail avatar-sm"
                        alt="friend">
                </a>

                <a href="javascript:void(0);" data-bs-container="#tooltip-container" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="Michael Zenaty" class="d-inline-block">
                    <img src="assets/images/Male-Avatar.jpg" class="rounded-circle img-thumbnail avatar-sm"
                        alt="friend">
                </a>

                <a href="javascript:void(0);" data-bs-container="#tooltip-container" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="James Anderson" class="d-inline-block">
                    <img src="assets/images/Male-Avatar.jpg" class="rounded-circle img-thumbnail avatar-sm"
                        alt="friend">
                </a>
            </div> -->
    </div>
</div>

<div class="row">
    <div class="col-lg-6">
        <div class="card text-white bg-info overflow-hidden" role="button" (click)="navigateTo('new project')">
            <div class="card-body">
                <div class="toll-free-box text-center">
                    <h4> <i class="mdi mdi-file-document-edit-outline"></i> Create a project.</h4>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="card text-white bg-danger overflow-hidden" role="button" (click)="navigateTo('project')">
            <div class="card-body">
                <div class="toll-free-box text-center">
                    <h4> <i class="mdi mdi-file-chart-outline"></i> View existing projects.</h4>
                </div>
            </div>
        </div>
    </div>

</div>