// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyCr7OSar1DPnLwF_5rK1E1A6tiTFdKqi3s",
    authDomain: "financial-forecast-v2.firebaseapp.com",
    projectId: "financial-forecast-v2",
    storageBucket: "financial-forecast-v2.appspot.com",
    messagingSenderId: "371163072833",
    appId: "1:371163072833:web:9f01d4cb4e78f4ad32b9c0"
  },
  production: true,
  demo: '', // other possible options are, saas creative and modern
  GOOGLE_MAPS_API_KEY: 'AIzaSyC5pcAkhXSo6-KqV9RtZdUAhdJno7r_8b0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
