import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FileStorageService {

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    private apiService: ApiService,
  ) { }


  saveProfileImageRecord(imageId: string, fileCategory: string, id: string, downloadUrl: string, fileName: string, fileSize: string, fileType: string, width:number, height:number) {
    let load = {
      url: downloadUrl,
      name: fileName,
      size: fileSize,
      formatType: fileType,
      imageId: imageId,
      uploadBy: id?id:"",
      width: width,
      height: height,
      date: new Date().getTime()
    }
    return this.db.list(`/files/${fileCategory}/${(id?id:imageId)}`).push(load);
  }

}