import { Injectable } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClockService {
  a: number = 0;
  private clock: Observable<number>;

  constructor() {
    this.clock = interval(1000).pipe(map(() =>
      this.a = (this.a + 1)
    ));
  }
  getCurrentTime() {
    return this.clock;
  }
}
