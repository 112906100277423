import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { NgbAlertModule, NgbProgressbarModule, NgbButtonsModule, NgbAlert, NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AdvancedTableModule } from 'src/app/shared/advanced-table/advanced-table.module';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { Subscription, first } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { User } from '@firebase/auth-types';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { ClockService } from 'src/app/core/service/clock.service';
import { UserService } from 'src/app/core/service/user.service';
import { ProjectService } from 'src/app/core/service/project.service';
import { Column, AdvancedTableComponent } from 'src/app/shared/advanced-table/advanced-table.component';
import { SortEvent } from 'src/app/shared/advanced-table/sortable.directive';
import { BreadcrumbItem } from 'src/app/shared/page-title/page-title.model';
import { ProjectDetails } from 'src/app/core/models-two/ProjectDetails';
import { PetroleumProductsService } from 'src/app/core/service/petroleum-products.service';
import { Country } from 'src/app/core/models-two/Country';
import { AppUser } from 'src/app/core/models-two/AppUser';

@Component({
  selector: 'app-new-project',
  standalone: true,
  imports: [
    CommonModule,
    AdvancedTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    WidgetModule,
    RouterLink,
    NgbButtonsModule,
  ],
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class NewProjectComponent implements OnInit, OnDestroy {


  subscription: Subscription = new Subscription();
  today: number = Date.now();
  pageTitle: BreadcrumbItem[] = [];
  //projectArray!: Client[];
  pageSizeOptions: number[] = [5, 10, 20, 50];
  pageSize: number = 5;
  selectAll: boolean = false;
  loading: boolean = false;
  columns: Column[] = [];
  @ViewChild('profileModal', { static: false }) private profileModal: any;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  @ViewChild('advancedTable') advancedTable!: AdvancedTableComponent;

  projectForm!: FormGroup;
  formSubmitted: boolean = false;

  show_alert: boolean;
  alert_message: string;
  alert_type: string;

  loggedInUser: User;
  projectObject: ProjectDetails;
  projectArray;
  userId: any;

  destinationLatitude: number;
  destinationLongitude: number;
  destinationZoom: number;
  googleLocationAddressLong: string;
  googleLocationAddressShort: any;
  profilePicture: any;
  invalidPhoneNumber: boolean;
  pickedImage: any;

  error: string;
  showForm: boolean = false;
  userCategoryViewChoice: number = 1;
  dateNow2: any;
  dateNow3: Date;
  id: any;
  defaultProjectArray: any[] = [];
  defaultProjectObject: any;
  importedProjectArray: ProjectDetails[] = [];
  showImportPanel: boolean = false;
  disableDownloadButton: boolean = false;
  alphabeticalProjectArray: ProjectDetails[] = [];
  workingProjectArray: ProjectDetails[] = [];
  countryArray: Country[];
  submitInProgress: boolean = false;
  modeArray: string[] = [];
  typeOfInvestmentArray: string[] = [];
  minimumDate: string;
  retailStaffArray: any[] = [];
  teamMembersArray: any[] = [];
  boardMemberArray: any[] = [];

  availableRetailStaffArray: any[] = [];
  pickedRetailStaffArray: any[] = [];
  pickedCompanySecretaryArray: any[] = [];
  pickedRetailDevelopmentManagerArray: any[] = [];
  pickedHeadOfRetailArray: any[] = [];
  pickedBoardMemberArray: any[] = [];
  pickedCEOArray: any[] = [];

  availableTeamArray: any[] = [];
  pickedTeamArray: any[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private projectService: ProjectService,
    private clockService: ClockService,
    private petroleumProductsService: PetroleumProductsService,
  ) {
    this.loadingAnimationFourSeconds();
    this.subscription.add(
      authenticationService.currentUser.pipe().subscribe((res: any) => {
        if (res) {
          if (res[0]) {
            this.loggedInUser = res[0];
            if (res[0].userId) {
              this.userId = res[0].userId;
            }
          } else {
          }
        }
      })
    );
  }

  ngOnInit(): void {
    let year = (new Date()).toLocaleDateString('es', { year: 'numeric' });
    let month = (new Date()).toLocaleDateString('es', { month: '2-digit' });
    let day = (new Date()).toLocaleDateString('es', { day: '2-digit' });
    this.minimumDate = `${year}-${month}-${day}`;

    this.projectForm = this.fb.group({
      id: ['', Validators.required],
      name: ['', Validators.required],
      countryId: ['', Validators.required],
      typeOfInvestment: ['Creation', Validators.required],
      mode: ['DODO', Validators.required],
      isComplete: [false],
      completionPercentage: [0],
      startDate: [(this.minimumDate), Validators.required],
      periodOfOperation: [10, Validators.required],
      monthsBeforeOperation: [3, Validators.required],
      plotSize: [4000, Validators.required],
      shopSize: [3000],
      created: [''],
      teamMembers: ['', Validators.required],
    });

    this.generateId();

    this.getUserArray();

    this.getCountryArray();

    this.modeArray = ["DODO"];
    this.typeOfInvestmentArray = ["Creation", "Renovation",];

  }

  ngAfterViewInit(): void {

  }

  getUserArray() {
    this.subscription.add(
      this.userService.getUsersArray().pipe(first()).subscribe((res: AppUser[]) => {
        if (res) {
          let indexA = 0;
          let aArray = [];
          aArray = res;

          this.availableRetailStaffArray = [];
          this.pickedRetailStaffArray = [];
          this.pickedCompanySecretaryArray = [];
          this.pickedHeadOfRetailArray = [];
          this.pickedRetailDevelopmentManagerArray = [];
          this.pickedCEOArray = [];
          this.pickedBoardMemberArray = [];

          this.pickedTeamArray = [];

          (aArray).forEach(element => {

            let profilePictureUrl = "";
            let displayName = "";
            let userId = "";

            if (element[0]) {
              if (element[0].profilePicture) {
                if (element[0].profilePicture[0]) {
                  if (element[0].profilePicture[0].url) {
                    profilePictureUrl = element[0].profilePicture[0].url;
                  }
                }
              }
              if (element[0].firstName) {
                displayName = displayName + `${element[0].firstName} `;
              }
              if (element[0].otherName) {
                displayName = displayName + `${(element[0].otherName).substring(0, 1)}. `;
              }
              if (element[0].surname) {
                displayName = displayName + `${(element[0].surname).substring(0, 1)}. `;
              }
              if (element[0].userId) {
                userId = element[0].userId;
              }
            }

            // New User
            // Retail Staff
            // Company Secretary
            // Retail Development Manager
            // Head of Retail
            // Board Member
            // Chief Executive Officer
            // IT Administrator

            // Item Requires
            // 1. URL
            // 2. Display Name - {First Name} {Other Name} {Surname}
            // 3. Role
            if ((element[0].role) === "New User") { };
            if ((element[0].role) === "IT Administrator") { };
            if ((element[0].role) === "Chief Executive Officer") {
              this.pickedCEOArray.push({
                "url": profilePictureUrl,
                "displayName": displayName,
                "role": (element[0].role),
                "userId": userId,
              });
              this.pickedTeamArray.push({
                "url": profilePictureUrl,
                "displayName": displayName,
                "role": (element[0].role),
                "userId": userId,
              });
            };
            if ((element[0].role) === "Board Member") {
              this.pickedBoardMemberArray.push({
                "url": profilePictureUrl,
                "displayName": displayName,
                "role": (element[0].role),
                "userId": userId,
              });
              this.pickedTeamArray.push({
                "url": profilePictureUrl,
                "displayName": displayName,
                "role": (element[0].role),
                "userId": userId,
              });
            };
            if ((element[0].role) === "Head of Retail") {
              this.pickedHeadOfRetailArray.push({
                "url": profilePictureUrl,
                "displayName": displayName,
                "role": (element[0].role),
                "userId": userId,
              });
              this.pickedTeamArray.push({
                "url": profilePictureUrl,
                "displayName": displayName,
                "role": (element[0].role),
                "userId": userId,
              });
            };
            if ((element[0].role) === "Retail Development Manager") {
              this.pickedRetailDevelopmentManagerArray.push({
                "url": profilePictureUrl,
                "displayName": displayName,
                "role": (element[0].role),
                "userId": userId,
              });
              this.pickedTeamArray.push({
                "url": profilePictureUrl,
                "displayName": displayName,
                "role": (element[0].role),
                "userId": userId,
              });
            };
            if ((element[0].role) === "Company Secretary") {
              this.pickedCompanySecretaryArray.push({
                "url": profilePictureUrl,
                "displayName": displayName,
                "role": (element[0].role),
                "userId": userId,
              });
              this.pickedTeamArray.push({
                "url": profilePictureUrl,
                "displayName": displayName,
                "role": (element[0].role),
                "userId": userId,
              });
            };
            if ((element[0].role) === "Retail Staff") {
              this.pickedRetailStaffArray.push({
                "url": profilePictureUrl,
                "displayName": displayName,
                "role": (element[0].role),
                "userId": userId,
              });
              this.pickedTeamArray.push({
                "url": profilePictureUrl,
                "displayName": displayName,
                "role": (element[0].role),
                "userId": userId,
              });
            };
            indexA += 1;
          }

            // Arrange Take Members

          );

          this.updateTeamSelectionToForm();
        }
      }
      ));
  }
  updateTeamSelectionToForm() {
    this.projectForm.get("teamMembers").setValue(this.pickedTeamArray);
  }

  getCountryArray() {
    this.subscription.add(
      this.petroleumProductsService.getCountryArray().pipe().subscribe((res: Country[]) => {
        if (res) {
          this.countryArray = res;
          if (res[0]) {
            if (res[0][0]) {
              if (res[0][0].id) {
                this.projectForm.get("countryId").setValue(res[0][0].id);
              };
            };
          };
        }
      })
    );
  };


  /**
   * convenience getter for easy access to form fields
   */
  get formValues() {
    return this.projectForm.controls;
  }



  /**
   * On form submit
   */

  onSubmit(load: any): void {
    this.submitInProgress = true;

    // Checks
    if (this.projectForm.controls['id'].invalid) {
      this.show_alert = true;
      this.alert_message = "Project Id is missing!";
      this.alert_type = "danger";

      this.submitInProgress = false;
      this.toastr.error('Project Id is missing!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectForm.controls['name'].invalid) {
      this.show_alert = true;
      this.alert_message = "Product Name is missing!";
      this.alert_type = "danger";

      this.submitInProgress = false;
      this.toastr.error('Product Name is missing!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectForm.get("mode").invalid) {
      this.show_alert = true;
      this.alert_message = "Project mode is missing!";
      this.alert_type = "danger";

      this.submitInProgress = false;
      this.toastr.error('Project mode is missing!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectForm.get("countryId").invalid) {
      this.show_alert = true;
      this.alert_message = "Project country is missing!";
      this.alert_type = "danger";

      this.submitInProgress = false;
      this.toastr.error('Project country is missing!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectForm.get("typeOfInvestment").invalid) {
      this.show_alert = true;
      this.alert_message = "Type of investment is missing!";
      this.alert_type = "danger";

      this.submitInProgress = false;
      this.toastr.error('Type of investment is missing!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectForm.get("startDate").invalid) {
      this.show_alert = true;
      this.alert_message = "Start Date is missing!";
      this.alert_type = "danger";

      this.submitInProgress = false;
      this.toastr.error('Start Date is missing!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectForm.get("periodOfOperation").invalid) {
      this.show_alert = true;
      this.alert_message = "Project periods of operations is missing!";
      this.alert_type = "danger";

      this.submitInProgress = false;
      this.toastr.error('Project periods of operations is missing!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectForm.get("monthsBeforeOperation").invalid) {
      this.show_alert = true;
      this.alert_message = "Project months before operations is missing!";
      this.alert_type = "danger";

      this.submitInProgress = false;
      this.toastr.error('Project months before operations is missing!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectForm.get("plotSize").invalid) {
      this.show_alert = true;
      this.alert_message = "Project plot size is missing!";
      this.alert_type = "danger";

      this.submitInProgress = false;
      this.toastr.error('Project plot size is missing!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectForm.get("shopSize").invalid) {
      this.show_alert = true;
      this.alert_message = "Project shop size is missing!";
      this.alert_type = "danger";

      this.submitInProgress = false;
      this.toastr.error('Project shop size is missing!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectForm.get("teamMembers").invalid) {
      this.show_alert = true;
      this.alert_message = "Project team members are missing!";
      this.alert_type = "danger";

      this.submitInProgress = false;
      this.toastr.error('Project team members are missing!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    this.subscription.add(
      this.projectService.startProject(load)
        .pipe(first()).subscribe((result) => {
          this.formSubmitted = true;
          this.submitInProgress = false;
          this.toastr.success(`${load.name} Created`, "Info!");
          this.router.navigate([`/portal/project/${this.id}`]);
          // this.projectForm.reset();
        },
          (error: string) => {
            this.error = error;
            this.submitInProgress = false;
          }));

  }

  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  loadingAnimationStart() {
    this.loading = true;
  }

  loadingAnimationStop() {
    this.loading = false;
  }

  getProjectList() {
    //this.petroleumProductArray = users;
    this.subscription.add(
      this.projectService.getProjectArray().pipe().subscribe((userList: any) => {
        if (userList) {
          this.workingProjectArray = [];
          this.defaultProjectArray = this.projectArray = userList;

          // Arranged Alphabetically
          userList.forEach(element => {
            this.workingProjectArray.push(element[0]);
          });

          this.alphabeticalProjectArray = this.workingProjectArray.sort((a: any, b: any) => (a.firstName).localeCompare(b.firstName));

        }
      })
    );

    this.subscription.add(
      this.projectService.getProjectObject().pipe().subscribe((userList) => {
        if (userList) {
          this.defaultProjectObject = this.projectObject = userList;
        }
      })
    );
  }



  numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  generateId() {
    // Get new Id
    this.subscription.add(
      this.projectService.getProjectId().pipe(first()).subscribe((result) => {
        if (result) {
          this.id = result;
          this.projectForm.get("id").setValue(result);
        }
      })
    );
  }


  cancelUpdate() {

    // Move to project list
    this.router.navigate([`/portal/project`]);

  }


  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }

  removeAddTeamMember(type: string, index: number) {
    
    let newItem: object = {};
    let new_pickedTeamArray_one: any[] = [];
    let new_pickedTeamArray_two: any[] = [];

    if (type === "remove") {
      newItem = this.pickedTeamArray[index];
      new_pickedTeamArray_one = this.pickedTeamArray;
      this.availableRetailStaffArray.push(newItem);
      this.pickedRetailStaffArray.splice(index, 1);

      // Update picked team members
      // Find that Member in picketTeamArray
      console.log(`displayName: `, newItem["displayName"]);
      new_pickedTeamArray_one.forEach(element => {
        if (element.displayName === newItem["displayName"]) {

        } else {
          new_pickedTeamArray_two.push(element);
        }
      });
      this.pickedTeamArray = [];
      this.pickedTeamArray = new_pickedTeamArray_two;
      this.updateTeamSelectionToForm();
    };
    if (type === "add") {
      newItem = this.availableRetailStaffArray[index];
      this.pickedRetailStaffArray.push(newItem);
      this.availableRetailStaffArray.splice(index, 1);

      // Update picked team members
      this.pickedTeamArray.push(newItem);
      this.updateTeamSelectionToForm();
    }
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}


