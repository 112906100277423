import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { PRIMARY_OUTLET, Router } from '@angular/router';
import { NgbAlertModule, NgbProgressbarModule, NgbButtonsModule, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription, first } from 'rxjs';
import { DefaultCountry } from 'src/app/core/models-two/DefaultCountry';
import { FuelLubricantGasProduct } from 'src/app/core/models-two/FuelLubricantGasProduct';
import { FuelLubricantGasProductTotal } from 'src/app/core/models-two/FuelLubricantGasProductTotal';
import { FuelProduct } from 'src/app/core/models-two/FuelProduct';
import { GasProduct } from 'src/app/core/models-two/GasProduct';
import { LubricantProduct } from 'src/app/core/models-two/LubricantProduct';
import { PetroleumProductsService } from 'src/app/core/service/petroleum-products.service';
import { ProjectService } from 'src/app/core/service/project.service';
import { WidgetModule } from 'src/app/shared/widget/widget.module';

@Component({
  selector: 'app-country',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbProgressbarModule,
    WidgetModule,
    NgbButtonsModule,
  ],
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit, OnDestroy {

  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  yearlyEscalationDefault: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  subscription: Subscription = new Subscription();
  countryForm!: FormGroup;
  productForm!: FormGroup;
  fuelProductForm!: FormGroup;
  lubricantProductForm!: FormGroup;
  gasProductForm!: FormGroup;

  show_alert: boolean;
  alert_message: string;
  alert_type: string;

  showForm: boolean = false;
  error: string;

  fuelVolumeGrowthForm!: FormGroup;
  fuelVolumeGrowthPotentialForm!: FormGroup;
  inflationRateForm!: FormGroup;

  formSubmitted: boolean = false;
  updateInProgress: boolean = false;
  tree: any;
  g: any;
  s: any;
  countryId: any;
  loading: boolean = false;
  selectedCountry: DefaultCountry;
  localCurrencyISOCode: string;
  fuelProductArray: FuelProduct[];
  lubricantProductArray: FuelLubricantGasProduct[];
  gasProductArray: FuelLubricantGasProduct[];
  fuelProductTotal: FuelLubricantGasProductTotal;
  lubricantProductTotal: FuelLubricantGasProductTotal;
  gasProductTotal: FuelLubricantGasProductTotal;
  gasProductTotalYearlyVolume: number;
  gasProductTotalCompanyUnitMargin: number;
  gasMarginArray: number[] = this.yearlyEscalationDefault;
  fuelAndLubricantMarginByVolume: number[] = this.yearlyEscalationDefault;
  gasMarginByVolumeArray: number[] = this.yearlyEscalationDefault;
  fuelAndLubricantProductTotal: FuelLubricantGasProductTotal;
  fuelMarginGrowthArray: number[] = this.yearlyEscalationDefault;
  fuelMarginArray: number[] = this.yearlyEscalationDefault;
  fuelMarginTwoArray: number[] = this.yearlyEscalationDefault;
  fuelMarginGrowthFactorTwoArray: number[] = this.yearlyEscalationDefault;
  fuelVolumeArray: number[] = this.yearlyEscalationDefault;
  fuelVolumeGrowthArray: number[] = this.yearlyEscalationDefault;
  fuelVolumeGrowthPotentialArray: number[] = this.yearlyEscalationDefault;
  monthsDuringThePeriodArray: number[] = this.yearlyEscalationDefault;
  fuelVolumeWithoutLubricantArray: number[] = this.yearlyEscalationDefault;
  fuelMarginTotalArray: number[] = this.yearlyEscalationDefault;
  fuelAndLubricantProductTotalYearlyVolume: number;
  petroleumProductArray: FuelLubricantGasProduct[];
  showPetroleumProductForm: boolean = false;
  pickedPetroleumProductCategory: any;
  productExists_Update: boolean;
  fuelMarginGrowthFactorArray: number[] = this.yearlyEscalationDefault;
  monthsBeforeOperationArray: number[] = this.yearlyEscalationDefault;
  fuelAndLubricantProductTotalAverageUnitMargin: number;
  inflationFactorArray: number[] = this.yearlyEscalationDefault;
  inflationFactorTwoArray: number[] = this.yearlyEscalationDefault;
  inflationRateArray: number[] = this.yearlyEscalationDefault;

  valuationParametersDiscountRateArray: number[] = [];
  valuationParametersDiscountRateIndexArray: number[] = [];
  valuationParametersYearCoefficientArray: number[] = [];
  valuationParametersStandardYearCoefficientArray: number[] = [];
  valuationParametersGsYearCoefficientArray: number[] = [];
  valuationParametersOtherHalfYearCoefficientArray: number[] = [];
  valuationParametersStandardDiscountRateIndexArray: number[] = [];
  valuationParametersGsDiscountRateIndexArray: number[] = [];
  valuationParametersOtherDiscountRateIndexArray: number[] = [];
  valuationParametersAssetPurchaseDiscountRateIndexArray: number[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private petroleumProductsService: PetroleumProductsService,
  ) {
    this.tree = router.parseUrl(router.url);
    this.g = this.tree.root.children[PRIMARY_OUTLET];
    this.s = this.g.segments;

    this.loadingAnimationFourSeconds();

    if (this.s[2].path) {
      if ((this.s[2].path.toString()).length === 7) {
        this.countryId = this.s[2].path.toString();

        this.subscription.add(
          this.projectService.getCountryArrayItem(this.s[2].path.toString()).pipe().subscribe((res: DefaultCountry[]) => {
            if (res[0]) {
              this.selectedCountry = res[0];
              if (res[0].id) { this.countryForm.get("id").setValue(res[0].id); };
              if (res[0].name) { this.countryForm.get("name").setValue(res[0].name); };
              if (res[0].unitsOfMeasure) { this.countryForm.get("unitsOfMeasure").setValue(res[0].unitsOfMeasure); };
              if (res[0].localCurrencyName) { this.countryForm.get("localCurrencyName").setValue(res[0].localCurrencyName); };
              if (res[0].localCurrencyISOCode) {
                this.countryForm.get("localCurrencyISOCode").setValue(res[0].localCurrencyISOCode);
                this.localCurrencyISOCode = res[0].localCurrencyISOCode;
              };
              if (res[0].corporateTaxRate) { this.countryForm.get("corporateTaxRate").setValue(res[0].corporateTaxRate); };
              if (res[0].discountRateForNetPresentValue) { this.countryForm.get("discountRateForNetPresentValue").setValue(res[0].discountRateForNetPresentValue); };
              if (res[0].usdFxRate) { this.countryForm.get("usdFxRate").setValue(res[0].usdFxRate); };
              if (res[0].networkChannelFixedCosts) { this.countryForm.get("networkChannelFixedCosts").setValue(res[0].networkChannelFixedCosts); };
              if (res[0].inflation) { this.countryForm.get("inflation").setValue(res[0].inflation); };
              if (res[0].interestRate) { this.countryForm.get("interestRate").setValue(res[0].interestRate); };
              if (res[0].created) { this.countryForm.get("date").setValue(res[0].created); };
              if (res[0].fuelProduct) { this.fuelProductArray = res[0].fuelProduct; };
              if (res[0].lubricantProduct) { this.lubricantProductArray = res[0].lubricantProduct };
              if (res[0].gasProduct) { this.gasProductArray = res[0].gasProduct };

              if (res[0].fuelProductTotal) { this.fuelProductTotal = res[0].fuelProductTotal; };
              if (res[0].lubricantProductTotal) { this.lubricantProductTotal = res[0].lubricantProductTotal; };
              if (res[0].gasProductTotal) {
                this.gasProductTotal = res[0].gasProductTotal;
                if (res[0].gasProductTotal.yearlyVolume) { this.gasProductTotalYearlyVolume = res[0].gasProductTotal.yearlyVolume };
                if (res[0].gasProductTotal.companyUnitMargin) { this.gasProductTotalCompanyUnitMargin = res[0].gasProductTotal.companyUnitMargin };
              };
              if (res[0].fuelAndLubricantProductTotal) {
                this.fuelAndLubricantProductTotal = res[0].fuelAndLubricantProductTotal;
                if (res[0].fuelAndLubricantProductTotal.averageUnitMargin) { this.fuelAndLubricantProductTotalAverageUnitMargin = res[0].fuelAndLubricantProductTotal.averageUnitMargin; };
                if (res[0].fuelAndLubricantProductTotal.yearlyVolume) { this.fuelAndLubricantProductTotalYearlyVolume = res[0].fuelAndLubricantProductTotal.yearlyVolume; };
              };
              if (res[0].economicEnvironment) {
                if (res[0].economicEnvironment.yearlyEscalation) {
                  if (res[0].economicEnvironment.yearlyEscalation.inflationRate) {
                    this.inflationRateArray = [];
                    this.inflationRateArray = res[0].economicEnvironment.yearlyEscalation.inflationRate;

                    // Populate Form
                    let inflationIndex = 0;
                    (this.inflationRateArray).forEach(element => {
                      this.inflationRateForm.get(`y${inflationIndex}`).setValue(element);
                      inflationIndex += 1;
                    });
                  }
                  if (res[0].economicEnvironment.yearlyEscalation.inflationFactor) {
                    this.inflationFactorArray = [];
                    this.inflationFactorArray = res[0].economicEnvironment.yearlyEscalation.inflationFactor;
                  }
                  if (res[0].economicEnvironment.yearlyEscalation.inflationFactorTwo) {
                    this.inflationFactorTwoArray = [];
                    this.inflationFactorTwoArray = res[0].economicEnvironment.yearlyEscalation.inflationFactorTwo;
                  }
                }
              }
              if (res[0].fuelVolumeAndMarginGrowth) {
                if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolumeGrowth) {
                  this.fuelVolumeGrowthArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolumeGrowth;
                }
                if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolumeGrowthPotential) {
                  this.fuelVolumeGrowthPotentialArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolumeGrowthPotential;
                }
                if (res[0].fuelVolumeAndMarginGrowth.monthsDuringThePeriod) {
                  this.monthsDuringThePeriodArray = res[0].fuelVolumeAndMarginGrowth.monthsDuringThePeriod;
                }
                if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginGrowth) {
                  this.fuelMarginGrowthArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginGrowth;
                }
                if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginGrowthFactor) {
                  this.fuelMarginGrowthFactorArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginGrowthFactor;
                }
                if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginGrowthFactorTwo) {
                  this.fuelMarginGrowthFactorTwoArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginGrowthFactorTwo;
                }
                if (res[0].fuelVolumeAndMarginGrowth.monthsBeforeOperation) {
                  this.monthsBeforeOperationArray = res[0].fuelVolumeAndMarginGrowth.monthsBeforeOperation;
                }
                if (res[0].fuelVolumeAndMarginGrowth.fuelVolumeWithoutLubricant) {
                  this.fuelVolumeWithoutLubricantArray = res[0].fuelVolumeAndMarginGrowth.fuelVolumeWithoutLubricant;
                }
                if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolume) {
                  this.fuelVolumeArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolume;
                }
                if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMargin) {
                  this.fuelMarginArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMargin;
                }
                if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume) {
                  this.fuelAndLubricantMarginByVolume = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume;
                }
                if (res[0].fuelVolumeAndMarginGrowth.gasMarginByVolume) {
                  this.gasMarginByVolumeArray = res[0].fuelVolumeAndMarginGrowth.gasMarginByVolume;
                }
                if (res[0].fuelVolumeAndMarginGrowth.gasMargin) {
                  this.gasMarginArray = res[0].fuelVolumeAndMarginGrowth.gasMargin;
                }
                if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginTotal) {
                  this.fuelMarginTotalArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginTotal;
                }
              }
              if (res[0].valuationParameters) {
                if (res[0].valuationParameters.discountRate) { this.valuationParametersDiscountRateArray = res[0].valuationParameters.discountRate; };
                if (res[0].valuationParameters.discountRateIndex) { this.valuationParametersDiscountRateIndexArray = res[0].valuationParameters.discountRateIndex; };
                if (res[0].valuationParameters.yearCoefficient) { this.valuationParametersYearCoefficientArray = res[0].valuationParameters.yearCoefficient; };
                if (res[0].valuationParameters.standardYearCoefficient) { this.valuationParametersStandardYearCoefficientArray = res[0].valuationParameters.standardYearCoefficient; };
                if (res[0].valuationParameters.gsYearCoefficient) { this.valuationParametersGsYearCoefficientArray = res[0].valuationParameters.gsYearCoefficient; };
                if (res[0].valuationParameters.otherHalfYearCoefficient) { this.valuationParametersOtherHalfYearCoefficientArray = res[0].valuationParameters.otherHalfYearCoefficient; };
                if (res[0].valuationParameters.standardDiscountRateIndex) { this.valuationParametersStandardDiscountRateIndexArray = res[0].valuationParameters.standardDiscountRateIndex; };
                if (res[0].valuationParameters.gsDiscountRateIndex) { this.valuationParametersGsDiscountRateIndexArray = res[0].valuationParameters.gsDiscountRateIndex; };
                if (res[0].valuationParameters.otherDiscountRateIndex) { this.valuationParametersOtherDiscountRateIndexArray = res[0].valuationParameters.otherDiscountRateIndex; };
                if (res[0].valuationParameters.assetPurchaseDiscountRateIndex) { this.valuationParametersAssetPurchaseDiscountRateIndexArray = res[0].valuationParameters.assetPurchaseDiscountRateIndex; };

              }
            }
          }));
      }
    }

    this.subscription.add(
      this.projectService.getPetroleumProductArray().pipe().subscribe((res: FuelLubricantGasProduct[]) => {
        if (res) {
          this.petroleumProductArray = res;
        }
      })
    );
  }

  ngOnInit(): void {

    this.countryForm = this.fb.group({
      name: ['', Validators.required],
      unitsOfMeasure: [''],
      localCurrencyName: ['', Validators.required],
      localCurrencyISOCode: ['', Validators.required],

      id: ['', Validators.required],
      usdFxRate: [0.68, Validators.required],
      taxCarryOverPeriod: [5, Validators.required],
      inflation: [5, Validators.required],
      interestRate: [5, Validators.required],
      networkChannelFixedCosts: [0, Validators.required],
      discountRateForNetPresentValue: [0, Validators.required],
      corporateTaxRate: [30, Validators.required],

      date: [''],
    });

    this.productForm = this.fb.group({
      productId: ['', Validators.required],
      productCategory: ['', Validators.required],
    });

    this.fuelProductForm = this.fb.group({
      productId: ['', Validators.required],
      productCategory: ['', Validators.required],
      networkGlobalPrice: [0, Validators.required],
      networkEPRAMargin: [0, Validators.required],
      networkDODOMargin: [0, Validators.required],
      networkDealerMarginM3: [0, Validators.required],
      networkDealerMarginL: [0, Validators.required],
      networkDiscountGiven: [0, Validators.required],
      networkDepotCost: [0, Validators.required],
      networkGalanaPro: [0, Validators.required],
      networkVarex: [0, Validators.required],
      networkExpectedGMVE: [0, Validators.required],

      unitSupplyPrice: ['', Validators.required],
      unitaryTax: ['', Validators.required],
      unitRevenue: ['', Validators.required],
      companyUnitMargin: ['', Validators.required],

      unitGMVE: ['', Validators.required],
      gMVE: ['', Validators.required],

      monthlyVolume: ['', Validators.required],
      yearlyVolume: ['', Validators.required],
    });

    this.lubricantProductForm = this.fb.group({
      productId: ['', Validators.required],
      productCategory: ['', Validators.required],
      networkDealerMarginM3: ['', Validators.required],

      unitSupplyPrice: ['', Validators.required],
      unitaryTax: ['', Validators.required],
      unitRevenue: ['', Validators.required],
      companyUnitMargin: ['', Validators.required],

      unitGMVE: ['', Validators.required],
      gMVE: ['', Validators.required],

      monthlyVolume: ['', Validators.required],
      yearlyVolume: ['', Validators.required],
    });

    this.gasProductForm = this.fb.group({
      productId: ['', Validators.required],
      productCategory: ['', Validators.required],
      networkDealerMarginM3: ['', Validators.required],

      unitSupplyPrice: ['', Validators.required],
      unitaryTax: ['', Validators.required],
      unitRevenue: ['', Validators.required],
      companyUnitMargin: ['', Validators.required],

      unitGMVE: ['', Validators.required],
      gMVE: ['', Validators.required],

      monthlyVolume: ['', Validators.required],
      yearlyVolume: ['', Validators.required],
    });

    this.fuelVolumeGrowthForm = this.fb.group({
      y0: [0],
      y1: [0, Validators.required],
      y2: [0, Validators.required],
      y3: [0, Validators.required],
      y4: [0, Validators.required],
      y5: [4, Validators.required],
      y6: [4, Validators.required],
      y7: [4, Validators.required],
      y8: [4, Validators.required],
      y9: [4, Validators.required],
      y10: [4, Validators.required],
      y11: [4, Validators.required],
      y12: [4, Validators.required],
      y13: [4, Validators.required],
      y14: [4, Validators.required],
      y15: [4, Validators.required],
    });

    this.fuelVolumeGrowthPotentialForm = this.fb.group({
      y0: [0],
      y1: [80],
      y2: [90],
      y3: [100],
      y4: [100],
      y5: [100],
      y6: [100],
      y7: [100],
      y8: [100],
      y9: [100],
      y10: [100],
      y11: [100],
      y12: [100],
      y13: [100],
      y14: [100],
      y15: [100],
    });

    this.inflationRateForm = this.fb.group({
      y0: [0],
      y1: [0.0125000000000004],
      y2: [0.0500000000000009],
      y3: [0.0500000000000012],
      y4: [0.0500000000000012],
      y5: [0.0500000000000014],
      y6: [0.0500000000000009],
      y7: [0.0500000000000012],
      y8: [0.0500000000000012],
      y9: [0.0500000000000014],
      y10: [0.0500000000000012],
      y11: [0.0500000000000012],
      y12: [0.0500000000000012],
      y13: [0.0500000000000009],
      y14: [0.0500000000000014],
      y15: [0.0500000000000009],
      y16: [0.0500000000000009],
      y17: [0.0500000000000012],
      y18: [0.0500000000000012],
      y19: [0.0500000000000014],
      y20: [0.0500000000000009],
      y21: [0.0500000000000012],
      y22: [0.0500000000000012],
      y23: [0.0500000000000014],
      y24: [0.0500000000000012],
      y25: [0.0500000000000012],
      y26: [0.0500000000000012],
      y27: [0.0500000000000009],
      y28: [0.0500000000000014],
      y29: [0.0500000000000009],
      y30: [0.0500000000000009],
    });

    // Product
    this.subscription.add(
      this.productForm.get("productId").valueChanges.subscribe(x => {

        if (x && (this.productForm.get("productId").dirty)) {

          if (this.petroleumProductArray) {

            this.petroleumProductArray.forEach(element => {
              if (element[0].id === x) {
                if (element[0].petroleumProductCategory_name) {
                  this.showPetroleumProductForm = true;
                  this.pickedPetroleumProductCategory = element[0].petroleumProductCategory_name;
                  this.productForm.get("productCategory").setValue(element[0].petroleumProductCategory_name);

                  if (element[0].petroleumProductCategory_name === "Fuel") {
                    this.fuelProductForm.get("productId").setValue(x);
                    this.fuelProductForm.get("productCategory").setValue(element[0].petroleumProductCategory_name);
                  }

                  if (element[0].petroleumProductCategory_name === "Lubricant") {
                    this.lubricantProductForm.get("productId").setValue(x);
                    this.lubricantProductForm.get("productCategory").setValue(element[0].petroleumProductCategory_name);
                  }

                  if (element[0].petroleumProductCategory_name === "Gas") {
                    this.gasProductForm.get("productId").setValue(x);
                    this.gasProductForm.get("productCategory").setValue(element[0].petroleumProductCategory_name);
                  }

                  // Load Product
                  if (element[0].name) {
                    this.loadProductFormData(element[0].petroleumProductCategory_name, element[0].name);
                  }

                }
              }
            });
          };
        }
      }));

    this.subscription.add(
      this.fuelProductForm.get("networkDODOMargin").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkDODOMargin").dirty) && !(this.fuelProductForm.get("networkDODOMargin").pristine)) {
          // this.calculateFuelMarginFormItems();

          this.fuelProductForm.get("networkDiscountGiven").setValue(Number(this.fuelProductForm.get("networkDODOMargin").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value));
          this.fuelProductForm.get("networkEPRAMargin").setValue(Number(this.fuelProductForm.get("networkGlobalPrice").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value));
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value));
          this.fuelProductForm.get("unitGMVE").setValue(this.fuelProductForm.get("networkExpectedGMVE").value);
          this.fuelProductForm.get("companyUnitMargin").setValue((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000);
          this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("networkGlobalPrice").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkGlobalPrice").dirty) && !(this.fuelProductForm.get("networkGlobalPrice").pristine)) {
          this.fuelProductForm.get("networkDealerMarginL").setValue(Number(this.fuelProductForm.get("networkDODOMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value));
          this.fuelProductForm.get("networkEPRAMargin").setValue(Number(this.fuelProductForm.get("networkGlobalPrice").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value));
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value));
          this.fuelProductForm.get("unitGMVE").setValue(this.fuelProductForm.get("networkExpectedGMVE").value);
          this.fuelProductForm.get("companyUnitMargin").setValue((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000);
          this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("networkDealerMarginL").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkDealerMarginL").dirty) && !(this.fuelProductForm.get("networkDealerMarginL").pristine)) {
          this.fuelProductForm.get("networkDiscountGiven").setValue(Number(this.fuelProductForm.get("networkDODOMargin").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value));
          this.fuelProductForm.get("networkEPRAMargin").setValue(Number(this.fuelProductForm.get("networkGlobalPrice").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value));
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value));
          this.fuelProductForm.get("unitGMVE").setValue(this.fuelProductForm.get("networkExpectedGMVE").value);
          this.fuelProductForm.get("companyUnitMargin").setValue((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000);
          this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("networkDepotCost").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkDepotCost").dirty) && !(this.fuelProductForm.get("networkDepotCost").pristine)) {
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value));
          this.fuelProductForm.get("unitGMVE").setValue(this.fuelProductForm.get("networkExpectedGMVE").value);
          this.fuelProductForm.get("companyUnitMargin").setValue((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000);
          this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("networkGalanaPro").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkGalanaPro").dirty) && !(this.fuelProductForm.get("networkGalanaPro").pristine)) {
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value));
          this.fuelProductForm.get("unitGMVE").setValue(this.fuelProductForm.get("networkExpectedGMVE").value);
          this.fuelProductForm.get("companyUnitMargin").setValue((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000);
          this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("networkVarex").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkVarex").dirty) && !(this.fuelProductForm.get("networkVarex").pristine)) {
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value));
          this.fuelProductForm.get("unitGMVE").setValue(this.fuelProductForm.get("networkExpectedGMVE").value);
          this.fuelProductForm.get("companyUnitMargin").setValue((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000);
          this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("unitSupplyPrice").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("unitSupplyPrice").dirty) && !(this.fuelProductForm.get("unitSupplyPrice").pristine)) {
          this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("unitaryTax").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("unitaryTax").dirty) && !(this.fuelProductForm.get("unitaryTax").pristine)) {
          this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("monthlyVolume").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("monthlyVolume").dirty) && !(this.fuelProductForm.get("monthlyVolume").pristine)) {
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    // Lubricant
    this.subscription.add(
      this.lubricantProductForm.get("unitGMVE").valueChanges.subscribe(x => {
        if ((this.lubricantProductForm.get("unitGMVE").dirty) && !(this.lubricantProductForm.get("unitGMVE").pristine)) {
          this.calculateLubricantMarginFormItems();
        }
      }));

    this.subscription.add(
      this.lubricantProductForm.get("unitaryTax").valueChanges.subscribe(x => {
        if ((this.lubricantProductForm.get("unitaryTax").dirty) && !(this.lubricantProductForm.get("unitaryTax").pristine)) {
          this.calculateLubricantMarginFormItems();
        }
      }));

    this.subscription.add(
      this.lubricantProductForm.get("monthlyVolume").valueChanges.subscribe(x => {
        if ((this.lubricantProductForm.get("monthlyVolume").dirty) && !(this.lubricantProductForm.get("monthlyVolume").pristine)) {
          this.calculateLubricantMarginFormItems();
        }
      }));

    this.subscription.add(
      this.lubricantProductForm.get("unitSupplyPrice").valueChanges.subscribe(x => {
        if ((this.lubricantProductForm.get("unitSupplyPrice").dirty) && !(this.lubricantProductForm.get("unitSupplyPrice").pristine)) {
          this.calculateLubricantMarginFormItems();
        }
      }));

    // Gas
    this.subscription.add(
      this.gasProductForm.get("unitGMVE").valueChanges.subscribe(x => {
        if ((this.gasProductForm.get("unitGMVE").dirty) && !(this.gasProductForm.get("unitGMVE").pristine)) {
          this.calculateGasMarginFormItems();
        }
      }));

    this.subscription.add(
      this.gasProductForm.get("unitaryTax").valueChanges.subscribe(x => {
        if ((this.gasProductForm.get("unitaryTax").dirty) && !(this.gasProductForm.get("unitaryTax").pristine)) {
          this.calculateGasMarginFormItems();
        }
      }));

    this.subscription.add(
      this.gasProductForm.get("monthlyVolume").valueChanges.subscribe(x => {
        if ((this.gasProductForm.get("monthlyVolume").dirty) && !(this.gasProductForm.get("monthlyVolume").pristine)) {
          this.calculateGasMarginFormItems();
        }
      }));

    this.subscription.add(
      this.gasProductForm.get("unitSupplyPrice").valueChanges.subscribe(x => {
        if ((this.gasProductForm.get("unitSupplyPrice").dirty) && !(this.gasProductForm.get("unitSupplyPrice").pristine)) {
          this.calculateGasMarginFormItems();
        }
      }));
  }

  calculateLubricantMarginFormItems() {
    this.lubricantProductForm.get("companyUnitMargin").setValue((Number(this.lubricantProductForm.get("unitGMVE").value)));
    this.lubricantProductForm.get("unitRevenue").setValue(Number(this.lubricantProductForm.get("unitaryTax").value) + Number(this.lubricantProductForm.get("unitSupplyPrice").value) + Number(this.lubricantProductForm.get("companyUnitMargin").value));
    this.lubricantProductForm.get("yearlyVolume").setValue(Number(this.lubricantProductForm.get("monthlyVolume").value) * 12);
    this.lubricantProductForm.get("gMVE").setValue(Number(this.lubricantProductForm.get("unitRevenue").value) * Number(this.lubricantProductForm.get("yearlyVolume").value));
  }

  calculateGasMarginFormItems() {
    this.gasProductForm.get("companyUnitMargin").setValue((Number(this.gasProductForm.get("unitGMVE").value)));
    this.gasProductForm.get("unitRevenue").setValue(Number(this.gasProductForm.get("unitaryTax").value) + Number(this.gasProductForm.get("unitSupplyPrice").value) + Number(this.gasProductForm.get("companyUnitMargin").value));
    this.gasProductForm.get("yearlyVolume").setValue(Number(this.gasProductForm.get("monthlyVolume").value) * 12);
    this.gasProductForm.get("gMVE").setValue(Number(this.gasProductForm.get("unitRevenue").value) * Number(this.gasProductForm.get("yearlyVolume").value));
  }

  calculateFuelMarginFormItems() {
    this.fuelProductForm.get("networkDiscountGiven").setValue(Number(this.fuelProductForm.get("networkDODOMargin").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value));
    this.fuelProductForm.get("networkEPRAMargin").setValue(Number(this.fuelProductForm.get("networkGlobalPrice").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value));
    this.fuelProductForm.get("networkExpectedGMVE").setValue(Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value));
    this.fuelProductForm.get("unitGMVE").setValue(this.fuelProductForm.get("networkExpectedGMVE").value);
    this.fuelProductForm.get("companyUnitMargin").setValue((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000);
    this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
    this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
    this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
  }

  addProduct(load: any) {
    console.log(`pickedPetroleumProductCategory - ${this.pickedPetroleumProductCategory}`);

    if (this.pickedPetroleumProductCategory === 'Fuel') {

      // Checks
      if (this.fuelProductForm.get("productId").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkGlobalPrice").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network global price!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network global price!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkDODOMargin").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network DODO margin!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network DODO margin!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkDealerMarginL").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network dealer margin!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network dealer margin!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkDiscountGiven").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network discount given!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network discount given!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkDepotCost").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network depot cost!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network depot cost!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkGalanaPro").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network galana pro!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network galana pro!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkVarex").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network varex!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network varex!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }


      // Checks
      if (this.fuelProductForm.get("networkExpectedGMVE").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network expected GMVE!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network expected GMVE!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("unitSupplyPrice").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product unit supply price!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unit supply price!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("unitaryTax").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product unitary tax!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unitary tax!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      this.loading = true;
      this.subscription.add(
        this.projectService.updateCountryPetroleumProduct(this.countryId, load)
          .pipe(first()).subscribe((result) => {
            // navigates to confirm mail screen
            // this.router.navigate(['/account/confirm-email']);
            this.formSubmitted = true;
            this.loading = false;

            // Clear form
            this.showForm = false;
            this.showPetroleumProductForm = false;
            this.productForm.reset();
            this.countryForm.reset();
            this.fuelProductForm.reset();
            this.lubricantProductForm.reset();
            this.gasProductForm.reset();

          },
            (error: string) => {
              this.error = error;
              this.loading = false;
            }));
    };

    if (this.pickedPetroleumProductCategory === 'Lubricant') {

      // Checks
      if (this.lubricantProductForm.get("productId").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.lubricantProductForm.get("networkDealerMarginM3").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network dealer margin!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network dealer margin!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.lubricantProductForm.get("unitSupplyPrice").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product unit supply price!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unit supply price!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.lubricantProductForm.get("unitaryTax").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product unitary tax!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unitary tax!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      this.loading = true;
      this.subscription.add(
        this.projectService.updateCountryPetroleumProduct(
          this.countryId,
          load,
        )
          .pipe(first()).subscribe((result) => {
            // navigates to confirm mail screen
            // this.router.navigate(['/account/confirm-email']);
            this.formSubmitted = true;
            this.loading = false;

            // Clear form
            this.showForm = false;
            this.showPetroleumProductForm = false;
            this.productForm.reset();
            this.countryForm.reset();
            this.fuelProductForm.reset();
            this.lubricantProductForm.reset();
            this.gasProductForm.reset();

          },
            (error: string) => {
              this.error = error;
              this.loading = false;
            }));

    };

    if (this.pickedPetroleumProductCategory === 'Gas') {

      // Checks
      if (this.gasProductForm.get("productId").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.gasProductForm.get("networkDealerMarginM3").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network dealer margin!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network dealer margin!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.gasProductForm.get("unitSupplyPrice").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product unit supply price!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unit supply price!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.gasProductForm.get("unitaryTax").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product unitary tax!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unitary tax!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      this.loading = true;
      this.subscription.add(
        this.projectService.updateCountryPetroleumProduct(this.countryId, load)
          .pipe(first()).subscribe((result) => {
            // navigates to confirm mail screen
            // this.router.navigate(['/account/confirm-email']);
            this.formSubmitted = true;
            this.loading = false;

            // Clear form
            this.showPetroleumProductForm = false;
            this.showForm = false;
            this.productForm.reset();
            this.countryForm.reset();
            this.fuelProductForm.reset();
            this.lubricantProductForm.reset();
            this.gasProductForm.reset();

          },
            (error: string) => {
              this.error = error;
              this.loading = false;
            }));

    };

  }

  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  updateCountryDetails(load: any): void {

    // Checks
    if (this.countryForm.controls['id'].invalid) {
      this.show_alert = true;
      this.alert_message = "Country Id is missing!";
      this.alert_type = "danger";

      this.toastr.error('Country Id is missing!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.controls['name'].invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the name!";
      this.alert_type = "danger";

      this.toastr.error('Provide the name!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("unitsOfMeasure").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the units of measurement!";
      this.alert_type = "danger";

      this.toastr.error('Provide the units of measurement!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("localCurrencyName").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the local currency name!";
      this.alert_type = "danger";

      this.toastr.error('Provide the local currency name!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("localCurrencyISOCode").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the local currency ISO Code!";
      this.alert_type = "danger";

      this.toastr.error('Provide the local currency ISO Code!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("corporateTaxRate").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the Corporate Tax Rate!";
      this.alert_type = "danger";

      this.toastr.error('Provide the Corporate Tax Rate!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("taxCarryOverPeriod").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the Tax Carry Over Period!";
      this.alert_type = "danger";

      this.toastr.error('Provide the Tax Carry Over Period!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("discountRateForNetPresentValue").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the Discount Rate For Net Present Value (NPV)!";
      this.alert_type = "danger";

      this.toastr.error('Provide the Discount Rate For Net Present Value (NPV)!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("usdFxRate").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the USD Fx Rate!";
      this.alert_type = "danger";

      this.toastr.error('Provide the USD Fx Rate!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("inflation").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the Inflation Rate!";
      this.alert_type = "danger";

      this.toastr.error('Provide the Inflation Rate!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("interestRate").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the Inflation Rate!";
      this.alert_type = "danger";

      this.toastr.error('Provide the Inflation Rate!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    this.loading = true;
    this.subscription.add(
      this.petroleumProductsService.updateCountry(load)
        .pipe(first()).subscribe((result) => {
          // navigates to confirm mail screen
          // this.router.navigate(['/account/confirm-email']);
          this.formSubmitted = true;
          this.loading = false;
          this.showForm = false;

          // Clear form
          this.productForm.reset();
          this.countryForm.reset();
          this.fuelProductForm.reset();
          this.lubricantProductForm.reset();
          this.gasProductForm.reset();

          // navigate via window to same page
          window.location.href = '/portal/country-admin';

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
          }));

  }

  loadProductFormData(productCategory: string, productName: string) {
    if (productCategory === "Fuel") {
      // Get Fuel Data
      if (this.selectedCountry) {
        if (this.selectedCountry.fuelProduct) {
          let load: any = this.selectedCountry.fuelProduct;

          load.forEach((element: FuelProduct) => {
            if (element.name === productName) {
              this.productExists_Update = true;
              if (element.id) { this.fuelProductForm.get("productId").setValue(element.id); };
              if (element.networkGlobalPrice) { this.fuelProductForm.get("networkGlobalPrice").setValue(element.networkGlobalPrice); };
              if (element.networkEPRAMargin) { this.fuelProductForm.get("networkEPRAMargin").setValue(element.networkEPRAMargin); };
              if (element.networkDODOMargin) { this.fuelProductForm.get("networkDODOMargin").setValue(element.networkDODOMargin); };
              if (element.networkDealerMarginL) { this.fuelProductForm.get("networkDealerMarginL").setValue(element.networkDealerMarginL); };
              if (element.networkDiscountGiven) { this.fuelProductForm.get("networkDiscountGiven").setValue(element.networkDiscountGiven); };
              if (element.networkDepotCost) { this.fuelProductForm.get("networkDepotCost").setValue(element.networkDepotCost); };
              if (element.networkGalanaPro) { this.fuelProductForm.get("networkGalanaPro").setValue(element.networkGalanaPro); };
              if (element.networkVarex) { this.fuelProductForm.get("networkVarex").setValue(element.networkVarex); };
              if (element.networkExpectedGMVE) { this.fuelProductForm.get("networkExpectedGMVE").setValue(element.networkExpectedGMVE); };
              if (element.unitSupplyPrice) { this.fuelProductForm.get("unitSupplyPrice").setValue(element.unitSupplyPrice); };
              if (element.unitaryTax) { this.fuelProductForm.get("unitaryTax").setValue(element.unitaryTax); };
              if (element.unitRevenue) { this.fuelProductForm.get("unitRevenue").setValue(element.unitRevenue); };
              if (element.companyUnitMargin) { this.fuelProductForm.get("companyUnitMargin").setValue(element.companyUnitMargin); };
              if (element.monthlyVolume) { this.fuelProductForm.get("monthlyVolume").setValue(element.monthlyVolume); };
              if (element.yearlyVolume) { this.fuelProductForm.get("yearlyVolume").setValue(element.yearlyVolume); };
              if (element.unitGMVE) { this.fuelProductForm.get("unitGMVE").setValue(element.unitGMVE ? element.unitGMVE : 0); };
              if (element.gMVE) { this.fuelProductForm.get("gMVE").setValue(element.gMVE); };
            }
          });

        }
      }

    }

    if (productCategory === "Lubricant") {
      // Get Fuel Data
      if (this.selectedCountry) {
        if (this.selectedCountry.lubricantProduct) {
          let load: any = this.selectedCountry.lubricantProduct;

          load.forEach((element: LubricantProduct) => {
            if (element.name === productName) {
              this.productExists_Update = true;
              if (element.id) { this.lubricantProductForm.get("productId").setValue(element.id); };
              if (element.networkDealerMarginM3) { this.lubricantProductForm.get("networkDealerMarginM3").setValue(element.networkDealerMarginM3); };
              if (element.unitSupplyPrice) { this.lubricantProductForm.get("unitSupplyPrice").setValue(element.unitSupplyPrice); };
              if (element.unitaryTax) { this.lubricantProductForm.get("unitaryTax").setValue(element.unitaryTax); };
              if (element.unitRevenue) { this.lubricantProductForm.get("unitRevenue").setValue(element.unitRevenue); };
              if (element.companyUnitMargin) { this.lubricantProductForm.get("companyUnitMargin").setValue(element.companyUnitMargin); };
              if (element.unitGMVE) { this.lubricantProductForm.get("unitGMVE").setValue(element.unitGMVE ? element.unitGMVE : 0); };
              if (element.gMVE) { this.lubricantProductForm.get("gMVE").setValue(element.gMVE); };
              if (element.monthlyVolume) { this.lubricantProductForm.get("monthlyVolume").setValue(element.monthlyVolume); };
              if (element.yearlyVolume) { this.lubricantProductForm.get("yearlyVolume").setValue(element.yearlyVolume); };
            }
          });

        }
      }

    }

    if (productCategory === "Gas") {
      // Get Fuel Data
      if (this.selectedCountry) {
        if (this.selectedCountry.gasProduct) {
          let load: any = this.selectedCountry.gasProduct;

          load.forEach((element: GasProduct) => {
            if (element.name === productName) {
              this.productExists_Update = true;
              if (element.id) { this.gasProductForm.get("productId").setValue(element.id); };
              if (element.networkDealerMarginM3) { this.gasProductForm.get("networkDealerMarginM3").setValue(element.networkDealerMarginM3); };
              if (element.unitSupplyPrice) { this.gasProductForm.get("unitSupplyPrice").setValue(element.unitSupplyPrice); };
              if (element.unitaryTax) { this.gasProductForm.get("unitaryTax").setValue(element.unitaryTax); };
              if (element.unitRevenue) { this.gasProductForm.get("unitRevenue").setValue(element.unitRevenue); };
              if (element.companyUnitMargin) { this.gasProductForm.get("companyUnitMargin").setValue(element.companyUnitMargin); };
              if (element.unitGMVE) { this.gasProductForm.get("unitGMVE").setValue(element.unitGMVE ? element.unitGMVE : 0); };
              if (element.gMVE) { this.gasProductForm.get("gMVE").setValue(element.gMVE); };
              if (element.monthlyVolume) { this.gasProductForm.get("monthlyVolume").setValue(element.monthlyVolume); };
              if (element.yearlyVolume) { this.gasProductForm.get("yearlyVolume").setValue(element.yearlyVolume); };
            }
          });

        }
      }

    }

    // Load Fuel Form


  }

  updateInflation(load: any) {
    this.loading = true;
    this.subscription.add(
      this.petroleumProductsService.updateCountryInflation(this.countryId, load)
        .pipe(first()).subscribe((result) => {
          // navigates to confirm mail screen
          // this.router.navigate(['/account/confirm-email']);
          this.formSubmitted = true;
          this.loading = false;
          this.showForm = false;
        },
          (error: string) => {
            this.error = error;
            this.loading = false;
          }));
  }

  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }

  get countryFormValues() {
    return this.countryForm.controls;
  }
  get productFormValues() {
    return this.productForm.controls;
  }
  get gasProductFormValues() {
    return this.gasProductForm.controls;
  }
  get lubricantProductFormValues() {
    return this.lubricantProductForm.controls;
  }
  get fuelProductFormValues() {
    return this.fuelProductForm.controls;
  }

  hideProductMenu() {
    this.showPetroleumProductForm = false;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
