import { Route } from "@angular/router";
import { MenuItem } from "../layouts/shared/models/menu.model";
import { FaqComponent } from "./faq/faq.component";
import { GuideComponent } from "./guide/guide.component";

export const HELP_MENU: MenuItem[] = [
    { key: 'ds-help', label: 'Help Navigation', isTitle: true },
    { key: 'ds-faq', label: 'FAQ', isTitle: false, icon: 'uil-home-alt', link: '/football/faq' },
    { key: 'ds-guide', label: 'Guides', isTitle: false, icon: 'uil-home-alt', link: '/football/guide' },
  ]

export const HELP_ROUTES: Route[] = [
    { path: '', redirectTo: 'faq', pathMatch: 'full', },
    {
        path: 'faq',
        /* canActivateChild: [AuthGuard, RegGuard], */
        children: [
            { path: '', component: FaqComponent, },
        ]
    },
    {
        path: 'guides',
        /* canActivateChild: [AuthGuard, RegGuard], */
        children: [
            { path: '', component: GuideComponent, },
        ]
    },
];