import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(
    private fns: AngularFireFunctions,
  ) { }

  addProjectDocument2(cid: string, pid: string, load: object) {
    const callable = this.fns.httpsCallable('addPD');
    return callable({
      cid: cid,
      pid: pid,
      load: load,
    });
  }

}

