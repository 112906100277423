import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { NgbAlertModule, NgbProgressbarModule, NgbButtonsModule, NgbAlert, NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AdvancedTableModule } from 'src/app/shared/advanced-table/advanced-table.module';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { Subscription, first } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@firebase/auth-types';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { ClockService } from 'src/app/core/service/clock.service';
import { UserService } from 'src/app/core/service/user.service';
import { ProjectService } from 'src/app/core/service/project.service';
import { Column, AdvancedTableComponent } from 'src/app/shared/advanced-table/advanced-table.component';
import { SortEvent } from 'src/app/shared/advanced-table/sortable.directive';
import { BreadcrumbItem } from 'src/app/shared/page-title/page-title.model';
import { ProjectDetails } from 'src/app/core/models-two/ProjectDetails';

@Component({
  selector: 'app-project-list',
  standalone: true,
  imports: [
    CommonModule,
    AdvancedTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbProgressbarModule,
    WidgetModule,
    NgbButtonsModule,
    NgbTooltipModule,
  ],
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit, OnDestroy {


  subscription: Subscription = new Subscription();
  today: number = Date.now();
  pageTitle: BreadcrumbItem[] = [];
  //projectArray!: Client[];
  pageSizeOptions: number[] = [5, 10, 20, 50];
  pageSize: number = 5;
  selectAll: boolean = false;
  loading: boolean = false;
  columns: Column[] = [];
  @ViewChild('profileModal', { static: false }) private profileModal: any;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  @ViewChild('advancedTable') advancedTable!: AdvancedTableComponent;

  projectForm!: FormGroup;
  formSubmitted: boolean = false;

  show_alert: boolean;
  alert_message: string;
  alert_type: string;

  loggedInUser: User;
  projectObject: ProjectDetails;
  projectArray;
  userId: any;

  destinationLatitude: number;
  destinationLongitude: number;
  destinationZoom: number;
  googleLocationAddressLong: string;
  googleLocationAddressShort: any;
  profilePicture: any;
  invalidPhoneNumber: boolean;
  pickedImage: any;

  error: string;
  showForm: boolean = false;
  userCategoryViewChoice: number = 1;
  dateNow2: any;
  dateNow3: Date;
  newInviteId: any;
  defaultProjectArray: any[] = [];
  defaultProjectObject: any;
  importedProjectArray: ProjectDetails[] = [];
  showImportPanel: boolean = false;
  disableDownloadButton: boolean = false;
  alphabeticalProjectArray: ProjectDetails[] = [];
  workingProjectArray: ProjectDetails[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private projectService: ProjectService,
    private clockService: ClockService,
  ) {
    this.loadingAnimationFourSeconds();
    this.subscription.add(
      authenticationService.currentUser.pipe().subscribe((res: any) => {
        if (res) {
          if (res[0]) {
            this.loggedInUser = res[0];
            if (res[0].userId) {
              this.userId = res[0].userId;
            }
          } else {
          }
        }
      })
    );
  }

  ngOnInit(): void {

    // get user data
    this.getProjectList();
    // initialize advance table 
    this.initAdvancedTableData();

    this.projectForm = this.fb.group({
      id: [''],
      index: [''],
      name: [''],
      currencyName: [''],
      currencyMagnitudeDealer: [''],
      currencyMagnitudeCompany: [''],
      unitsOfMeasure: [''],
      localCurrencyName: [''],
      localCurrencyISOCode: [''],
      corporateTaxRate: [''],
      taxCarryOverPeriod: [''],
      discountRate: [''],
      fxRate: [''],
      taxFuelTurnover: [''],
      product: [''],
      inflation: [''],

      created: [''],
    });

  }

  ngAfterViewInit(): void {

  }


  /**
   * convenience getter for easy access to form fields
   */
  get formValues() {
    return this.projectForm.controls;
  }


  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  loadingAnimationStart() {
    this.loading = true;
  }

  loadingAnimationStop() {
    this.loading = false;
  }

  getProjectList() {
    //this.petroleumProductArray = users;
    this.subscription.add(
      this.projectService.getProjectArray().pipe().subscribe((userList: any) => {
        if (userList) {
          this.workingProjectArray = [];
          this.defaultProjectArray = this.projectArray = userList;

          // Arranged Alphabetically
          userList.forEach(element => {
            this.workingProjectArray.push(element[0]);
          });

          this.alphabeticalProjectArray = this.workingProjectArray.sort((a: any, b: any) => (a.firstName).localeCompare(b.firstName));

        }
      })
    );

    this.subscription.add(
      this.projectService.getProjectObject().pipe().subscribe((userList) => {
        if (userList) {
          this.defaultProjectObject = this.projectObject = userList;
        }
      })
    );
  }

  /**
   * initialize advance table columns
   */
  initAdvancedTableData(): void {
    this.columns = [
      {
        name: 'name',
        label: 'Name',
        formatter: (p: ProjectDetails[]) => p[0].name,
      },
      {
        name: 'created',
        label: 'Date Created',
        formatter: (p: ProjectDetails[]) => {
          if (p[0].created) {
            return (new Date(p[0].created)).toLocaleString();
          } else {

          }
        },
      },
      {
        name: 'appraisal progress',
        label: 'Appraisal Progress',
        formatter: this.appraisalProgressFormatter.bind(this),
        sort: false
      },
      {
        name: 'report progress',
        label: 'Report Progress',
        formatter: this.reportProgressFormatter.bind(this),
        sort: false
      },
      {
        name: 'quick link',
        label: 'Quick Links',
        formatter: this.userActionFormatter.bind(this),
        sort: false
      }
    ]
  }

  /**
   * handles operations that need to be performed after loading table
   */
  handleTableLoad(): void {
    // user cell
    document.querySelectorAll('.report').forEach((e) => {
      e.addEventListener("click", () => {
        //Navigate to report page
        this.router.navigate([`portal/report/${e.id}`]);
      });
    })
    document.querySelectorAll('.appraisal').forEach((e) => {
      e.addEventListener("click", () => {
        //Navigate to appraisal page
        this.router.navigate([`portal/project/${e.id}`]);
      });
    })
    document.querySelectorAll('.appraisalSummary').forEach((e) => {
      e.addEventListener("click", () => {
        //Navigate to appraisal page
        this.router.navigate([`portal/result/${e.id}`]);
      });
    })
    document.querySelectorAll('.postInvestment').forEach((e) => {
      e.addEventListener("click", () => {
        //Navigate to appraisal page
        this.router.navigate([`portal/post-investment/${e.id}`]);
      });
    })
    document.querySelectorAll('.delete').forEach((e) => {
      e.addEventListener("click", () => {
        if (confirm("Are you sure you want to delete this project?")) {
          // Move item to petroleumProduct/delete
          this.deleteProject(e.id);
        }
      });
    })
  }


  deleteProject(id: string) {

    let projectName = "";
    if (this.defaultProjectObject) { if (this.defaultProjectObject[id]) { if (this.defaultProjectObject[id][0]) { if (this.defaultProjectObject[id][0].name) { projectName = this.defaultProjectObject[id][0].name; }; }; }; };

    this.subscription.add(
      this.projectService.deleteProject(id)
        .pipe(first()).subscribe((result) => {

          //Get User Data
          this.show_alert = true;
          this.alert_message = `${projectName} Deleted!`;
          this.alert_type = "success";

          this.toastr.success(`${projectName} Deleted!`, "Info!");

          setTimeout(() => {
            this.show_alert = false;
            this.selfClosingAlert.close();
          }, 3000);

        },
          (error: string) => {
            this.toastr.error(`${projectName} was not Deleted, error: ${error}!`, "Info!");
            this.error = error;
            this.loading = false;
          }));
  }


  // table user formatter
  userNameFormatter(a: ProjectDetails): SafeHtml {
    let userCell: string = "";
    userCell += `<p class='m-0 d-inline-block align-middle font-16'>`
    if (a[0]) {
      if (a[0].name) {
        userCell += `${a[0].name} `
      }
      if (a[0].petroleumProductCategory_name) {
        userCell += `${a[0].petroleumProductCategory_name} `
      }
    }
    userCell = userCell + `</p>`
    return this.sanitizer.bypassSecurityTrustHtml(userCell);
  }

  codeFormatter(a: ProjectDetails): SafeHtml {
    let userCell: string = "";
    //Category for each
    if (a[0]) {
      if (a[0].id) {
        userCell = `<p class='m-0 d-inline-block align-middle'>${a[0].id}</p>`
      } else {
        userCell = `<p class='m-0 d-inline-block align-middle text-danger">Missing</p>`
      };
    } else {
      userCell = `<p class='m-0 d-inline-block align-middle text-danger">Missing</p>`
    }
    return this.sanitizer.bypassSecurityTrustHtml(userCell);
  }

  // table action cell formatter
  userActionFormatter(a:ProjectDetails): SafeHtml {
    if (a[0]) {
      if (a[0].id) {
        return this.sanitizer.bypassSecurityTrustHtml(
          `<button id="${a[0].id}" class="btn btn-sm btn-outline-secondary mb-1 mb-sm-1 mb-md-1 mb-lg-1 mb-xl-1 appraisal" type="button" *ngIf="!updateInProgress"> <i class=""></i>Appraisal View/Edit</button>
          <button id="${a[0].id}" class="btn btn-sm btn-outline-secondary mb-1 mb-sm-1 mb-md-1 mb-lg-1 mb-xl-1 appraisalSummary" type="button" *ngIf="!updateInProgress"> <i class=""></i>Appraisal Summary</button>
            <button id="${a[0].id}" class="btn btn-sm btn-outline-secondary mb-1 mb-sm-1 mb-md-1 mb-lg-1 mb-xl-1 report" type="button" *ngIf="!updateInProgress"> <i class=""></i>Report</button>
            <button id="${a[0].id}" class="btn btn-sm btn-outline-secondary mb-1 mb-sm-1 mb-md-1 mb-lg-1 mb-xl-1 postInvestment" type="button" *ngIf="!updateInProgress"> <i class=""></i>Post Investment</button>
            <button id="${a[0].id}" class="btn btn-sm btn-outline-danger mb-1 mb-sm-1 mb-md-1 mb-lg-1 mb-xl-1 delete" type="button" *ngIf="!updateInProgress"> <i class=""></i>X</button>`
        )
      }
    }
  }

  appraisalProgressFormatter(a:ProjectDetails): SafeHtml {
    let userCell: string = "";
    if (a[0]) {
      if (a[0].setupProgress) {
        userCell += `<p class='m-0 d-inline-block align-middle'>${(a[0].setupProgress).toFixed(2)}%</p>`
      }
      if (a[0].setupProgress === 0) {
        userCell += `<p class='m-0 d-inline-block align-middle'>0%</p>`
      }
      return this.sanitizer.bypassSecurityTrustHtml(userCell);
    }

  }

  reportProgressFormatter(a:ProjectDetails): SafeHtml {
    let userCell: string = "";
    if (a[0]) {
      if (a[0].setupProgress) {
        userCell += `<p class='m-0 d-inline-block align-middle'>${(a[0].setupProgress).toFixed(2)}%</p>`
      }
      if (a[0].setupProgress === 0) {
        userCell += `<p class='m-0 d-inline-block align-middle'>0%</p>`
      }
      return this.sanitizer.bypassSecurityTrustHtml(userCell);
    }
  }

  numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  // compares two cell valueuser?: ProjectDetails;
  user_complete?: boolean;
  compare(v1: string | number | boolean, v2: string | number | boolean): number {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  /**
   * Sort the table data
   * @param event column name,sort direction
   */
  sort(event: SortEvent): void {
    if (event.direction === '') {
      this.projectArray = this.defaultProjectArray;
      //this.getProjectList();
    } else {
      this.projectArray = [...this.projectArray].sort((a, b) => {
        const res = this.compare(a["0"][event.column], b["0"][event.column]);
        return event.direction === 'asc' ? res : -res;
      });
    }
  }

  /**
 * Table Data Match with Search input
 * @param tableRow Table row
 * @param term Search the value
 */
  matches(tableRow: ProjectDetails, term: string) {
    return tableRow[0].name?.toLowerCase().includes(term)
      || tableRow[0].petroleumProductCategory_name?.toLowerCase().includes(term);
  }

  /**
   * Search Method
  */
  searchData(searchTerm: string): void {
    if (searchTerm === '') {
      // this.getProjectList();
      this.projectArray = this.defaultProjectArray;
    }
    else {
      let updatedData = this.projectArray = this.defaultProjectArray;
      //  filter
      updatedData = updatedData.filter(a => this.matches(a, searchTerm));
      this.projectArray = updatedData;
    }
  }

  hasChanged($event: any) {
    // Clear all other address details?
  }

  generateId() {
    // Get new Id
    this.subscription.add(
      this.projectService.getProjectId().pipe(first()).subscribe((result) => {
        if (result) {
          this.newInviteId = result;
          this.projectForm.get("id").setValue(result);
        }
      })
    );
  }

  createRecord() {
    this.router.navigate([`portal/project/new`]);
  }

  editForm(id: string) {

    // Show new tool form
    this.showForm = true;

    // Clear form
    this.projectForm.reset();

    //Populate Form
    if (this.projectObject) {
      if (this.projectObject[id]) {
        if (this.projectObject[id][0]) {
          if (this.projectObject[id][0].id) { this.projectForm.get("id").setValue(this.projectObject[id][0].id); };
          if (this.projectObject[id][0].index) { this.projectForm.get("index").setValue(this.projectObject[id][0].index); };
          if (this.projectObject[id][0].name) { this.projectForm.get("name").setValue(this.projectObject[id][0].name); };

          if (this.projectObject[id][0].currencyName) { this.projectForm.get("currencyName").setValue(this.projectObject[id][0].currencyName); };
          if (this.projectObject[id][0].currencyMagnitudeDealer) { this.projectForm.get("currencyMagnitudeDealer").setValue(this.projectObject[id][0].currencyMagnitudeDealer); };
          if (this.projectObject[id][0].currencyMagnitudeCompany) { this.projectForm.get("currencyMagnitudeCompany").setValue(this.projectObject[id][0].currencyMagnitudeCompany); };
          if (this.projectObject[id][0].unitsOfMeasure) { this.projectForm.get("unitsOfMeasure").setValue(this.projectObject[id][0].unitsOfMeasure); };
          if (this.projectObject[id][0].localCurrencyName) { this.projectForm.get("localCurrencyName").setValue(this.projectObject[id][0].localCurrencyName); };
          if (this.projectObject[id][0].localCurrencyISOCode) { this.projectForm.get("localCurrencyISOCode").setValue(this.projectObject[id][0].localCurrencyISOCode); };
          if (this.projectObject[id][0].corporateTaxRate) { this.projectForm.get("corporateTaxRate").setValue(this.projectObject[id][0].corporateTaxRate); };
          if (this.projectObject[id][0].taxCarryOverPeriod) { this.projectForm.get("taxCarryOverPeriod").setValue(this.projectObject[id][0].taxCarryOverPeriod); };
          if (this.projectObject[id][0].discountRate) { this.projectForm.get("discountRate").setValue(this.projectObject[id][0].discountRate); };
          if (this.projectObject[id][0].fxRate) { this.projectForm.get("fxRate").setValue(this.projectObject[id][0].fxRate); };
          if (this.projectObject[id][0].taxFuelTurnover) { this.projectForm.get("taxFuelTurnover").setValue(this.projectObject[id][0].taxFuelTurnover); };
          if (this.projectObject[id][0].product) { this.projectForm.get("product").setValue(this.projectObject[id][0].product); };
          if (this.projectObject[id][0].inflation) { this.projectForm.get("inflation").setValue(this.projectObject[id][0].inflation); };
          if (this.projectObject[id][0].created) { this.projectForm.get("created").setValue(this.projectObject[id][0].created); };

        }
      }
    }
  }

  cancelUpdate() {
    // Show new tool form
    this.showForm = false;

    // Clear form
    this.projectForm.reset();

  }

  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}


