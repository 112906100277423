import { CommonModule, formatCurrency, formatDate, ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { PRIMARY_OUTLET, Router, RouterLink } from '@angular/router';
import { NgbAlert, NgbAlertModule, NgbModal, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, first } from 'rxjs';
import { ProjectDetails } from 'src/app/core/models-two/ProjectDetails';
import { IpGeoService } from 'src/app/core/service/ip-geo.service';
import { PetroleumProductsService } from 'src/app/core/service/petroleum-products.service';
import { ProjectService } from 'src/app/core/service/project.service';
import { FooterComponent } from 'src/app/shared/footer/footer.component';
import { GiftSecondNavbarComponent } from 'src/app/shared/gift-second-navbar/gift-second-navbar.component';
import { NavbarComponent } from 'src/app/shared/navbar/navbar.component';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ApexNonAxisChartSeries, ApexChart, ApexPlotOptions, ApexAxisChartSeries, ApexStroke, ApexDataLabels, ApexXAxis, ApexLegend, ApexFill, ApexGrid, ApexYAxis, ApexTooltip, ApexResponsive, ApexTitleSubtitle, ApexAnnotations, ApexMarkers, ApexStates, } from "ng-apexcharts";
import { FileStorageService } from 'src/app/core/service/file-storage.service';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { AppUser } from 'src/app/core/models-two/AppUser';
import { CompanyInvestment } from 'src/app/core/models-two/CompanyInvestment';
import { CompanyInvestmentItem } from 'src/app/core/models-two/CompanyInvestmentItem';
import { CompanySummary } from 'src/app/core/models-two/CompanySummary';
import { CompanyFinancing } from 'src/app/core/models-two/CompanyFinancing';
import { RemarkItem } from 'src/app/core/models-two/RemarkItem';
import { SensitivityAnalysis } from 'src/app/core/models-two/SensitivityAnalysis';
import { StationMapLocation } from 'src/app/core/models-two/StationMapLocation';
import { StationMapStreetView } from 'src/app/core/models-two/StationMapStreetView';
import { StationCompetitor } from 'src/app/core/models-two/StationCompetitor';
import { Volkhov } from 'src/assets/fonts/Volkhov-normal';

import jsPDF from 'jspdf';

const SERIES = {
  monthDataSeries1: {
    prices: [
      -0.92724,
      -0.46485,
      -0.20001,
      -0.04085,
      0.04773,
      0.10296,
      0.13943,
      0.16464,
      0.18254,
      0.27646,
      0.27646,
      0.27646,
      0.27646,
      0.27646,
      0.27646,
      0.27646,
      0.27646,
      0.27646,
      0.27646,
      0.27646
    ],
    dates: [
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042"
    ]
  },
  monthDataSeries2: {
    prices: [
      -0.92724,
      -0.46485,
      -0.20001,
      -0.04085,
      0.04773,
      0.10296,
      0.13943,
      0.16464,
      0.18254,
      0.21437,
      0.21437,
      0.21437,
      0.21437,
      0.21437,
      0.21437,
      0.21437,
      0.21437,
      0.21437,
      0.21437,
      0.21437
    ],
    dates: [
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042"
    ]
  },
  monthDataSeries3: {
    prices: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0
    ],
    dates: [
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042"
    ]
  }
};

type MapConfig = {
  lat: number;
  lng: number;
  title?: string;
  markers?: MapConfig[];
  styles?: any[];
}

interface ApexChartOptions {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  chart: ApexChart;
  plotOptions: ApexPlotOptions;
  labels: string[];
  colors: string[];
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  xaxis: ApexXAxis;
  legend: ApexLegend;
  tooltip: ApexTooltip;
  fill: ApexFill;
  grid: ApexGrid;
  title: ApexTitleSubtitle;
  annotations: ApexAnnotations;
  markers: ApexMarkers;
  states: ApexStates;
  responsive: ApexResponsive[];
  yaxis: ApexYAxis | ApexYAxis[];
  subtitle: ApexTitleSubtitle
}

@Component({
  selector: 'app-report',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WidgetModule,
    NgbAlertModule,
    NavbarComponent,
    FooterComponent,
    RouterLink,
    GiftSecondNavbarComponent,
    NgbProgressbarModule,
    NgbTooltipModule,
    GoogleMapsModule,
    NgApexchartsModule,
  ],
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy, AfterViewInit {

  // Forms
  paybackPeriodRemarkForm!: FormGroup;
  mapEditForm!: FormGroup;
  newCompetitorForm!: FormGroup;
  netPresentValueRemarkForm!: FormGroup;
  accountingRateOfReturnRemarkForm!: FormGroup;
  discountedPaybackPeriodRemarkForm!: FormGroup;
  profitabilityRemarkForm!: FormGroup;
  sensitivityRemarkForm!: FormGroup;
  mapLocationRemarkForm!: FormGroup;

  streetViewOneForm!: FormGroup;
  streetViewTwoForm!: FormGroup;
  streetViewThreeForm!: FormGroup;
  streetViewFourForm!: FormGroup;

  // Chart
  lineChartOptions2: Partial<ApexChartOptions> = {};
  // Chart

  // Map
  zoom = 18;
  center: google.maps.LatLngLiteral = { lat: -1.129573, lng: 36.9845406, };
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: false,
    disableDefaultUI: true,
    fullscreenControl: false,
    disableDoubleClickZoom: true,
    mapTypeId: 'roadmap',
    // maxZoom:this.maxZoom,
    // minZoom:this.minZoom,
  };
  markerOptions: google.maps.MarkerOptions = { draggable: false, label: `Delta Service Station`, title: `Delta Service Station` };
  markerPosition: { lat: number; lng: number; } = { lat: -1.129573, lng: 36.9845406, };
  oneView: any;
  authState: boolean;
  user: AppUser;
  userId: any;
  oneViewData: { url: any; name: any; size: string; formatType: any; imageId: string; uploadBy: any; width: number; height: number; date: number; };
  fourViewPicture: any;
  fourViewData: { url: any; name: any; size: string; formatType: any; imageId: string; uploadBy: any; width: number; height: number; date: number; };
  threeViewData: { url: any; name: any; size: string; formatType: any; imageId: string; uploadBy: any; width: number; height: number; date: number; };
  twoViewPicture: any;
  twoViewData: { url: any; name: any; size: string; formatType: any; imageId: string; uploadBy: any; width: number; height: number; date: number; };
  oneViewPicture: any;
  threeViewPicture: any;
  pickedImage: any;
  npvIntro: string;
  paybackPeriodIntro: string;
  discountedPaybackPeriodIntro: string;
  accountingRateOfReturnIntro: string;
  npvRecommended: string;
  paybackPeriodRecommended: string;
  discountedPaybackPeriodRecommended: string;
  accountingRateOfReturnRecommended: string;
  profitabilityRecommended: string;
  sensitivityRecommended: string;
  projectAppraisalNpv: number = 0;
  projectAppraisalPaybackPeriod: number = 0;
  projectAppraisalDiscountedPaybackPeriod: number = 0;
  projectAppraisalAccountingRateOfReturn: number = 0;

  projectName: string = "Revamping of Thika Rd Convenience Shop & Restaurant.";
  projectLocationDescription: string = "Ruiru";
  projectDescription: string = "Upgrade of current shop and modernize the look and feel with a restaurant to supplement.";
  projectStartDate: number = 1719592959000;
  projectEndDate: number = 1719592959000;

  display: google.maps.LatLngLiteral;
  gmapConfig2!: MapConfig;
  bounds: google.maps.LatLngBoundsLiteral = {
    east: 10,
    north: 10,
    south: -10,
    west: -10,
  };
  // Map


  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  subscription: Subscription = new Subscription();
  show_alert: boolean;
  alert_message: string;
  alert_type: string;
  tree: any;
  g: any;
  s: any;
  loading: boolean = false;
  projectId: string;
  updateInProgress: boolean = false;
  error: string;
  submitInProgress: boolean = false;
  progressPercentage: number = 0;

  // Forms
  investmentReportForm!: FormGroup;
  npvReportForm!: FormGroup;
  salesProfitAndCashFlowReportForm!: FormGroup;
  paybackPeriodReportForm!: FormGroup;
  discountedPaybackPeriodReportForm!: FormGroup;
  accountingRateOfReturnReportForm!: FormGroup;
  profitabilityReportForm!: FormGroup;
  sensitivityAnalysisReportForm!: FormGroup;
  mapReportForm!: FormGroup;
  imagesReportForm!: FormGroup;
  projectCommitteeRecommendationReportForm!: FormGroup;

  // Investment Report
  showProjectInvestmentReportSection: boolean = true;
  showProjectInvestmentReportEdit: boolean = false;
  projectInvestmentReportSubmitted: boolean = false;
  projectInvestmentProgressPercentage: number = 0;

  // Sales, Profit & Cash Flow
  showProjectSalesProfitAndCashFlowReportSection: boolean = true;
  showProjectSalesProfitAndCashFlowReportEdit: boolean = false;
  projectSalesProfitAndCashFlowReportSubmitted: boolean = false;
  projectSalesProfitAndCashFlowProgressPercentage: number = 0;

  // Net Present Value (NPV
  showProjectNPVReportSection: boolean = true;
  showProjectNPVReportEdit: boolean = false;
  projectNPVReportSubmitted: boolean = false;
  projectNPVProgressPercentage: number = 0;

  // Payback Period
  showProjectPaybackPeriodReportSection: boolean = true;
  showProjectPaybackPeriodReportEdit: boolean = false;
  projectPaybackPeriodReportSubmitted: boolean = false;
  projectPaybackPeriodProgressPercentage: number = 0;

  // Discounted Payback Period.
  showProjectDiscountedPaybackPeriodReportSection: boolean = true;
  showProjectDiscountedPaybackPeriodReportEdit: boolean = false;
  projectDiscountedPaybackPeriodReportSubmitted: boolean = false;
  projectDiscountedPaybackPeriodProgressPercentage: number = 0;

  // Accounting Rate Of Return (ARR)
  showProjectAccountingRateOfReturnReportSection: boolean = true;
  showProjectAccountingRateOfReturnReportEdit: boolean = false;
  projectAccountingRateOfReturnReportSubmitted: boolean = false;
  projectAccountingRateOfReturnProgressPercentage: number = 0;

  // Profitability
  showProjectProfitabilityReportSection: boolean = true;
  showProjectProfitabilityReportEdit: boolean = false;
  projectProfitabilityReportSubmitted: boolean = false;
  projectProfitabilityProgressPercentage: number = 0;

  // Sensitivity Analysis
  showProjectSensitivityAnalysisReportSection: boolean = true;
  showProjectSensitivityAnalysisReportEdit: boolean = false;
  projectSensitivityAnalysisReportSubmitted: boolean = false;
  projectSensitivityAnalysisProgressPercentage: number = 0;

  // Map
  showProjectMapReportSection: boolean = true;
  showProjectMapReportEdit: boolean = false;
  projectMapReportSubmitted: boolean = false;
  projectMapProgressPercentage: number = 0;

  // Images
  showProjectImagesReportSection: boolean = true;
  showProjectImagesReportEdit: boolean = false;
  projectImagesReportSubmitted: boolean = false;
  projectImagesProgressPercentage: number = 0;

  // ProjectCommitteeRecommendation
  showProjectProjectCommitteeRecommendationReportSection: boolean = true;
  showProjectProjectCommitteeRecommendationReportEdit: boolean = false;
  projectProjectCommitteeRecommendationReportSubmitted: boolean = false;
  projectProjectCommitteeRecommendationProgressPercentage: number = 0;


  projectArray: ProjectDetails[];
  uploadProgress$: any;
  brandingItemArray: CompanyInvestmentItem[];
  constructionItemArray: CompanyInvestmentItem[];
  equipmentItemArray: CompanyInvestmentItem[];
  companyInvestmentBrandingTotal: number;
  companyInvestmentConstructionTotal: number;
  companyInvestmentEquipmentTotal: number;
  companyInvestmentTotal: number;
  companySummaryNetFuelMarginArray: number[] = [];
  companySummaryDiversificationMarginArray: number[] = [];
  companySummaryOtherRevenueArray: number[] = [];
  companySummaryOperatingExpensesArray: number[] = [];
  companySummaryRentalsAndFeesDueArray: number[] = [];
  companySummaryEBITDAArray: number[] = [];
  companySummaryInvestmentArray: number[] = [];
  companySummaryChangeInWorkingCapitalArray: number[] = [];
  companySummaryFinancingInterestPaidArray: number[] = [];
  companySummaryTaxesArray: number[] = [];
  companySummaryOtherCashItemsArray: number[] = [];
  companySummaryNetCashAfterTaxArray: number[] = [];
  companySummaryFinancialResultArray: number[] = [];
  companySummaryInflationRateArray: number[] = [];
  companyInterestReceivedArray: number[] = [];
  companySummaryEBITDARealArray: number[] = [];
  companySummaryOtherCashItemsRealArray: number[] = [];
  companySummaryInvestmentRealArray: number[] = [];
  companySummaryTaxesRealArray: number[] = [];
  companySummaryChangeInWorkingCapitalRealArray: number[] = [];
  companySummaryNetCashAfterTaxRealArray: number[] = [];
  companySummaryRealCompanyCashBalanceArray: number[] = [];
  companySummaryDiscountedNetCashAfterTaxRealArray: number[] = [];
  companySummaryNetCashAfterTaxDiscountedArray: number[] = [];
  inflationRateArray: number[] = [];
  financingTotalArray: number[];
  netPresentValueRemarkArray: RemarkItem[];
  paybackPeriodRemarkArray: RemarkItem[];
  discountedPaybackPeriodRemarkArray: RemarkItem[];
  accountingRateOfReturnRemarkArray: RemarkItem[];
  profitabilityRemarkArray: RemarkItem[];
  sensitivityRemarkArray: RemarkItem[];
  mapLocationRemarkArray: RemarkItem[];
  profilePictureUrl: any;
  loggedInUserFirstName: any;
  loggedInUserSurname: any;
  loggedInUserLastName: any;
  loggedInUserRole: any;
  loggedInUserDate: number;

  volumeMinus15PercentIrr: number = 0;
  volumePlus15PercentIrr: number = 0;
  marginMinus20PercentIrr: number = 0;
  marginPlus20PercentIrr: number = 0;
  investmentMinus10PercentIrr: number = 0;
  investmentPlus10PercentIrr: number = 0;
  operatingCostMinus10PercentIrr: number = 0;
  operatingCostPlus10PercentIrr: number = 0;
  workingCapitalMinus10PercentIrr: number = 0;
  workingCapitalPlus10PercentIrr: number = 0;

  formSubmitted: boolean = false;
  stationMapLocationLatitude: number = -1.129573;
  stationMapLocationLongitude: number = 36.9845406;
  stationName: string = "Delta Service Station";
  stationMapLocationStreetName: string = "";
  stationMapLocationTown: string = "";
  stationMapLocationCountry: string = "";
  stationMapLocationDescription: string = "";
  stationCompetitorArray: any;
  newCompetitorPicture: any;
  showNewCompetitorEdit: boolean = false;
  dateToday: string;
  userName: string;
  projectCommitteeRecommendation: string = "Pending";
  chiefExecutiveOfficesJustification: string = "All is in order";
  staticMapSource: string;
  staticMapWidth: number = 0;
  staticMapHeight: number = 0;
  googleMapStaticImage: any[] = [];
  dataURL2: string = "";
  dataURLArray: any[] = [];
  oneViewDescription: string;
  twoViewDescription: string;
  threeViewDescription: string;
  fourViewDescription: string;
  aa: void;
  ab: void;
  ac: void;
  dataURLGPRSMapArray: any[] = [];
  doNotShow: boolean = true;

  oneViewPicture_width: number = 0;
  oneViewPicture_height: number = 0;
  twoViewPicture_width: number = 0;
  twoViewPicture_height: number = 0;
  threeViewPicture_width: number = 0;
  threeViewPicture_height: number = 0;
  fourViewPicture_width: number = 0;
  fourViewPicture_height: number = 0;
  dataURL4: any;
  dataURL5: any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private scroller: ViewportScroller,
    private ipGeoService: IpGeoService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private fileStorage: FileStorageService,
    private authenticationService: AuthenticationService,
    private petroleumProductsService: PetroleumProductsService,
  ) {
    this.tree = router.parseUrl(router.url);
    this.g = this.tree.root.children[PRIMARY_OUTLET];
    this.s = this.g.segments;

    this.loadingAnimationFourSeconds();

    this.subscription.add(authenticationService.currentUser.pipe().subscribe((res: AppUser) => {

      if (res) {
        this.user = res;
        this.authState = true;

        if (res[0]) {
          let firstName = "";
          let otherName = "";
          let surname = "";

          this.loggedInUserDate = (new Date()[Symbol.toPrimitive]('number'));
          if (res[0].userId) { this.userId = res[0].userId; };
          if (res[0].firstName) {
            this.loggedInUserFirstName = res[0].firstName;
            firstName = res[0].firstName;
          };
          if (res[0].surname) {
            this.loggedInUserSurname = res[0].surname;
            surname = res[0].surname;
          };
          if (res[0].otherName) {
            this.loggedInUserLastName = res[0].otherName;
            otherName = res[0].otherName;
          };
          if (res[0].role) { this.loggedInUserRole = res[0].role; };
          if (res[0].profilePicture) {
            if (res[0].profilePicture[0]) {
              if (res[0].profilePicture[0].url) {
                this.profilePictureUrl = res[0].profilePicture[0].url;
              } else {
                this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
              }
            } else {
              this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
            }
          } else {
            this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
          }

          this.userName = `${firstName} ${otherName} ${surname}`;
        } else {
          this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
        }

      } else {
        this.authState = false;
      }

    }));

    if (this.s[2].path) {
      if ((this.s[2].path.toString()).length === 22) {
        this.projectId = this.s[2].path.toString();

        this.subscription.add(
          this.projectService.getProjectArrayItem(this.s[2].path.toString()).pipe().subscribe((res: ProjectDetails[]) => {
            if (res) {
              this.projectArray = res;

              // projectAppraisalNpv
              if (res[0].netPresentValue) { this.projectAppraisalNpv = res[0].netPresentValue; };
              // projectAppraisalPaybackPeriod
              if (res[0].paybackPeriod) { this.projectAppraisalPaybackPeriod = res[0].paybackPeriod; };
              // projectAppraisalDiscountedPaybackPeriod
              if (res[0].paybackPeriodDiscounted) { this.projectAppraisalDiscountedPaybackPeriod = res[0].paybackPeriodDiscounted; };
              // projectAppraisalAccountingRateOfReturn
              if (res[0].internalRateOfReturn) { this.projectAppraisalAccountingRateOfReturn = res[0].internalRateOfReturn; };


              if (res[0].name) { this.projectName = res[0].name };
              if (res[0].locationDescription) { this.projectLocationDescription = res[0].locationDescription };
              if (res[0].description) { this.projectDescription = res[0].description };
              if (res[0].constructionRenovationStartDate) { this.projectStartDate = res[0].constructionRenovationStartDate };
              if (res[0].constructionRenovationEndDate) { this.projectEndDate = res[0].constructionRenovationEndDate };

              if (res[0].economicEnvironment) {
                if (res[0].economicEnvironment.yearlyEscalation) {
                  if (res[0].economicEnvironment.yearlyEscalation.inflationRate) {
                    this.inflationRateArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                    let indexA = 0;
                    for (let index = 0; index < 16; index++) {
                      this.inflationRateArray[indexA] = ((res[0].economicEnvironment.yearlyEscalation.inflationRate[indexA]));
                      indexA += 1;
                    }
                  }
                }
              }

              if (res[0].report) {
                if (res[0].report.netPresentValueRemark) {
                  this.netPresentValueRemarkArray = res[0].report.netPresentValueRemark;
                }
                if (res[0].report.paybackPeriodRemark) {
                  this.paybackPeriodRemarkArray = res[0].report.paybackPeriodRemark;
                }
                if (res[0].report.discountedPaybackPeriodRemark) {
                  this.discountedPaybackPeriodRemarkArray = res[0].report.discountedPaybackPeriodRemark;
                }
                if (res[0].report.profitabilityRemark) {
                  this.profitabilityRemarkArray = res[0].report.profitabilityRemark;
                }
                if (res[0].report.sensitivityRemark) {
                  this.sensitivityRemarkArray = res[0].report.sensitivityRemark;
                }
                if (res[0].report.mapLocationRemark) {
                  this.mapLocationRemarkArray = res[0].report.mapLocationRemark;
                }
                if (res[0].report.accountingRateOfReturnRemark) {
                  this.accountingRateOfReturnRemarkArray = res[0].report.accountingRateOfReturnRemark;
                }
              }
              if (res[0].country) {
                if (res[0].country.name) {
                  this.mapEditForm.get("country").setValue(res[0].country.name);
                }
              }
            }

          }
          )
        )

        // Sensitivity
        this.subscription.add(
          this.projectService.getProjectSensitivityObject(this.projectId).pipe().subscribe((res: SensitivityAnalysis) => {
            if (res) {
              if (res.irrSummary) {
                if (res.irrSummary.volumeMinus15Percent) { this.volumeMinus15PercentIrr = res.irrSummary.volumeMinus15Percent };
                if (res.irrSummary.volumePlus15Percent) { this.volumePlus15PercentIrr = res.irrSummary.volumePlus15Percent };
                if (res.irrSummary.marginMinus20Percent) { this.marginMinus20PercentIrr = res.irrSummary.marginMinus20Percent };
                if (res.irrSummary.marginPlus20Percent) { this.marginPlus20PercentIrr = res.irrSummary.marginPlus20Percent };
                if (res.irrSummary.investmentMinus10Percent) { this.investmentMinus10PercentIrr = res.irrSummary.investmentMinus10Percent };
                if (res.irrSummary.investmentPlus10Percent) { this.investmentPlus10PercentIrr = res.irrSummary.investmentPlus10Percent };
                if (res.irrSummary.operatingCostMinus10Percent) { this.operatingCostMinus10PercentIrr = res.irrSummary.operatingCostMinus10Percent };
                if (res.irrSummary.operatingCostPlus10Percent) { this.operatingCostPlus10PercentIrr = res.irrSummary.operatingCostPlus10Percent };
                if (res.irrSummary.workingCapitalMinus10Percent) { this.workingCapitalMinus10PercentIrr = res.irrSummary.workingCapitalMinus10Percent };
                if (res.irrSummary.workingCapitalPlus10Percent) { this.workingCapitalPlus10PercentIrr = res.irrSummary.workingCapitalPlus10Percent };
              };
            };
          }));

        this.subscription.add(
          this.projectService.getProjectFinancingObject(this.projectId).pipe().subscribe((res: CompanyFinancing) => {
            if (res) {
              if (res.yearlyEscalation) {
                if (res.yearlyEscalation.total) {
                  this.financingTotalArray = res.yearlyEscalation.total;
                };
              };
            };
          }));

        this.subscription.add(
          this.projectService.getProjectCompanyInvestmentObject(this.s[2].path.toString()).pipe().subscribe((res: CompanyInvestment) => {
            if (res) {
              if (res.branding) {
                this.brandingItemArray = res.branding;
              }
              if (res.construction) {
                this.constructionItemArray = res.construction;
              }
              if (res.equipment) {
                this.equipmentItemArray = res.equipment;
              }
              if (res.brandingTotal) {
                this.companyInvestmentBrandingTotal = res.brandingTotal;
              };
              if (res.constructionTotal) {
                this.companyInvestmentConstructionTotal = res.constructionTotal;
              };
              if (res.equipmentTotal) {
                this.companyInvestmentEquipmentTotal = res.equipmentTotal;
              };
              if (res.total) { this.companyInvestmentTotal = res.total; }
            }
          })
        );

        this.subscription.add(
          this.projectService.getProjectCompanySummaryObject(this.s[2].path.toString()).pipe().subscribe((res: CompanySummary) => {
            if (res) {
              if (res.yearlyEscalation) {
                if (res.yearlyEscalation.netFuelMargin) { this.companySummaryNetFuelMarginArray = res.yearlyEscalation.netFuelMargin };
                if (res.yearlyEscalation.companyDiversificationMargin) { this.companySummaryDiversificationMarginArray = res.yearlyEscalation.companyDiversificationMargin };
                if (res.yearlyEscalation.companyOtherRevenue) { this.companySummaryOtherRevenueArray = res.yearlyEscalation.companyOtherRevenue };
                if (res.yearlyEscalation.companyOperatingExpenses) { this.companySummaryOperatingExpensesArray = res.yearlyEscalation.companyOperatingExpenses };
                if (res.yearlyEscalation.companyRentalsAndFeesDue) { this.companySummaryRentalsAndFeesDueArray = res.yearlyEscalation.companyRentalsAndFeesDue };
                if (res.yearlyEscalation.ebitda) { this.companySummaryEBITDAArray = res.yearlyEscalation.ebitda };
                if (res.yearlyEscalation.companyInvestment) { this.companySummaryInvestmentArray = res.yearlyEscalation.companyInvestment };
                if (res.yearlyEscalation.changeInWorkingCapital) { this.companySummaryChangeInWorkingCapitalArray = res.yearlyEscalation.changeInWorkingCapital };
                if (res.yearlyEscalation.financingInterestPaid) { this.companySummaryFinancingInterestPaidArray = res.yearlyEscalation.financingInterestPaid };
                if (res.yearlyEscalation.taxAmount) { this.companySummaryTaxesArray = res.yearlyEscalation.taxAmount };
                if (res.yearlyEscalation.otherCashItems) { this.companySummaryOtherCashItemsArray = res.yearlyEscalation.otherCashItems };
                if (res.yearlyEscalation.netCashAfterTax) { this.companySummaryNetCashAfterTaxArray = res.yearlyEscalation.netCashAfterTax };
                if (res.yearlyEscalation.financialResult) { this.companySummaryFinancialResultArray = res.yearlyEscalation.financialResult };
                if (res.yearlyEscalation.inflationRate) { this.companySummaryInflationRateArray = res.yearlyEscalation.inflationRate };
                if (res.yearlyEscalation.companyInterestReceived) { this.companyInterestReceivedArray = res.yearlyEscalation.companyInterestReceived };

                if (res.yearlyEscalation.ebitdaReal) { this.companySummaryEBITDARealArray = res.yearlyEscalation.ebitdaReal };
                if (res.yearlyEscalation.otherCashItemsReal) { this.companySummaryOtherCashItemsRealArray = res.yearlyEscalation.otherCashItemsReal };
                if (res.yearlyEscalation.companyInvestmentReal) { this.companySummaryInvestmentRealArray = res.yearlyEscalation.companyInvestmentReal };
                if (res.yearlyEscalation.taxAmountReal) { this.companySummaryTaxesRealArray = res.yearlyEscalation.taxAmountReal };
                if (res.yearlyEscalation.changeInWorkingCapitalReal) { this.companySummaryChangeInWorkingCapitalRealArray = res.yearlyEscalation.changeInWorkingCapitalReal };
                if (res.yearlyEscalation.netCashAfterTaxReal) { this.companySummaryNetCashAfterTaxRealArray = res.yearlyEscalation.netCashAfterTaxReal };
                if (res.yearlyEscalation.realCompanyCashBalance) { this.companySummaryRealCompanyCashBalanceArray = res.yearlyEscalation.realCompanyCashBalance };

                if (res.yearlyEscalation.discountedNetCashAfterTaxReal) { this.companySummaryDiscountedNetCashAfterTaxRealArray = res.yearlyEscalation.discountedNetCashAfterTaxReal };
                if (res.yearlyEscalation.netCashAfterTaxDiscounted) { this.companySummaryNetCashAfterTaxDiscountedArray = res.yearlyEscalation.netCashAfterTaxDiscounted };
              };
            }
          }));

        this.subscription.add(
          this.projectService.getProjectStationMapLocationObject(this.s[2].path.toString()).pipe(first()).subscribe((res: StationMapLocation) => {
            if (res) {
              if (res.lat) {
                this.stationMapLocationLatitude = res.lat;
                this.mapEditForm.get("lat").setValue(res.lat);
              };
              if (res.lng) {
                this.stationMapLocationLongitude = res.lng;
                this.mapEditForm.get("lng").setValue(res.lng);
              };
              if (res.streetName) {
                this.stationMapLocationStreetName = res.streetName;
                this.mapEditForm.get("streetName").setValue(res.streetName);
              };
              if (res.town) {
                this.stationMapLocationTown = res.town;
                this.mapEditForm.get("town").setValue(res.town);
              };
              if (res.description) {
                this.stationMapLocationDescription = res.description;
                this.mapEditForm.get("description").setValue(res.description);
              };
            };
          }));

        this.subscription.add(
          this.projectService.getProjectStationMapStreetViewObject(this.s[2].path.toString()).pipe().subscribe((res: StationMapStreetView) => {
            if (res) {
              if (res.streetViewOne) {
                if (res.streetViewOne.description) {
                  this.streetViewOneForm.get("description").setValue(res.streetViewOne.description);
                  this.oneViewDescription = res.streetViewOne.description;
                };
                if (res.streetViewOne.picture) {
                  if (res.streetViewOne.picture.url) {
                    this.oneViewPicture = res.streetViewOne.picture.url;
                  };
                  if (res.streetViewOne.picture.width) { this.oneViewPicture_width = res.streetViewOne.picture.width; };
                  if (res.streetViewOne.picture.height) { this.oneViewPicture_height = res.streetViewOne.picture.height; };
                  this.getBase64ImageFromURL();
                };
              };
              if (res.streetViewTwo) {
                if (res.streetViewTwo.description) {
                  this.streetViewTwoForm.get("description").setValue(res.streetViewTwo.description);
                  this.twoViewDescription = res.streetViewTwo.description;
                };
                if (res.streetViewTwo.picture) {
                  if (res.streetViewTwo.picture.url) {
                    this.twoViewPicture = res.streetViewTwo.picture.url;
                  };
                  if (res.streetViewTwo.picture.width) { this.twoViewPicture_width = res.streetViewTwo.picture.width; };
                  if (res.streetViewTwo.picture.height) { this.twoViewPicture_height = res.streetViewTwo.picture.height; };
                };
              };
              if (res.streetViewThree) {
                if (res.streetViewThree.description) {
                  this.streetViewThreeForm.get("description").setValue(res.streetViewThree.description);
                  this.threeViewDescription = res.streetViewThree.description;
                };
                if (res.streetViewThree.picture) {
                  if (res.streetViewThree.picture.url) {
                    this.threeViewPicture = res.streetViewThree.picture.url;
                  };
                  if (res.streetViewThree.picture.width) { this.threeViewPicture_width = res.streetViewThree.picture.width; };
                  if (res.streetViewThree.picture.height) { this.threeViewPicture_height = res.streetViewThree.picture.height; };
                };
              };
              if (res.streetViewFour) {
                if (res.streetViewFour.description) {
                  this.streetViewFourForm.get("description").setValue(res.streetViewFour.description);
                  this.fourViewDescription = res.streetViewFour.description;
                };
                if (res.streetViewFour.picture) {
                  if (res.streetViewFour.picture.url) {
                    this.fourViewPicture = res.streetViewFour.picture.url;
                  };
                  if (res.streetViewFour.picture.width) { this.fourViewPicture_width = res.streetViewFour.picture.width; };
                  if (res.streetViewFour.picture.height) { this.fourViewPicture_height = res.streetViewFour.picture.height; };
                };
              };
            };
          }));

        this.subscription.add(
          this.projectService.getProjectStationLocationCompetitorsArray(this.s[2].path.toString()).pipe().subscribe((res: StationCompetitor | any) => {
            if (res) {
              this.stationCompetitorArray = res;
            }
          }));
      }
    }



  }
  ngAfterViewInit(): void {
    // this.aa = this.getBase64ImageFromAssetFolder();
    // this.ab = this.getBase64ImageFromURL();

    this.getBase64Image_html_GPRSMap(document.getElementById("image1"));
    this.getBase64Image_html_GPRSMap(document.getElementById("image2"));
    this.getBase64Image_html_GPRSMap(document.getElementById("image3"));
    this.getBase64Image_html_GPRSMap(document.getElementById("image4"));
    this.getBase64Image_html_GPRSMap(document.getElementById("image5"));
  }

  ngOnInit(): void {

    // this.dataURLArray.push("");


    this.initMapConfig();
    this.initLineChart();

    this.newCompetitorForm = this.fb.group({
      lat: [0, Validators.required],
      lng: [0, Validators.required],
      name: ["", Validators.required],
      streetName: [""],
      town: [""],
      country: [""],
      description: [""],
      picture: [""],
    });

    // 
    let staticMapZoom = 18;
    let staticMapLat = -1.129573;
    let staticMapLng = 36.9845406;
    let staticMapWidth = this.staticMapWidth = 600;
    let staticMapHeight = this.staticMapHeight = 300;
    let staticMapAPI = "AIzaSyAHB-6cWBo_ja9g3_ZECU0TOApftK7tsNU";
    let staticMapType = "roadmap";
    let staticMapLabel = "Station%Location";

    this.staticMapSource = `https://maps.googleapis.com/maps/api/staticmap?center=${staticMapLat},${staticMapLng}&zoom=${staticMapZoom}&size=${staticMapWidth}x${staticMapHeight}&markers=color:red%7Clabel:${staticMapLabel}%7C${staticMapLat},${staticMapLng}&markers=size:tiny%7&maptype=${staticMapType}&key=${staticMapAPI}`;

    this.subscription.add(
      this.newCompetitorForm.get("lng").valueChanges.subscribe(x => {

        if ((((Number(this.newCompetitorForm.get("lng").value)) > 0) || ((Number(this.newCompetitorForm.get("lng").value)) < 0)) && (((Number(this.newCompetitorForm.get("lat").value)) > 0) || ((Number(this.newCompetitorForm.get("lat").value)) < 0))) {
          // Get the street,town & country.
          this.getLocation((this.newCompetitorForm.get("lat").value), (this.newCompetitorForm.get("lng").value), "newCompetitor");
        }
      }));

    this.subscription.add(
      this.newCompetitorForm.get("lat").valueChanges.subscribe(x => {

        if ((((Number(this.newCompetitorForm.get("lng").value)) > 0) || ((Number(this.newCompetitorForm.get("lng").value)) < 0)) && (((Number(this.newCompetitorForm.get("lat").value)) > 0) || ((Number(this.newCompetitorForm.get("lat").value)) < 0))) {
          // Get the street,town & country.
          this.getLocation((this.newCompetitorForm.get("lat").value), (this.newCompetitorForm.get("lng").value), "newCompetitor");
        }
      }));

    this.mapEditForm = this.fb.group({
      lat: [-1.129573, Validators.required],
      lng: [36.9845406, Validators.required],
      streetName: ["", Validators.required],
      town: ["", Validators.required],
      country: ["", Validators.required],
      description: [""],
    });

    this.subscription.add(
      this.mapEditForm.get("lng").valueChanges.subscribe(x => {

        if ((((Number(this.mapEditForm.get("lng").value)) > 0) || ((Number(this.mapEditForm.get("lng").value)) < 0)) && (((Number(this.mapEditForm.get("lat").value)) > 0) || ((Number(this.mapEditForm.get("lat").value)) < 0))) {
          // Get the street,town & country.
          this.getLocation((this.mapEditForm.get("lat").value), (this.mapEditForm.get("lng").value), "main");
        }
      }));

    this.subscription.add(
      this.mapEditForm.get("lat").valueChanges.subscribe(x => {

        if ((((Number(this.mapEditForm.get("lng").value)) > 0) || ((Number(this.mapEditForm.get("lng").value)) < 0)) && (((Number(this.mapEditForm.get("lat").value)) > 0) || ((Number(this.mapEditForm.get("lat").value)) < 0))) {
          // Get the street,town & country.
          this.getLocation((this.mapEditForm.get("lat").value), (this.mapEditForm.get("lng").value), "main");
        }
      }));

    this.subscription.add(
      this.mapEditForm.get("lat").valueChanges.subscribe(x => {
        if (((Number(x)) > 0) || ((Number(x)) < 0)) {

          this.center.lat = (Number(x));
          this.markerPosition.lat = (Number(x));
          this.stationMapLocationLatitude = (Number(x));

          this.gmapConfig2 = {
            lat: this.stationMapLocationLatitude,
            lng: this.stationMapLocationLongitude,
            markers: [
              {
                lat: this.stationMapLocationLatitude,
                lng: this.stationMapLocationLongitude,
                title: `${this.stationName}`
              },
            ]
          }

        }
      }));

    this.subscription.add(
      this.mapEditForm.get("lng").valueChanges.subscribe(x => {
        if (((Number(x)) > 0) || ((Number(x)) < 0)) {

          this.center.lng = (Number(x));
          this.markerPosition.lng = (Number(x));
          this.stationMapLocationLongitude = (Number(x));

          this.gmapConfig2 = {
            lat: this.stationMapLocationLatitude,
            lng: this.stationMapLocationLongitude,
            markers: [
              {
                lat: this.stationMapLocationLatitude,
                lng: this.stationMapLocationLongitude,
                title: `${this.stationName}`
              },
            ]
          }

        }
      }));

    this.streetViewOneForm = this.fb.group({
      picture: ["", Validators.required],
      description: [""],
    });

    this.streetViewTwoForm = this.fb.group({
      picture: ["", Validators.required],
      description: [""],
    });

    this.streetViewThreeForm = this.fb.group({
      picture: ["", Validators.required],
      description: [""],
    });

    this.streetViewFourForm = this.fb.group({
      picture: ["", Validators.required],
      description: [""],
    });

    this.paybackPeriodRemarkForm = this.fb.group({
      remark: ["", Validators.required],
    });

    this.discountedPaybackPeriodRemarkForm = this.fb.group({
      remark: ["", Validators.required],
    });

    this.netPresentValueRemarkForm = this.fb.group({
      remark: ["", Validators.required],
    });

    this.sensitivityRemarkForm = this.fb.group({
      remark: ["", Validators.required],
    });

    this.mapLocationRemarkForm = this.fb.group({
      remark: ["", Validators.required],
    });

    this.profitabilityRemarkForm = this.fb.group({
      remark: ["", Validators.required],
    });

    this.accountingRateOfReturnRemarkForm = this.fb.group({
      remark: ["", Validators.required],
    });

    this.npvIntro = "This capital investment appraisal technique measures the cash in-flow, whether excess or shortfall, after the routine finance commitments are met with. All capital investment appraisals have a single objective – drive towards a positive NPV. The NPV is a mathematical calculation involving net cash flow at a particular present time 't' at discount rate at the same time, i.e. (t – initial capital outlay). ";
    this.paybackPeriodIntro = "Appraising capital investment on the basis of time that would be taken to get back your initial investment.";
    this.discountedPaybackPeriodIntro = "Capital investment appraisals using discounted payback period( the time value of money or discounted value of cash flow is considered for calculation of payback period).";
    this.accountingRateOfReturnIntro = "This capital investment appraisal technique compares the profit that can be earned by the concerned project to the amount of initial investment capital that would be required for the project.";
    this.npvRecommended = ">0 / +";
    this.paybackPeriodRecommended = "</=8 Years";
    this.discountedPaybackPeriodRecommended = "</=10Years";
    this.accountingRateOfReturnRecommended = ">/= 15%";


    this.investmentReportForm = this.fb.group({
      remark: ['', Validators.required],
    });
    this.salesProfitAndCashFlowReportForm = this.fb.group({
      remark: ['', Validators.required],
    });
    this.npvReportForm = this.fb.group({
      remark: ['', Validators.required],
    });
    this.paybackPeriodReportForm = this.fb.group({
      remark: ['', Validators.required],
    });
    this.discountedPaybackPeriodReportForm = this.fb.group({
      remark: ['', Validators.required],
    });
    this.accountingRateOfReturnReportForm = this.fb.group({
      remark: ['', Validators.required],
    });
    this.profitabilityReportForm = this.fb.group({
      remark: ['', Validators.required],
    });
    this.sensitivityAnalysisReportForm = this.fb.group({
      remark: ['', Validators.required],
    });
    this.mapReportForm = this.fb.group({
      remark: ['', Validators.required],
    });
    this.imagesReportForm = this.fb.group({
      remark: ['', Validators.required],
    });
    this.projectCommitteeRecommendationReportForm = this.fb.group({
      remark: ['', Validators.required],
    });
  };

  /**
   * initialize line chart config
   */
  initLineChart(): void {
    this.lineChartOptions2 = {
      series: [
        {
          name: "EBITDA Multiplier",
          data: SERIES.monthDataSeries1.prices
        },
        {
          name: "Gordon Shapiro",
          data: SERIES.monthDataSeries2.prices
        },
        {
          name: "Capital Employed NBV",
          data: SERIES.monthDataSeries3.prices
        },
        {
          name: "Capital Employed NBV",
          data: SERIES.monthDataSeries3.prices
        }
      ],
      chart: {
        height: 350,
        type: "line",
        toolbar: {
          show: false
        }
      },
      annotations: {
        yaxis: [
          {
            y: 8200,
            borderColor: "#00E396",
            label: {
              borderColor: "#00E396",
              style: {
                color: "#fff",
                background: "#00E396"
              },
              text: "Support"
            }
          }

        ],
        xaxis: [
          {
            x: new Date("23 Nov 2017").getTime(),
            strokeDashArray: 0,
            borderColor: "#775DD0",
            label: {
              borderColor: "#775DD0",
              style: {
                color: "#fff",
                background: "#775DD0"
              },
              text: "Anno Test"
            }
          },
          {
            x: new Date("26 Nov 2017").getTime(),
            x2: new Date("28 Nov 2017").getTime(),
            fillColor: "#B3F7CA",
            opacity: 0.4,
            label: {
              borderColor: "#B3F7CA",
              style: {
                fontSize: "10px",
                color: "#fff",
                background: "#00E396"
              },
              offsetY: -10,
              text: "X-axis range"
            }
          }
        ],
        points: [
          {
            x: new Date("01 Dec 2017").getTime(),
            y: 8607.55,
            marker: {
              size: 8,
              fillColor: "#fff",
              strokeColor: "red",
              radius: 2,
              cssClass: "apexcharts-custom-class"
            },
            label: {
              borderColor: "#FF4560",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#FF4560"
              },

              text: "Point Annotation"
            }
          }
        ]
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 3
      },
      grid: {
        padding: {
          right: 30,
          left: 20
        }
      },
      colors: ['#727cf5', '#39afd1', '#fa5c7c'],
      title: {
        text: "Line with Annotations",
        align: "left"
      },
      labels: SERIES.monthDataSeries1.dates,
      xaxis: {
        type: "datetime"
      }
    };


  }

  // Investment Report
  showProjectInvestmentReportSectionButton(a: boolean) {
    this.showProjectInvestmentReportSection = a;
    // Toast
    this.toastr.info(`View - Investment`, "Info!");
  }

  showProjectInvestmentReportEditButton(a: boolean) {
    this.showProjectInvestmentReportEdit = a;
  }

  updateProjectInvestmentReport(form: any): void {
    this.updateInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectInvestmentReport(
        this.projectId,
        form
      )
        .pipe(first()).subscribe((result) => {
          this.toastr.success(`Step 1. Investment Report - Update Successful`, "Success!");
          this.updateInProgress = false;
        },
          (error: string) => {
            this.toastr.error(`Error: ${error}`, "Error!");
            this.error = error;
            this.updateInProgress = false;
          }));
  }

  submitProjectInvestmentReport() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectReportProgress(this.projectId, "projectInvestmentReportSubmitted", true).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 1. Investment Report - Submitted`, "Info!");
        if (this.projectInvestmentReportSubmitted) {
          this.showProjectInvestmentReportSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepTwo");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  // Sales, Profit $ Cash Flow.
  showProjectSalesProfitAndCashFlowReportSectionButton(a: boolean) {
    this.showProjectSalesProfitAndCashFlowReportSection = a;
    // Toast
    this.toastr.info(`View - Sales, Profit $ Cash Flow.`, "Info!");
  }

  showProjectSalesProfitAndCashFlowReportEditButton(a: boolean) {
    this.showProjectSalesProfitAndCashFlowReportEdit = a;
  }

  updateProjectSalesProfitAndCashFlowReport(form: any): void {
    this.updateInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectSalesProfitAndCashFlowReport(
        this.projectId,
        form
      )
        .pipe(first()).subscribe((result) => {
          this.toastr.success(`Step 2. Sales, Profit & Cash Flow - Report - Update Successful`, "Success!");
          this.updateInProgress = false;
        },
          (error: string) => {
            this.toastr.error(`Error: ${error}`, "Error!");
            this.error = error;
            this.updateInProgress = false;
          }));
  }

  submitProjectSalesProfitAndCashFlowReport() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectReportProgress(this.projectId, "projectSalesProfitAndCashFlowReportSubmitted", true).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 2. Sales, Profit & CashFlow - Report - Submitted`, "Info!");
        if (this.projectSalesProfitAndCashFlowReportSubmitted) {
          this.showProjectSalesProfitAndCashFlowReportSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepThree");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  // Net Present Value (NPV)
  showProjectNPVReportSectionButton(a: boolean) {
    this.showProjectNPVReportSection = a;
    // Toast
    this.toastr.info(`View - Net Present Value (NPV)`, "Info!");
  }

  showProjectNPVReportEditButton(a: boolean) {
    this.showProjectNPVReportEdit = a;
  }

  updateProjectNPVReport(form: any): void {
    this.updateInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectNPVReport(
        this.projectId,
        form
      )
        .pipe(first()).subscribe((result) => {
          this.toastr.success(`Step 3. Net Present Value (NPV) - Report - Update Successful`, "Success!");
          this.updateInProgress = false;
        },
          (error: string) => {
            this.toastr.error(`Error: ${error}`, "Error!");
            this.error = error;
            this.updateInProgress = false;
          }));
  }

  submitProjectNPVReport() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectReportProgress(this.projectId, "projectNPVReportSubmitted", true).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 3. Net Present Value (NPV) - Report - Submitted`, "Info!");
        if (this.projectNPVReportSubmitted) {
          this.showProjectNPVReportSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepFour");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  // Payback Period.
  showProjectPaybackPeriodReportSectionButton(a: boolean) {
    this.showProjectPaybackPeriodReportSection = a;
    // Toast
    this.toastr.info(`View - Payback Period.`, "Info!");
  }

  showProjectPaybackPeriodReportEditButton(a: boolean) {
    this.showProjectPaybackPeriodReportEdit = a;
  }

  updateProjectPaybackPeriodReport(form: any): void {
    this.updateInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectPaybackPeriodReport(
        this.projectId,
        form
      )
        .pipe(first()).subscribe((result) => {
          this.toastr.success(`Step 4. Payback Period - Report - Update Successful`, "Success!");
          this.updateInProgress = false;
        },
          (error: string) => {
            this.toastr.error(`Error: ${error}`, "Error!");
            this.error = error;
            this.updateInProgress = false;
          }));
  }

  submitProjectPaybackPeriodReport() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectReportProgress(this.projectId, "projectPaybackPeriodReportSubmitted", true).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 4. Payback Period - Report - Submitted`, "Info!");
        if (this.projectPaybackPeriodReportSubmitted) {
          this.showProjectPaybackPeriodReportSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepFive");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }


  // Discounted - Payback Period.
  showProjectDiscountedPaybackPeriodReportSectionButton(a: boolean) {
    this.showProjectDiscountedPaybackPeriodReportSection = a;
    // Toast
    this.toastr.info(`View - Discounted - Payback Period.`, "Info!");
  }

  showProjectDiscountedPaybackPeriodReportEditButton(a: boolean) {
    this.showProjectDiscountedPaybackPeriodReportEdit = a;
  }

  updateProjectDiscountedPaybackPeriodReport(form: any): void {
    this.updateInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectDiscountedPaybackPeriodReport(
        this.projectId,
        form
      )
        .pipe(first()).subscribe((result) => {
          this.toastr.success(`Step 5. Payback Period - Discounted - Report - Update Successful`, "Success!");
          this.updateInProgress = false;
        },
          (error: string) => {
            this.toastr.error(`Error: ${error}`, "Error!");
            this.error = error;
            this.updateInProgress = false;
          }));
  }

  submitProjectDiscountedPaybackPeriodReport() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectReportProgress(this.projectId, "projectDiscountedPaybackPeriodReportSubmitted", true).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 5. Payback Period - Discounted - Report - Submitted`, "Info!");
        if (this.projectDiscountedPaybackPeriodReportSubmitted) {
          this.showProjectDiscountedPaybackPeriodReportSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepSix");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  // Accounting Rate Of Return (ARR).
  showProjectAccountingRateOfReturnReportSectionButton(a: boolean) {
    this.showProjectAccountingRateOfReturnReportSection = a;
    // Toast
    this.toastr.info(`View - Accounting Rate Of Return (ARR).`, "Info!");
  }

  showProjectAccountingRateOfReturnReportEditButton(a: boolean) {
    this.showProjectAccountingRateOfReturnReportEdit = a;
  }

  updateProjectAccountingRateOfReturnReport(form: any): void {
    this.updateInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectAccountingRateOfReturnReport(
        this.projectId,
        form
      )
        .pipe(first()).subscribe((result) => {
          this.toastr.success(`Step 6. Accounting Rate Of Return (ARR) - Report - Update Successful`, "Success!");
          this.updateInProgress = false;
        },
          (error: string) => {
            this.toastr.error(`Error: ${error}`, "Error!");
            this.error = error;
            this.updateInProgress = false;
          }));
  }

  submitProjectAccountingRateOfReturnReport() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectReportProgress(this.projectId, "projectAccountingRateOfReturnReportSubmitted", true).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 6. Accounting Rate Of Return (ARR) - Report - Submitted`, "Info!");
        if (this.projectAccountingRateOfReturnReportSubmitted) {
          this.showProjectAccountingRateOfReturnReportSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("step Seven");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  // Profitability.
  showProjectProfitabilityReportSectionButton(a: boolean) {
    this.showProjectProfitabilityReportSection = a;
    // Toast
    this.toastr.info(`View - Profitability.`, "Info!");
  }

  showProjectProfitabilityReportEditButton(a: boolean) {
    this.showProjectProfitabilityReportEdit = a;
  }

  updateProjectProfitabilityReport(form: any): void {
    this.updateInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectProfitabilityReport(
        this.projectId,
        form
      )
        .pipe(first()).subscribe((result) => {
          this.toastr.success(`Step 7. Profitability - Report - Update Successful`, "Success!");
          this.updateInProgress = false;
        },
          (error: string) => {
            this.toastr.error(`Error: ${error}`, "Error!");
            this.error = error;
            this.updateInProgress = false;
          }));
  }

  submitProjectProfitabilityReport() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectReportProgress(this.projectId, "projectProfitabilityReportSubmitted", true).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 7. Profitability - Report - Submitted`, "Info!");
        if (this.projectProfitabilityReportSubmitted) {
          this.showProjectProfitabilityReportSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepEight");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  // Sensitivity Analysis.
  showProjectSensitivityAnalysisReportSectionButton(a: boolean) {
    this.showProjectSensitivityAnalysisReportSection = a;
    // Toast
    this.toastr.info(`View - Sensitivity Analysis.`, "Info!");
  }

  showProjectSensitivityAnalysisReportEditButton(a: boolean) {
    this.showProjectSensitivityAnalysisReportEdit = a;
  }

  updateProjectSensitivityAnalysisReport(form: any): void {
    this.updateInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectSensitivityAnalysisReport(
        this.projectId,
        form
      )
        .pipe(first()).subscribe((result) => {
          this.toastr.success(`Step 8. Sensitivity - Report - Update Successful`, "Success!");
          this.updateInProgress = false;
        },
          (error: string) => {
            this.toastr.error(`Error: ${error}`, "Error!");
            this.error = error;
            this.updateInProgress = false;
          }));
  }

  submitProjectSensitivityAnalysisReport() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectReportProgress(this.projectId, "projectSensitivityAnalysisReportSubmitted", true).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 8. Sensitivity - Report - Submitted`, "Info!");
        if (this.projectSensitivityAnalysisReportSubmitted) {
          this.showProjectSensitivityAnalysisReportSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepNine");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  // Map.
  showProjectMapReportSectionButton(a: boolean) {
    this.showProjectMapReportSection = a;
    // Toast
    this.toastr.info(`View - Map.`, "Info!");
  }

  showProjectMapReportEditButton(a: boolean) {
    this.showProjectMapReportEdit = a;
  }

  updateProjectMapReport(form: any): void {
    this.updateInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectMapReport(
        this.projectId,
        form
      )
        .pipe(first()).subscribe((result) => {
          this.toastr.success(`Step 9. Map - Report - Update Successful`, "Success!");
          this.updateInProgress = false;
        },
          (error: string) => {
            this.toastr.error(`Error: ${error}`, "Error!");
            this.error = error;
            this.updateInProgress = false;
          }));
  }

  submitProjectMapReport() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectReportProgress(this.projectId, "projectMapReportSubmitted", true).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 9. Map - Report - Submitted`, "Info!");
        if (this.projectMapReportSubmitted) {
          this.showProjectMapReportSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepTen");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  // Images.
  showProjectImagesReportSectionButton(a: boolean) {
    this.showProjectImagesReportSection = a;
    // Toast
    this.toastr.info(`View - Images.`, "Info!");
  }

  showProjectImagesReportEditButton(a: boolean) {
    this.showProjectImagesReportEdit = a;
  }

  updateProjectImagesReport(form: any): void {
    this.updateInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectImagesReport(
        this.projectId,
        form
      )
        .pipe(first()).subscribe((result) => {
          this.toastr.success(`Step 10. Images - Report - Update Successful`, "Success!");
          this.updateInProgress = false;
        },
          (error: string) => {
            this.toastr.error(`Error: ${error}`, "Error!");
            this.error = error;
            this.updateInProgress = false;
          }));
  }

  submitProjectImagesReport() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectReportProgress(this.projectId, "projectImagesReportSubmitted", true).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 10. Images - Report - Submitted`, "Info!");
        if (this.projectImagesReportSubmitted) {
          this.showProjectImagesReportSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepEleven");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  // ProjectCommitteeRecommendation.
  showProjectProjectCommitteeRecommendationReportSectionButton(a: boolean) {
    this.showProjectProjectCommitteeRecommendationReportSection = a;
    // Toast
    this.toastr.info(`View - ProjectCommitteeRecommendation.`, "Info!");
  }

  showProjectProjectCommitteeRecommendationReportEditButton(a: boolean) {
    this.showProjectProjectCommitteeRecommendationReportEdit = a;
  }

  updateProjectProjectCommitteeRecommendationReport(form: any): void {
    this.updateInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectProjectCommitteeRecommendationReport(
        this.projectId,
        form
      )
        .pipe(first()).subscribe((result) => {
          this.toastr.success(`Step 10. ProjectCommitteeRecommendation - Report - Update Successful`, "Success!");
          this.updateInProgress = false;
        },
          (error: string) => {
            this.toastr.error(`Error: ${error}`, "Error!");
            this.error = error;
            this.updateInProgress = false;
          }));
  }

  submitProjectProjectCommitteeRecommendationReport() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectReportProgress(this.projectId, "projectProjectCommitteeRecommendationReportSubmitted", true).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 10. ProjectCommitteeRecommendation - Report - Submitted`, "Info!");
        if (this.projectProjectCommitteeRecommendationReportSubmitted) {
          this.showProjectProjectCommitteeRecommendationReportSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepEleven");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  loadingAnimationStart() {
    this.loading = true;
  }

  loadingAnimationStop() {
    this.loading = false;
  }

  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }

  scrollToElement(a: string) {
    // document.getElementById(a).scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start',
    //   inline: 'nearest',
    // });

    this.scroller.scrollToAnchor(a);

    setTimeout(() => {
      window.scrollBy(0, -75);
    }, 500);
  }

  // Map
  /**
   * initialize map configuration
   */
  initMapConfig(): void {

    this.gmapConfig2 = {
      lat: this.stationMapLocationLatitude,
      lng: this.stationMapLocationLongitude,
      markers: [
        {
          lat: this.stationMapLocationLatitude,
          lng: this.stationMapLocationLongitude,
          title: `${this.stationName}`
        },
      ]
    }
  }

  mapReady(map: any) {
    map.setOptions({
      zoomControl: "true",
      // zoomControlOptions: {
      //   position: google.maps.ControlPosition.TOP_LEFT
      // }
    });
  }

  moveMap(event: google.maps.MapMouseEvent) {
    this.center = (event.latLng.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    this.display = event.latLng.toJSON();
  }
  // Map

  // Street View
  enlargeImage(content: TemplateRef<NgbModal>, view: any, index?: number) {

    if (view === "One") {
      this.pickedImage = this.oneViewPicture;
      this.modalService.open(content, { size: "lg" });
    }
    if (view === "Two") {
      this.pickedImage = this.twoViewPicture;
      this.modalService.open(content, { size: "lg" });
    }
    if (view === "Three") {
      this.pickedImage = this.threeViewPicture;
      this.modalService.open(content, { size: "lg" });
    }
    if (view === "Four") {
      this.pickedImage = this.fourViewPicture;
      this.modalService.open(content, { size: "lg" });
    }
    if (view === "newCompetitor") {
      this.pickedImage = this.newCompetitorPicture;
      this.modalService.open(content, { size: "lg" });
    }

    if (view === "Competitor") {
      if ((index === 0) || (index > 0)) {
        this.pickedImage = this.stationCompetitorArray[index].picture.url;
        this.modalService.open(content, { size: "lg" });
      }
    }



  }

  /**
  * Upload Picture
  */

  loadFiles(event: any, view: any) {
    this.updateInProgress = true;
    let fileCategory = "stationStreetView";
    let height: number;
    let width: number;
    let fileName = event.target["files"][0].name;
    let fileType = event.target["files"][0].type;
    let fileSize = event.target["files"][0].size;
    let imageId = fileCategory + (Date.now().toString().substr(0, 8) + '-' + Math.random().toString(36).substr(2, 5) + '-' + Math.random().toString(36).substr(2, 5)).toUpperCase();

    function formatBytes(bytes: number): string {
      const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const factor = 1024;
      let index = 0;

      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }

      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }

    let fileSize2 = formatBytes(fileSize);

    let path = `${fileCategory}/${fileName}`;

    this.subscription.add(this.projectService.uploadImage(event, path).subscribe((result) => {
      const { downloadUrl$, uploadProgress$ } = result;
      this.uploadProgress$ = uploadProgress$;

      const reader = new FileReader();
      reader.readAsDataURL(event.target["files"][0]);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          height = img.naturalHeight;
          width = img.naturalWidth;
        };
      };

      if (downloadUrl$ && this.userId && width && height) {
        this.fileStorage.saveProfileImageRecord(imageId, fileCategory, this.userId, downloadUrl$, fileName, fileSize2, fileType, height, width).then(() => {
          this.toastr.success("Upload successful", "Info!");
          this.updateInProgress = false;
        });

        if (view === "One") {
          this.oneViewData = {
            "url": downloadUrl$,
            "name": fileName,
            "size": fileSize2,
            "formatType": fileType,
            "imageId": imageId,
            "uploadBy": this.userId,
            "width": width,
            "height": height,
            "date": new Date().getTime()
          };
          this.saveStreetViewImages(this.oneViewData, view);
          this.oneViewPicture = downloadUrl$;
        }

        if (view === "Two") {
          this.twoViewData = {
            "url": downloadUrl$,
            "name": fileName,
            "size": fileSize2,
            "formatType": fileType,
            "imageId": imageId,
            "uploadBy": this.userId,
            "width": width,
            "height": height,
            "date": new Date().getTime()
          };
          this.saveStreetViewImages(this.twoViewData, view);
          this.twoViewPicture = downloadUrl$;
        }

        if (view === "Three") {
          this.threeViewData = {
            "url": downloadUrl$,
            "name": fileName,
            "size": fileSize2,
            "formatType": fileType,
            "imageId": imageId,
            "uploadBy": this.userId,
            "width": width,
            "height": height,
            "date": new Date().getTime()
          };
          this.saveStreetViewImages(this.threeViewData, view);
          this.threeViewPicture = downloadUrl$;
        }

        if (view === "Four") {
          this.fourViewData = {
            "url": downloadUrl$,
            "name": fileName,
            "size": fileSize2,
            "formatType": fileType,
            "imageId": imageId,
            "uploadBy": this.userId,
            "width": width,
            "height": height,
            "date": new Date().getTime()
          };
          this.saveStreetViewImages(this.fourViewData, view);
          this.fourViewPicture = downloadUrl$;
        }

        if (view === "newCompetitor") {
          let competitorImageData = {
            "url": downloadUrl$,
            "name": fileName,
            "size": fileSize2,
            "formatType": fileType,
            "imageId": imageId,
            "uploadBy": this.userId,
            "width": width,
            "height": height,
            "date": new Date().getTime()
          };
          this.newCompetitorForm.get("picture").setValue(competitorImageData);
          this.newCompetitorPicture = downloadUrl$;
        }
      }
    }));
  }

  deleteImage(view: any) {

  }


  deleteCompetitor(i: number) {
    console.log("i:", i);
    this.updateInProgress = true;
    this.submitInProgress = true;

    if ((i > 0) || (i === 0)) {
      this.subscription.add(
        this.projectService.deleteCompetitor(this.projectId, i, this.userId).pipe().subscribe((res: any) => {
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`Competitor Deleted!`, "Info!");
        },
          (error: string) => {
            this.toastr.error(`Error: ${error}`, "Error!");
            this.error = error;
            this.updateInProgress = false;
            this.submitInProgress = false;
          }));
    }
  }

  showAddCompetitorForm() {
    this.showNewCompetitorEdit = true;
  }

  hideAddCompetitorForm() {
    this.showNewCompetitorEdit = false;
    this.newCompetitorForm.reset();
    this.newCompetitorPicture = "";
  }

  closeModal() {
    this.modalService.dismissAll();
  }
  // Street View

  submitRemark(form: any, formName: string) {
    this.submitInProgress = true;

    // Check
    if (!(form.remark)) {
      this.show_alert = true;
      this.alert_message = "Remark is missing!";
      this.alert_type = "danger";

      this.toastr.error('Remark is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (!(this.projectId)) {
      this.show_alert = true;
      this.alert_message = "Project Id is missing!";
      this.alert_type = "danger";

      this.toastr.error('Project Id is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (!(this.userId)) {
      this.show_alert = true;
      this.alert_message = "User Id is missing!";
      this.alert_type = "danger";

      this.toastr.error('User Id is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    this.subscription.add(
      this.projectService.submitReportRemark(this.projectId, formName, form.remark, this.userId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Remark - Submitted`, "Info!");
        // setTimeout(() => {
        //   this.scrollToElement("stepTwo");
        // }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  get mapEditFormValues() {
    return this.mapEditForm.controls;
  }

  get newCompetitorFormValues() {
    return this.newCompetitorForm.controls;
  }

  addCompetitor(form: any) {
    this.updateInProgress = true;
    this.submitInProgress = true;

    // Check
    if (this.newCompetitorForm.get("lat").invalid) {
      this.show_alert = true;
      this.alert_message = "latitude is missing!";
      this.alert_type = "danger";

      this.toastr.error('Latitude is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (this.newCompetitorForm.get("lng").invalid) {
      this.show_alert = true;
      this.alert_message = "Longitude is missing!";
      this.alert_type = "danger";

      this.toastr.error('Longitude  is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (this.newCompetitorForm.get("name").invalid) {
      this.show_alert = true;
      this.alert_message = "Name is missing!";
      this.alert_type = "danger";

      this.toastr.error('Name is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (this.newCompetitorForm.get("streetName").invalid) {
      this.show_alert = true;
      this.alert_message = "Street name is missing!";
      this.alert_type = "danger";

      this.toastr.error('Street name is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (this.newCompetitorForm.get("town").invalid) {
      this.show_alert = true;
      this.alert_message = "Town is missing!";
      this.alert_type = "danger";

      this.toastr.error('Town is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (this.newCompetitorForm.get("country").invalid) {
      this.show_alert = true;
      this.alert_message = "Country is missing!";
      this.alert_type = "danger";

      this.toastr.error('Country is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (this.newCompetitorForm.get("description").invalid) {
      this.show_alert = true;
      this.alert_message = "Description is missing!";
      this.alert_type = "danger";

      this.toastr.error('Description is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    this.subscription.add(
      this.projectService.addCompetitor(this.projectId, form, this.userId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Competitor ${form.name} added!`, "Info!");

        this.hideAddCompetitorForm();

        this.updateInProgress = false;
        this.submitInProgress = false;
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.updateInProgress = false;
          this.submitInProgress = false;
        }));

  }

  updateStationMapLocation(form: any) {

    // Check
    if (this.mapEditForm.get("lat").invalid) {
      this.show_alert = true;
      this.alert_message = "Latitude is missing!";
      this.alert_type = "danger";

      this.toastr.error('Latitude is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (this.mapEditForm.get("lng").invalid) {
      this.show_alert = true;
      this.alert_message = "Longitude is missing!";
      this.alert_type = "danger";

      this.toastr.error('Longitude is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (this.mapEditForm.get("streetName").invalid) {
      this.show_alert = true;
      this.alert_message = "Street Name is missing!";
      this.alert_type = "danger";

      this.toastr.error('Street Name is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (this.mapEditForm.get("town").invalid) {
      this.show_alert = true;
      this.alert_message = "Town is missing!";
      this.alert_type = "danger";

      this.toastr.error('Town is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (this.mapEditForm.get("country").invalid) {
      this.show_alert = true;
      this.alert_message = "Country is missing!";
      this.alert_type = "danger";

      this.toastr.error('Country is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (this.mapEditForm.get("description").invalid) {
      this.show_alert = true;
      this.alert_message = "Description is missing!";
      this.alert_type = "danger";

      this.toastr.error('Description is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    this.subscription.add(
      this.projectService.updateStationMapLocation(this.projectId, form, this.userId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Station Map Location Updated`, "Info!");

        // Pull from DB Ones
        this.getStationMapLocation();
        // setTimeout(() => {
        //   this.scrollToElement("stepTwo");
        // }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));

  }

  getStationMapLocation() {

    this.subscription.add(
      this.projectService.getProjectStationMapLocationObject(this.projectId).pipe(first()).subscribe((res: StationMapLocation) => {
        if (res) {
          if (res.lat) {
            this.stationMapLocationLatitude = res.lat;
            this.mapEditForm.get("lat").setValue(res.lat);
          };
          if (res.lng) {
            this.stationMapLocationLongitude = res.lng;
            this.mapEditForm.get("lng").setValue(res.lng);
          };
          if (res.streetName) {
            this.stationMapLocationStreetName = res.streetName;
            this.mapEditForm.get("streetName").setValue(res.streetName);
          };
          if (res.town) {
            this.stationMapLocationTown = res.town;
            this.mapEditForm.get("town").setValue(res.town);
          };
          if (res.description) {
            this.stationMapLocationDescription = res.description;
            this.mapEditForm.get("description").setValue(res.description);
          };
        };
      }));

  }

  updateStreetViewImage(streetViewForm: any, id: string) {
    this.updateInProgress = true;
    this.submitInProgress = true;

    if (id === "One") {
      if (this.streetViewOneForm.get("description").invalid) {
        this.show_alert = true;
        this.alert_message = "Description is missing!";
        this.alert_type = "danger";

        this.toastr.error('Description is missing!', 'Error!');

        this.loading = false;
        this.updateInProgress = false;
        this.submitInProgress = false;

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }
    }

    if (id === "Two") {
      if (this.streetViewTwoForm.get("description").invalid) {
        this.show_alert = true;
        this.alert_message = "Description is missing!";
        this.alert_type = "danger";

        this.toastr.error('Description is missing!', 'Error!');

        this.loading = false;
        this.updateInProgress = false;
        this.submitInProgress = false;

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }
    }

    if (id === "Three") {
      if (this.streetViewThreeForm.get("description").invalid) {
        this.show_alert = true;
        this.alert_message = "Description is missing!";
        this.alert_type = "danger";

        this.toastr.error('Description is missing!', 'Error!');

        this.loading = false;
        this.updateInProgress = false;
        this.submitInProgress = false;

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }
    }

    if (id === "Four") {
      if (this.streetViewFourForm.get("description").invalid) {
        this.show_alert = true;
        this.alert_message = "Description is missing!";
        this.alert_type = "danger";

        this.toastr.error('Description is missing!', 'Error!');

        this.loading = false;
        this.updateInProgress = false;
        this.submitInProgress = false;

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }
    }

    this.subscription.add(
      this.projectService.streetViewDescriptionUpdate(this.projectId, streetViewForm, id).pipe().subscribe((res: any) => {
        this.updateInProgress = false;
        this.submitInProgress = false;
        this.toastr.success(`Street view description ${id} updated`, "Info!");

      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.updateInProgress = false;
          this.submitInProgress = false;
        }));

  }

  saveStreetViewImages(imageData: any, id: string) {
    this.updateInProgress = true;
    this.submitInProgress = true;

    if (this.projectId && id && imageData) {
      this.subscription.add(
        this.projectService.streetViewImageUpdate(this.projectId, imageData, id).pipe().subscribe((res: any) => {
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`Street view image ${id} updated`, "Info!");

        },
          (error: string) => {
            this.toastr.error(`Error: ${error}`, "Error!");
            this.error = error;
            this.updateInProgress = false;
            this.submitInProgress = false;
          }));
    };
  }

  getLocation(lat: number, lng: number, id: string) {
    var origin = new google.maps.LatLng((Number(lat)), (Number(lng)));

    // Nation Center
    var destination = new google.maps.LatLng(-1.2831686, 36.8196603);

    this.subscription.add(
      this.ipGeoService.getLocationFromLatLng((Number(lat)), (Number(lng)))
        .subscribe((result) => {

          let town = "";
          let country = "";
          let county = "";
          let street = "";

          result.formatted_address;
          result.address_components.forEach(element => {
            if (element.types[0] === "country") {
              country = element.long_name;
            };
            if (element.types[0] === "locality") {
              town = element.long_name;
            };
            if (element.types[0] === "administrative_area_level_1") {
              county = element.long_name;
            };
            if (element.types[0] === "administrative_area_2") {
              street = element.long_name;
            };
          });

          if (id === "main") {
            this.mapEditForm.get("town").setValue(town);
            this.mapEditForm.get("country").setValue(country);
          };

          if (id === "newCompetitor") {
            this.newCompetitorForm.get("town").setValue(town);
            this.newCompetitorForm.get("country").setValue(country);
          };

        }));

    this.subscription.add(
      this.ipGeoService.getDirections(origin, destination)
        .subscribe((result) => {

          if (id === "main") {
            this.mapEditForm.get("streetName").setValue(result.routes[0].summary);
          };

          if (id === "newCompetitor") {
            this.newCompetitorForm.get("streetName").setValue(result.routes[0].summary);
          };
        }));
  }

  navigateTo(type: string) {

    if (type === "resultPDF") {
      this.toastr.info(`PDF Download Feature under development!`, "Info!");
    }

    if (type === "reportPDF") {
      // this.toastr.info(`PDF Download Feature under development!`, "Info!");
      this.printReportPDF();
    }

  }

  getBase64ImageFromAssetFolder() {

    // let url = "https://cdn.glitch.com/4c9ebeb9-8b9a-4adc-ad0a-238d9ae00bb5%2Fmdn_logo-only_color.svg?1535749917189";
    // let url = this.staticMapSource;
    let url = "../../../../assets/images/staticmap.png";
    let w = this.staticMapWidth;
    let h = this.staticMapHeight;
    let t = "image/png";
    let id = "asset";

    var img = new Image();
    img.crossOrigin = 'anonymous';
    img.addEventListener("load", imageReceived, true);
    img.src = url;

    function imageReceived() {

      const canvas = document.createElement("canvas");
      canvas.id = `canvas${id}y`;
      canvas.getContext("canvas", { preserveDrawingBuffer: true });
      canvas.setAttribute('crossOrigin', 'anonymous');
      const context = canvas.getContext("2d");

      canvas.width = w;
      canvas.height = h;
      canvas.innerText = "";

      context.fillStyle = "#FFF";
      context.fillRect(0, 0, w, h);
      context.drawImage(img, 0, 0, w, h);

      let dataURL = canvas.toDataURL("image/png");
      // console.log("dataURL Asset:", dataURL);
      this.dataURL5 = `${dataURL}`;
      console.log("dataURL Asset:", dataURL);
      console.log("canvas:", canvas);
    };

  }

  getBase64ImageFromURL() {

    console.log("dataURL URL - Start: ");

    let url = this.oneViewPicture;
    console.log("this.oneViewPicture: ", this.oneViewPicture);
    // let url = this.staticMapSource;
    let w = this.oneViewPicture_width;
    let h = this.oneViewPicture_height;
    console.log("w: ", w);
    console.log("h: ", h);
    let t = "image/jpeg";
    let id = "url";

    var img = new Image();
    // img.crossOrigin = 'anonymous';
    img.addEventListener("load", imageReceived, true);
    img.src = url;

    function imageReceived() {

      const canvas = document.createElement("canvas");
      canvas.id = `canvas${id}y`;
      canvas.getContext("canvas", { preserveDrawingBuffer: true });
      canvas.setAttribute('crossOrigin', 'anonymous');
      const context = canvas.getContext("2d");

      canvas.width = w;
      canvas.height = h;
      canvas.innerText = "";

      context.fillStyle = "#FFF";
      context.fillRect(0, 0, w, h);
      context.drawImage(img, 0, 0, w, h);
      let dataURL = canvas.toDataURL("image/png");
      // console.log("dataURL URL: ", dataURL);
      this.dataURL4 = `${dataURL}`;
      console.log("dataURL URL: ", dataURL);
      console.log("canvas:", canvas);
    };

  }

  getBase64Image_html_GPRSMap(img) {
    if (img.id === "image 5") {
      img.crossOrigin = 'anonymous';
    }

    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.fillStyle = "#FFF";
    ctx.fillRect(0, 0, img.width, img.height);
    ctx.drawImage(img, 0, 0, img.width, img.height);
    var dataURL = canvas.toDataURL("image/jpeg");
    console.log("dataURL: ", dataURL);
    this.dataURLGPRSMapArray.push({
      "dataURL": dataURL,
      "id": img.id
    });
  }

  printReportPDF() {
    console.log("this.dataURLGPRSMapArray: ", this.dataURLGPRSMapArray);

    let date = new Date();
    let year = date.getFullYear();
    let month = ((date.getMonth() + 1).toString()).padStart(2, '0');
    let day = ((date.getDate()).toString()).padStart(2, '0');

    //
    this.dateToday = `${day}-${month}-${year}`;

    this.notify("Downloading Dealer Summary ...");

    const param = {
      outputType: "save",
      returnJsPDFDocObject: false,
      fileName: `${this.projectName} - Report - ${this.dateToday}.pdf`,
      orientationLandscape: false,
      compress: true,
      pageEnable: true,
      pageLabel: "Page",
    };

    var doc = new jsPDF(param.orientationLandscape ? "landscape" : "p", "mm", "a4", param.compress);

    // add the font to jsPDF
    doc.addFileToVFS("Volkhov.ttf", Volkhov);
    doc.addFont('Volkhov.ttf', 'Volkhov', 'normal');

    var docWidth = doc.internal.pageSize.width;
    var docHeight = doc.internal.pageSize.height;

    var colorBlack = "#000000";
    var colorGray = "#4d4e53";
    var colorYellow = "#CDAE59";
    var colorBlue = "#353E59";
    var colorRed = "#fa5c7c";
    var colorGreen = "#0acf97";
    //starting at 15mm
    var currentHeight = 15;
    //var startPointRectPanel1 = currentHeight + 6;

    var pdfConfig = {
      headerTextSize: 16,
      labelTextSize: 12,
      fieldTextSize: 11,
      lineHeight: 9,
      subLineHeight: 5,
      headerLineHeight: 6,
      headerSubLineHeight: 4,
      headerFieldTextSize: 10,
    };

    var addHeader = function () {
      currentHeight = 15;
      // Logo Image
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/logo_315_x_315.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        10 + 0,
        currentHeight - 5 + 0,
        35,
        35
      );

      doc.setTextColor(colorGray);
      doc.setFontSize(pdfConfig.headerFieldTextSize);

      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("Delta Office Suites 2nd floor,", docWidth - 10, currentHeight, { align: 'right' });
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("Block B Off Waiyaki Way &", docWidth - 10, currentHeight, { align: 'right' });
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("Manyani West Road, Muthangari.", docWidth - 10, currentHeight, { align: 'right' });
      currentHeight += pdfConfig.headerSubLineHeight;
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("+254 709 497 000", docWidth - 10, currentHeight, { align: 'right' });
      doc.setFontSize(pdfConfig.headerFieldTextSize);
      // doc.setTextColor(colorGray);
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("info@galanaenergies.com", docWidth - 10, currentHeight, { align: 'right' });

      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("www.galanaenergies.com", docWidth - 10, currentHeight, { align: 'right' });

      //line breaker after logo & business info
      doc.line(10, 45, docWidth - 10, 45);
      currentHeight = 45;
    }

    addHeader();

    // Start
    doc.setFont('Volkhov', 'normal');
    doc.setTextColor(colorBlack);
    doc.setFontSize(12);

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(16);
    doc.text(`Project Report`, docWidth / 2, currentHeight, { align: 'center' },);
    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    doc.setFontSize(12);

    // General Info
    doc.line(5, currentHeight, docWidth - 5, currentHeight);
    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(14);
    doc.text("1.", 10, currentHeight, { align: 'left' },);
    doc.text("Project Summary.", 20, currentHeight, { align: 'left' },);
    doc.setFontSize(12);

    currentHeight += pdfConfig.subLineHeight;
    doc.line(5, currentHeight, docWidth - 5, currentHeight);
    currentHeight += pdfConfig.lineHeight;

    doc.text("Project Name :", 25, currentHeight, { align: 'left' },);
    if (this.projectName) {
      var textLines = doc.splitTextToSize(`${this.projectName}`, 100);
      doc.text(textLines, (docWidth / 3), currentHeight, { align: 'left' },);
      currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
    };

    doc.text("Location :", 25, currentHeight, { align: 'left' },);
    if (this.projectLocationDescription) {
      var textLines = doc.splitTextToSize(`${this.projectLocationDescription}`, 100);
      doc.text(textLines, (docWidth / 3), currentHeight, { align: 'left' },);
      currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
    };

    doc.text("Project Scope :", 25, currentHeight, { align: 'left' },);
    if (this.projectDescription) {
      var textLines = doc.splitTextToSize(`${this.projectDescription}`, 100);
      doc.text(textLines, (docWidth / 3), currentHeight);
      currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
    };

    doc.text("Project Dates :", 25, currentHeight, { align: 'left' },);
    doc.text("Start :", (docWidth / 3), currentHeight, { align: 'left' },);
    doc.text("End :", ((docWidth / 3) + 30), currentHeight, { align: 'left' },);

    currentHeight += pdfConfig.subLineHeight;
    if (this.projectStartDate) { doc.text(`${formatDate(this.projectStartDate, 'dd/MM/yyyy', 'en-US')}`, (docWidth / 3), currentHeight, { align: 'left' },); };
    if (this.projectEndDate) { doc.text(`${formatDate(this.projectEndDate, 'dd/MM/yyyy', 'en-US')}`, ((docWidth / 3) + 30), currentHeight, { align: 'left' },); };
    currentHeight += pdfConfig.subLineHeight;


    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(14);
    doc.text("2.", 10, currentHeight, { align: 'left' },);
    doc.text("Project Estimate/Actual Data.", 20, currentHeight, { align: 'left' },);
    doc.setFontSize(12);

    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.text("A. Investments", 25, currentHeight, { align: 'left' },);
    currentHeight += pdfConfig.lineHeight;

    if (this.companyInvestmentBrandingTotal) {
      doc.text(`i.`, 27, currentHeight, { align: 'left' },);
      doc.text(`Branding`, 34, currentHeight, { align: 'left' },);
      doc.text(`${formatCurrency(this.companyInvestmentBrandingTotal, 'en-US', '', '', '1.2-2')}`, docWidth - 15, currentHeight, { align: 'right' },);
      currentHeight += pdfConfig.subLineHeight;
    };

    if ((this.brandingItemArray).length > 0) {
      let x = this.brandingItemArray;
      x.forEach(element => {
        if (element.name) {
          doc.text(`${element.name}`, 37, currentHeight, { align: 'left' },);
        };
        if (element.total) {
          doc.text(`${formatCurrency((element.total), 'en-US', '', '', '1.2-2')}`, docWidth - 50, currentHeight, { align: 'right' },);
        };
        currentHeight += pdfConfig.subLineHeight;
      });
    };

    if (this.companyInvestmentConstructionTotal) {
      doc.text(`ii.`, 27, currentHeight, { align: 'left' },);
      doc.text(`Construction`, 34, currentHeight, { align: 'left' },);
      doc.text(`${formatCurrency(this.companyInvestmentConstructionTotal, 'en-US', '', '', '1.2-2')}`, docWidth - 15, currentHeight, { align: 'right' },);
      currentHeight += pdfConfig.subLineHeight;
    };

    if ((this.constructionItemArray).length > 0) {
      let x = this.constructionItemArray;
      x.forEach(element => {
        if (element.name) {
          doc.text(`${element.name}`, 37, currentHeight, { align: 'left' },);
        };
        if (element.total) {
          doc.text(`${formatCurrency((element.total), 'en-US', '', '', '1.2-2')}`, docWidth - 50, currentHeight, { align: 'right' },);
        };
        currentHeight += pdfConfig.subLineHeight;
      });
    };

    if (this.companyInvestmentEquipmentTotal) {
      doc.text(`iii.`, 27, currentHeight, { align: 'left' },);
      doc.text(`Equipment`, 34, currentHeight, { align: 'left' },);
      doc.text(`${formatCurrency(this.companyInvestmentEquipmentTotal, 'en-US', '', '', '1.2-2')}`, docWidth - 15, currentHeight, { align: 'right' },);
      currentHeight += pdfConfig.subLineHeight;
    };

    if ((this.equipmentItemArray).length > 0) {
      let x = this.equipmentItemArray;
      x.forEach(element => {
        if (element.name) {
          doc.text(`${element.name}`, 37, currentHeight, { align: 'left' },);
        };
        if (element.total) {
          doc.text(`${formatCurrency((element.total), 'en-US', '', '', '1.2-2')}`, docWidth - 50, currentHeight, { align: 'right' },);
        };
        currentHeight += pdfConfig.subLineHeight;
      });
    };

    if (this.companyInvestmentTotal) {
      currentHeight += pdfConfig.subLineHeight;
      doc.text(`Total`, (docWidth / 2), currentHeight, { align: 'left' },);
      doc.text(`${formatCurrency(this.companyInvestmentTotal, 'en-US', '', '', '1.2-2')}`, docWidth - 15, currentHeight, { align: 'right' },);
      currentHeight += pdfConfig.subLineHeight;
    };

    currentHeight += pdfConfig.subLineHeight;
    doc.line(20, currentHeight, docWidth - 20, currentHeight);

    doc.addPage();

    // Page 2

    addHeader();
    doc.setFontSize(12);
    doc.setTextColor(colorBlack);
    currentHeight += pdfConfig.lineHeight;

    doc.text("B. Sales, Profit & Cashflow.", 10, currentHeight, { align: 'left' },);
    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(10);

    doc.setTextColor(colorGray);

    // Titles
    doc.text(`Year 1`, 80, currentHeight, { align: 'left' },);
    doc.text(`Year 2`, 105, currentHeight, { align: 'left' },);
    doc.text(`Year 3`, 130, currentHeight, { align: 'left' },);
    doc.text(`Year 4`, 155, currentHeight, { align: 'left' },);
    doc.text(`Year 5`, 180, currentHeight, { align: 'left' },);

    doc.setTextColor(colorBlack);
    currentHeight += pdfConfig.lineHeight;

    doc.text("Net Fuel Margin", 15, currentHeight, { align: 'left' },);
    if (this.companySummaryNetFuelMarginArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.companySummaryNetFuelMarginArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }

        };

        indexA += 1;
        xPosition += 26;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Diversification", 15, currentHeight, { align: 'left' },);
    if (this.companySummaryDiversificationMarginArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.companySummaryDiversificationMarginArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 26;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Other Revenues", 15, currentHeight, { align: 'left' },);
    if (this.companySummaryOtherRevenueArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.companySummaryOtherRevenueArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 26;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Operating Expenses", 15, currentHeight, { align: 'left' },);
    if (this.companySummaryOperatingExpensesArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.companySummaryOperatingExpensesArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`(${formatCurrency(element, 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 26;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Rentals & Fees Due", 15, currentHeight, { align: 'left' },);
    if (this.companySummaryRentalsAndFeesDueArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.companySummaryRentalsAndFeesDueArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 26;
      });


    };
    currentHeight += pdfConfig.subLineHeight;
    doc.setTextColor(colorGray);
    doc.setFont('Volkhov', 'italic');
    doc.text("Financial Interest Received", 23, currentHeight, { align: 'left' },);

    if (this.companyInterestReceivedArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.companyInterestReceivedArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 26;
      });


    };
    currentHeight += pdfConfig.subLineHeight;
    doc.setFont('Volkhov', 'normal');
    doc.setTextColor(colorBlack);
    doc.text("EBITDA", 10, currentHeight, { align: 'left' },);
    if (this.companySummaryEBITDAArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.companySummaryEBITDAArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 26;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Investments", 15, currentHeight, { align: 'left' },);
    if (this.companySummaryInvestmentArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.companySummaryInvestmentArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`(${formatCurrency(element, 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 26;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Change in Working Capital", 15, currentHeight, { align: 'left' },);
    if (this.companySummaryChangeInWorkingCapitalArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.companySummaryChangeInWorkingCapitalArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 26;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Taxes", 15, currentHeight, { align: 'left' },);
    if (this.companySummaryTaxesArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.companySummaryTaxesArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`(${formatCurrency(element, 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 26;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Other Cash Items", 15, currentHeight, { align: 'left' },);
    if (this.companySummaryOtherCashItemsArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.companySummaryOtherCashItemsArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              if (indexA === 0) {
                doc.text(`(${formatCurrency(element, 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
              } else {
                doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
              }

            }
          }
        };

        indexA += 1;
        xPosition += 26;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Net Cash After Tax", 10, currentHeight, { align: 'left' },);
    if (this.companySummaryNetCashAfterTaxArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.companySummaryNetCashAfterTaxArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 26;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Financial Result", 15, currentHeight, { align: 'left' },);
    if (this.financingTotalArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.financingTotalArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 26;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Inflation Rate (%)", 15, currentHeight, { align: 'left' },);
    if (this.inflationRateArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.inflationRateArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency((element * 100 * (-1)), 'en-US', '', '', '1.2-2')}) %`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency((element * 100), 'en-US', '', '', '1.2-2')} %`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 26;
      });


    };
    currentHeight += pdfConfig.lineHeight;

    doc.text("Net Cash After Tax (Discounted)", 10, currentHeight, { align: 'left' },);
    if (this.companySummaryNetCashAfterTaxDiscountedArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.companySummaryNetCashAfterTaxDiscountedArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency((element * (-1)), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency((element), 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 26;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Net Cash After Tax (Real & Discounted)", 10, currentHeight, { align: 'left' },);
    if (this.companySummaryDiscountedNetCashAfterTaxRealArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.companySummaryDiscountedNetCashAfterTaxRealArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency((element * (-1)), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency((element), 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 26;
      });
    };
    currentHeight += pdfConfig.lineHeight;
    doc.line(20, currentHeight, docWidth - 20, currentHeight);

    doc.addPage();

    // Page 3

    addHeader();
    doc.setFontSize(12);


    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(14);
    doc.setTextColor(colorBlack);
    doc.text("3.", 10, currentHeight, { align: 'left' },);
    doc.text("Evaluation.", 20, currentHeight, { align: 'left' },);
    doc.setFontSize(12);

    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.text("i. Net Present Value (NPV).", 20, currentHeight, { align: 'left' },);
    currentHeight += pdfConfig.lineHeight;

    if (this.npvIntro) {
      doc.text(`Definition :`, 27, currentHeight, { align: 'left' },);
      var textLines = doc.setTextColor(colorGray).setFontSize(10).splitTextToSize(`${this.npvIntro}`, 120);
      doc.text(textLines, ((docWidth / 3) + 10), currentHeight);
      currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
      doc.setFontSize(12);
      doc.setTextColor(colorBlack);
    };

    if (this.npvRecommended) {
      doc.text(`Recommended :`, 27, currentHeight, { align: 'left' },);
      doc.text(`${this.npvRecommended}`, (docWidth / 3) * 2, currentHeight, { align: 'center' },);
      currentHeight += pdfConfig.subLineHeight;
    };

    if (this.projectAppraisalNpv) {
      doc.text(`Project Projection :`, 27, currentHeight, { align: 'left' },);
      doc.setDrawColor(114, 124, 245);
      doc.roundedRect((((docWidth / 3) * 2) - 30), (currentHeight - 4), 60, 5, 1, 1);
      doc.setDrawColor(0);
      doc.text(`${formatCurrency((this.projectAppraisalNpv), 'en-US', '', '', '1.2-2')}`, (docWidth / 3) * 2, currentHeight, { align: 'center' },);
      currentHeight += pdfConfig.subLineHeight;
    };

    if (this.netPresentValueRemarkArray) {
      doc.line(50, currentHeight, docWidth - 50, currentHeight);

      currentHeight += pdfConfig.subLineHeight;
      doc.text(`Remarks :`, 27, currentHeight, { align: 'left' },);
      currentHeight += pdfConfig.subLineHeight;
      let x = [];
      x = this.netPresentValueRemarkArray;

      x.forEach(element => {
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect((docWidth / 3) + 10, (currentHeight - 7), (((docWidth / 3) * 2) - 30), 20, 1, 1);
        doc.setFillColor(235, 235, 235);
        doc.roundedRect((docWidth / 3) + 12, (currentHeight - 5), (((docWidth / 3) * 2) - 70), 16, 1, 1, "F");
        doc.setDrawColor(0);

        let firstName = "";
        let surname = "";
        let otherName = "";
        let displayName = "";

        if (element.from) {
          if (element.remark) {
            var textLines = doc.setFontSize(10).splitTextToSize(`${element.remark}`, 80);
            doc.text(textLines, ((docWidth / 3) + 15), currentHeight);
            // currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
          };
          if (element.from.firstName) { firstName = element.from.firstName; };
          if (element.from.surname) { surname = element.from.surname; };
          if (element.from.otherName) { otherName = element.from.otherName };
          displayName = `${firstName} ${(surname).substring(0, 1)} ${(otherName).substring(0, 1)}`;
          if (displayName) {
            doc.setFontSize(10);
            doc.text(`${displayName}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
          }
          if (element.from.role) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${element.from.role}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };
          if (element.date) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${formatDate(element.date, 'dd/MM/yy HH:mm', 'en-US')}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };
        };
        currentHeight += pdfConfig.subLineHeight;
      });

    };

    // currentHeight += pdfConfig.subLineHeight;
    doc.line(20, currentHeight, docWidth - 20, currentHeight);

    // 

    currentHeight += pdfConfig.lineHeight;

    doc.text("ii. Payback Period.", 20, currentHeight, { align: 'left' },);
    currentHeight += pdfConfig.lineHeight;

    if (this.paybackPeriodIntro) {
      doc.text(`Definition :`, 27, currentHeight, { align: 'left' },);
      var textLines = doc.setTextColor(colorGray).setFontSize(10).splitTextToSize(`${this.paybackPeriodIntro}`, 120);
      doc.text(textLines, ((docWidth / 3) + 10), currentHeight);
      currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
      doc.setFontSize(12);
      doc.setTextColor(colorBlack);
    };

    if (this.paybackPeriodRecommended) {
      doc.text(`Recommended :`, 27, currentHeight, { align: 'left' },);
      doc.text(`${this.paybackPeriodRecommended}`, (docWidth / 3) * 2, currentHeight, { align: 'center' },);
      currentHeight += pdfConfig.subLineHeight;
    };

    if (this.projectAppraisalPaybackPeriod) {
      doc.text(`Project Projection :`, 27, currentHeight, { align: 'left' },);
      doc.setDrawColor(114, 124, 245);
      doc.roundedRect((((docWidth / 3) * 2) - 30), (currentHeight - 4), 60, 5, 1, 1);
      doc.setDrawColor(0);
      doc.text(`${formatCurrency((this.projectAppraisalPaybackPeriod), 'en-US', '', '', '1.2-2')}`, (docWidth / 3) * 2, currentHeight, { align: 'center' },);
      currentHeight += pdfConfig.subLineHeight;
    };

    if (this.paybackPeriodRemarkArray) {
      doc.line(50, currentHeight, docWidth - 50, currentHeight);

      currentHeight += pdfConfig.subLineHeight;
      doc.text(`Remarks :`, 27, currentHeight, { align: 'left' },);
      currentHeight += pdfConfig.subLineHeight;
      let x = [];
      x = this.paybackPeriodRemarkArray;

      x.forEach(element => {
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect((docWidth / 3) + 10, (currentHeight - 7), (((docWidth / 3) * 2) - 30), 20, 1, 1);
        doc.setFillColor(235, 235, 235);
        doc.roundedRect((docWidth / 3) + 12, (currentHeight - 5), (((docWidth / 3) * 2) - 70), 16, 1, 1, "F");
        doc.setDrawColor(0);

        let firstName = "";
        let surname = "";
        let otherName = "";
        let displayName = "";

        if (element.from) {
          if (element.remark) {
            var textLines = doc.setFontSize(10).splitTextToSize(`${element.remark}`, 80);
            doc.text(textLines, ((docWidth / 3) + 15), currentHeight);
            // currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
          };
          if (element.from.firstName) { firstName = element.from.firstName; };
          if (element.from.surname) { surname = element.from.surname; };
          if (element.from.otherName) { otherName = element.from.otherName };
          displayName = `${firstName} ${(surname).substring(0, 1)} ${(otherName).substring(0, 1)}`;
          if (displayName) {
            doc.setFontSize(10);
            doc.text(`${displayName}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
          }
          if (element.from.role) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${element.from.role}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };
          if (element.date) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${formatDate(element.date, 'dd/MM/yy HH:mm', 'en-US')}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };


        };

      });

    };

    currentHeight += pdfConfig.subLineHeight;
    doc.line(20, currentHeight, docWidth - 20, currentHeight);

    // 

    currentHeight += pdfConfig.lineHeight;

    doc.addPage();

    // Page 4

    addHeader();
    doc.setFontSize(12);
    doc.setTextColor(colorBlack);

    currentHeight += pdfConfig.lineHeight;

    doc.text("iii. Discounted - Payback Period.", 20, currentHeight, { align: 'left' },);
    currentHeight += pdfConfig.lineHeight;

    if (this.discountedPaybackPeriodIntro) {
      doc.text(`Definition :`, 27, currentHeight, { align: 'left' },);
      var textLines = doc.setTextColor(colorGray).setFontSize(10).splitTextToSize(`${this.discountedPaybackPeriodIntro}`, 120);
      doc.text(textLines, ((docWidth / 3) + 10), currentHeight);
      currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
      doc.setFontSize(12);
      doc.setTextColor(colorBlack);
    };

    if (this.discountedPaybackPeriodRecommended) {
      doc.text(`Recommended :`, 27, currentHeight, { align: 'left' },);
      doc.text(`${this.discountedPaybackPeriodRecommended}`, (docWidth / 3) * 2, currentHeight, { align: 'center' },);
      currentHeight += pdfConfig.subLineHeight;
    };

    if (this.projectAppraisalDiscountedPaybackPeriod) {
      doc.text(`Project Projection :`, 27, currentHeight, { align: 'left' },);
      doc.setDrawColor(114, 124, 245);
      doc.roundedRect((((docWidth / 3) * 2) - 30), (currentHeight - 4), 60, 5, 1, 1);
      doc.setDrawColor(0);
      doc.text(`${formatCurrency((this.projectAppraisalDiscountedPaybackPeriod), 'en-US', '', '', '1.2-2')}`, (docWidth / 3) * 2, currentHeight, { align: 'center' },);
      currentHeight += pdfConfig.subLineHeight;
    };

    if (this.discountedPaybackPeriodRemarkArray) {
      doc.line(50, currentHeight, docWidth - 50, currentHeight);

      currentHeight += pdfConfig.subLineHeight;
      doc.text(`Remarks :`, 27, currentHeight, { align: 'left' },);
      currentHeight += pdfConfig.subLineHeight;
      let x = [];
      x = this.discountedPaybackPeriodRemarkArray;

      x.forEach(element => {
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect((docWidth / 3) + 10, (currentHeight - 7), (((docWidth / 3) * 2) - 30), 20, 1, 1);
        doc.setFillColor(235, 235, 235);
        doc.roundedRect((docWidth / 3) + 12, (currentHeight - 5), (((docWidth / 3) * 2) - 70), 16, 1, 1, "F");
        doc.setDrawColor(0);

        let firstName = "";
        let surname = "";
        let otherName = "";
        let displayName = "";

        if (element.from) {
          if (element.remark) {
            var textLines = doc.setFontSize(10).splitTextToSize(`${element.remark}`, 80);
            doc.text(textLines, ((docWidth / 3) + 15), currentHeight);
            // currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
          };
          if (element.from.firstName) { firstName = element.from.firstName; };
          if (element.from.surname) { surname = element.from.surname; };
          if (element.from.otherName) { otherName = element.from.otherName };
          displayName = `${firstName} ${(surname).substring(0, 1)} ${(otherName).substring(0, 1)}`;
          if (displayName) {
            doc.setFontSize(10);
            doc.text(`${displayName}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
          }
          if (element.from.role) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${element.from.role}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };
          if (element.date) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${formatDate(element.date, 'dd/MM/yy HH:mm', 'en-US')}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };


        };

      });

    };

    currentHeight += pdfConfig.subLineHeight;
    doc.line(20, currentHeight, docWidth - 20, currentHeight);


    currentHeight += pdfConfig.lineHeight;

    doc.text("iv. Accounting Rate Of Return (ARR).", 20, currentHeight, { align: 'left' },);
    currentHeight += pdfConfig.lineHeight;

    if (this.accountingRateOfReturnIntro) {
      doc.text(`Definition :`, 27, currentHeight, { align: 'left' },);
      var textLines = doc.setTextColor(colorGray).setFontSize(10).splitTextToSize(`${this.accountingRateOfReturnIntro}`, 120);
      doc.text(textLines, ((docWidth / 3) + 10), currentHeight);
      currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
      doc.setFontSize(12);
      doc.setTextColor(colorBlack);
    };

    if (this.accountingRateOfReturnRecommended) {
      doc.text(`Recommended :`, 27, currentHeight, { align: 'left' },);
      doc.text(`${this.accountingRateOfReturnRecommended}`, (docWidth / 3) * 2, currentHeight, { align: 'center' },);
      currentHeight += pdfConfig.subLineHeight;
    };

    if (this.projectAppraisalAccountingRateOfReturn) {
      doc.text(`Project Projection :`, 27, currentHeight, { align: 'left' },);
      doc.setDrawColor(114, 124, 245);
      doc.roundedRect((((docWidth / 3) * 2) - 30), (currentHeight - 4), 60, 5, 1, 1);
      doc.setDrawColor(0);
      doc.text(`${formatCurrency((this.projectAppraisalAccountingRateOfReturn), 'en-US', '', '', '1.2-2')}`, (docWidth / 3) * 2, currentHeight, { align: 'center' },);
      currentHeight += pdfConfig.subLineHeight;
    };

    if (this.accountingRateOfReturnRemarkArray) {
      doc.line(50, currentHeight, docWidth - 50, currentHeight);

      currentHeight += pdfConfig.subLineHeight;
      doc.text(`Remarks :`, 27, currentHeight, { align: 'left' },);
      currentHeight += pdfConfig.subLineHeight;
      let x = [];
      x = this.accountingRateOfReturnRemarkArray;

      x.forEach(element => {
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect((docWidth / 3) + 10, (currentHeight - 7), (((docWidth / 3) * 2) - 30), 20, 1, 1);
        doc.setFillColor(235, 235, 235);
        doc.roundedRect((docWidth / 3) + 12, (currentHeight - 5), (((docWidth / 3) * 2) - 70), 16, 1, 1, "F");
        doc.setDrawColor(0);

        let firstName = "";
        let surname = "";
        let otherName = "";
        let displayName = "";

        if (element.from) {
          if (element.remark) {
            var textLines = doc.setFontSize(10).splitTextToSize(`${element.remark}`, 80);
            doc.text(textLines, ((docWidth / 3) + 15), currentHeight);
            // currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
          };
          if (element.from.firstName) { firstName = element.from.firstName; };
          if (element.from.surname) { surname = element.from.surname; };
          if (element.from.otherName) { otherName = element.from.otherName };
          displayName = `${firstName} ${(surname).substring(0, 1)} ${(otherName).substring(0, 1)}`;
          if (displayName) {
            doc.setFontSize(10);
            doc.text(`${displayName}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
          }
          if (element.from.role) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${element.from.role}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };
          if (element.date) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${formatDate(element.date, 'dd/MM/yy HH:mm', 'en-US')}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };


        };

      });

    };

    currentHeight += pdfConfig.subLineHeight;
    doc.line(20, currentHeight, docWidth - 20, currentHeight);

    // 
    doc.addPage();

    // Page 4

    addHeader();
    doc.setFontSize(12);
    doc.setTextColor(colorBlack);

    currentHeight += pdfConfig.lineHeight;

    doc.text("v. Profitability.", 20, currentHeight, { align: 'left' },);
    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(10);
    doc.setTextColor(colorGray);

    // Titles
    doc.text(`Year 1`, 80, currentHeight, { align: 'left' },);
    doc.text(`Year 2`, 106, currentHeight, { align: 'left' },);
    doc.text(`Year 3`, 132, currentHeight, { align: 'left' },);
    doc.text(`Year 4`, 158, currentHeight, { align: 'left' },);
    doc.text(`Year 5`, 184, currentHeight, { align: 'left' },);

    currentHeight += pdfConfig.subLineHeight;

    doc.setTextColor(colorBlack);

    doc.text("Net Cash After Tax (Discounted)", 10, currentHeight, { align: 'left' },);
    if (this.companySummaryNetCashAfterTaxDiscountedArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.companySummaryNetCashAfterTaxDiscountedArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency((element * (-1)), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency((element), 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 26;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Net Cash After Tax (Real & Discounted)", 10, currentHeight, { align: 'left' },);
    if (this.companySummaryDiscountedNetCashAfterTaxRealArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 80;
      x = this.companySummaryDiscountedNetCashAfterTaxRealArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency((element * (-1)), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency((element), 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 26;
      });
    };
    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(12);
    doc.setTextColor(colorBlack);



    currentHeight += pdfConfig.subLineHeight;
    doc.line(20, currentHeight, docWidth - 20, currentHeight);

    // 
    currentHeight += pdfConfig.lineHeight;

    // 

    doc.addPage();

    // Page 4

    addHeader();
    doc.setFontSize(12);
    doc.setTextColor(colorBlack);

    // Next Page
    // Section 4.
    // Recommendations

    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(14);
    doc.setTextColor(colorBlack);
    doc.text("4.", 10, currentHeight, { align: 'left' },);
    doc.text("Physical Environment.", 20, currentHeight, { align: 'left' },);
    doc.setFontSize(12);

    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.text("A. Station Map Location.", 20, currentHeight, { align: 'left' },);
    currentHeight += pdfConfig.subLineHeight;

    // Image Try


    // if (this.googleMapStaticImage.length > 0) {
    // console.log("dataURL2 B: ", this.dataURL2);

    doc.setDrawColor(128, 128, 128);
    doc.roundedRect(10, 78, 190, 100, 1, 1);
    doc.setFillColor(235, 235, 235);
    doc.roundedRect((10 + 2), (78 + 2), 186, 96, 1, 1, "F");
    doc.setDrawColor(0);

    let xy = this.dataURLGPRSMapArray;
    xy.forEach(element => {
      if (element.id === "image5") {
        doc.addImage(element.dataURL, "PNG", 12, 80, 186, 96);
        currentHeight += 50;
      }
    });

    // let imageData = this.getBase64Image(document.getElementById("image5"));
    // console.log("1",imageData);
    // doc.addImage(imageData, "PNG", 20, currentHeight, 100, 50);
    // console.log("2",imageData);
    // doc.addImage(imageData, 20, currentHeight, 100, 50);


    // } else {
    //   var imageHeader = new Image();
    //   imageHeader.src = "../../../../assets/images/staticmap.png";
    //   doc.addImage(imageHeader, "PNG", 20, currentHeight, 100, 50);
    // };

    currentHeight += pdfConfig.lineHeight;



    // let mapImage: any = this.getBase64ImageFromHTMLImage(document.getElementById("image5"));
    // console.log("this.dataURLArray: ", this.dataURLArray);
    // doc.addImage(mapImage.dataURL, "PNG", 20, currentHeight, 100, 50);

    // Image Try



    currentHeight = 185;

    doc.text("Map GPS Coordinates", 20, currentHeight, { align: 'left' },);

    currentHeight += pdfConfig.lineHeight;

    doc.text("Latitude : ", 25, currentHeight, { align: 'left' },);
    doc.text("Longitude : ", 105, currentHeight, { align: 'left' },);

    currentHeight += pdfConfig.lineHeight;

    doc.text("Description", 20, currentHeight, { align: 'left' },);

    currentHeight += pdfConfig.lineHeight;

    doc.text("Street Name : ", 25, currentHeight, { align: 'left' },);

    currentHeight += pdfConfig.subLineHeight;

    doc.text("Town : ", 25, currentHeight, { align: 'left' },);
    doc.text("Country : ", 105, currentHeight, { align: 'left' },);

    currentHeight += pdfConfig.subLineHeight;

    doc.text("Description : ", 25, currentHeight, { align: 'left' },);

    doc.setDrawColor(128, 128, 128);
    doc.roundedRect((docWidth / 3), (currentHeight - 2), 130, 20, 1, 1);
    doc.setFillColor(235, 235, 235);
    doc.roundedRect((docWidth / 3) + 2, (currentHeight), (130 - 4), 16, 1, 1, "F");
    doc.setDrawColor(0);

    currentHeight += pdfConfig.lineHeight;
    currentHeight += pdfConfig.lineHeight;
    currentHeight += pdfConfig.lineHeight;
    currentHeight += pdfConfig.lineHeight;

    doc.line(20, currentHeight, docWidth - 20, currentHeight);

    doc.addPage();

    // Page 4

    if (this.oneViewPicture) {
      addHeader();
      doc.setFontSize(12);
      doc.setTextColor(colorBlack);

      currentHeight += pdfConfig.lineHeight;

      doc.text("B. Station Street View.", 10, currentHeight, { align: 'left' },);
      currentHeight += pdfConfig.subLineHeight;

      doc.setDrawColor(128, 128, 128);
      doc.roundedRect(10, currentHeight, 190, 200, 1, 1);
      doc.setFillColor(235, 235, 235);
      doc.roundedRect((10 + 2), (currentHeight + 2), 186, 196, 1, 1, "F");
      doc.setDrawColor(0);

      let xy = this.dataURLGPRSMapArray;
      xy.forEach(element => {
        if (element.id === "image1") {
          doc.addImage(element.dataURL, "JPEG", 12, 80, 186, 196);
        }
      });

      doc.text(`Image 1.`, 20, (currentHeight + 7), { align: 'left' },);

      if (this.oneViewDescription) {
        var textLines = doc.setTextColor(colorGray).splitTextToSize(`${this.oneViewDescription}`, 120);
        doc.text(textLines, (docWidth / 2), (docHeight - 60), { align: 'center' },);
        currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
      }

      doc.addPage();
    }

    if (this.twoViewPicture) {
      addHeader();
      doc.setFontSize(12);
      doc.setTextColor(colorBlack);

      currentHeight += pdfConfig.lineHeight;

      doc.setDrawColor(128, 128, 128);
      doc.roundedRect(10, currentHeight, 190, 200, 1, 1);
      doc.setFillColor(235, 235, 235);
      doc.roundedRect((10 + 2), (currentHeight + 2), 186, 196, 1, 1, "F");
      doc.setDrawColor(0);

      if (this.dataURLGPRSMapArray.length > 0) {
        let xy = this.dataURLGPRSMapArray;
        xy.forEach(element => {
          if (element.id === "image2") {
            doc.addImage(element.dataURL, "JPEG", 12, 80, 186, 196);
          }
        });
      }

      doc.text(`Image 2.`, 20, (currentHeight + 7), { align: 'left' },);

      if (this.twoViewDescription) {
        var textLines = doc.setTextColor(colorGray).splitTextToSize(`${this.twoViewDescription}`, 120);
        doc.text(textLines, (docWidth / 2), (docHeight - 60), { align: 'center' },);
        currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
      }

      doc.addPage();
    }

    if (this.threeViewPicture) {
      addHeader();
      doc.setFontSize(12);
      doc.setTextColor(colorBlack);

      currentHeight += pdfConfig.lineHeight;

      doc.setDrawColor(128, 128, 128);
      doc.roundedRect(10, currentHeight, 190, 200, 1, 1);
      doc.setFillColor(235, 235, 235);
      doc.roundedRect((10 + 2), (currentHeight + 2), 186, 196, 1, 1, "F");
      doc.setDrawColor(0);

      let xy = this.dataURLGPRSMapArray;
      xy.forEach(element => {
        if (element.id === "image3") {
          doc.addImage(element.dataURL, "JPEG", 12, 80, 186, 196);
        }
      });

      doc.text(`Image 3.`, 20, (currentHeight + 7), { align: 'left' },);

      if (this.threeViewDescription) {
        var textLines = doc.setTextColor(colorGray).splitTextToSize(`${this.threeViewDescription}`, 120);
        doc.text(textLines, (docWidth / 2), (docHeight - 60), { align: 'center' },);
        currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
      }

      doc.addPage();
    }

    if (this.fourViewPicture) {
      addHeader();
      doc.setFontSize(12);
      doc.setTextColor(colorBlack);

      currentHeight += pdfConfig.lineHeight;

      doc.setDrawColor(128, 128, 128);
      doc.roundedRect(10, currentHeight, 190, 200, 1, 1);
      doc.setFillColor(235, 235, 235);
      doc.roundedRect((10 + 2), (currentHeight + 2), 186, 196, 1, 1, "F");
      doc.setDrawColor(0);

      let xy = this.dataURLGPRSMapArray;
      xy.forEach(element => {
        if (element.id === "image4") {
          doc.addImage(element.dataURL, "JPEG", 12, 80, 186, 196);
        }
      });

      doc.text(`Image 4.`, 20, (currentHeight + 7), { align: 'left' },);

      if (this.fourViewDescription) {
        var textLines = doc.setTextColor(colorGray).splitTextToSize(`${this.fourViewDescription}`, 120);
        doc.text(textLines, (docWidth / 2), (docHeight - 60), { align: 'center' },);
        currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
      }

      doc.addPage();
    }



    // Page 4

    addHeader();
    doc.setFontSize(12);
    doc.setTextColor(colorBlack);

    // Next Page
    // Section 5.
    // Recommendations

    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(14);
    doc.setTextColor(colorBlack);
    doc.text("5.", 10, currentHeight, { align: 'left' },);
    doc.text("Project Committee Recommendation.", 20, currentHeight, { align: 'left' },);
    doc.setFontSize(12);

    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.text("A. Recommendation.", 20, currentHeight, { align: 'left' },);
    currentHeight += pdfConfig.lineHeight;

    doc.text(`i. Project Committee Recommendation :`, 27, currentHeight, { align: 'left' },);

    if (this.projectCommitteeRecommendation) {
      if (this.projectCommitteeRecommendation === "Approved") {
        doc.setTextColor(colorGreen);
        doc.text(`${this.projectCommitteeRecommendation}`, (docWidth / 4) * 3, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        currentHeight += pdfConfig.lineHeight;
        doc.setTextColor(colorBlack);
      };
      if (this.projectCommitteeRecommendation === "Pending") {
        doc.setTextColor(colorGray);
        doc.text(`${this.projectCommitteeRecommendation}`, (docWidth / 4) * 3, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        currentHeight += pdfConfig.lineHeight;
        doc.setTextColor(colorBlack);
      };
      if (this.projectCommitteeRecommendation === "Rejected") {
        doc.setTextColor(colorRed);
        doc.text(`${this.projectCommitteeRecommendation}`, (docWidth / 4) * 3, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        currentHeight += pdfConfig.lineHeight;
        doc.setTextColor(colorBlack);
      };
    };

    doc.text(`ii. Justification :`, 27, currentHeight, { align: 'left' },);
    doc.setFontSize(10);
    doc.setTextColor(colorGray);
    doc.text(`Chief Executive Offices`, 27, (currentHeight + (pdfConfig.subLineHeight)), { align: 'left' },);
    doc.setFontSize(12);
    doc.setTextColor(colorBlack);

    // 
    doc.setDrawColor(128, 128, 128);
    doc.roundedRect((docWidth / 3), (currentHeight - 5), 130, 40, 1, 1);
    doc.setFillColor(235, 235, 235);
    doc.roundedRect(((docWidth / 3) + 2), (currentHeight - 3), 126, 36, 1, 1, "F");
    doc.setDrawColor(0);

    if (this.chiefExecutiveOfficesJustification) {
      var textLines = doc.splitTextToSize(`${this.chiefExecutiveOfficesJustification}`, 120);
      doc.text(textLines, ((docWidth / 3) + 4), currentHeight + 1);
      doc.setFontSize(12);
      doc.setTextColor(colorBlack);
      currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
      currentHeight += pdfConfig.subLineHeight;
    };

    currentHeight = 150;

    doc.text("B. Ratification.", 20, currentHeight, { align: 'left' },);
    currentHeight += pdfConfig.subLineHeight;
    doc.setFontSize(11);
    doc.setTextColor(colorGray);
    doc.text("(Any two if required.)", 25, currentHeight, { align: 'left' },);
    doc.setFontSize(12);
    doc.setTextColor(colorBlack);
    currentHeight += pdfConfig.lineHeight;
    currentHeight += pdfConfig.lineHeight;


    doc.setDrawColor(128, 128, 128);
    doc.roundedRect(30, (currentHeight - 5), 50, 60, 1, 1);
    doc.roundedRect(30, ((currentHeight - 5) + 45), 50, 15, 1, 1);
    doc.roundedRect(90, (currentHeight - 5), 50, 60, 1, 1);
    doc.roundedRect(90, ((currentHeight - 5) + 45), 50, 15, 1, 1);
    doc.roundedRect(150, (currentHeight - 5), 50, 60, 1, 1);
    doc.roundedRect(150, ((currentHeight - 5) + 45), 50, 15, 1, 1);
    doc.setDrawColor(0);

    currentHeight += 48;

    doc.setFontSize(11);
    doc.text(`Chairman`, 55, currentHeight, { align: 'center' },);
    doc.text(`Secretary`, 115, currentHeight, { align: 'center' },);
    doc.text(`Committee Member`, 175, currentHeight, { align: 'center' },);











    //#region Add num of pages at the bottom
    if (doc.getNumberOfPages() > 1) {
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        doc.setFontSize(pdfConfig.headerFieldTextSize);
        doc.setTextColor(colorGray);

        if (param.pageEnable) {
          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_210_x_30.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            1,
            doc.internal.pageSize.height - 30,
            210,
            30
          );

          // doc.line(10, docHeight - 15, docWidth - 10, docHeight - 15);
          if (this.userName) { doc.text(`Printed By: ${this.userName}`, 40, docHeight - 10, { align: 'left' }); };
          if (this.dateToday) { doc.text(`${this.dateToday}`, 40, docHeight - 5, { align: 'left' }); };
          doc.text("From: www.financialforecast.cloud", (docWidth / 2), docHeight - 5, { align: 'center' });
          doc.text(
            param.pageLabel + " " + i + " / " + doc.getNumberOfPages(),
            docWidth - 30,
            doc.internal.pageSize.height - 7,
            { align: 'left' }
          );
        }

      }
    }

    //#region Add num of first page at the bottom
    if (doc.getNumberOfPages() === 1 && param.pageEnable) {

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_210_x_30.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        1,
        doc.internal.pageSize.height - 30,
        210,
        30
      );

      doc.setFontSize(pdfConfig.fieldTextSize);
      doc.setTextColor(colorGray);
      // doc.line(10, docHeight - 15, docWidth - 10, docHeight - 15);
      if (this.userName) { doc.text(`Printed By: ${this.userName}`, 40, docHeight - 10, { align: 'left' }); };
      if (this.dateToday) { doc.text(`${this.dateToday}`, 40, docHeight - 5, { align: 'left' }); };
      doc.text("www.financialforecast.cloud", (docWidth / 2), docHeight - 5, { align: 'center' });
      doc.text(
        param.pageLabel + " 1 / 1",
        docWidth - 30,
        doc.internal.pageSize.height - 7,
        { align: 'left' }
      );
    }
    //#endregion


    let returnObj = {
      pagesNumber: doc.getNumberOfPages(),
    };



    if (param.outputType === "save") {
      // this.disableDownloadButton = false;
      doc.save(param.fileName);
    }

    return returnObj;
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
