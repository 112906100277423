<div class="card bg-transparent">
    <app-ui-preloader [display]="loading"></app-ui-preloader>
    <div class="card">
        <div class="card-header">
            <div class="row flex-grow-1">
                <div class="col">
                    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                        <h1 class="display-4">User Guide</h1>
                        <p class="lead">List of users guide instructions.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Logo -->
        <div class="card-body advancedList" id="advancedList">
            <div class="row mb-2">
                <div class="col-sm-5">
                    <a (click)="createRecord()" class="btn btn-primary mb-2 me-1"><i
                            class="mdi mdi-plus-circle me-2"></i>
                        New User Guide</a>
                </div>
                <div class="col-sm-7">
                    <div class="text-sm-end">
                    </div>
                </div><!-- end col-->
            </div>
            <!-- end row -->
            <!-- <app-ui-preloader [display]="loading"></app-ui-preloader> -->

            <!-- user table -->
            <app-advanced-table #advancedTable (handleTableLoad)="handleTableLoad()" [tableData]="userGuideArray"
                [columns]="columns" [pagination]="true" [isSearchable]="true" [pageSizeOptions]="pageSizeOptions"
                (search)="searchData($event)" [hasItemNumbering]="true" [isSortable]="true" (sort)="sort($event)"
                tableClasses="table-centered nowrap w-100" theadClasses="table-light">
            </app-advanced-table>
        </div> <!-- end card-body-->
    </div>

    <div class="card userGuideFormEdit" id="userGuideFormEdit">
        <div class="card-header">

            <!-- title-->
            <div class="row flex-grow-1">
                <div class="col">
                    <div class="pricing-header px-3 py-3 pt-md-3 pb-md-2 mx-auto text-center">
                        <h1 class="display-6" *ngIf="userGuideUpdateType === 'update'">Edit User Guide Item</h1>
                        <h1 class="display-6" *ngIf="userGuideUpdateType === 'new'">New User Guide Item</h1>
                        <p class="lead text-muted">Fill in the details.</p>
                    </div>
                </div>
            </div>
    
        </div>
        <div class="card-body">

            <h5 class="mb-3">Gif illustration</h5>

            <div class="row">
                <div class="card" role="button" (click)="enlargeImage(sizeableModal)">
                    <img src="../../../assets/images/Female-Avatar.png" height="100px"
                        style="border-style: dashed;object-fit: scale-down;" class="card-img-top" alt="..."
                        *ngIf="!gif">
                    <img [src]="gif" class="card-img-top" height="100px"
                        style="border-style: dashed;object-fit: scale-down;" alt="..." *ngIf="gif">
                    <ul class="list-group text-center list-group-flush">
                        <li class="list-group-item" *ngIf="!gif">
                            <div class="form-group">
                                <small class="text-body-secondary">Upload a Gif.</small>
                                <input type="file" class="form-control" accept=".gif" [multiple]="false"
                                    (change)="loadFiles($event)">
                            </div>
                        </li>
                        <li class="list-group-item" *ngIf="gif">
                            <button type="button" (click)="deleteImage()" class="btn btn-sm btn-outline-danger">
                                Delete
                            </button>
                        </li>
                    </ul>
                </div>

            </div>


            <form name="signup-form" [formGroup]="userGuideForm">

                <h5 class="mb-3">User Guide Details</h5>

                <div class="row">
                    <div class="col-md-12 mb-3">
                        <label for="title" class="form-label">Title<span class="text-danger">
                                *</span></label>
                        <input class="form-control" type="text" id="title" placeholder="Enter your first name" required
                            formControlName="title"
                            [ngClass]="{'is-invalid': (formSubmitted && formValues['title'].invalid)||(formValues['title'].touched && formValues['title'].invalid)}">
                        <div *ngIf="(formSubmitted && formValues['title'].invalid)||(formValues['title'].touched && formValues['title'].invalid)"
                            class="invalid-tooltip">
                            <div *ngIf="formValues['title'].errors?.['required']">
                                Title is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label for="description" class="form-label">Description<span class="text-danger">
                                *</span></label>
                        <textarea class="form-control" type="text" id="description" placeholder="Enter the description."
                            required formControlName="description" cols="6"
                            [ngClass]="{'is-invalid': (formSubmitted && formValues['description'].invalid)||(formValues['description'].touched && formValues['description'].invalid)}"></textarea>
                        <div *ngIf="(formSubmitted && formValues['description'].invalid)||(formValues['description'].touched && formValues['description'].invalid)"
                            class="invalid-tooltip">
                            <div *ngIf="formValues['description'].errors?.['required']">
                                Description is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="chapterNumber" class="form-label">Chapter Number<span class="text-danger">
                                *</span></label>
                        <input class="form-control" type="text" id="chapterNumber" placeholder="Enter the chapter number i.e. 2.3" required
                            formControlName="chapterNumber"
                            [ngClass]="{'is-invalid': (formSubmitted && formValues['chapterNumber'].invalid)||(formValues['chapterNumber'].touched && formValues['chapterNumber'].invalid)}">
                        <div *ngIf="(formSubmitted && formValues['chapterNumber'].invalid)||(formValues['chapterNumber'].touched && formValues['chapterNumber'].invalid)"
                            class="invalid-tooltip">
                            <div *ngIf="formValues['chapterNumber'].errors?.['required']">
                                Chapter number is required!
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="level" class="form-label">Level<span class="text-danger"></span></label>
                        <input class="form-control" type="level" id="level" readonly
                            formControlName="level">
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="number" class="form-label">Number<span class="text-danger"></span></label>
                        <input class="form-control" type="number" id="number" readonly
                            formControlName="number">
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="created" class="form-label">Created<span class="text-danger"></span></label>
                        <input class="form-control" type="created" id="created" readonly
                            formControlName="created">
                    </div>

                </div>

                <div class="row mt-2">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="d-grid">
                            <button class="btn btn-secondary" (click)="cancelEdit()" type="submit"
                                *ngIf="!updateInProgress"><i class=""></i>Cancel Edit
                            </button>
                            <button class="btn btn-primary" type="button" disabled *ngIf="updateInProgress">
                                <span class="spinner-border spinner-border-sm me-1" role="status"
                                    aria-hidden="true"></span>
                                Loading...
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="d-grid">
                            <button class="btn btn-success" (click)="onSubmit(userGuideForm.value)" type="submit"
                                *ngIf="!updateInProgress"><i class=""></i>Update
                            </button>
                            <button class="btn btn-primary" type="button" disabled *ngIf="updateInProgress">
                                <span class="spinner-border spinner-border-sm me-1" role="status"
                                    aria-hidden="true"></span>
                                Loading...
                            </button>
                        </div>
                    </div>
                </div>

            </form>

        </div>
    </div>
</div>

<ng-template #sizeableModal let-modal>
    <div class="modal-body">
        <div (click)="closeModal()" class="card mb-0 px-0">
            <img [src]="pickedImage" class="img-fluid card-img" alt="image" *ngIf="pickedImage" />
        </div>
    </div>
</ng-template>