import { MenuItem } from '../models/menu.model';

// menu items
export const MENU: MenuItem[] = [
    { key: 'ds-navigation', label: 'Navigation', isTitle: true },
    { key: 'ds-ask-home', label: 'Home', isTitle: false, icon: 'uil-home-alt', link: '/' },
    { key: 'ds-ask-question', label: 'Ask a question', isTitle: false, icon: 'uil-comment-alt-question', link: '/platform/ask-question' },
    { key: 'ds-question', label: 'Questions', isTitle: false, icon: 'uil-comments', link: '/platform/question-list' },
    // {
    //     key: 'ds-platform', label: 'Tags', isTitle: false, icon: 'uil-tag-alt', collapsed: false,
    //     children: [
    //         //{ key: 'ds-question-list', label: 'Questions', link: '/platform/question-list', parentKey: 'platform' },
    //         // { key: 'ds-question', label: 'Question', link: '/platform/question', parentKey: 'platform' },
    //         // { key: 'ds-answer', label: 'Answer', link: '/platform/answer', parentKey: 'platform' },

    //         { key: 'ds-farming-category', label: 'Category', link: '/platform/farming-category', parentKey: 'platform' },
    //         { key: 'ds-related-projects', label: 'Projects', link: '/platform/related-project', parentKey: 'platform' },
    //         { key: 'ds-location', label: 'Locations', link: '/platform/location', parentKey: 'platform' },
    //         //{ key: 'ds-tags', label: 'Tags', link: '/platform/tags', parentKey: 'platform' },
    //     ]
    // },
    { key: 'question-review', label: 'Pending Review', isTitle: false, icon: 'uil-file-exclamation', link: 'platforms/question-review' },
    // { key: 'user', label: 'Users', isTitle: false, icon: 'uil-users-alt', link: 'platforms/community'},

    //{ key: 'landing', label: 'About', isTitle: false, icon: 'uil-globe', link: '/landing' },
]