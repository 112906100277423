import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class IpGeoService {
  address: string;

  constructor(
    private http: HttpClient
  ) { }

  getIpAddress() {
    return this.http
      .get('https://api.ipify.org/?format=json')
      .pipe(
        catchError(this.handleError)
      );
  }

  getGEOLocation(ip) {

    let url = "https://api.ipgeolocation.io/ipgeo?apiKey=b81cc74e124247a0b99438bc3c22f79c&ip=" + ip + "&fields=geo";
    return this.http
      .get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // console.error('An error occurred:', error.error.message);
    } else {

      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
    }

    return throwError(
      'Something bad happened; please try again later.');
  }

  getLatLng(): Observable<any> {
    let pos;
    return Observable.create(observer => {
      if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            if (position) {
              pos = position;
            }
            observer.next(pos);
            observer.complete();
          },
          (error) => observer.error(error)
        );
      } else {
        observer.error('Unsupported Browser');
      }
    });
  }

  getLocationFromBrowser(position): Observable<any> {
    let address;
    return Observable.create(observer => {
      let geocoder = new google.maps.Geocoder();
      let request = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };

      geocoder.geocode({ location: request }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0] != null) {
            address = results[0];
          }
          observer.next(address);
          observer.complete();
        } else {
          observer.error('Unsupported Browser');
        }
      });
    });
  }

  getLocationFromLatLng(latitude:number, longitude:number): Observable<any> {
    let address;
    return Observable.create(observer => {
      let geocoder = new google.maps.Geocoder();
      let request = {
        lat: latitude,
        lng: longitude
      };

      geocoder.geocode({ location: request }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0] != null) {
            address = results[0];
          }
          observer.next(address);
          observer.complete();
        } else {
          observer.error('Unsupported Browser');
        }
      });
    });
  }

  getDirections(origin, destination): Observable<any> {
    let directionResult;
    return Observable.create(observer => {
      let directionsService = new google.maps.DirectionsService();
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            directionResult = result
            observer.next(directionResult);
            observer.complete();
          } else {
            observer.error(`error fetching directions ${result}`);
          }
        }
      );
    })
  }

  getAddress(latitude, longitude): Observable<any> {
    return Observable.create(observer => {
      let geocoder = new google.maps.Geocoder();
      if (geocoder) {
        geocoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
          
          if (status === 'OK') {
            if (results[0]) {
              this.address = results[0].formatted_address;
              observer.next(this.address);
              observer.complete();
            } else {
              window.alert('No results found');
              observer.error('No results found');
            }
          } else {
            window.alert('Geocoder failed due to: ' + status);
            observer.error('Geocoder failed due to: ' + status);
          }

        });
      }
    })
  }

  getStaticImageFrom(latitude, longitude): Observable<any> {
    return Observable.create(observer => {
      let geocoder = new google.maps.Geocoder();
      if (geocoder) {
        geocoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
          
          if (status === 'OK') {
            if (results[0]) {
              this.address = results[0].formatted_address;
              observer.next(this.address);
              observer.complete();
            } else {
              window.alert('No results found');
              observer.error('No results found');
            }
          } else {
            window.alert('Geocoder failed due to: ' + status);
            observer.error('Geocoder failed due to: ' + status);
          }

        });
      }
    })
  }

}
