import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { User } from '@firebase/auth-types';
import { NgbAlert, NgbAlertModule, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription, first } from 'rxjs';
import { CompanyInvestment } from 'src/app/core/models-two/CompanyInvestment';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { ClockService } from 'src/app/core/service/clock.service';
import { ProjectService } from 'src/app/core/service/project.service';
import { UserService } from 'src/app/core/service/user.service';
import { WidgetModule } from 'src/app/shared/widget/widget.module';

@Component({
  selector: 'app-investment',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    WidgetModule,
    NgbButtonsModule,
  ],
  templateUrl: './investment.component.html',
  styleUrls: ['./investment.component.scss']
})
export class InvestmentComponent implements OnInit, OnDestroy {

  yearlyEscalationDefault: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  subscription: Subscription = new Subscription();
  today: number = Date.now();
  loading: boolean = false;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;

  brandingItemForm!: FormGroup;
  equipmentItemForm!: FormGroup;
  constructionItemForm!: FormGroup;
  investmentItemForm!: FormGroup;
  investmentAmortizationForm!: FormGroup;

  brandingItemList: FormArray;
  equipmentItemList: FormArray;
  constructionItemList: FormArray;

  formSubmitted: boolean = false;

  show_alert: boolean;
  alert_message: string;
  alert_type: string;
  loggedInUser: User;
  error: string;
  localCurrencyISOCode: any = "KES";
  userId: any;

  brandingTotal: number = 0;
  constructionTotal: number = 0;
  equipmentTotal: number = 0;
  investmentTotal: number = 0;

  companyInvestmentArray: number[] = this.yearlyEscalationDefault;
  companyInvestmentTotalArray: number[] = this.yearlyEscalationDefault;
  companyInvestmentBrandingTotal: number = 0;
  companyInvestmentConstructionTotal: number = 0;
  companyInvestmentEquipmentTotal: number = 0;
  companyInvestmentInvestmentTotal: number = 0;

  brandingAmortizationArray: number[] = this.yearlyEscalationDefault;
  constructionAmortizationArray: number[] = this.yearlyEscalationDefault;
  equipmentAmortizationArray: number[] = this.yearlyEscalationDefault;
  totalAmortizationArray: number[] = this.yearlyEscalationDefault;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private clockService: ClockService,
  ) {
    this.loadingAnimationFourSeconds();
    this.subscription.add(
      authenticationService.currentUser.pipe().subscribe((res: any) => {
        if (res) {
          if (res) {
            this.loggedInUser = res;
            if (res.userId) {
              this.userId = res.userId;
            }
          } else {
          }
        }
      })
    );
    this.subscription.add(
      this.projectService.getDefaultCompanyInvestmentObject().pipe().subscribe((res: CompanyInvestment) => {
        if (res) {
          if (res.branding) {
            this.brandingItemList.removeAt(0);
            let brandingIndex = 0;
            this.brandingItemForm.get("name").setValue("Branding");
            (res.branding).forEach(element => {
              this.brandingItemList.push(this.createItem());
              this.brandingItemList.get(`${brandingIndex}`).get("name").setValue(element.name);
              this.brandingItemList.get(`${brandingIndex}`).get("total").setValue(element.total);
              brandingIndex += 1;
            });
          }

          if (res.construction) {
            this.constructionItemList.removeAt(0);
            let constructionIndex = 0;
            this.constructionItemForm.get("name").setValue("Construction");

            (res.construction).forEach(element => {
              this.constructionItemList.push(this.createItem());
              this.constructionItemList.get(`${constructionIndex}`).get("name").setValue(element.name);
              this.constructionItemList.get(`${constructionIndex}`).get("total").setValue(element.total);
              constructionIndex += 1;
            });
          }

          if (res.equipment) {
            this.equipmentItemList.removeAt(0);
            let equipmentIndex = 0;
            this.equipmentItemForm.get("name").setValue("Equipment");
            (res.equipment).forEach(element => {
              this.equipmentItemList.push(this.createItem());
              this.equipmentItemList.get(`${equipmentIndex}`).get("name").setValue(element.name);
              this.equipmentItemList.get(`${equipmentIndex}`).get("total").setValue(element.total);
              equipmentIndex += 1;
            });
          }

          if (res.brandingTotal) { 
            this.companyInvestmentBrandingTotal = res.brandingTotal;
            this.investmentAmortizationForm.get("brandingAmortizationAmount").setValue(res.brandingTotal); 
          };
          if (res.constructionTotal) { 
            this.companyInvestmentConstructionTotal = res.constructionTotal 
            this.investmentAmortizationForm.get("constructionAmortizationAmount").setValue(res.constructionTotal);
          };
          if (res.equipmentTotal) { 
            this.companyInvestmentEquipmentTotal = res.equipmentTotal
            this.investmentAmortizationForm.get("equipmentAmortizationAmount").setValue(res.equipmentTotal);
          };
          if(res.brandingAmortizationPeriod){
            this.investmentAmortizationForm.get("brandingAmortizationPeriod").setValue(res.brandingAmortizationPeriod);
          };
          if(res.constructionAmortizationPeriod){
            this.investmentAmortizationForm.get("constructionAmortizationPeriod").setValue(res.constructionAmortizationPeriod);
          };
          if(res.equipmentAmortizationPeriod){
            this.investmentAmortizationForm.get("equipmentAmortizationPeriod").setValue(res.equipmentAmortizationPeriod);
          };
          if (res.total) { 
            this.companyInvestmentInvestmentTotal = res.total; 
          };

          if (res.yearlyEscalation) {
            if (res.yearlyEscalation.brandingAmortization) {
              this.brandingAmortizationArray = res.yearlyEscalation.brandingAmortization;
            };
            if (res.yearlyEscalation.constructionAmortization) {
              this.constructionAmortizationArray = res.yearlyEscalation.constructionAmortization;
            };
            if (res.yearlyEscalation.equipmentAmortization) {
              this.equipmentAmortizationArray = res.yearlyEscalation.equipmentAmortization;
            };
            if (res.yearlyEscalation.totalAmortization) {
              this.totalAmortizationArray = res.yearlyEscalation.totalAmortization;
            };
            if (res.yearlyEscalation.investment) {
              this.companyInvestmentArray = res.yearlyEscalation.investment;
            };
            if (res.yearlyEscalation.total) {
              this.companyInvestmentTotalArray = res.yearlyEscalation.total;
            };
          }

        }
      })
    );
  }

  // returns all form groups under items
  get brandingItemFormGroup() {
    return this.brandingItemForm.get('item') as FormArray;
  }

  get equipmentItemFormGroup() {
    return this.equipmentItemForm.get('item') as FormArray;
  }

  get constructionItemFormGroup() {
    return this.constructionItemForm.get('item') as FormArray;
  }

  ngOnInit(): void {

    this.brandingItemForm = this.fb.group({
      name: ['Branding', Validators.required],
      item: this.fb.array([this.createItem()]),
    });

    this.equipmentItemForm = this.fb.group({
      name: ['Equipment', Validators.required],
      item: this.fb.array([this.createItem()]),
    });

    this.constructionItemForm = this.fb.group({
      name: ['Construction', Validators.required],
      item: this.fb.array([this.createItem()]),
    });

    this.investmentItemForm = this.fb.group({
      brandingTotal: [0, Validators.required],
      equipmentTotal: [0, Validators.required],
      constructionTotal: [0, Validators.required],
      total: [0, Validators.required],
    });

    this.investmentAmortizationForm = this.fb.group({
      brandingAmortizationPeriod: [10, Validators.required],
      brandingAmortizationAmount: [0, Validators.required],
      constructionAmortizationPeriod: [10, Validators.required],
      constructionAmortizationAmount: [0, Validators.required],
      equipmentAmortizationPeriod: [10, Validators.required],
      equipmentAmortizationAmount: [0, Validators.required],
    });

    this.brandingItemList = this.brandingItemForm.get('item') as FormArray;
    this.equipmentItemList = this.equipmentItemForm.get('item') as FormArray;
    this.constructionItemList = this.constructionItemForm.get('item') as FormArray;

    this.subscription.add(
      this.investmentAmortizationForm.get("brandingAmortizationPeriod").valueChanges.subscribe(x => {
        this.brandingAmortizationArray = [];
        this.totalAmortizationArray = [];
        let yearlyValue = 0;
        let years = (this.investmentAmortizationForm.get("brandingAmortizationPeriod").value);
        // Get Amount / Divide by years
        yearlyValue = (this.investmentAmortizationForm.get("brandingAmortizationAmount").value) / (years);

        for (let index = 0; index < 16; index++) {
          if ((index > 0) && (index < (years + 1))) {
            this.brandingAmortizationArray.push(yearlyValue);
          } else {
            this.brandingAmortizationArray.push(0);
          }

          // Calculate Total
          this.totalAmortizationArray.push(
            this.brandingAmortizationArray[index] +
            this.constructionAmortizationArray[index] +
            this.equipmentAmortizationArray[index]
          )
        }
      })
    );

    this.subscription.add(
      this.investmentAmortizationForm.get("constructionAmortizationPeriod").valueChanges.subscribe(x => {
        this.constructionAmortizationArray = [];
        this.totalAmortizationArray = [];
        let yearlyValue = 0;
        let years = (this.investmentAmortizationForm.get("constructionAmortizationPeriod").value);
        // Get Amount / Divide by years
        yearlyValue = (this.investmentAmortizationForm.get("constructionAmortizationAmount").value) / (years);

        for (let index = 0; index < 16; index++) {
          if ((index > 0) && (index < (years + 1))) {
            this.constructionAmortizationArray.push(yearlyValue);
          } else {
            this.constructionAmortizationArray.push(0);
          }

          // Calculate Total
          this.totalAmortizationArray.push(
            this.brandingAmortizationArray[index] +
            this.constructionAmortizationArray[index] +
            this.equipmentAmortizationArray[index]
          )
        }
      })
    );

    this.subscription.add(
      this.investmentAmortizationForm.get("equipmentAmortizationPeriod").valueChanges.subscribe(x => {
        this.equipmentAmortizationArray = [];
        this.totalAmortizationArray = [];
        let yearlyValue = 0;
        let years = (this.investmentAmortizationForm.get("equipmentAmortizationPeriod").value);
        // Get Amount / Divide by years
        yearlyValue = (this.investmentAmortizationForm.get("equipmentAmortizationAmount").value) / (years);

        for (let index = 0; index < 16; index++) {
          if ((index > 0) && (index < (years + 1))) {
            this.equipmentAmortizationArray.push(yearlyValue);
          } else {
            this.equipmentAmortizationArray.push(0);
          }

          // Calculate Total
          this.totalAmortizationArray.push(
            this.brandingAmortizationArray[index] +
            this.constructionAmortizationArray[index] +
            this.equipmentAmortizationArray[index]
          )
        }
      })
    );

    this.subscription.add(
      this.brandingItemList.valueChanges.subscribe(x => {

        this.brandingTotal = 0
        this.brandingItemList.value.forEach(element => {
          this.brandingTotal += element.total;
        });
this.investmentAmortizationForm.get("brandingAmortizationAmount").setValue(this.brandingTotal);
        this.investmentTotal = this.equipmentTotal + this.constructionTotal + this.brandingTotal;
        this.investmentItemForm.get("brandingTotal").setValue(this.brandingTotal);
        this.investmentItemForm.get("total").setValue(this.investmentTotal);
        this.calculateYearlyEscalation(this.investmentTotal);
      }));

    this.subscription.add(
      this.constructionItemList.valueChanges.subscribe(x => {

        this.constructionTotal = 0
        this.constructionItemList.value.forEach(element => {
          this.constructionTotal += element.total;
        });
        this.investmentAmortizationForm.get("constructionAmortizationAmount").setValue(this.constructionTotal);
        this.investmentTotal = this.equipmentTotal + this.constructionTotal + this.brandingTotal;
        this.investmentItemForm.get("constructionTotal").setValue(this.constructionTotal);
        this.investmentItemForm.get("total").setValue(this.investmentTotal);
        this.calculateYearlyEscalation(this.investmentTotal);
      }));

    this.subscription.add(
      this.equipmentItemList.valueChanges.subscribe(x => {

        this.equipmentTotal = 0
        this.equipmentItemList.value.forEach(element => {
          this.equipmentTotal += element.total;
        });
        this.investmentAmortizationForm.get("equipmentAmortizationAmount").setValue(this.equipmentTotal);
        this.investmentTotal = this.equipmentTotal + this.constructionTotal + this.brandingTotal;
        this.investmentItemForm.get("equipmentTotal").setValue(this.equipmentTotal);
        this.investmentItemForm.get("total").setValue(this.investmentTotal);
        this.calculateYearlyEscalation(this.investmentTotal);
      }));

  }

  calculateYearlyEscalation(a: number) {
    this.companyInvestmentArray = [];
    this.companyInvestmentTotalArray = [];
    for (let index = 0; index < 16; index++) {
      if (index === 0) {
        this.companyInvestmentArray.push(a);
      } else {
        this.companyInvestmentArray.push(0);
      }
      this.companyInvestmentTotalArray.push(this.companyInvestmentArray[index]);
    }

  }

  createItem(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      total: [1, Validators.required],
    });
  }

  // add a item form group
  addBrandingItem() {
    this.brandingItemList.push(this.createItem());
  }

  addEquipmentItem() {
    this.equipmentItemList.push(this.createItem());
  }

  addConstructionItem() {
    this.constructionItemList.push(this.createItem());
  }

  removeBrandingItem(index) {
    // this.itemList = this.form.get('items') as FormArray;
    this.brandingItemList.removeAt(index);
  }

  removeEquipmentItem(index) {
    // this.EquipmentItemList = this.form.get('items') as FormArray;
    this.equipmentItemList.removeAt(index);
  }

  removeConstructionItem(index) {
    // this.EquipmentItemList = this.form.get('items') as FormArray;
    this.constructionItemList.removeAt(index);
  }

  ngAfterViewInit(): void {

  }

  /**
   * convenience getter for easy access to form fields
   */
  get brandingItemFormValues() {
    return this.brandingItemForm.controls;
  }

  get equipmentItemFormValues() {
    return this.equipmentItemForm.controls;
  }

  get constructionItemFormValues() {
    return this.constructionItemForm.controls;
  }

  get investmentAmortizationFormValues() {
    return this.investmentAmortizationForm.controls;
  }

  /**
   * On form submit
   */

  saveInvestmentItems(
    brandingForm: any,
    equipmentForm: any,
    constructionForm: any,
    investmentItemForm: any,
    investmentAmortizationForm: any,
  ): void {

    this.loading = true;
    this.subscription.add(
      this.projectService.updateDefaultCompanyInvestment(
        brandingForm,
        equipmentForm,
        constructionForm,
        investmentItemForm,
        investmentAmortizationForm,
        this.brandingAmortizationArray,
        this.constructionAmortizationArray,
        this.equipmentAmortizationArray,
        this.totalAmortizationArray,
        this.companyInvestmentArray,
        this.companyInvestmentTotalArray,
      )
        .pipe(first()).subscribe((result) => {
          this.loading = false;
          window.location.href = '/portal/investment';

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
          }));

  }

  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  loadingAnimationStart() {
    this.loading = true;
  }

  loadingAnimationStop() {
    this.loading = false;
  }

  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}


