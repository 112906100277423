<!-- NAVBAR START -->
<nav class="navbar navbar-expand-md fixed-top py-0 navbar bg-light">
    <div class="container">

        <!-- logo -->
        <a routerLink="/" class="navbar-brand me-lg-5">
            <img src="../../../../../assets/images/logo/logo.svg" alt="" class="logo-dark" height="70" />
        </a>

        <button class="navbar-toggler" type="button" (click)="mobileMenuOpen=!mobileMenuOpen"
            [attr.aria-expanded]="mobileMenuOpen" aria-label="Toggle navigation">
            <i class="mdi mdi-menu"></i>
        </button>

        <!-- menus -->
        <div class="collapse navbar-collapse" #collapse="ngbCollapse" [(ngbCollapse)]="!mobileMenuOpen"
            id="navbarNavDropdown">

            <!-- left menu -->
            <ul class="navbar-nav me-auto align-items-center">
                <li class="nav-item mx-lg-1">
                    <!-- <a class="nav-link" (click)="onOptionClick($event,'/')" role="button" (click)="mobileMenuOpen=!mobileMenuOpen">Home</a> -->
                </li>
                <!-- <li class="nav-item mx-lg-1">
                    <a class="nav-link" (click)="onOptionClick($event,'/portal')" role="button" (click)="mobileMenuOpen=!mobileMenuOpen">Start</a>
                </li>
                <li class="nav-item mx-lg-1">
                    <a class="nav-link" (click)="onOptionClick($event,'/help/faq')" role="button" (click)="mobileMenuOpen=!mobileMenuOpen">FAQ</a>
                </li>
                <li class="nav-item mx-lg-1">
                    <a class="nav-link" (click)="onOptionClick($event,'/contact-us')" role="button" (click)="mobileMenuOpen=!mobileMenuOpen">Contact Us</a>
                </li> -->
            </ul>

            <div class="d-lg-flex text-center pb-2 pb-sm-2 pb-md-0">
                <button class="btn btn-outline-primary" (click)="onOptionClick($event,'/portal')">Start</button>
            </div>

            <!-- right menu -->
        </div>
    </div>
</nav>
<!-- NAVBAR END -->