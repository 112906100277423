import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  today!: number;
  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.today = Date.now();
  }

  navigateTo(type:string) {

    if(type === "support"){
      this.router.navigate([`/portal/support`]);
    }
    if(type === "contact us"){
      this.router.navigate([`/portal/support`]);
    }
    if(type === "about"){
      this.router.navigate([`/portal/user-guide`]);
    }

  }

}
