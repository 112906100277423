<div class="card bg-transparent">
    <div class="card-header">
        <!-- title-->
        <div class="row flex-grow-1">
            <div class="col">
                <div class="pricing-header px-3 py-3 pt-md-3 pb-md-2 mx-auto text-center">
                    <h1 class="display-6">Investment</h1>
                    <p class="lead text-muted">Standardized Petrol Station Investment.</p>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="card">
    <div class="card-body">
        <div class="card">
            <div class="card-header">
                <h5>
                    <span>i. Branding</span>
                </h5>
            </div>
            <div class="card-body">
                <form name="signup-form" [formGroup]="brandingItemForm">
                    <div *ngFor="let category of brandingItemFormGroup.controls; let i = index;" formArrayName="item">
                        <div class="input-group input-group-sm" [formGroupName]='i'>
                            <span class="input-group-text" id="inputGroup-sizing-sm">{{i+1}}</span>
                            <input type="text" class="form-control" placeholder="Name" id="name" aria-label="Sizing example input"
                                formControlName="name" aria-describedby="inputGroup-sizing-sm">
                            <input type="number" min="1" class="form-control" id="cost"
                                aria-label="Sizing example input" formControlName="cost"
                                aria-describedby="inputGroup-sizing-sm">
                            <button class="btn btn-outline-danger" (click)="removeBrandingItem(i)"
                                type="button">Delete</button>
                        </div>
                    </div>
                </form>
                <div class="row ms-1">
                    <div class="col-sm-6"></div>
                    <div class="col-sm-6">
                        <div class="d-grid">
                            <button class="btn btn-sm btn-outline-success mt-2" (click)="addBrandingItem()"
                                type="button"><i class=""></i>Add Branding Item</button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="card-footer">
                <div class="row ms-1">
                    <div class="col-sm-12 text-end">
                        <span class="px-3">
                            Total
                        </span>
                        <span *ngIf="brandingTotal">
                            {{brandingTotal | number:'1.2-2'}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h5>
                    <span>ii. Construction</span>
                </h5>
            </div>
            <div class="card-body">
                <form name="signup-form" [formGroup]="constructionItemForm">
                    <div *ngFor="let category of constructionItemFormGroup.controls; let i = index;"
                        formArrayName="item">
                        <div class="input-group input-group-sm" [formGroupName]='i'>
                            <span class="input-group-text" id="inputGroup-sizing-sm">{{i+1}}</span>
                            <input type="text" class="form-control" placeholder="Name" id="name" aria-label="Sizing example input"
                                formControlName="name" aria-describedby="inputGroup-sizing-sm">
                            <input type="number" min="1" class="form-control" id="cost"
                                aria-label="Sizing example input" formControlName="cost"
                                aria-describedby="inputGroup-sizing-sm">
                            <button class="btn btn-outline-danger" (click)="removeConstructionItem(i)"
                                type="button">Delete</button>
                        </div>
                    </div>
                </form>
                <div class="row ms-1">
                    <div class="col-sm-6"></div>
                    <div class="col-sm-6">
                        <div class="d-grid">
                            <button class="btn btn-sm btn-outline-success mt-2" (click)="addConstructionItem()"
                                type="button"><i class=""></i>Add Construction Item</button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="card-footer">
                <div class="row ms-1">
                    <div class="col-sm-12 text-end">
                        <span class="px-3">
                            Total
                        </span>
                        <span *ngIf="constructionTotal">
                            {{constructionTotal | number:'1.2-2'}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h5>
                    <span>iii. Equipment</span>
                </h5>
            </div>
            <div class="card-body">
                <form name="signup-form" [formGroup]="equipmentItemForm">
                    <div *ngFor="let category of equipmentItemFormGroup.controls; let i = index;" formArrayName="item">
                        <div class="input-group input-group-sm" [formGroupName]='i'>
                            <span class="input-group-text" id="inputGroup-sizing-sm">{{i+1}}</span>
                            <input type="text" class="form-control" placeholder="Name" id="name" aria-label="Sizing example input"
                                formControlName="name" aria-describedby="inputGroup-sizing-sm">
                            <input type="number" min="1" class="form-control" id="cost"
                                aria-label="Sizing example input" formControlName="cost"
                                aria-describedby="inputGroup-sizing-sm">
                            <button class="btn btn-outline-danger" (click)="removeEquipmentItem(i)"
                                type="button">Delete</button>
                        </div>
                    </div>
                </form>
                <div class="row ms-1">
                    <div class="col-sm-6"></div>
                    <div class="col-sm-6">
                        <div class="d-grid">
                            <button class="btn btn-sm btn-outline-success mt-2" (click)="addEquipmentItem()"
                                type="button"><i class=""></i>Add Equipment Item</button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="card-footer">
                <div class="row ms-1">
                    <div class="col-sm-12 text-end">
                        <span class="px-3">
                            Total
                        </span>
                        <span *ngIf="equipmentTotal">
                            {{equipmentTotal | number:'1.2-2'}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <div class="row text-center">
            <div class="col-6 text-end">
                <span>Investment Total :</span>
            </div>
            <div class="col-6 text-start">
                <span *ngIf="investmentTotal">{{investmentTotal | number:'1.2-2'}}</span>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-header mb-0">
        <span>
            Investment Amortization
        </span>
    </div>
    <div class="card-body" name="working-capital-form" [formGroup]="investmentAmortizationForm">
        <table class="table table-sm table-borderless table-centered mb-0">
            <thead>
                <tr>
                    <th class="p-0 w-25">
                    </th>
                    <th class="p-0 w-25">
                    </th>
                    <th class="p-0 w-50">
                    </th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
                <tr>
                    <td class="p-0">
                        <span>Branding</span>
                    </td>
                    <td class="p-0 ps-1">
                        <div class="input-group input-group-sm input-group-merge">
                            <input class="form-control form-control-sm " type="number"
                                id="brandingAmortizationPeriod"
                                formControlName="brandingAmortizationPeriod"
                                [ngClass]="{'is-invalid': (formSubmitted && investmentAmortizationFormValues['brandingAmortizationPeriod'].invalid)||(investmentAmortizationFormValues['brandingAmortizationPeriod'].touched && investmentAmortizationFormValues['brandingAmortizationPeriod'].invalid)}">
                            <div class="input-group-text">
                                <span>Years</span>
                            </div>
                        </div>
                    </td>
                    <td class="p-0 text-end">
                        <span *ngIf="brandingTotal">
                            {{brandingTotal | number : '1.2-2'}}
                        </span>
                        <span *ngIf="brandingTotal === 0">
                            {{0 | number : '1.2-2'}}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td class="p-0">
                        <span>Construction</span>
                    </td>
                    <td class="p-0 ps-1">
                        <div class="input-group input-group-sm input-group-merge">
                            <input class="form-control form-control-sm " type="number"
                                id="constructionAmortizationPeriod" min="1" placeholder="23"
                                formControlName="constructionAmortizationPeriod"
                                [ngClass]="{'is-invalid': (formSubmitted && investmentAmortizationFormValues['constructionAmortizationPeriod'].invalid)||(investmentAmortizationFormValues['constructionAmortizationPeriod'].touched && investmentAmortizationFormValues['constructionAmortizationPeriod'].invalid)}">
                            <div class="input-group-text">
                                <span>Years</span>
                            </div>
                        </div>
                    </td>
                    <td class="p-0 text-end">
                        <span *ngIf="constructionTotal">
                            {{constructionTotal | number : '1.2-2'}}
                        </span>
                        <span *ngIf="equipmentTotal === 0">
                            {{0 | number : '1.2-2'}}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td class="p-0">
                        <span>Equipment</span>
                    </td>
                    <td class="p-0 ps-1">
                        <div class="input-group input-group-sm input-group-merge">
                            <input class="form-control form-control-sm " type="number"
                                id="equipmentAmortizationPeriod" min="1" placeholder="23"
                                formControlName="equipmentAmortizationPeriod"
                                [ngClass]="{'is-invalid': (formSubmitted && investmentAmortizationFormValues['equipmentAmortizationPeriod'].invalid)||(investmentAmortizationFormValues['equipmentAmortizationPeriod'].touched && investmentAmortizationFormValues['equipmentAmortizationPeriod'].invalid)}">
                            <div class="input-group-text">
                                <span>Years</span>
                            </div>
                        </div>
                    </td>
                    <td class="p-0 text-end">
                        <span *ngIf="equipmentTotal">
                            {{equipmentTotal | number : '1.2-2'}}
                        </span>
                        <span *ngIf="equipmentTotal === 0">
                            {{0 | number : '1.2-2'}}
                        </span>
                    </td>
                </tr>
                
            </tbody>

        </table>
    </div>
</div>
<div class="card">
    <div class="card-header">
        <span>
            <p class="py-0 my-0"><strong>Amortization</strong></p>
        </span>
    </div>
    <div class=card-body>
        <div class="board">
            <table class="table table-sm table-bordered table-centered mb-0">
                <thead>
                    <tr>
                        <th style="min-width:50px">

                        </th>
                        <th>
                            Y - 0
                        </th>
                        <th>
                            Y - 1
                        </th>
                        <th>
                            Y - 2
                        </th>
                        <th>
                            Y - 3
                        </th>
                        <th>
                            Y - 4
                        </th>
                        <th>
                            Y - 5
                        </th>
                        <th>
                            Y - 6
                        </th>
                        <th>
                            Y - 7
                        </th>
                        <th>
                            Y - 8
                        </th>
                        <th>
                            Y - 9
                        </th>
                        <th>
                            Y - 10
                        </th>
                        <th>
                            Y - 11
                        </th>
                        <th>
                            Y - 12
                        </th>
                        <th>
                            Y - 13
                        </th>
                        <th>
                            Y - 14
                        </th>
                        <th>
                            Y - 15
                        </th>
                    </tr>
                </thead>
                <tbody class="table-group-divider">
                    <tr>
                        <td class="px-1 py-0 col-auto">
                            <span>
                                Branding Amortization
                            </span>
                        </td>
                        <td class="px-1 py-0"
                            *ngFor="let m of brandingAmortizationArray;let i = index;">
                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                            <span *ngIf="m===0">-</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="px-1 py-0 col-auto">
                            <span>
                                Construction Amortization
                            </span>
                        </td>
                        <td class="px-1 py-0" *ngFor="let m of constructionAmortizationArray;let i = index;">
                            <span *ngIf="!(m===0)">{{m | number: '1.4-4'}}</span>
                            <span *ngIf="m===0">-</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="px-1 py-0 col-auto">
                            <span>
                                Equipment Amortization
                            </span>
                        </td>
                        <td class="px-1 py-0" *ngFor="let m of equipmentAmortizationArray;let i = index;">
                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                            <span *ngIf="m===0">-</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="px-1 py-0 col-auto">
                            <span>
                                Total
                            </span>
                        </td>
                        <td class="px-1 py-0"
                            *ngFor="let m of totalAmortizationArray;let i = index;">
                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                            <span *ngIf="m===0">-</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-header mb-0">
        <h5>
            <span>
                Yearly Escalation
            </span>
        </h5>
    </div>
    <div class="card-body">
        <div class="card-body">
            <div class="board">
                <table class="table table-sm table-bordered table-centered mb-0">
                    <thead>
                        <tr>
                            <th style="min-width:50px">

                            </th>
                            <th>
                                Y - 0
                            </th>
                            <th>
                                Y - 1
                            </th>
                            <th>
                                Y - 2
                            </th>
                            <th>
                                Y - 3
                            </th>
                            <th>
                                Y - 4
                            </th>
                            <th>
                                Y - 5
                            </th>
                            <th>
                                Y - 6
                            </th>
                            <th>
                                Y - 7
                            </th>
                            <th>
                                Y - 8
                            </th>
                            <th>
                                Y - 9
                            </th>
                            <th>
                                Y - 10
                            </th>
                            <th>
                                Y - 11
                            </th>
                            <th>
                                Y - 12
                            </th>
                            <th>
                                Y - 13
                            </th>
                            <th>
                                Y - 14
                            </th>
                            <th>
                                Y - 15
                            </th>
                        </tr>
                    </thead>
                    <tbody class="table-group-divider">
                        <tr>
                            <td class="p-0">
                                <span>Investments</span>
                            </td>
                            <td class="py-0"
                                *ngFor="let m of companyInvestmentArray;let i = index;">
                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0">
                                <span>
                                    <p class="py-0 my-0"><strong>Investments Total</strong></p>
                                </span>
                            </td>
                            <td class="py-0"
                                *ngFor="let m of companyInvestmentTotalArray;let i = index;">
                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-sm-12">
                <div class="d-grid">
                    <button class="btn btn-sm btn-success"
                        (click)="saveInvestmentItems(brandingItemForm.value,equipmentItemForm.value,constructionItemForm.value,investmentItemForm.value,investmentAmortizationForm.value)"
                        type="button"><i class=""></i>Update</button>
                </div>
            </div>
        </div>
    </div>
</div>