import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { NgbAlertModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { FooterComponent } from 'src/app/shared/footer/footer.component';
import { GiftSecondNavbarComponent } from 'src/app/shared/gift-second-navbar/gift-second-navbar.component';
import { NavbarComponent } from 'src/app/shared/navbar/navbar.component';
import { WidgetModule } from 'src/app/shared/widget/widget.module';

interface MoneyHistory {
  title: string;
  amount: number;
  variant: string;
  icon: string;
}

interface CardDropdownOption {
  label: string;
  icon?: string;
  variant?: string;
  hasDivider?: boolean;
}

const MONEYHISTORY: MoneyHistory[] = [
  {
      title: 'Income',
      icon: 'mdi mdi-arrow-up-bold-outline',
      amount: 276548,
      variant: 'primary'
  },
  {
      title: 'Expenses',
      icon: 'mdi mdi-arrow-down-bold-outline',
      amount: 50216,
      variant: 'danger'
  },
  {
      title: 'Transfar',
      icon: 'mdi mdi-swap-horizontal',
      amount: 98100,
      variant: 'success'
  }
];

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WidgetModule,
    NgbAlertModule,
    NavbarComponent,
    FooterComponent,
    RouterLink,
    GiftSecondNavbarComponent,
    NgbProgressbarModule,
    NgbTooltipModule,
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  statWidget: { title: string; value: number; change: string; arrow: string; subtitle: string; icon: string; }[];
  loading: boolean = false;
  dropdownOptions: CardDropdownOption[] = [];
  moneyHistory: MoneyHistory[] = [];
  subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.moneyHistory = MONEYHISTORY;
    this.dropdownOptions = [
      { label: 'Refresh', icon: 'mdi mdi-cached' },
      { label: 'Edit', icon: 'mdi mdi-circle-edit-outline' },
      { label: 'Remove', icon: 'mdi mdi-delete-outline', variant: 'danger' },
    ];

    this.statWidget = [
      {
        "title": "Active Users",
        "value": 121,
        "change": "5.27%",
        "arrow": 'up',
        "subtitle": 'Since last month',
        "icon": 'uil uil-users-alt',
      },
      {
        title: 'Views per minute', 
        value: 560, 
        change: '1.08%', 
        arrow: 'down', 
        subtitle: 'Since previous week',
        icon:'uil uil-window-restore'
      },
      {
        title: 'Views per minute', 
        value: 560, 
        change: '1.08%', 
        arrow: 'down', 
        subtitle: 'Since previous week',
        icon:'uil uil-window-restore'
      }
    ]
  }

  navigateTo(type:string) {

    if(type === "new project"){
      this.router.navigate([`/portal/project/new`]);
      this.toastr.info(`Create a new project!`, "Info!");
    }

    if(type === "project"){
      this.router.navigate([`/portal/project`]);
      this.toastr.info(`View/Edit Project List`, "Info!");
    }

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  

}
