import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database/';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { trace } from '@angular/fire/compat/performance';
import * as firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { AuthenticationService } from './auth.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { WebsiteMessage } from '../models/WebsiteMessage';
import { AppUser } from '../models-two/AppUser';


@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(
    private db: AngularFireDatabase,
    private fns: AngularFireFunctions,
    private storage: AngularFireStorage,
  ) { }

  getUser(userId: string): AngularFireObject<AppUser> {
    return this.db.object('/user/' + userId);
  }

  getUsersArray() {
    return this.db.list('/user').valueChanges();
  }

  getUsersObject() {
    return this.db.object('/user').valueChanges();
  }

  //Change to Backend Function***
  initAuthProfileSave(user: any) {
    console.log("initAuthProfileSave: ");
    let firstName = "";
    let otherName = "";
    let surname = "";
    let email = "";
    let userId = "";
    let password = "";
    let createDate = 0;
    let profilePicture = {};
    let phoneNumber = 0;
    let role = "";
    let lastSignInDate = 0;

    if (user.email) { email = user.email };
    if (user.phoneNumber) { phoneNumber = user.phoneNumber };
    if (user.uid) { userId = user.uid };
    if (user.firstName) { firstName = user.firstName };
    if (user.otherName) { otherName = user.otherName };
    if (user.surname) { surname = user.surname };
    if (user.password) { password = user.password };
    if (user.lastSignInDate) { lastSignInDate = user.lastSignInDate };
    if (user.createDate) { createDate = user.createDate };
    if (user.profilePicture) { profilePicture = user.profilePicture };
    if (user.role) { role = user.role };

    this.db.object('/user/' + userId + '/0').update({
      userId: userId,
      firstName: firstName,
      otherName: otherName,
      surname: surname,
      email: email,
      password: password,
      phoneNumber: phoneNumber,
      createDate: createDate,
      lastSignInDate: lastSignInDate,
      role: role,
    });

    return this.db.object(`/user/${userId}/0/profilePicture/0`).set(profilePicture);
    //Add role code - backend
  }

  updateUser(userId: string, userForm: any) {
    const callable = this.fns.httpsCallable('updateUser');
    return callable({
      id: userId,
      a: userForm,
    });
  }

  updateRole(index: number, roleForm: any) {
    const callable = this.fns.httpsCallable('updateRole');
    return callable({
      id: index,
      a: roleForm,
    });
  }

  updateUserRole(load: any, userId: string) {
    const callable = this.fns.httpsCallable('updateUserRole');
    return callable({
      load: load,
      userId: userId,
    });
  }

  uploadProfileImage(event, path: string): Observable<any> {
    let downloadURL = "";
    let uploadPercent = 0;
    const file = event.target["files"][0];
    const filePath = path;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    return Observable.create(observer => {
      task.percentageChanges().subscribe((result) => {
        uploadPercent = result;
        observer.next(
          {
            uploadProgress$: result,
            downloadUrl$: downloadURL,
          }
        );
      })

      // get notified when the download URL is available
      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((result) => {
            downloadURL = result;
            observer.next(
              {
                uploadProgress$: uploadPercent,
                downloadUrl$: result,
              }
            );
            observer.complete();
          })
        })
      )
        .subscribe()
    })

  }

  sendMessage(messageLoad: WebsiteMessage) {
    const callable = this.fns.httpsCallable('websiteMessage');
    return callable({
      type: 'question-id',
      name: messageLoad.name,
      subject: messageLoad.subject,
      message: messageLoad.message,
      email: messageLoad.email,
      phoneNumber: messageLoad.phoneNumber
    });
  }

  //Correct method 
  submitLandingPageMessage(load: any) {
    try {
      return this.db.object(`/messages/anonymous`).update(load).then((result) => {
        return true;
      });
    } catch (err) {
      return err;
    }
  }

  deleteUser(userId: string) {
    const callable = this.fns.httpsCallable('deleteUser');
    return callable({
      id: userId,
    });
  }
}
