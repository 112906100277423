<section class="py-5 bg-light-lighten border-top border-bottom border-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center">
                    <h3>Get In <span class="text-primary">Touch</span></h3>
                    <p class="text-dark mt-2">Please fill out the following form and we will get back to you
                        shortly.
                        For more
                        <br>information please contact us.
                    </p>
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-3" *ngIf="companyDetails">
            <div class="col-md-3">
                <p class="text-muted"><span class="fw-bold">Customer Support:</span><br>
                    <span class="d-block text-dark mt-1">+254
                        <span *ngIf="companyDetails.phoneNumber">{{companyDetails.phoneNumber}}</span>
                    </span>
                </p>
                <p class="text-muted mt-4"><span class="fw-bold">Email Address:</span><br>
                    <span class="d-block text-dark mt-1">
                        <span *ngIf="companyDetails.email">{{companyDetails.email}}</span>
                    </span>
                </p>
                <p class="text-muted mt-4"><span class="fw-bold">Office Address:</span><br>
                    <span class="d-block text-dark mt-1">
                            <span *ngIf="companyDetails.apartmentHouseNumber">Office No.
                                {{companyDetails.apartmentHouseNumber}},</span>
                            <span *ngIf="companyDetails.floor"> {{companyDetails.floor}}
                                Floor,</span>
                            <span *ngIf="companyDetails.building"><br>
                                {{companyDetails.building}},</span>
                            <span *ngIf="companyDetails.street"> {{companyDetails.street}}</span>
                            <span *ngIf="companyDetails.cityTown"><br>
                                {{companyDetails.cityTown}},</span>
                            <span *ngIf="companyDetails.country"> {{companyDetails.country}}.</span>
                    </span>
                </p>
                <p class="text-muted mt-4">
                    <span class="fw-bold">Office Time:</span><br>
                    <span class="d-block text-dark mt-1">Mon - Fri:
                        <span
                            *ngIf="companyDetails.openTimeMondayToFriday">{{companyDetails.openTimeMondayToFriday}}</span>
                    </span>
                    <span class="d-block text-dark mt-1">Sat:
                        <span *ngIf="companyDetails.openTimeSaturday">{{companyDetails.openTimeSaturday}}</span>
                    </span>
                    <span class="d-block text-dark mt-1">Sun:
                        <span *ngIf="companyDetails.openTimeSunday">{{companyDetails.openTimeSunday}}</span>
                    </span>
                </p>
            </div>

            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <h4 class="header-title mb-3">Our Location</h4>
                        <google-map #myGoogleMap height="300px" width="100%" (mapClick)="moveMap($event)"
                            (mapMousemove)="move($event)" [zoom]="zoom" [center]="center" [options]="options">
                            <map-marker *ngIf="markerPosition" [position]="markerPosition"
                                [options]="markerOptions"></map-marker>
                            <map-info-window>
                                <span>Delta Energies</span>
                            </map-info-window>
                        </google-map>
                    </div> <!-- end card-body-->
                </div> <!-- end card-->
            </div>

            <div class="col-md-5">
                <form [formGroup]="contactForm" (ngSubmit)="submitted=true">
                    <div class="row mt-4">
                        <div class="col-lg-6">
                            <div class="mb-2">
                                <label for="firstName" class="form-label">Name<span class="text-danger">
                                        *</span></label>
                                <input class="form-control" type="text" id="firstName" placeholder="John"
                                    required formControlName="firstName" (paste)="false"
                                    [ngClass]="{'is-invalid': (formSubmitted && contactForm.controls['firstName'].invalid)||(contactForm.controls['firstName'].touched && contactForm.controls['firstName'].invalid)}">
                                <div *ngIf="(formSubmitted && contactForm.controls['firstName'].invalid)||(contactForm.controls['firstName'].touched && contactForm.controls['firstName'].invalid)"
                                    class="invalid-tooltip">
                                    <div *ngIf="contactForm.controls['firstName'].errors?.['required']">
                                        First name is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-2">
                                <label for="surname" class="form-label">Surname<span class="text-danger">
                                        *</span></label>
                                <input class="form-control" type="text" id="surname" placeholder="Smith"
                                    required formControlName="surname" (paste)="false"
                                    [ngClass]="{'is-invalid': (formSubmitted && contactForm.controls['surname'].invalid)||(contactForm.controls['surname'].touched && contactForm.controls['surname'].invalid)}">
                                <div *ngIf="(formSubmitted && contactForm.controls['surname'].invalid)||(contactForm.controls['surname'].touched && contactForm.controls['surname'].invalid)"
                                    class="invalid-tooltip">
                                    <div *ngIf="contactForm.controls['surname'].errors?.['required']">
                                        Surname is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-2">
                                <label class="form-label">Phone Number</label>
                                <div class="input-group flex-nowrap">
                                    <span class="input-group-text" id="basic-addon1">+254</span>
                                    <input type="text" class="form-control" placeholder="722 000 000"
                                        formControlName="phoneNumber" (paste)="false" aria-label="Username"
                                        aria-describedby="basic-addon1" required
                                        [ngClass]="{'is-invalid': invalidPhoneNumber}">
                                    <div *ngIf="invalidPhoneNumber" class="invalid-tooltip">
                                        <span>
                                            Write a valid Phone Number.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-2">
                                <label for="email" class="form-label">Email address<span class="text-danger">
                                        *</span></label>
                                <input class="form-control" type="email" id="email" required
                                    placeholder="Enter your email" formControlName="email" (paste)="false"
                                    [ngClass]="{'is-invalid': (formSubmitted && contactForm.controls['email'].invalid)||(contactForm.controls['email'].touched && contactForm.controls['email'].invalid)}">
                                <div *ngIf="(formSubmitted && contactForm.controls['email'].invalid)||(contactForm.controls['email'].touched && contactForm.controls['email'].invalid)"
                                    class="invalid-tooltip">
                                    <div *ngIf="contactForm.controls['email'].errors?.['required']">
                                        Email is required.
                                    </div>
                                    <div *ngIf="contactForm.controls['email'].errors?.['email']">
                                        Email must be a valid Email Address
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-lg-12">
                            <div class="mb-2">
                                <label for="subject" class="form-label">Subject<span class="text-danger">
                                        *</span></label>
                                <input class="form-control" type="text" id="subject" placeholder="Enter your first name"
                                    required formControlName="subject" (paste)="false"
                                    [ngClass]="{'is-invalid': (formSubmitted && contactForm.controls['subject'].invalid)||(contactForm.controls['subject'].touched && contactForm.controls['subject'].invalid)}">
                                <div *ngIf="(formSubmitted && contactForm.controls['subject'].invalid)||(contactForm.controls['subject'].touched && contactForm.controls['subject'].invalid)"
                                    class="invalid-tooltip">
                                    <div *ngIf="contactForm.controls['subject'].errors?.['required']">
                                        First Name is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-1">
                        <div class="col-lg-12">
                            <div class="mb-2">
                                <label for="message" class="form-label">Message<span class="text-danger">
                                        *</span></label>
                                <textarea class="form-control" type="text" id="message" cols="3"
                                    placeholder="Enter your message" required formControlName="message" (paste)="false"
                                    [ngClass]="{'is-invalid': (formSubmitted && contactForm.controls['message'].invalid)||(contactForm.controls['message'].touched && contactForm.controls['message'].invalid)}">
                                        </textarea>
                                <div *ngIf="(formSubmitted && contactForm.controls['message'].invalid)||(contactForm.controls['message'].touched && contactForm.controls['message'].invalid)"
                                    class="invalid-tooltip">
                                    <div *ngIf="contactForm.controls['message'].errors?.['required']">
                                        Message is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-12 text-end">
                            <!-- <re-captcha [(ngModel)]="declarativeFormCaptchaValue" name="captcha" required (resolved)="addTokenLog('Declarative form mode resolved', $event)"></re-captcha> -->
                            <button (click)="sendMessage(contactForm.value)" class="btn btn-primary">Send a Message
                            </button>
                        </div>
                    </div>
                </form>
                <!-- <div class="row" style="height: 20px;">
                        <ngb-alert #selfClosingAlert [type]="alert_type" *ngIf="show_alert" [dismissible]="false" class="mt-3">
                            {{alert_message}}
                        </ngb-alert>
                    </div> -->
            </div>
        </div>
    </div>
</section>