<div class="card bg-transparent">
    <app-ui-preloader [display]="loading"></app-ui-preloader>
    <div class="card">
        <div class="card-header">
            <div class="row flex-grow-1">
                <div class="col">
                    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                        <h1 class="display-4">Role</h1>
                        <p class="lead">List of roles available.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Logo -->
        <div class="card-body advancedList" id="advancedList">
            <div class="row mb-2">
                <div class="col-sm-5">
                    <a (click)="createNewRole()" class="btn btn-primary mb-2 me-1"><i
                            class="mdi mdi-plus-circle me-2"></i>
                        New Role</a>
                </div>
                <div class="col-sm-7">
                    <div class="text-sm-end">
                    </div>
                </div><!-- end col-->
            </div>
            <!-- end row -->
            <!-- <app-ui-preloader [display]="loading"></app-ui-preloader> -->

            <!-- user table -->
            <app-advanced-table #advancedTable (handleTableLoad)="handleTableLoad()" [tableData]="roleArray"
                [columns]="columns" [pagination]="true" [isSearchable]="true" [pageSizeOptions]="pageSizeOptions"
                (search)="searchData($event)" [hasItemNumbering]="true" [isSortable]="true" (sort)="sort($event)"
                tableClasses="table-centered nowrap w-100" theadClasses="table-light">
            </app-advanced-table>
        </div> <!-- end card-body-->
    </div>

    <div class="card" *ngIf="showForm">
        <div class="card-body roleFormEdit" id="roleFormEdit">

            <form name="signup-form" [formGroup]="roleForm">

                <h5 class="mb-3">Personal Details</h5>

                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="id" class="form-label">ID<span class="text-danger">
                                *</span></label>
                        <input class="form-control" type="text" id="id"
                            required formControlName="id" readonly
                            [ngClass]="{'is-invalid': (formSubmitted && formValues['id'].invalid)||(formValues['id'].touched && formValues['id'].invalid)}">
                        <div *ngIf="(formSubmitted && formValues['id'].invalid)||(formValues['id'].touched && formValues['id'].invalid)"
                            class="invalid-tooltip">
                            <div *ngIf="formValues['id'].errors?.['required']">
                                ID is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="name" class="form-label">Name<span class="text-danger">
                                *</span></label>
                        <input class="form-control" type="text" id="name" placeholder="Editor"
                            required formControlName="name"
                            [ngClass]="{'is-invalid': (formSubmitted && formValues['name'].invalid)||(formValues['name'].touched && formValues['name'].invalid)}">
                        <div *ngIf="(formSubmitted && formValues['name'].invalid)||(formValues['name'].touched && formValues['name'].invalid)"
                            class="invalid-tooltip">
                            <div *ngIf="formValues['name'].errors?.['required']">
                                Name is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="abbreviation" class="form-label">Abbreviation<span class="text-danger">
                                *</span></label>
                        <input class="form-control" type="text" id="abbreviation" placeholder="CEO"
                            required formControlName="abbreviation"
                            [ngClass]="{'is-invalid': (formSubmitted && formValues['abbreviation'].invalid)||(formValues['abbreviation'].touched && formValues['abbreviation'].invalid)}">
                        <div *ngIf="(formSubmitted && formValues['abbreviation'].invalid)||(formValues['abbreviation'].touched && formValues['abbreviation'].invalid)"
                            class="invalid-tooltip">
                            <div *ngIf="formValues['abbreviation'].errors?.['required']">
                                Abbreviation is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="rank" class="form-label">Rank<span class="text-danger">
                                *</span></label>
                        <input class="form-control" type="text" id="rank" placeholder="Enter the rank"
                            required formControlName="rank"
                            [ngClass]="{'is-invalid': (formSubmitted && formValues['rank'].invalid)||(formValues['rank'].touched && formValues['rank'].invalid)}">
                        <div *ngIf="(formSubmitted && formValues['rank'].invalid)||(formValues['rank'].touched && formValues['rank'].invalid)"
                            class="invalid-tooltip">
                            <div *ngIf="formValues['rank'].errors?.['required']">
                                Rank is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="privileges" class="form-label">Privileges<span class="text-danger">
                                *</span></label>
                        <input class="form-control" type="text" id="privileges" placeholder="Assign privileges"
                            required formControlName="privileges"
                            [ngClass]="{'is-invalid': (formSubmitted && formValues['privileges'].invalid)||(formValues['privileges'].touched && formValues['privileges'].invalid)}">
                        <div *ngIf="(formSubmitted && formValues['privileges'].invalid)||(formValues['privileges'].touched && formValues['privileges'].invalid)"
                            class="invalid-tooltip">
                            <div *ngIf="formValues['privileges'].errors?.['required']">
                                Privileges is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="date" class="form-label">Date Created<span class="text-danger">
                                *</span></label>
                        <input class="form-control" type="text" id="date"
                            required formControlName="date" readonly
                            [ngClass]="{'is-invalid': (formSubmitted && formValues['date'].invalid)||(formValues['date'].touched && formValues['date'].invalid)}">
                        <div *ngIf="(formSubmitted && formValues['date'].invalid)||(formValues['date'].touched && formValues['date'].invalid)"
                            class="invalid-tooltip">
                            <div *ngIf="formValues['date'].errors?.['required']">
                                Date is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="description" class="form-label">Description<span class="text-danger">
                                *</span></label>
                        <textarea class="form-control" type="text" id="description" placeholder="Enter the description."
                            required formControlName="description" cols="6"
                            [ngClass]="{'is-invalid': (formSubmitted && formValues['description'].invalid)||(formValues['description'].touched && formValues['description'].invalid)}"></textarea>
                        <div *ngIf="(formSubmitted && formValues['description'].invalid)||(formValues['description'].touched && formValues['description'].invalid)"
                            class="invalid-tooltip">
                            <div *ngIf="formValues['description'].errors?.['required']">
                                Description is required.
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row mt-2">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="d-grid">
                            <button class="btn btn-secondary" (click)="cancelEdit()" type="submit"
                                *ngIf="!updateInProgress"><i class=""></i>Cancel Edit
                            </button>
                            <button class="btn btn-primary" type="button" disabled *ngIf="updateInProgress">
                                <span class="spinner-border spinner-border-sm me-1" role="status"
                                    aria-hidden="true"></span>
                                Loading...
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="d-grid">
                            <button class="btn btn-success" (click)="onSubmit(roleForm.value)" type="submit"
                                *ngIf="!updateInProgress"><i class=""></i>Update
                            </button>
                            <button class="btn btn-primary" type="button" disabled *ngIf="updateInProgress">
                                <span class="spinner-border spinner-border-sm me-1" role="status"
                                    aria-hidden="true"></span>
                                Loading...
                            </button>
                        </div>
                    </div>
                </div>

            </form>

        </div>
    </div>
</div>