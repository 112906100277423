import { Route } from "@angular/router";
import { MenuItem } from "../layouts/shared/models/menu.model";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SupportTicketComponent } from "./support-ticket/support-ticket.component";

export const SUPPORT_MENU: MenuItem[] = [
    { key: 'ds-help', label: 'Help Navigation', isTitle: true },
    { key: 'ds-dashboard', label: 'Dashboard', isTitle: false, icon: 'uil-home-alt', link: '/support/dashboard' },
    { key: 'ds-support-ticket', label: 'Support Ticket', isTitle: false, icon: 'uil-home-alt', link: '/support/support-ticket' },
  ]

export const SUPPORT_ROUTES: Route[] = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full', },
    {
        path: 'dashboard',
        /* canActivateChild: [AuthGuard, RegGuard], */
        children: [
            { path: '', component: DashboardComponent, },
        ]
    },
    {
        path: 'support-ticket',
        /* canActivateChild: [AuthGuard, RegGuard], */
        children: [
            { path: '', component: SupportTicketComponent, },
        ]
    },
];