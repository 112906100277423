<div class="card bg-transparent">
    <app-ui-preloader [display]="loading"></app-ui-preloader>
    <div class="card-header">
        <!-- title-->
        <div class="row flex-grow-1">
            <div class="col">
                <div class="pricing-header px-3 py-3 pt-md-3 pb-md-2 mx-auto text-center">
                    <h1 class="display-6">Operating Expenses</h1>
                    <p class="lead text-muted">Standardized Petrol Station Operating Expenses.</p>
                </div>
            </div>
        </div>

    </div>
    <div class="card-body">
        <div class="card">
            <div class="card-body">
                <div class="card">
                    <div class="card-header">
                        <h5>
                            <span>i. Salaries And Bonus</span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <form name="signup-form" [formGroup]="salariesAndBonusItemForm">
                            <div *ngFor="let category of salariesAndBonusItemFormGroup.controls; let i = index;"
                                formArrayName="item">
                                <div class="input-group input-group-sm" [formGroupName]='i'>
                                    <span class="input-group-text" id="inputGroup-sizing-sm">{{i+1}}</span>
                                    <input type="text" class="form-control" placeholder="Name" id="name"
                                        aria-label="Sizing example input" formControlName="name"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <input type="number" min="1" class="form-control" id="cost"
                                        aria-label="Sizing example input" formControlName="cost"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <button class="btn btn-outline-danger" (click)="removeSalariesAndBonusItem(i)"
                                        type="button">Delete</button>
                                </div>
                            </div>
                        </form>
                        <div class="row ms-1">
                            <div class="col-sm-6"></div>
                            <div class="col-sm-6">
                                <div class="d-grid">
                                    <button class="btn btn-sm btn-outline-success mt-2"
                                        (click)="addSalariesAndBonusItem()" type="button"><i class=""></i>Add Salaries
                                        And Bonus Item</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="card-footer">
                        <div class="row ms-1">
                            <div class="col-sm-12 text-end">
                                <span class="px-3">
                                    Total
                                </span>
                                <span *ngIf="salariesAndBonusTotal">
                                    {{salariesAndBonusTotal | number:'1.2-2'}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h5>
                            <span>ii. Employee Contribution</span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <form name="signup-form" [formGroup]="employeeContributionItemForm">
                            <div *ngFor="let category of employeeContributionItemFormGroup.controls; let i = index;"
                                formArrayName="item">
                                <div class="input-group input-group-sm" [formGroupName]='i'>
                                    <span class="input-group-text" id="inputGroup-sizing-sm">{{i+1}}</span>
                                    <input type="text" class="form-control" placeholder="Name" id="name"
                                        aria-label="Sizing example input" formControlName="name"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <input type="number" min="1" class="form-control" id="cost"
                                        aria-label="Sizing example input" formControlName="cost"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <button class="btn btn-outline-danger" (click)="removeEmployeeContributionItem(i)"
                                        type="button">Delete</button>
                                </div>
                            </div>
                        </form>
                        <div class="row ms-1">
                            <div class="col-sm-6"></div>
                            <div class="col-sm-6">
                                <div class="d-grid">
                                    <button class="btn btn-sm btn-outline-success mt-2"
                                        (click)="addEmployeeContributionItem()" type="button"><i class=""></i>Add
                                        Employee Contribution Item</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="card-footer">
                        <div class="row ms-1">
                            <div class="col-sm-12 text-end">
                                <span class="px-3">
                                    Total
                                </span>
                                <span *ngIf="employeeContributionTotal">
                                    {{employeeContributionTotal | number:'1.2-2'}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h5>
                            <span>iii. Electricity</span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <form name="signup-form" [formGroup]="electricityItemForm">
                            <div *ngFor="let category of electricityItemFormGroup.controls; let i = index;"
                                formArrayName="item">
                                <div class="input-group input-group-sm" [formGroupName]='i'>
                                    <span class="input-group-text" id="inputGroup-sizing-sm">{{i+1}}</span>
                                    <input type="text" class="form-control" placeholder="Name" id="name"
                                        aria-label="Sizing example input" formControlName="name"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <input type="number" min="1" class="form-control" id="cost"
                                        aria-label="Sizing example input" formControlName="cost"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <button class="btn btn-outline-danger" (click)="removeElectricityItem(i)"
                                        type="button">Delete</button>
                                </div>
                            </div>
                        </form>
                        <div class="row ms-1">
                            <div class="col-sm-6"></div>
                            <div class="col-sm-6">
                                <div class="d-grid">
                                    <button class="btn btn-sm btn-outline-success mt-2" (click)="addElectricityItem()"
                                        type="button"><i class=""></i>Add Electricity Item</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="card-footer">
                        <div class="row ms-1">
                            <div class="col-sm-12 text-end">
                                <span class="px-3">
                                    Total
                                </span>
                                <span *ngIf="electricityTotal">
                                    {{electricityTotal | number:'1.2-2'}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h5>
                            <span>iv. Miscellaneous</span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <form name="signup-form" [formGroup]="miscellaneousItemForm">
                            <div *ngFor="let category of miscellaneousItemFormGroup.controls; let i = index;"
                                formArrayName="item">
                                <div class="input-group input-group-sm" [formGroupName]='i'>
                                    <span class="input-group-text" id="inputGroup-sizing-sm">{{i+1}}</span>
                                    <input type="text" class="form-control" placeholder="Name" id="name"
                                        aria-label="Sizing example input" formControlName="name"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <input type="number" min="1" class="form-control" id="cost"
                                        aria-label="Sizing example input" formControlName="cost"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <button class="btn btn-outline-danger" (click)="removeMiscellaneousItem(i)"
                                        type="button">Delete</button>
                                </div>
                            </div>
                        </form>
                        <div class="row ms-1">
                            <div class="col-sm-6"></div>
                            <div class="col-sm-6">
                                <div class="d-grid">
                                    <button class="btn btn-sm btn-outline-success mt-2" (click)="addMiscellaneousItem()"
                                        type="button"><i class=""></i>Add Miscellaneous Item</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="card-footer">
                        <div class="row ms-1">
                            <div class="col-sm-12 text-end">
                                <span class="px-3">
                                    Total
                                </span>
                                <span *ngIf="miscellaneousTotal">
                                    {{miscellaneousTotal | number:'1.2-2'}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h5>
                            <span>v. Product Loss</span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <form name="signup-form" [formGroup]="productLossItemForm">
                            <div *ngFor="let category of productLossItemFormGroup.controls; let i = index;"
                                formArrayName="item">
                                <div class="input-group input-group-sm" [formGroupName]='i'>
                                    <span class="input-group-text" id="inputGroup-sizing-sm">{{i+1}}</span>
                                    <input type="text" class="form-control" placeholder="Name" id="name"
                                        aria-label="Sizing example input" formControlName="name"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <input type="number" min="1" class="form-control" id="cost"
                                        aria-label="Sizing example input" formControlName="cost"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <button class="btn btn-outline-danger" (click)="removeProductLossItem(i)"
                                        type="button">Delete</button>
                                </div>
                            </div>
                        </form>
                        <div class="row ms-1">
                            <div class="col-sm-6"></div>
                            <div class="col-sm-6">
                                <div class="d-grid">
                                    <button class="btn btn-sm btn-outline-success mt-2" (click)="addProductLossItem()"
                                        type="button"><i class=""></i>Add ProductLoss Item</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row ms-1">
                            <div class="col-sm-12 text-end">
                                <span class="px-3">
                                    Total
                                </span>
                                <span *ngIf="productLossTotal">
                                    {{productLossTotal | number:'1.2-2'}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer text-center">
                <h2 class="dispaly-4">
                    Operating Expenses Total : <span *ngIf="operatingExpenseTotal">{{operatingExpenseTotal |
                        number:'1.2-2'}}</span>
                </h2>
            </div>
            <div class="card-footer">
                <div class="row ms-1">
                    <div class="col-sm-12">
                        <div class="d-grid">
                            <button class="btn btn-sm btn-success mt-2" (click)="saveOperatingExpensesItems(
                                    salariesAndBonusItemForm.value,
                                    employeeContributionItemForm.value,
                                    electricityItemForm.value,
                                    miscellaneousItemForm.value,
                                    productLossItemForm.value,
                                    operatingExpenseItemForm.value
                                    )" type="button"><i class=""></i>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>