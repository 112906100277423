import { Component, ElementRef, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { NgbAlertModule, NgbProgressbarModule, NgbButtonsModule, NgbAlert, NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AdvancedTableModule } from 'src/app/shared/advanced-table/advanced-table.module';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { Subscription, first } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { ClockService } from 'src/app/core/service/clock.service';
import { UserService } from 'src/app/core/service/user.service';
import { Column, AdvancedTableComponent } from 'src/app/shared/advanced-table/advanced-table.component';
import { SortEvent } from 'src/app/shared/advanced-table/sortable.directive';
import { BreadcrumbItem } from 'src/app/shared/page-title/page-title.model';
import { AppUser } from 'src/app/core/models-two/AppUser';
import { FileStorageService } from 'src/app/core/service/file-storage.service';
import { ProjectService } from 'src/app/core/service/project.service';
import { CompanyRole } from 'src/app/core/models-two/CompanyRole';

@Component({
  selector: 'app-user',
  standalone: true,
  imports: [
    CommonModule,
    AdvancedTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbProgressbarModule,
    WidgetModule,
    NgbButtonsModule,
    NgbTooltipModule,
  ],
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {

  profileForm!: FormGroup;
  subscription: Subscription = new Subscription();
  today: number = Date.now();
  pageTitle: BreadcrumbItem[] = [];
  //userArray!: Client[];
  pageSizeOptions: number[] = [5, 10, 20, 50];
  pageSize: number = 5;
  selectAll: boolean = false;
  loading: boolean = false;
  columns: Column[] = [];
  @ViewChild('profileModal', { static: false }) private profileModal: any;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  @ViewChild('advancedTable') advancedTable!: AdvancedTableComponent;

  projectForm!: FormGroup;
  formSubmitted: boolean = false;

  show_alert: boolean;
  alert_message: string;
  alert_type: string;

  loggedInUser: AppUser;
  userObject: AppUser;
  userArray: AppUser[];
  userId: any;

  destinationLatitude: number;
  destinationLongitude: number;
  destinationZoom: number;
  googleLocationAddressLong: string;
  googleLocationAddressShort: any;
  profilePicture: any;
  invalidPhoneNumber: boolean;
  pickedImage: any;

  error: string;
  showForm: boolean = false;
  userCategoryViewChoice: number = 1;
  dateNow2: any;
  dateNow3: Date;
  newInviteId: any;
  defaultUserArray: any[] = [];
  defaultUserObject: any;
  importedUserArray: AppUser[] = [];
  showImportPanel: boolean = false;
  disableDownloadButton: boolean = false;
  alphabeticalUserArray: AppUser[] = [];
  workingUserArray: AppUser[] = [];
  updateInProgress: boolean = false;
  showPassword: boolean = false;
  pickedUserId: string;
  uploadProgress$: any;
  roleArray: CompanyRole[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private scroller: ViewportScroller,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private fileStorage: FileStorageService,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private ngZone: NgZone,
    private clockService: ClockService,
  ) {
    this.loadingAnimationFourSeconds();
    this.subscription.add(
      authenticationService.currentUser.pipe().subscribe((res: any) => {
        if (res) {
          if (res[0]) {
            this.loggedInUser = res[0];
            if (res[0].userId) {
              this.userId = res[0].userId;
            }
          } else {
          }
        }
      })
    );

    // Read Role Array
    // roleArray
    this.subscription.add(
      projectService.getRoleArray().pipe().subscribe((res: CompanyRole | any) => {
        this.roleArray = res;
      }));

  }

  ngOnInit(): void {

    this.profileForm = this.fb.group({
      userId: ['', Validators.required],
      firstName: ['', Validators.required],
      otherName: ['', Validators.required],
      surname: [''],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
      role: [''],
      profilePicture: [''],
    });

    this.subscription.add(
      this.profileForm.get("phoneNumber").valueChanges.subscribe(x => {
        if (x) {
          //Check if phoneNumber is 9 digits
          if ((Number(x) > 100000000) && (Number(x) < 999999999)) {
            // If so isValid
            this.invalidPhoneNumber = false;
          } else {
            // If not isInvalid
            this.invalidPhoneNumber = true;
          }
        }

      })
    );

    // get user data
    this.getUserList();
    // initialize advance table 
    this.initAdvancedTableData();

    this.projectForm = this.fb.group({
      id: [''],
      index: [''],
      name: [''],
      currencyName: [''],
      currencyMagnitudeDealer: [''],
      currencyMagnitudeCompany: [''],
      unitsOfMeasure: [''],
      localCurrencyName: [''],
      localCurrencyISOCode: [''],
      corporateTaxRate: [''],
      taxCarryOverPeriod: [''],
      discountRate: [''],
      fxRate: [''],
      taxFuelTurnover: [''],
      product: [''],
      inflation: [''],

      created: [''],
    });

  }

  ngAfterViewInit(): void {

  }


  /**
   * convenience getter for easy access to form fields
   */
  get formValues() {
    return this.profileForm.controls;
  }


  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  loadingAnimationStart() {
    this.loading = true;
  }

  loadingAnimationStop() {
    this.loading = false;
  }

  getUserList() {
    //this.petroleumProductArray = users;
    this.subscription.add(
      this.userService.getUsersArray().pipe().subscribe((userList: AppUser[]) => {
        if (userList) {
          this.workingUserArray = [];
          this.defaultUserArray = this.userArray = userList;

          // Arranged Alphabetically
          userList.forEach(element => {
            this.workingUserArray.push(element[0]);
          });

          this.alphabeticalUserArray = this.workingUserArray.sort((a: any, b: any) => (a.firstName).localeCompare(b.firstName));

        }
      })
    );

    this.subscription.add(
      this.userService.getUsersObject().pipe().subscribe((userList: AppUser) => {
        if (userList) {
          this.defaultUserObject = this.userObject = userList;
        }
      })
    );
  }

  /**
   * initialize advance table columns
   */
  initAdvancedTableData(): void {
    this.columns = [
      {
        name: 'profile picture',
        label: 'Profile Picture',
        formatter: this.profilePictureFormatter.bind(this),
        sort: false
      },
      {
        name: 'firstName',
        label: 'Name',
        formatter: (p: AppUser[]) => (`${p[0].firstName} ${p[0].surname} ${p[0].otherName}`),
      },
      {
        name: 'createDate',
        label: 'Date Created',
        formatter: (p: AppUser[]) => {
          if (p[0].createDate) {
            return (new Date(p[0].createDate)).toLocaleString();
          } else {

          }
        },
      },
      {
        name: 'role',
        label: 'Role',
        formatter: (p: AppUser[]) => (`${p[0].role}`),
      },
      {
        name: 'quick link',
        label: 'Quick Links',
        formatter: this.userActionFormatter.bind(this),
        sort: false
      }
    ]
  }

  /**
   * handles operations that need to be performed after loading table
   */
  handleTableLoad(): void {
    // user cell
    document.querySelectorAll('.edit').forEach((e) => {
      e.addEventListener("click", () => {
        //Navigate to report page

        // Load edit Form
        // Show Edit Form
        this.loadEditForm(e.id);

      });
    })

    document.querySelectorAll('.delete').forEach((e) => {
      e.addEventListener("click", () => {
        if (confirm("Are you sure you want to delete this project?")) {
          // Move item to petroleumProduct/delete
          this.deleteUser(e.id);
        }
      });
    })
  }


  deleteUser(id: string) {

    let projectName = "";
    if (this.defaultUserObject) { if (this.defaultUserObject[id]) { if (this.defaultUserObject[id][0]) { if (this.defaultUserObject[id][0].name) { projectName = this.defaultUserObject[id][0].name; }; }; }; };

    this.subscription.add(
      this.userService.deleteUser(id)
        .pipe(first()).subscribe((result) => {

          //Get User Data
          this.show_alert = true;
          this.alert_message = `${projectName} Deleted!`;
          this.alert_type = "success";

          this.toastr.success(`${projectName} Deleted!`, "Info!");

          setTimeout(() => {
            this.show_alert = false;
            this.selfClosingAlert.close();
          }, 3000);

        },
          (error: string) => {
            this.toastr.error(`${projectName} was not Deleted, error: ${error}!`, "Info!");
            this.error = error;
            this.loading = false;
          }));
  }


  // table user formatter
  userNameFormatter(a: AppUser): SafeHtml {
    let userCell: string = "";
    userCell += `<p class='m-0 d-inline-block align-middle font-16'>`
    if (a[0]) {
      if (a[0].name) {
        userCell += `${a[0].name} `
      }
      if (a[0].petroleumProductCategory_name) {
        userCell += `${a[0].petroleumProductCategory_name} `
      }
    }
    userCell = userCell + `</p>`
    return this.sanitizer.bypassSecurityTrustHtml(userCell);
  }

  codeFormatter(a: AppUser): SafeHtml {
    let userCell: string = "";
    //Category for each
    if (a[0]) {
      if (a[0].id) {
        userCell = `<p class='m-0 d-inline-block align-middle'>${a[0].id}</p>`
      } else {
        userCell = `<p class='m-0 d-inline-block align-middle text-danger">Missing</p>`
      };
    } else {
      userCell = `<p class='m-0 d-inline-block align-middle text-danger">Missing</p>`
    }
    return this.sanitizer.bypassSecurityTrustHtml(userCell);
  }

  // table action cell formatter
  userActionFormatter(a: AppUser): SafeHtml {
    if (a[0]) {
      if (a[0].userId) {
        return this.sanitizer.bypassSecurityTrustHtml(
          `<button id="${a[0].userId}" class="btn btn-sm btn-outline-primary edit" type="button" *ngIf="!updateInProgress"> <i class=""></i>View/Edit</button>
            <button id="${a[0].userId}" class="btn btn-sm btn-outline-danger delete" type="button" *ngIf="!updateInProgress"> <i class=""></i>X</button>`
        );
      }
    }
  }

  profilePictureFormatter(a: AppUser[]): SafeHtml {
    let userCell: string = `<img src="assets/images/Male-Avatar.jpg" alt="contact-img" title="contact-img" class="avatar-md rounded-circle me-0" height="48" />`;
    if (a[0]) {
      if (a[0].profilePicture) {
        if (a[0].profilePicture[0]) {
          if (a[0].profilePicture[0].url) {
            userCell = `<img src="${a[0].profilePicture[0].url}" alt="contact-img" title="contact-img" class="avatar-md rounded-circle me-0" height="48" />`
          }
        }
      }
    }
    return this.sanitizer.bypassSecurityTrustHtml(userCell);
  }

  numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  // compares two cell valueuser?: User;
  user_complete?: boolean;
  compare(v1: string | number | boolean, v2: string | number | boolean): number {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  /**
   * Sort the table data
   * @param event column name,sort direction
   */
  sort(event: SortEvent): void {
    if (event.direction === '') {
      this.userArray = this.defaultUserArray;
      //this.getUserList();
    } else {
      this.userArray = [...this.userArray].sort((a, b) => {
        const res = this.compare(a[0][event.column], b[0][event.column]);
        return event.direction === 'asc' ? res : -res;
      });
    }
  }

  /**
 * Table Data Match with Search input
 * @param tableRow Table row
 * @param term Search the value
 */
  matches(tableRow: AppUser, term: string) {
    return tableRow.firstName?.toLowerCase().includes(term)
    || tableRow.otherName?.toLowerCase().includes(term)
      || tableRow.surname?.toLowerCase().includes(term);
  }

  /**
   * Search Method
  */
  searchData(searchTerm: string): void {
    if (searchTerm === '') {
      // this.getUserList();
      this.userArray = this.defaultUserArray;
    }
    else {
      let updatedData = this.userArray = this.defaultUserArray;
      //  filter
      updatedData = updatedData.filter(a => this.matches(a, searchTerm));
      this.userArray = updatedData;
    }
  }

  hasChanged($event: any) {
    // Clear all other address details?
  }

  createRecord() {
    
    
  }

  loadEditForm(id: string) {

    // Picked User Id
    this.pickedUserId = id;

    // Show new tool form
    this.showForm = true;

    // Clear form
    this.profileForm.reset();

    // Reset Picture
    this.profilePicture = "";

    if (this.userObject) {
      if (this.userObject[id]) {
        if (this.userObject[id][0]) {
          if (this.userObject[id][0].phoneNumber) { this.profileForm.get("phoneNumber").setValue(this.userObject[id][0].phoneNumber); };
          if (this.userObject[id][0].email) { this.profileForm.get("email").setValue(this.userObject[id][0].email); };
          if (this.userObject[id][0].firstName) { this.profileForm.get("firstName").setValue(this.userObject[id][0].firstName); };
          if (this.userObject[id][0].otherName) { this.profileForm.get("otherName").setValue(this.userObject[id][0].otherName); };
          if (this.userObject[id][0].surname) { this.profileForm.get("surname").setValue(this.userObject[id][0].surname); };
          if (this.userObject[id][0].role) { this.profileForm.get("role").setValue(this.userObject[id][0].role); };
          if (this.userObject[id][0]) {
            if (this.userObject[id][0].profilePicture) {
              if (this.userObject[id][0].profilePicture[0]) {
                this.profileForm.get("profilePicture").setValue(this.userObject[id][0].profilePicture[0]);
                if (this.userObject[id][0].profilePicture[0].url) {
                  this.profilePicture = this.userObject[id][0].profilePicture[0].url;
                }
              }
            }
          };
          if (this.userObject[id][0].userId) { this.profileForm.get("userId").setValue(this.userObject[id][0].userId); }
        }
      }
    }

    // Scroll to Form
    this.scrollToElement("profileFormEdit");
  }

  /**
* Upload Profile Picture
*/

  loadFiles(event) {
    this.updateInProgress = true;
    let fileCategory = "userProfile";
    let height: number;
    let width: number;
    let fileName = event.target["files"][0].name;
    let fileType = event.target["files"][0].type;
    let fileSize = event.target["files"][0].size;
    let imageId = fileCategory + (Date.now().toString().substr(0, 8) + '-' + Math.random().toString(36).substr(2, 5) + '-' + Math.random().toString(36).substr(2, 5)).toUpperCase();

    function formatBytes(bytes: number): string {
      const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const factor = 1024;
      let index = 0;

      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }

      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }

    let fileSize2 = formatBytes(fileSize);

    let path = `${fileCategory}/${fileName}`;

    this.subscription.add(this.userService.uploadProfileImage(event, path).subscribe((result) => {
      const { downloadUrl$, uploadProgress$ } = result;
      this.uploadProgress$ = uploadProgress$;

      const reader = new FileReader();
      reader.readAsDataURL(event.target["files"][0]);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          height = img.naturalHeight;
          width = img.naturalWidth;
        };
      };

      if (downloadUrl$ && this.pickedUserId && width && height) {
        this.fileStorage.saveProfileImageRecord(imageId, fileCategory, this.pickedUserId, downloadUrl$, fileName, fileSize2, fileType, height, width).then(() => {
          this.toastr.success("Upload successful", "Info!");
          this.updateInProgress = false;
        });
        this.profileForm.get("profilePicture").setValue({
          "url": downloadUrl$,
          "name": fileName,
          "size": fileSize2,
          "formatType": fileType,
          "imageId": imageId,
          "uploadBy": this.pickedUserId,
          "width": width,
          "height": height,
          "date": new Date().getTime()
        });
        this.profilePicture = downloadUrl$;
      }
    }));
  }

  /**
 * On form submit
 */

  onSubmit(userForm: any): void {
    this.updateInProgress = true;

    // Checks
    if (this.profileForm.controls['firstName'].invalid) {
      this.show_alert = true;
      this.alert_message = "Provide your First Name!";
      this.alert_type = "danger";

      this.toastr.error('Provide your First Name!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.profileForm.controls['surname'].invalid) {
      this.show_alert = true;
      this.alert_message = "Provide your surname!";
      this.alert_type = "danger";

      this.toastr.error('Provide your surname!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.profileForm.controls['otherName'].invalid) {
      this.show_alert = true;
      this.alert_message = "Provide your other name!";
      this.alert_type = "danger";

      this.toastr.error('Provide your other name!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.profileForm.get("email").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide your E-mail!";
      this.alert_type = "danger";

      this.toastr.error('Provide your E-mail!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.profileForm.get("phoneNumber").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide your phone number!";
      this.alert_type = "danger";

      this.toastr.error('Provide your phone number!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    if (this.pickedUserId) {
      console.log("userForm: ", userForm);
      this.subscription.add(
        this.userService.updateUser(this.pickedUserId, userForm)
          .pipe(first()).subscribe((result) => {
            // navigates to confirm mail screen
            // this.router.navigate(['/account/confirm-email']);

            // Navigate to Return URL

            this.formSubmitted = true;
            this.updateInProgress = false;
          },
            (error: string) => {
              this.error = error;
              this.updateInProgress = false;
            }));
    }

  }

  cancelUpdate() {
    // Show new tool form
    this.showForm = false;

    setTimeout(() => {
      this.scrollToElement("advancedList");
    }, 500);

    // Clear form
    this.projectForm.reset();
  }

  /**
  * convenience getter for easy access to form fields
  */
  get profileFormValues() { return this.profileForm.controls; }

  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }

  enlargeImage(content: TemplateRef<NgbModal>) {
    //Modal Large image
    if (this.profilePicture) {
      this.pickedImage = this.profilePicture;
      this.modalService.open(content, { size: "lg" });
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  deleteImage() {
    this.profilePicture = "";
    this.profileForm.get("profilePicture").setValue("");
  }

  cancelEdit() {
    // Close Module
    this.closeModal();
    // Scroll to List
    this.scrollToElement("advancedList");
    // Reset Form
    this.showForm = false;
  }

  scrollToElement(a: string) {
    // document.getElementById(a).scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start',
    //   inline: 'nearest',
    // });

    this.scroller.scrollToAnchor(a);

    setTimeout(() => {
      window.scrollBy(0, -75);
    }, 500);

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}


