import { Route } from "@angular/router";
import { MenuItem } from "../layouts/shared/models/menu.model";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ProjectComponent } from "./project/project.component";
import { UserComponent } from "./user/user.component";
import { AuthGuard } from "../core/guards/auth.guard";
import { RoleComponent } from "./role/role.component";
import { SupportComponent } from "./support/support.component";
import { EmailComponent } from "./email/email.component";
import { UserGuideComponent } from "./user-guide/user-guide.component";

export const ADMIN_MENU: MenuItem[] = [
    { key: 'ds-admin', label: 'Admin Navigation', isTitle: true },
    { key: 'ds-dashboard', label: 'Dashboard', isTitle: false, icon: 'uil-home-alt', link: '/admin/dashboard' },
    { key: 'ds-project', label: 'Project', isTitle: false, icon: 'uil-home-alt', link: '/admin/project' },
    { key: 'ds-user', label: 'User', isTitle: false, icon: 'uil-home-alt', link: '/admin/user' },
    { key: 'ds-user-guide', label: 'User Guide', isTitle: false, icon: 'uil-home-alt', link: '/admin/user-guide' },
    { key: 'ds-role', label: 'Roles', isTitle: false, icon: 'uil-home-alt', link: '/admin/role' },
    { key: 'ds-support', label: 'Support', isTitle: false, icon: 'uil-home-alt', link: '/admin/support' },
    { key: 'ds-email', label: 'Email', isTitle: false, icon: 'uil-home-alt', link: '/admin/email' },
  ]

export const ADMIN_ROUTES: Route[] = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full', },
    {
        path: 'dashboard',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: DashboardComponent, },
        ]
    },
    {
        path: 'project',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: ProjectComponent, },
        ]
    },
    {
        path: 'user',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: UserComponent, },
        ]
    },
    {
        path: 'user-guide',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: UserGuideComponent, },
        ]
    },
    {
        path: 'role',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: RoleComponent, },
        ]
    },
    {
        path: 'support',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: SupportComponent, },
        ]
    },
    {
        path: 'email',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: EmailComponent, },
        ]
    },
];