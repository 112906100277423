<div class="card bg-transparent">
    <app-ui-preloader [display]="loading"></app-ui-preloader>
    <div class="card">
        <div class="card-header">
            <div class="row flex-grow-1">
                <div class="col">
                    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                        <h1 class="display-4">Users</h1>
                        <p class="lead">List of users registered.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Logo -->
        <div class="card-body advancedList" id="advancedList">
            <div class="row mb-2">
                <div class="col-sm-5">
                </div>
                <div class="col-sm-7">
                    <div class="text-sm-end">
                    </div>
                </div><!-- end col-->
            </div>
            <!-- end row -->
            <!-- <app-ui-preloader [display]="loading"></app-ui-preloader> -->

            <!-- user table -->
            <app-advanced-table #advancedTable (handleTableLoad)="handleTableLoad()" [tableData]="userArray"
                [columns]="columns" [pagination]="true" [isSearchable]="true" [pageSizeOptions]="pageSizeOptions"
                (search)="searchData($event)" [hasItemNumbering]="true" [isSortable]="true" (sort)="sort($event)"
                tableClasses="table-centered nowrap w-100" theadClasses="table-light">
            </app-advanced-table>
        </div> <!-- end card-body-->
    </div>

    <div class="card" *ngIf="showForm">
        <div class="card-body profileFormEdit" id="profileFormEdit">

            <h5 class="mb-3">Profile Picture</h5>

            <div class="row">
                <div class="card" role="button" (click)="enlargeImage(sizeableModal)">
                    <img src="../../../assets/images/Female-Avatar.png" height="100px"
                        style="border-style: dashed;object-fit: scale-down;" class="card-img-top" alt="..."
                        *ngIf="!profilePicture">
                    <img [src]="profilePicture" class="card-img-top" height="100px"
                        style="border-style: dashed;object-fit: scale-down;" alt="..." *ngIf="profilePicture">
                    <ul class="list-group text-center list-group-flush">
                        <li class="list-group-item" *ngIf="!profilePicture">
                            <div class="form-group">
                                <small class="text-body-secondary">Upload a profile picture.</small>
                                <input type="file" class="form-control" accept=".png,.jpg,.jpeg" [multiple]="false"
                                    (change)="loadFiles($event)">
                            </div>
                        </li>
                        <li class="list-group-item" *ngIf="profilePicture">
                            <button type="button" (click)="deleteImage()" class="btn btn-sm btn-outline-danger">
                                Delete
                            </button>
                        </li>
                    </ul>
                </div>

            </div>


            <form name="signup-form" [formGroup]="profileForm">

                <h5 class="mb-3">Personal Details</h5>

                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="firstName" class="form-label">First Name<span class="text-danger">
                                *</span></label>
                        <input class="form-control" type="text" id="firstName" placeholder="Enter your first name"
                            required formControlName="firstName"
                            [ngClass]="{'is-invalid': (formSubmitted && formValues['firstName'].invalid)||(formValues['firstName'].touched && formValues['firstName'].invalid)}">
                        <div *ngIf="(formSubmitted && formValues['firstName'].invalid)||(formValues['firstName'].touched && formValues['firstName'].invalid)"
                            class="invalid-tooltip">
                            <div *ngIf="formValues['firstName'].errors?.['required']">
                                First Name is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="surname" class="form-label">Surname<span class="text-danger">
                                *</span></label>
                        <input class="form-control" type="text" id="surname" placeholder="Enter your first name"
                            required formControlName="surname"
                            [ngClass]="{'is-invalid': (formSubmitted && formValues['surname'].invalid)||(formValues['surname'].touched && formValues['surname'].invalid)}">
                        <div *ngIf="(formSubmitted && formValues['surname'].invalid)||(formValues['surname'].touched && formValues['surname'].invalid)"
                            class="invalid-tooltip">
                            <div *ngIf="formValues['surname'].errors?.['required']">
                                Surname is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="otherName" class="form-label">Other Name<span class="text-danger">
                                *</span></label>
                        <input class="form-control" type="text" id="otherName" placeholder="Enter your other name"
                            required formControlName="otherName"
                            [ngClass]="{'is-invalid': (formSubmitted && formValues['otherName'].invalid)||(formValues['otherName'].touched && formValues['otherName'].invalid)}">
                        <div *ngIf="(formSubmitted && formValues['otherName'].invalid)||(formValues['otherName'].touched && formValues['otherName'].invalid)"
                            class="invalid-tooltip">
                            <div *ngIf="formValues['otherName'].errors?.['required']">
                                Other Name is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="email" class="form-label">Email address <span class="text-danger">*</span></label>
                        <input class="form-control" type="email" id="email" placeholder="Enter your email"
                            formControlName="email">
                    </div>
                    <div class="col-md-6 mb-3">
                        <label class="form-label">Phone Number <span class="text-danger">*</span></label>
                        <div class="input-group flex-nowrap">
                            <span class="input-group-text" id="basic-addon1">+254</span>
                            <input type="text" class="form-control" placeholder="722 000 000"
                                formControlName="phoneNumber" aria-label="Username" aria-describedby="basic-addon1"
                                [ngClass]="{'is-invalid': invalidPhoneNumber}">
                            <div *ngIf="invalidPhoneNumber" class="invalid-tooltip">
                                <span>
                                    Write a valid Phone Number.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="role" class="form-label">Role<span class="text-danger"></span></label>
                        <select class="form-select" name="role" formControlName="role" id="role" required
                            [ngClass]="{'is-invalid': (formSubmitted && formValues['role'].invalid)||(formValues['role'].touched && formValues['role'].invalid)}">
                            <option *ngFor="let m of roleArray" [value]="m.name">
                                {{m.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="d-grid">
                            <button class="btn btn-secondary" (click)="cancelEdit()" type="submit"
                                *ngIf="!updateInProgress"><i class=""></i>Cancel Edit
                            </button>
                            <button class="btn btn-primary" type="button" disabled *ngIf="updateInProgress">
                                <span class="spinner-border spinner-border-sm me-1" role="status"
                                    aria-hidden="true"></span>
                                Loading...
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="d-grid">
                            <button class="btn btn-success" (click)="onSubmit(profileForm.value)" type="submit"
                                *ngIf="!updateInProgress"><i class=""></i>Update
                            </button>
                            <button class="btn btn-primary" type="button" disabled *ngIf="updateInProgress">
                                <span class="spinner-border spinner-border-sm me-1" role="status"
                                    aria-hidden="true"></span>
                                Loading...
                            </button>
                        </div>
                    </div>
                </div>

            </form>

        </div>
    </div>
</div>

<ng-template #sizeableModal let-modal>
    <div class="modal-body">
        <div (click)="closeModal()" class="card mb-0 px-0">
            <img [src]="pickedImage" class="img-fluid card-img" alt="image" *ngIf="pickedImage" />
        </div>
    </div>
</ng-template>