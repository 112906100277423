import { Component, ElementRef, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { NgbAlertModule, NgbProgressbarModule, NgbButtonsModule, NgbAlert, NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AdvancedTableModule } from 'src/app/shared/advanced-table/advanced-table.module';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { Subscription, first } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { ClockService } from 'src/app/core/service/clock.service';
import { UserService } from 'src/app/core/service/user.service';
import { Column, AdvancedTableComponent } from 'src/app/shared/advanced-table/advanced-table.component';
import { SortEvent } from 'src/app/shared/advanced-table/sortable.directive';
import { BreadcrumbItem } from 'src/app/shared/page-title/page-title.model';
import { AppUser } from 'src/app/core/models-two/AppUser';
import { FileStorageService } from 'src/app/core/service/file-storage.service';
import { ProjectService } from 'src/app/core/service/project.service';
import { CompanyRole } from 'src/app/core/models-two/CompanyRole';

@Component({
  selector: 'app-role',
  standalone: true,
  imports: [
    CommonModule,
    AdvancedTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbProgressbarModule,
    WidgetModule,
    NgbButtonsModule,
    NgbTooltipModule,
  ],
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit, OnDestroy {

  roleForm!: FormGroup;
  subscription: Subscription = new Subscription();
  today: number = Date.now();
  pageTitle: BreadcrumbItem[] = [];
  //roleArray!: Client[];
  pageSizeOptions: number[] = [5, 10, 20, 50];
  pageSize: number = 5;
  selectAll: boolean = false;
  loading: boolean = false;
  columns: Column[] = [];
  @ViewChild('profileModal', { static: false }) private profileModal: any;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  @ViewChild('advancedTable') advancedTable!: AdvancedTableComponent;

  projectForm!: FormGroup;
  formSubmitted: boolean = false;

  show_alert: boolean;
  alert_message: string;
  alert_type: string;

  loggedInUser: AppUser;
  userId: any;

  destinationLatitude: number;
  destinationLongitude: number;
  destinationZoom: number;
  googleLocationAddressLong: string;
  googleLocationAddressShort: any;
  profilePicture: any;
  invalidPhoneNumber: boolean;
  pickedImage: any;

  error: string;
  showForm: boolean = false;
  userCategoryViewChoice: number = 1;
  dateNow2: any;
  dateNow3: Date;
  newInviteId: any;
  defaultRoleArray: any[] = [];
  defaultRoleObject: any;
  importedUserArray: AppUser[] = [];
  showImportPanel: boolean = false;
  disableDownloadButton: boolean = false;
  alphabeticalRoleArray: CompanyRole[] = [];
  workingRoleArray: CompanyRole[] = [];
  updateInProgress: boolean = false;
  showPassword: boolean = false;
  roleIndex: number;
  uploadProgress$: any;
  roleArray: CompanyRole[] = [];
  roleObject: CompanyRole[];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private scroller: ViewportScroller,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private fileStorage: FileStorageService,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private ngZone: NgZone,
    private clockService: ClockService,
  ) {
    this.loadingAnimationFourSeconds();
    this.subscription.add(
      authenticationService.currentUser.pipe().subscribe((res: any) => {
        if (res) {
          if (res[0]) {
            this.loggedInUser = res[0];
            if (res[0].userId) {
              this.userId = res[0].userId;
            }
          } else {
          }
        }
      })
    );
  }

  ngOnInit(): void {

    this.roleForm = this.fb.group({
      id: ['', Validators.required],
      name: ['', Validators.required],
      description: ['', Validators.required],
      abbreviation: [''],
      rank: [''],
      privileges: [''],
      date: [''],
    });

    // get user data
    this.getRoleList();
    // initialize advance table 
    this.initAdvancedTableData();

  }


  /**
   * convenience getter for easy access to form fields
   */
  get formValues() {
    return this.roleForm.controls;
  }


  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  loadingAnimationStart() {
    this.loading = true;
  }

  loadingAnimationStop() {
    this.loading = false;
  }

  getRoleList() {
    //this.petroleumProductArray = users;
    this.subscription.add(
      this.projectService.getRoleArray().pipe().subscribe((list: CompanyRole[]) => {
        if (list) {
          this.workingRoleArray = [];
          this.defaultRoleArray = this.roleArray = list;

          // Arranged Alphabetically
          list.forEach(element => {
            this.workingRoleArray.push(element);
          });

          this.alphabeticalRoleArray = this.workingRoleArray.sort((a: any, b: any) => (a.name).localeCompare(b.name));

        }
      })
    );

    this.subscription.add(
      this.projectService.getRoleObject().pipe().subscribe((list: CompanyRole[]) => {
        if (list) {
          this.defaultRoleObject = this.roleObject = list;
        }
      })
    );
  }

  /**
   * initialize advance table columns
   */
  initAdvancedTableData(): void {
    this.columns = [
      {
        name: 'name',
        label: 'Name',
        formatter: (p: CompanyRole) => p.name,
      },
      {
        name: 'rank',
        label: 'Rank',
        formatter: (p: CompanyRole) => p.rank,
      },
      {
        name: 'description',
        label: 'Description',
        formatter: (p: CompanyRole) => p.description,
        sort: false
      },
      {
        name: 'quick link',
        label: 'Quick Links',
        formatter: this.userActionFormatter.bind(this),
        sort: false
      }
    ]
  }

  /**
   * handles operations that need to be performed after loading table
   */
  handleTableLoad(): void {
    // user cell
    document.querySelectorAll('.edit').forEach((e) => {
      e.addEventListener("click", () => {
        //Navigate to report page

        // Load edit Form
        // Show Edit Form
        this.loadEditForm(Number(e.id));

      });
    })

    document.querySelectorAll('.delete').forEach((e) => {
      e.addEventListener("click", () => {
        if (confirm("Are you sure you want to delete this project?")) {
          // Move item to petroleumProduct/delete
          this.deleteRole(Number(e.id));
        }
      });
    })
  }


  deleteRole(id: number) {
    this.updateInProgress = true;

    let name = "";
    if (this.defaultRoleObject) {
      if (this.defaultRoleObject[id]) {
        if (this.defaultRoleObject[id].name) {
          name = this.defaultRoleObject[id].name;
        };
      };
    };

    this.subscription.add(
      this.projectService.deleteRole(id)
        .pipe(first()).subscribe((result) => {

          //Get Role Data
          this.show_alert = true;
          this.alert_message = `${name} role deleted!`;
          this.alert_type = "success";

          this.toastr.success(`${name} role deleted!`, "Info!");

          setTimeout(() => {
            this.show_alert = false;
            this.selfClosingAlert.close();
            this.updateInProgress = false;
          }, 3000);

        },
          (error: string) => {
            this.toastr.error(`${name} role was not deleted, error: ${error}!`, "Info!");
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
          }));
  }


  // table action cell formatter
  userActionFormatter(a: CompanyRole): SafeHtml {
    if (a) {
      if (a.id || ((Number(a.id)) === 0)) {
        return this.sanitizer.bypassSecurityTrustHtml(
          `<button id="${a.id}" class="btn btn-sm btn-outline-primary edit" type="button" *ngIf="!updateInProgress"> <i class=""></i>View/Edit</button>
            <button id="${a.id}" class="btn btn-sm btn-outline-danger delete" type="button" *ngIf="!updateInProgress"> <i class=""></i>X</button>`
        );
      }
    }
  }

  numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  // compares two cell valueuser?: Role;
  user_complete?: boolean;
  compare(v1: string | number | boolean, v2: string | number | boolean): number {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  /**
   * Sort the table data
   * @param event column name,sort direction
   */
  sort(event: SortEvent): void {
    if (event.direction === '') {
      this.roleArray = this.defaultRoleArray;
      //this.getRoleList();
    } else {
      this.roleArray = [...this.roleArray].sort((a, b) => {
        const res = this.compare(a[event.column], b[event.column]);
        return event.direction === 'asc' ? res : -res;
      });
    }
  }

  /**
 * Table Data Match with Search input
 * @param tableRow Table row
 * @param term Search the value
 */
  matches(tableRow: CompanyRole, term: string) {
    return tableRow.name?.toLowerCase().includes(term)
      || tableRow.description?.toLowerCase().includes(term);
  }

  /**
   * Search Method
  */
  searchData(searchTerm: string): void {
    if (searchTerm === '') {
      // this.getRoleList();
      this.roleArray = this.defaultRoleArray;
    }
    else {
      let updatedData = this.roleArray = this.defaultRoleArray;
      //  filter
      updatedData = updatedData.filter(a => this.matches(a, searchTerm));
      this.roleArray = updatedData;
    }
  }

  hasChanged($event: any) {
    // Clear all other address details?
  }

  loadEditForm(id: number) {

    // Picked CompanyRole[] Id
    this.roleIndex = id;

    // Show new tool form
    this.showForm = true;

    // Clear form
    this.roleForm.reset();

    // Reset Picture
    this.profilePicture = "";

    if (this.roleObject) {
      if (this.roleObject[id]) {
        if (this.roleObject[id]) {
          if (this.roleObject[id].id) { this.roleForm.get("id").setValue(this.roleObject[id].id); } else { this.roleForm.get("id").setValue(id) };
          if (this.roleObject[id].name) { this.roleForm.get("name").setValue(this.roleObject[id].name); };
          if (this.roleObject[id].abbreviation) { this.roleForm.get("abbreviation").setValue(this.roleObject[id].abbreviation); };
          if (this.roleObject[id].description) { this.roleForm.get("description").setValue(this.roleObject[id].description); };
          if (this.roleObject[id].rank) { this.roleForm.get("rank").setValue(this.roleObject[id].rank); };
          if (this.roleObject[id].date) { this.roleForm.get("date").setValue(this.roleObject[id].date); };
          if (this.roleObject[id].privileges) { this.roleForm.get("privileges").setValue(this.roleObject[id].privileges); };
        }
      }
    }

    // Scroll to Form
    this.scrollToElement("roleFormEdit");
  }

  /**
 * On form submit
 */

  onSubmit(roleForm: any): void {
    this.updateInProgress = true;

    // Checks
    if (this.roleForm.controls['id'].invalid) {
      this.show_alert = true;
      this.alert_message = "ID is missing!";
      this.alert_type = "danger";

      this.toastr.error('ID is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    };

    // Checks
    if (this.roleForm.controls['name'].invalid) {
      this.show_alert = true;
      this.alert_message = "Name is missing!";
      this.alert_type = "danger";

      this.toastr.error('Name is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    };

    // Checks
    if (this.roleForm.controls['description'].invalid) {
      this.show_alert = true;
      this.alert_message = "Description is missing!";
      this.alert_type = "danger";

      this.toastr.error('Description is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    };

    // Checks
    if (this.roleForm.controls['abbreviation'].invalid) {
      this.show_alert = true;
      this.alert_message = "Abbreviation is missing!";
      this.alert_type = "danger";

      this.toastr.error('Abbreviation is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    };

    // Checks
    if (this.roleForm.controls['rank'].invalid) {
      this.show_alert = true;
      this.alert_message = "Rank is missing!";
      this.alert_type = "danger";

      this.toastr.error('Rank is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    };

    // Checks
    if (this.roleForm.controls['date'].invalid) {
      this.show_alert = true;
      this.alert_message = "Date is missing!";
      this.alert_type = "danger";

      this.toastr.error('Date is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    };

    // Checks
    if (this.roleForm.controls['privileges'].invalid) {
      this.show_alert = true;
      this.alert_message = "Privileges are missing!";
      this.alert_type = "danger";

      this.toastr.error('Privileges are missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    };



    this.subscription.add(
      this.userService.updateRole(this.roleIndex, roleForm)
        .pipe(first()).subscribe((result) => {
          // navigates to confirm mail screen
          // this.router.navigate(['/account/confirm-email']);

          // Navigate to Return URL

          this.formSubmitted = true;
          this.updateInProgress = false;
        },
          (error: string) => {
            this.error = error;
            this.updateInProgress = false;
          }));

  }


  /**
  * convenience getter for easy access to form fields
  */
  get roleFormValues() { return this.roleForm.controls; }

  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }

  createNewRole() {


  }

  cancelEdit() {
    // Scroll to List
    this.scrollToElement("advancedList");
    // Reset Form
    this.showForm = false;
  }

  scrollToElement(a: string) {
    this.scroller.scrollToAnchor(a);

    setTimeout(() => {
      window.scrollBy(0, -75);
    }, 500);

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}


