<div class="card bg-transparent">
    <app-ui-preloader [display]="loading"></app-ui-preloader>
    <div class="card-header">

        <!-- title-->
        <div class="row flex-grow-1">
            <div class="col">
                <div class="pricing-header px-3 py-3 pt-md-3 pb-md-2 mx-auto text-center">
                    <h1 class="display-6">Create Project</h1>
                    <p class="lead text-muted">Fill in the project details.</p>
                </div>
            </div>
        </div>

    </div>
    <div class="card-body">

        <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

        <form name="signup-form" [formGroup]="projectForm">

            <h4 class="mb-3">Project Details</h4>

            <div class="row">
                <div class="col-6 mb-3">
                    <label for="name" class="form-label">Name<span class="text-danger">
                            *</span></label>
                    <input class="form-control" type="text" id="name" placeholder="First Project" formControlName="name"
                        [ngClass]="{'is-invalid': (formSubmitted && formValues['name'].invalid)||(formValues['name'].touched && formValues['name'].invalid)}">
                    <div *ngIf="(formSubmitted && formValues['name'].invalid)||(formValues['name'].touched && formValues['name'].invalid)"
                        class="invalid-tooltip">
                        <div *ngIf="formValues['name'].errors?.['required']">
                            Name is required.
                        </div>
                    </div>
                </div>
                <div class="col-6 mb-3">
                    <label for="id" class="form-label">Project Id<span class="text-danger">
                            *</span></label>
                    <input class="form-control" type="text" id="id" readonly formControlName="id">
                </div>
            </div>

            <div class="row">
                <div class="col-6 mb-3">
                    <label for="countryId" class="form-label">Country<span class="text-danger">
                            *</span></label>
                    <select class="form-select" name="countryId" formControlName="countryId" id="countryId" required
                        [ngClass]="{'is-invalid': (formSubmitted && formValues['countryId'].invalid)||(formValues['countryId'].touched && formValues['countryId'].invalid)}">
                        <option *ngFor="let m of countryArray" [value]="m[0].id">
                            {{m[0].name}}
                        </option>
                    </select>
                    <div *ngIf="(formSubmitted && formValues['countryId'].invalid)||(formValues['countryId'].touched && formValues['countryId'].invalid)"
                        class="invalid-tooltip">
                        <div *ngIf="formValues['countryId'].errors?.['required']">
                            Country Name is required.
                        </div>
                    </div>
                </div>
                <div class="col-6 mb-3">
                    <label for="typeOfInvestment" class="form-label">Type Of Investment<span class="text-danger">
                            *</span></label>
                    <select class="form-select" name="typeOfInvestment" formControlName="typeOfInvestment"
                        id="typeOfInvestment" required
                        [ngClass]="{'is-invalid': (formSubmitted && formValues['typeOfInvestment'].invalid)||(formValues['typeOfInvestment'].touched && formValues['typeOfInvestment'].invalid)}">
                        <option *ngFor="let m of typeOfInvestmentArray" [value]="m">
                            {{m}}
                        </option>
                    </select>
                    <div *ngIf="(formSubmitted && formValues['typeOfInvestment'].invalid)||(formValues['typeOfInvestment'].touched && formValues['typeOfInvestment'].invalid)"
                        class="invalid-tooltip">
                        <div *ngIf="formValues['typeOfInvestment'].errors?.['required']">
                            Currency Name is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 mb-3">
                    <label for="mode" class="form-label">Partnership Mode<span class="text-danger">
                            *</span></label>
                    <select class="form-select" name="mode" formControlName="mode" id="mode" required
                        [ngClass]="{'is-invalid': (formSubmitted && formValues['mode'].invalid)||(formValues['mode'].touched && formValues['mode'].invalid)}">
                        <option *ngFor="let m of modeArray" [value]="m">
                            {{m}}
                        </option>
                    </select>
                    <div *ngIf="(formSubmitted && formValues['mode'].invalid)||(formValues['mode'].touched && formValues['mode'].invalid)"
                        class="invalid-tooltip">
                        <div *ngIf="formValues['mode'].errors?.['required']">
                            Mode is required.
                        </div>
                    </div>
                </div>
                <div class="col-6 mb-3">
                    <label for="startDate" class="form-label">Start Date<span class="text-danger">
                            *</span></label>
                    <input class="form-control" type="date" id="startDate" [min]="minimumDate"
                        formControlName="startDate"
                        [ngClass]="{'is-invalid': (formSubmitted && formValues['startDate'].invalid)||(formValues['startDate'].touched && formValues['startDate'].invalid)}">
                    <div *ngIf="(formSubmitted && formValues['startDate'].invalid)||(formValues['startDate'].touched && formValues['startDate'].invalid)"
                        class="invalid-tooltip">
                        <div *ngIf="formValues['startDate'].errors?.['required']">
                            Start Date is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 mb-3">
                    <label for="periodOfOperation" class="form-label">Periods Of Operations<span class="text-danger">
                            *</span></label>
                    <input class="form-control" type="number" min="1" max="10" id="periodOfOperation" placeholder="10"
                        formControlName="periodOfOperation" readonly
                        [ngClass]="{'is-invalid': (formSubmitted && formValues['periodOfOperation'].invalid)||(formValues['periodOfOperation'].touched && formValues['periodOfOperation'].invalid)}">
                    <div *ngIf="(formSubmitted && formValues['periodOfOperation'].invalid)||(formValues['periodOfOperation'].touched && formValues['periodOfOperation'].invalid)"
                        class="invalid-tooltip">
                        <div *ngIf="formValues['periodOfOperation'].errors?.['required']">
                            Periods Of Operations is required.
                        </div>
                    </div>
                </div>
                <div class="col-6 mb-3">
                    <label for="monthsBeforeOperation" class="form-label">Months Before Operations<span
                            class="text-danger">
                            *</span></label>
                    <div class="input-group input-group-merge">
                        <input class="form-control" type="number" min="1" max="72" id="monthsBeforeOperation"
                            placeholder="3" formControlName="monthsBeforeOperation"
                            [ngClass]="{'is-invalid': (formSubmitted && formValues['monthsBeforeOperation'].invalid)||(formValues['monthsBeforeOperation'].touched && formValues['monthsBeforeOperation'].invalid)}">
                        <div class="input-group-text">
                            <span>Months</span>
                        </div>
                    </div>
                    <div *ngIf="(formSubmitted && formValues['monthsBeforeOperation'].invalid)||(formValues['monthsBeforeOperation'].touched && formValues['monthsBeforeOperation'].invalid)"
                        class="invalid-tooltip">
                        <div *ngIf="formValues['monthsBeforeOperation'].errors?.['required']">
                            Months Before Operations is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 mb-3">
                    <label for="plotSize" class="form-label">Plot Size<span class="text-danger">
                            *</span></label>
                    <div class="input-group input-group-merge">
                        <input class="form-control" type="number" min="1" max="100000" id="plotSize" placeholder="4,000"
                            formControlName="plotSize"
                            [ngClass]="{'is-invalid': (formSubmitted && formValues['plotSize'].invalid)||(formValues['plotSize'].touched && formValues['plotSize'].invalid)}">
                        <div class="input-group-text">
                            <span>Meters Cubed</span>
                        </div>
                    </div>
                    <div *ngIf="(formSubmitted && formValues['plotSize'].invalid)||(formValues['plotSize'].touched && formValues['plotSize'].invalid)"
                        class="invalid-tooltip">
                        <div *ngIf="formValues['plotSize'].errors?.['required']">
                            Plot Size is required.
                        </div>
                    </div>
                </div>
                <div class="col-6 mb-3">
                    <label for="shopSize" class="form-label">Shop Size<span class="text-danger">
                            *</span></label>

                    <div class="input-group input-group-merge">
                        <input class="form-control" type="number" min="1" max="100000" id="shopSize" placeholder="2,000"
                            formControlName="shopSize"
                            [ngClass]="{'is-invalid': (formSubmitted && formValues['shopSize'].invalid)||(formValues['shopSize'].touched && formValues['shopSize'].invalid)}">
                        <div class="input-group-text">
                            <span>Meters Squared</span>
                        </div>
                    </div>
                    <div *ngIf="(formSubmitted && formValues['shopSize'].invalid)||(formValues['shopSize'].touched && formValues['shopSize'].invalid)"
                        class="invalid-tooltip">
                        <div *ngIf="formValues['shopSize'].errors?.['required']">
                            Shop Size is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="row mt-2" *ngIf="(availableRetailStaffArray.length>0)">
                        <div id="tooltip-container">
                            <h5>Available Members:</h5>
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="(availableRetailStaffArray.length>0)">
                        <p class="text-muted fw-semibold mb-1">Retail Staff</p>
    
                        <div class="row row-cols-sm-4 row-cols-md-4 row-cols-lg-6 row-cols-xl-6 row-cols-xxl-6 g-1">
                            <div class="col" *ngFor="let m of availableRetailStaffArray; let i = index;">
                                <div class="card text-center border border-primary bg-transparent">
                                    <img class="rounded-circle card-img-top"
                                        [src]="(m.url)?(m.url):'assets/images/Male-Avatar.jpg'" alt="Card image cap">
                                    <div class="card-body py-0">
                                        <span class="text-center mb-0" *ngIf="m.displayName">{{m.displayName}}</span>
                                    </div>
                                    <div class="card-footer">
                                        <div class="d-grid">
                                            <button class="btn btn-sm btn-outline-success" (click)="removeAddTeamMember('add',i)" type="button"
                                                *ngIf="!submitInProgress">
                                                <i class=""></i>+</button>
                                            <button class="btn btn-sm btn-outline-success" type="button" disabled *ngIf="submitInProgress">
                                                <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                                Loading...
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                    </div>
    
    
    
                </div>
                <div class="col-12">
                    <div class="row mt-2">
                        <div id="tooltip-container">
                            <h5>Team Members:</h5>
                        </div>
                    </div>
                    <!-- <div class="row mt-2" *ngIf="(pickedBoardMemberArray.length>0)">
                        <p class="text-muted fw-semibold mb-1">Board</p>
                        <div class="row row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 g-1">
                            <div class="col" *ngFor="let m of pickedBoardMemberArray; let i = index;">
                                <div class="card text-center border border-primary bg-transparent">
                                    <img class="rounded-circle card-img-top"
                                        [src]="(m.url)?(m.url):'assets/images/Male-Avatar.jpg'" alt="Card image cap">
                                    <div class="card-body py-0">
                                        <span class="text-center mb-0" *ngIf="m.displayName">{{m.displayName}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="(pickedCEOArray.length>0)">
                        <div class="col-6 col-sm-6 col-lg-4 col-xl-4 col-xxl-4 text-start">
                            <p class="text-muted fw-semibold mb-1">CEO</p>
                            <div class="row row-cols-1 g-1">
                                <div class="col" *ngFor="let m of pickedCEOArray; let i = index;">
                                    <div class="card text-center border border-primary bg-transparent">
                                        <img class="rounded-circle card-img-top"
                                            [src]="(m.url)?(m.url):'assets/images/Male-Avatar.jpg'" alt="Card image cap">
                                        <div class="card-body py-0">
                                            <span class="text-center mb-0" *ngIf="m.displayName">{{m.displayName}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-lg-4 col-xl-4 col-xxl-4 text-start">
                            <p class="text-muted fw-semibold mb-1">Secretary</p>
                            <div class="row row-cols-1 g-1">
                                <div class="col" *ngFor="let m of pickedCompanySecretaryArray; let i = index;">
                                    <div class="card text-center border border-primary bg-transparent">
                                        <img class="rounded-circle card-img-top"
                                            [src]="(m.url)?(m.url):'assets/images/Male-Avatar.jpg'" alt="Card image cap">
                                        <div class="card-body py-0">
                                            <span class="text-center mb-0" *ngIf="m.displayName">{{m.displayName}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-lg-4 col-xl-4 col-xxl-4 text-start">
                            <p class="text-muted fw-semibold mb-1">Head Of Retail</p>
                            <div class="row row-cols-1 g-1">
                                <div class="col" *ngFor="let m of pickedHeadOfRetailArray; let i = index;">
                                    <div class="card text-center border border-primary bg-transparent">
                                        <img class="rounded-circle card-img-top"
                                            [src]="(m.url)?(m.url):'assets/images/Male-Avatar.jpg'" alt="Card image cap">
                                        <div class="card-body py-0">
                                            <span class="text-center mb-0" *ngIf="m.displayName">{{m.displayName}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-lg-4 col-xl-4 col-xxl-4 text-start">
                            <p class="text-muted fw-semibold mb-1">Retail Development Manager</p>
                            <div class="row row-cols-1 g-1">
                                <div class="col" *ngFor="let m of pickedRetailDevelopmentManagerArray; let i = index;">
                                    <div class="card text-center border border-primary bg-transparent">
                                        <img class="rounded-circle card-img-top"
                                            [src]="(m.url)?(m.url):'assets/images/Male-Avatar.jpg'" alt="Card image cap">
                                        <div class="card-body py-0">
                                            <span class="text-center mb-0" *ngIf="m.displayName">{{m.displayName}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="(pickedRetailStaffArray.length>0)">
                        <p class="text-muted fw-semibold mb-1">Retail Staff</p>
                        <div class="row row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 g-1">
                            <div class="col" *ngFor="let m of pickedRetailStaffArray; let i = index;">
                                <div class="card text-center border border-primary bg-transparent">
                                    <img class="rounded-circle card-img-top"
                                        [src]="(m.url)?(m.url):'assets/images/Male-Avatar.jpg'" alt="Card image cap">
                                    <div class="card-body py-0">
                                        <span class="text-center mb-0" *ngIf="m.displayName">{{m.displayName}}</span>
                                    </div>
                                    <div class="card-footer">
                                        <div class="d-grid">
                                            <button class="btn btn-sm btn-outline-danger" (click)="removeAddTeamMember('remove',i)" type="button"
                                                *ngIf="!submitInProgress">
                                                <i class=""></i>X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled *ngIf="submitInProgress">
                                                <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                                Loading...
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
    
                    <div class="row mt-2" *ngIf="(pickedTeamArray.length>0)">
                        <div class="row row-cols-sm-4 row-cols-md-4 row-cols-lg-6 row-cols-xl-6 row-cols-xxl-6 g-1">
                            <div class="col" *ngFor="let m of pickedTeamArray; let i = index;">
                                <div class="card text-center border border-primary bg-transparent">
                                    <img class="rounded-circle card-img-top"
                                        [src]="(m.url)?(m.url):'assets/images/Male-Avatar.jpg'" alt="Card image cap">
                                    <div class="card-body py-0">
                                        <span class="text-center mb-0" *ngIf="m.displayName">{{m.displayName}}</span>
                                    </div>
                                    <div class="card-body py-0">
                                        <small><span class="text-center text-muted mb-0" *ngIf="m.role">{{m.role}}</span></small>
                                    </div>
                                    <div class="card-footer" *ngIf="(m.role) === 'Retail Staff'">
                                        <div class="d-grid">
                                            <button class="btn btn-sm btn-outline-danger" (click)="removeAddTeamMember('remove',i)" type="button"
                                                *ngIf="!submitInProgress">
                                                <i class=""></i>X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled *ngIf="submitInProgress">
                                                <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                                Loading...
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-outline-secondary" (click)="cancelUpdate()" type="submit"><i
                                class=""></i>Cancel</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-outline-success" (click)="onSubmit(projectForm.value)" type="button"
                            *ngIf="!submitInProgress">
                            <i class=""></i>Create</button>
                        <button class="btn btn-outline-primary" type="button" disabled *ngIf="submitInProgress">
                            <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>