<div class="card bg-transparent">
    <app-ui-preloader [display]="loading"></app-ui-preloader>
    <div class="card">
        <div class="card-header">
            <div class="row flex-grow-1">
                <div class="col">
                    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                        <h1 class="display-4">Petroleum Products Category</h1>
                        <p class="lead">List of petroleum product categories.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Logo -->
        <div class="card-body">
            <div class="row mb-2">
                <div class="col-sm-5">
                    <a (click)="createRecord()" class="btn btn-primary mb-2 me-1"><i
                            class="mdi mdi-plus-circle me-2"></i>
                        Add Product Category</a>
                </div>
                <div class="col-sm-7">
                    <div class="text-sm-end">
                    </div>
                </div><!-- end col-->
            </div>
            <!-- end row -->
            <!-- <app-ui-preloader [display]="loading"></app-ui-preloader> -->

            <!-- user table -->
            <app-advanced-table #advancedTable (handleTableLoad)="handleTableLoad()" [tableData]="fuelCategoryArray"
                [columns]="columns" [pagination]="true" [isSearchable]="true" [pageSizeOptions]="pageSizeOptions"
                (search)="searchData($event)" [hasItemNumbering]="true" [isSortable]="true" (sort)="sort($event)"
                tableClasses="table-centered nowrap w-100" theadClasses="table-light">
            </app-advanced-table>
        </div> <!-- end card-body-->
    </div>
</div>

<div class="card bg-transparent" *ngIf="showForm">
    <div class="card-header">
        <!-- title-->
        <div class="row flex-grow-1">
            <div class="col">
                <div class="pricing-header px-3 py-3 pt-md-3 pb-md-2 mx-auto text-center">
                    <h1 class="display-6">Product Category</h1>
                    <p class="lead text-muted">Fill in the product category details.</p>
                </div>
            </div>
        </div>

    </div>
    <div class="card-body">

        <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

        <form name="signup-form" [formGroup]="productForm">

            <h4 class="mb-3">Product Category Details</h4>

            <div class="row">
                <div class="col-6 mb-3">
                    <label for="name" class="form-label">Name<span class="text-danger">
                            *</span></label>
                    <input class="form-control" type="text" id="name" placeholder="Enter the product name"
                        formControlName="name"
                        [ngClass]="{'is-invalid': (formSubmitted && formValues['name'].invalid)||(formValues['name'].touched && formValues['name'].invalid)}">
                    <div *ngIf="(formSubmitted && formValues['name'].invalid)||(formValues['name'].touched && formValues['name'].invalid)"
                        class="invalid-tooltip">
                        <div *ngIf="formValues['name'].errors?.['required']">
                            Name is required.
                        </div>
                    </div>
                </div>
                <div class="col-6 mb-3">
                    <label for="id" class="form-label">Category Id<span class="text-danger">
                            *</span></label>
                    <input class="form-control" type="text" id="id" readonly formControlName="id">
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb-3">
                    <label for="description" class="form-label">Description<span class="text-danger">
                            *</span></label>
                    <input class="form-control" type="text" id="description" placeholder="Pick a category"
                        formControlName="description"
                        [ngClass]="{'is-invalid': (formSubmitted && formValues['description'].invalid)||(formValues['description'].touched && formValues['description'].invalid)}">
                    <div *ngIf="(formSubmitted && formValues['description'].invalid)||(formValues['description'].touched && formValues['description'].invalid)"
                        class="invalid-tooltip">
                        <div *ngIf="formValues['description'].errors?.['required']">
                            Category is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div class="d-grid">
                        <button class="btn btn-secondary mt-2" (click)="cancelUpdate()" type="submit"><i
                                class=""></i>Cancel</button>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div class="d-grid">
                        <button class="btn btn-success mt-2" (click)="onSubmit(productForm.value)" type="submit"><i
                                class=""></i>Update</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>