import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { NgbAlertModule, NgbProgressbarModule, NgbButtonsModule, NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdvancedTableModule } from 'src/app/shared/advanced-table/advanced-table.module';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { Subscription, first } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@firebase/auth-types';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { ClockService } from 'src/app/core/service/clock.service';
import { UserService } from 'src/app/core/service/user.service';
import { PetroleumProductsService } from 'src/app/core/service/petroleum-products.service';
import { Column, AdvancedTableComponent } from 'src/app/shared/advanced-table/advanced-table.component';
import { SortEvent } from 'src/app/shared/advanced-table/sortable.directive';
import { BreadcrumbItem } from 'src/app/shared/page-title/page-title.model';
import { PetroleumProductCategory } from 'src/app/core/models-two/PetroleumProductCategory';

@Component({
  selector: 'app-petroleum-products-category-admin',
  standalone: true,
  imports: [
    CommonModule,
    AdvancedTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbProgressbarModule,
    WidgetModule,
    NgbButtonsModule,
  ],
  templateUrl: './petroleum-products-category-admin.component.html',
  styleUrls: ['./petroleum-products-category-admin.component.scss']
})
export class PetroleumProductsCategoryAdminComponent implements OnInit, OnDestroy {


  subscription: Subscription = new Subscription();
  today: number = Date.now();
  pageTitle: BreadcrumbItem[] = [];
  //petroleumProductCategoryArray!: Client[];
  pageSizeOptions: number[] = [5, 10, 20, 50];
  pageSize: number = 5;
  selectAll: boolean = false;
  loading: boolean = false;
  columns: Column[] = [];
  @ViewChild('profileModal', { static: false }) private profileModal: any;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  @ViewChild('advancedTable') advancedTable!: AdvancedTableComponent;

  productForm!: FormGroup;
  formSubmitted: boolean = false;

  show_alert: boolean;
  alert_message: string;
  alert_type: string;

  loggedInUser: User;
  fuelCategoryObject: any;
  fuelCategoryArray;
  userId: any;

  destinationLatitude: number;
  destinationLongitude: number;
  destinationZoom: number;
  googleLocationAddressLong: string;
  googleLocationAddressShort: any;
  profilePicture: any;
  invalidPhoneNumber: boolean;
  pickedImage: any;

  error: string;
  showForm: boolean = false;
  userCategoryViewChoice: number = 1;
  dateNow2: any;
  dateNow3: Date;
  newInviteId: any;
  defaultPetroleumProductCategoryArray: any[] = [];
  defaultPetroleumProductCategoryObject: any;
  importedPetroleumProductCategoryArray: PetroleumProductCategory[] = [];
  showImportPanel: boolean = false;
  disableDownloadButton: boolean = false;
  alphabeticalPetroleumProductArray: PetroleumProductCategory[] = [];
  workingPetroleumProductCategoryArray: PetroleumProductCategory[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private petroleumProductsService: PetroleumProductsService,
    private clockService: ClockService,
  ) {
    this.loadingAnimationFourSeconds();
    this.subscription.add(
      authenticationService.currentUser.pipe().subscribe((res: any) => {
        if (res) {
          if (res[0]) {
            this.loggedInUser = res[0];
            if (res[0].userId) {
              this.userId = res[0].userId;
            }
          } else {
          }
        }
      })
    );
  }

  ngOnInit(): void {

    // get user data
    this.getPetroleumProductCategoryList();
    // initialize advance table 
    this.initAdvancedTableData();

    this.productForm = this.fb.group({
      id: [''],
      index: [''],
      name: [''],
      description: [''],
      created: [''],
    });

  }

  ngAfterViewInit(): void {

  }


  /**
   * convenience getter for easy access to form fields
   */
  get formValues() {
    return this.productForm.controls;
  }



  /**
   * On form submit
   */

  onSubmit(load: any): void {

    // Checks
    if (this.productForm.controls['id'].invalid) {
      this.show_alert = true;
      this.alert_message = "Product Id is missing!";
      this.alert_type = "danger";

      this.toastr.error('Product Id is missing!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.productForm.controls['name'].invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the product Name!";
      this.alert_type = "danger";

      this.toastr.error('Provide the product Name!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.productForm.controls['description'].invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the category description!";
      this.alert_type = "danger";

      this.toastr.error('Provide the category description!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    this.loading = true;
    this.subscription.add(
      this.petroleumProductsService.updatePetroleumProductCategory(load)
        .pipe(first()).subscribe((result) => {
          // navigates to confirm mail screen
          // this.router.navigate(['/account/confirm-email']);
          this.formSubmitted = true;
          this.loading = false;
          this.showForm = false;

          // Clear form
          this.productForm.reset();

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
          }));

  }

  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  loadingAnimationStart() {
    this.loading = true;
  }

  loadingAnimationStop() {
    this.loading = false;
  }

  getPetroleumProductCategoryList() {
    //this.petroleumProductCategoryArray = users;
    this.subscription.add(
      this.petroleumProductsService.getPetroleumProductCategoryArray().pipe().subscribe((userList: any) => {
        if (userList) {
          this.workingPetroleumProductCategoryArray = [];
          this.defaultPetroleumProductCategoryArray = this.fuelCategoryArray = userList;

          // Arranged Alphabetically
          userList.forEach(element => {
            this.workingPetroleumProductCategoryArray.push(element[0]);
          });

          this.workingPetroleumProductCategoryArray.sort((a: any, b: any) => (a.firstName).localeCompare(b.firstName));

        }
      })
    );

    this.subscription.add(
      this.petroleumProductsService.getPetroleumProductCategoryObject().pipe().subscribe((userList) => {
        if (userList) {
          this.defaultPetroleumProductCategoryObject = this.fuelCategoryObject = userList;
        }
      })
    );
  }

  /**
   * initialize advance table columns
   */
  initAdvancedTableData(): void {
    this.columns = [
      {
        name: 'name',
        label: 'Name',
        formatter: (p: PetroleumProductCategory[]) => p[0].name,
      },
      {
        name: 'description',
        label: 'Description',
        formatter: (p: PetroleumProductCategory[]) => p[0].description,
      },
      {
        name: 'action',
        label: 'Action',
        formatter: this.userActionFormatter.bind(this),
        sort: false
      }
    ]
  }

  /**
   * handles operations that need to be performed after loading table
   */
  handleTableLoad(): void {
    // user cell
    document.querySelectorAll('.edit').forEach((e) => {
      e.addEventListener("click", () => {
        //Navigate to edit page
        this.editForm(e.id);
      });
    })
    document.querySelectorAll('.delete').forEach((e) => {
      e.addEventListener("click", () => {


        // Move item to petroleumProductCategory/delete
        this.deletePetroleumProductCategory(e.id);

      });
    })
  }


  deletePetroleumProductCategory(id: string) {
    this.subscription.add(
      this.petroleumProductsService.deletePetroleumProductCategory(id)
        .pipe(first()).subscribe((result) => {

          //Get User Data
          this.show_alert = true;
          this.alert_message = `Petroleum Product ${id} Deleted!`;
          this.alert_type = "success";

          this.toastr.success(`Petroleum Product ${id} Deleted!`, "Info!");

          setTimeout(() => {
            this.show_alert = false;
            this.selfClosingAlert.close();
          }, 3000);

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
          }));
  }


  // table user formatter
  userNameFormatter(user:PetroleumProductCategory): SafeHtml {
    let userCell: string = "";
    userCell += `<p class='m-0 d-inline-block align-middle font-16'>`
    if (user[0]) {
      if (user[0].name) {
        userCell += `${user[0].name} `
      }
      if (user[0].description) {
        userCell += `${user[0].description} `
      }
    }
    userCell = userCell + `</p>`
    return this.sanitizer.bypassSecurityTrustHtml(userCell);
  }

  codeFormatter(user:PetroleumProductCategory): SafeHtml {
    let userCell: string = "";
    //Category for each
    if (user[0]) {
      if (user[0].id) {
        userCell = `<p class='m-0 d-inline-block align-middle'>${user[0].id}</p>`
      } else {
        userCell = `<p class='m-0 d-inline-block align-middle text-danger">Missing</p>`
      };
    } else {
      userCell = `<p class='m-0 d-inline-block align-middle text-danger">Missing</p>`
    }
    return this.sanitizer.bypassSecurityTrustHtml(userCell);
  }

  // table action cell formatter
  userActionFormatter(user): SafeHtml {
    if (user[0]) {
      if (user[0].id) {
          return this.sanitizer.bypassSecurityTrustHtml(
            `<a id="${user[0].id}" class="action-icon edit"> <i class="mdi mdi-square-edit-outline"></i></a>
            <a id="${user[0].id}" class="action-icon delete"> <i class="mdi mdi-delete"></i></a>`
          )
      }
    }
  }

  numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  // compares two cell valueuser?: PetroleumProductCategory;
  user_complete?: boolean;
  compare(v1: string | number | boolean, v2: string | number | boolean): number {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  /**
   * Sort the table data
   * @param event column name,sort direction
   */
  sort(event: SortEvent): void {
    if (event.direction === '') {
      this.fuelCategoryArray = this.defaultPetroleumProductCategoryArray;
      //this.getPetroleumProductCategoryList();
    } else {
      this.fuelCategoryArray = [...this.fuelCategoryArray].sort((a, b) => {
        const res = this.compare(a["0"][event.column], b["0"][event.column]);
        return event.direction === 'asc' ? res : -res;
      });
    }
  }

  /**
 * Table Data Match with Search input
 * @param tableRow Table row
 * @param term Search the value
 */
  matches(tableRow: PetroleumProductCategory, term: string) {
    return tableRow[0].name?.toLowerCase().includes(term)
      || tableRow[0].description?.toLowerCase().includes(term);
  }

  /**
   * Search Method
  */
  searchData(searchTerm: string): void {
    if (searchTerm === '') {
      // this.getPetroleumProductCategoryList();
      this.fuelCategoryArray = this.defaultPetroleumProductCategoryArray;
    }
    else {
      let updatedData = this.fuelCategoryArray = this.defaultPetroleumProductCategoryArray;
      //  filter
      updatedData = updatedData.filter(user => this.matches(user, searchTerm));
      this.fuelCategoryArray = updatedData;
    }

  }

  hasChanged($event: any) {
    // Clear all other address details?
  }

  generateId() {
    // Get new Id
    this.subscription.add(
      this.petroleumProductsService.getPetroleumProductCategoryId().pipe(first()).subscribe((result) => {
        if (result) {
          this.newInviteId = result;
          this.productForm.get("id").setValue(result);
        }
      })
    );
  }

  createRecord() {
    // Show new tool form
    this.showForm = true;

    // Clear form
    this.productForm.reset();

    // Generate ID
    this.generateId();
  }

  editForm(id: string) {

    // Show new tool form
    this.showForm = true;

    // Clear form
    this.productForm.reset();

    //Populate Form
    if (this.fuelCategoryObject) {
      if (this.fuelCategoryObject[id]) {
        if (this.fuelCategoryObject[id][0]) {
          if (this.fuelCategoryObject[id][0].id) { this.productForm.get("id").setValue(this.fuelCategoryObject[id][0].id); };
          if (this.fuelCategoryObject[id][0].index) { this.productForm.get("index").setValue(this.fuelCategoryObject[id][0].index); };
          if (this.fuelCategoryObject[id][0].name) { this.productForm.get("name").setValue(this.fuelCategoryObject[id][0].name); };
          if (this.fuelCategoryObject[id][0].description) { this.productForm.get("description").setValue(this.fuelCategoryObject[id][0].description); };
          if (this.fuelCategoryObject[id][0].created) { this.productForm.get("created").setValue(this.fuelCategoryObject[id][0].created); };
          
        }
      }
    }
  }

  cancelUpdate() {
    // Show new tool form
    this.showForm = false;

    // Clear form
    this.productForm.reset();

  }


  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}


