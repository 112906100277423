import { Component, ElementRef, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { NgbAlertModule, NgbProgressbarModule, NgbButtonsModule, NgbAlert, NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AdvancedTableModule } from 'src/app/shared/advanced-table/advanced-table.module';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { Subscription, first } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { ClockService } from 'src/app/core/service/clock.service';
import { UserService } from 'src/app/core/service/user.service';
import { Column, AdvancedTableComponent } from 'src/app/shared/advanced-table/advanced-table.component';
import { SortEvent } from 'src/app/shared/advanced-table/sortable.directive';
import { BreadcrumbItem } from 'src/app/shared/page-title/page-title.model';
import { AppUser } from 'src/app/core/models-two/AppUser';
import { FileStorageService } from 'src/app/core/service/file-storage.service';
import { ProjectService } from 'src/app/core/service/project.service';
import { CompanyRole } from 'src/app/core/models-two/CompanyRole';
import { UserGuide } from 'src/app/core/models-two/UserGuide';

@Component({
  selector: 'app-user-guide',
  standalone: true,
  imports: [
    CommonModule,
    AdvancedTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbProgressbarModule,
    WidgetModule,
    NgbButtonsModule,
    NgbTooltipModule,
  ],
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss']
})
export class UserGuideComponent implements OnInit, OnDestroy {

  userGuideForm!: FormGroup;
  subscription: Subscription = new Subscription();
  today: number = Date.now();
  pageTitle: BreadcrumbItem[] = [];
  //userGuideArray!: Client[];
  pageSizeOptions: number[] = [5, 10, 20, 50];
  pageSize: number = 5;
  selectAll: boolean = false;
  loading: boolean = false;
  columns: Column[] = [];
  @ViewChild('profileModal', { static: false }) private profileModal: any;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  @ViewChild('advancedTable') advancedTable!: AdvancedTableComponent;

  formSubmitted: boolean = false;

  show_alert: boolean;
  alert_message: string;
  alert_type: string;

  loggedInUser: AppUser;
  userGuideObject: UserGuide;
  userGuideArray: UserGuide[];
  userId: any;

  destinationLatitude: number;
  destinationLongitude: number;
  destinationZoom: number;
  googleLocationAddressLong: string;
  googleLocationAddressShort: any;
  gif: any;
  invalidPhoneNumber: boolean;
  pickedImage: any;

  error: string;
  showForm: boolean = false;
  userCategoryViewChoice: number = 1;
  dateNow2: any;
  dateNow3: Date;
  newInviteId: any;
  defaultUserGuideArray: any[] = [];
  defaultUserObject: any;
  showImportPanel: boolean = false;
  disableDownloadButton: boolean = false;
  workingUserGuideArray: UserGuide[] = [];
  updateInProgress: boolean = false;
  showPassword: boolean = false;
  uploadProgress$: any;
  alphabeticalUserGuideArray: UserGuide[];
  pickedUserGuideIndex: string;
  userGuideUpdateType: string = "new";

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private scroller: ViewportScroller,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private fileStorage: FileStorageService,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private ngZone: NgZone,
    private clockService: ClockService,
  ) {
    this.loadingAnimationFourSeconds();
    this.subscription.add(
      authenticationService.currentUser.pipe().subscribe((res: any) => {
        if (res) {
          if (res[0]) {
            this.loggedInUser = res[0];
            if (res[0].userId) {
              this.userId = res[0].userId;
            }
          } else {
          }
        }
      })
    );

  }

  resetUserGuideFormValue() {
    this.userGuideForm.get("title").setValue("");
    this.userGuideForm.get("description").setValue("");
    this.userGuideForm.get("picture").setValue("");
    this.userGuideForm.get("gif").setValue("");
    this.userGuideForm.get("level").setValue("");
    this.userGuideForm.get("number").setValue("");
    this.userGuideForm.get("chapterNumber").setValue("");
    this.userGuideForm.get("created").setValue("");

    this.userGuideForm.get("title").markAsUntouched();
    this.userGuideForm.get("description").markAsUntouched();
    this.userGuideForm.get("picture").markAsUntouched();
    this.userGuideForm.get("gif").markAsUntouched();
    this.userGuideForm.get("level").markAsUntouched();
    this.userGuideForm.get("number").markAsUntouched();
    this.userGuideForm.get("chapterNumber").markAsUntouched();
    this.userGuideForm.get("created").markAsUntouched();

    this.formSubmitted = false;
  }

  ngOnInit(): void {

    this.userGuideForm = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      picture: [''],
      gif: [''],
      level: [''],
      number: [''],
      chapterNumber: ['', Validators.required],
      created: [''],
    });

    // get user data
    this.getUserGuideList();
    // initialize advance table 
    this.initAdvancedTableData();

  }


  /**
   * convenience getter for easy access to form fields
   */
  get formValues() {
    return this.userGuideForm.controls;
  }


  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  loadingAnimationStart() {
    this.loading = true;
  }

  loadingAnimationStop() {
    this.loading = false;
  }

  getUserGuideList() {
    //this.petroleumProductArray = users;
    this.subscription.add(
      this.projectService.getUsersGuideArray().pipe().subscribe((userGuideList: UserGuide[]) => {
        if (userGuideList) {
          this.workingUserGuideArray = [];
          this.defaultUserGuideArray = this.userGuideArray = userGuideList;

          // Arranged Alphabetically
          userGuideList.forEach(element => {
            this.workingUserGuideArray.push(element[0]);
          });

          this.alphabeticalUserGuideArray = this.workingUserGuideArray.sort((a: any, b: any) => (a.firstName).localeCompare(b.firstName));

        }
      })
    );

    this.subscription.add(
      this.projectService.getUsersGuideObject().pipe().subscribe((userGuideList: UserGuide) => {
        if (userGuideList) {
          this.defaultUserObject = this.userGuideObject = userGuideList;
        }
      })
    );
  }

  /**
   * initialize advance table columns
   */
  initAdvancedTableData(): void {
    this.columns = [
      {
        name: 'title',
        label: 'Title',
        formatter: (p: UserGuide) => (`${p.title}`),
      },
      {
        name: 'chapterNumber',
        label: 'Chapter',
        formatter: (p: UserGuide) => (`${p.chapterNumber}`),
      },
      {
        name: 'quick link',
        label: 'Quick Links',
        formatter: this.userActionFormatter.bind(this),
        sort: false
      }
    ]
  }

  /**
   * handles operations that need to be performed after loading table
   */
  handleTableLoad(): void {
    // user cell
    document.querySelectorAll('.edit').forEach((e) => {
      e.addEventListener("click", () => {
        //Navigate to report page

        // Load edit Form
        // Show Edit Form
        this.loadEditForm(e.id);

      });
    })

    document.querySelectorAll('.delete').forEach((e) => {
      e.addEventListener("click", () => {
        if (confirm("Are you sure you want to delete this project?")) {
          // Move item to petroleumProduct/delete
          this.deleteUserGuide(Number(e.id));
        }
      });
    })
  }


  deleteUserGuide(index: number) {

    let userGuideName = "";
    if (this.defaultUserObject) {
      if (this.defaultUserObject[index]) {
        if (this.defaultUserObject[index].name) {
          userGuideName = this.defaultUserObject[index].name;
        };
      };
    };

    this.subscription.add(
      this.projectService.updateUserGuide("delete", (index.toString()), "", this.userId)
        .pipe(first()).subscribe((result) => {

          //Get User Data
          this.show_alert = true;
          this.alert_message = `${userGuideName} Deleted!`;
          this.alert_type = "success";

          this.toastr.success(`${userGuideName} Deleted!`, "Info!");

          setTimeout(() => {
            this.show_alert = false;
            this.selfClosingAlert.close();
          }, 3000);

        },
          (error: string) => {
            this.toastr.error(`${userGuideName} was not Deleted, error: ${error}!`, "Info!");
            this.error = error;
            this.loading = false;
          }));
  }



  // table action cell formatter
  userActionFormatter(a: UserGuide): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<button id="${a.id}" class="btn btn-sm btn-outline-primary edit" type="button" *ngIf="!updateInProgress"> <i class=""></i>View/Edit</button>
            <button id="${a.id}" class="btn btn-sm btn-outline-danger delete" type="button" *ngIf="!updateInProgress"> <i class=""></i>X</button>`
    );

  }

  numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  // compares two cell valueuser?: User;
  user_complete?: boolean;
  compare(v1: string | number | boolean, v2: string | number | boolean): number {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  /**
   * Sort the table data
   * @param event column name,sort direction
   */
  sort(event: SortEvent): void {
    if (event.direction === '') {
      this.userGuideArray = this.defaultUserGuideArray;
      //this.getUserList();
    } else {
      this.userGuideArray = [...this.userGuideArray].sort((a, b) => {
        const res = this.compare(a[0][event.column], b[0][event.column]);
        return event.direction === 'asc' ? res : -res;
      });
    }
  }

  /**
 * Table Data Match with Search input
 * @param tableRow Table row
 * @param term Search the value
 */
  matches(tableRow: UserGuide, term: string) {
    return tableRow.title?.toLowerCase().includes(term)
      || tableRow.description?.toLowerCase().includes(term);
  }

  /**
   * Search Method
  */
  searchData(searchTerm: string): void {
    if (searchTerm === '') {
      // this.getUserList();
      this.userGuideArray = this.defaultUserGuideArray;
    }
    else {
      let updatedData = this.userGuideArray = this.defaultUserGuideArray;
      //  filter
      updatedData = updatedData.filter(a => this.matches(a, searchTerm));
      this.userGuideArray = updatedData;
    }
  }

  hasChanged($event: any) {
    // Clear all other address details?
  }

  createRecord() {
    this.resetUserGuideFormValue();
    this.userGuideUpdateType = "new";

    // Scroll to Form
    this.scrollToElement("userGuideFormEdit");

  }

  loadEditForm(id: string) {

    // Picked User Id
    this.pickedUserGuideIndex = id;

    // Clear form
    this.resetUserGuideFormValue();
    this.userGuideUpdateType = "update";

    // Reset Picture
    this.gif = "";

    if (this.userGuideObject) {
      if (this.userGuideObject[id]) {
        if (this.userGuideObject[id]) {
          if (this.userGuideObject[id].title) { this.userGuideForm.get("title").setValue(this.userGuideObject[id].title); };
          if (this.userGuideObject[id].description) { this.userGuideForm.get("description").setValue(this.userGuideObject[id].description); };
          if (this.userGuideObject[id].chapterNumber) { this.userGuideForm.get("chapterNumber").setValue(this.userGuideObject[id].chapterNumber); };
          if (this.userGuideObject[id].level) { this.userGuideForm.get("level").setValue(this.userGuideObject[id].level); };
          if (this.userGuideObject[id].number) {
            this.userGuideForm.get("number").setValue(this.userGuideObject[id].number);
          };
          if ((this.userGuideObject[id].number) === 0) {
            this.userGuideForm.get("number").setValue(0);
          };
          if (this.userGuideObject[id].created) { this.userGuideForm.get("created").setValue(this.userGuideObject[id].created); };
          if (this.userGuideObject[id]) {
            if (this.userGuideObject[id].gif) {
              if (this.userGuideObject[id].gif[0]) {
                this.userGuideForm.get("gif").setValue(this.userGuideObject[id].gif[0]);
                if (this.userGuideObject[id].gif[0].url) {
                  this.gif = this.userGuideObject[id].gif[0].url;
                }
              }
            }
          };
        }
      }
    }

    // Scroll to Form
    this.scrollToElement("userGuideFormEdit");
  }

  /**
* Upload Profile Picture
*/

  loadFiles(event) {
    this.updateInProgress = true;
    let fileCategory = "userGuide";
    let height: number;
    let width: number;
    let fileName = event.target["files"][0].name;
    let fileType = event.target["files"][0].type;
    let fileSize = event.target["files"][0].size;
    let imageId = fileCategory + (Date.now().toString().substr(0, 8) + '-' + Math.random().toString(36).substr(2, 5) + '-' + Math.random().toString(36).substr(2, 5)).toUpperCase();

    function formatBytes(bytes: number): string {
      const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const factor = 1024;
      let index = 0;

      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }

      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }

    let fileSize2 = formatBytes(fileSize);

    let path = `${fileCategory}/${fileName}`;

    this.subscription.add(this.userService.uploadProfileImage(event, path).subscribe((result) => {
      const { downloadUrl$, uploadProgress$ } = result;
      this.uploadProgress$ = uploadProgress$;

      const reader = new FileReader();
      reader.readAsDataURL(event.target["files"][0]);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          height = img.naturalHeight;
          width = img.naturalWidth;
        };
      };
      if (downloadUrl$ && this.userId && width && height) {
        this.fileStorage.saveProfileImageRecord(imageId, fileCategory, this.userId, downloadUrl$, fileName, fileSize2, fileType, height, width).then(() => {
          this.toastr.success("Upload successful", "Info!");
          this.updateInProgress = false;
        });
        this.userGuideForm.get("gif").setValue({
          "url": downloadUrl$,
          "name": fileName,
          "size": fileSize2,
          "formatType": fileType,
          "imageId": imageId,
          "uploadBy": this.userId,
          "width": width,
          "height": height,
          "date": new Date().getTime()
        });
        this.gif = downloadUrl$;
      }
    }));
  }

  /**
 * On form submit
 */

  onSubmit(userForm: any): void {
    this.updateInProgress = true;

    // Checks
    if (this.userGuideForm.controls['title'].invalid) {
      this.show_alert = true;
      this.alert_message = "Title is missing!";
      this.alert_type = "danger";

      this.toastr.error('Title is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.userGuideForm.controls['description'].invalid) {
      this.show_alert = true;
      this.alert_message = "Description is missing!";
      this.alert_type = "danger";

      this.toastr.error('Description is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.userGuideForm.controls['chapterNumber'].invalid) {
      this.show_alert = true;
      this.alert_message = "Chapter number is missing!";
      this.alert_type = "danger";

      this.toastr.error('Chapter number is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    if (this.pickedUserGuideIndex && this.userId && this.userGuideUpdateType) {
      this.subscription.add(
        this.projectService.updateUserGuide(this.userGuideUpdateType, this.pickedUserGuideIndex, userForm, this.userId)
          .pipe(first()).subscribe((result) => {
            // navigates to confirm mail screen
            // this.router.navigate(['/account/confirm-email']);

            // Navigate to Return URL

            this.formSubmitted = true;
            this.updateInProgress = false;
            this.userGuideUpdateType = "new";
            this.resetUserGuideFormValue();
          },
            (error: string) => {
              this.error = error;
              this.updateInProgress = false;
            }));
    }

    if (!(this.pickedUserGuideIndex) && this.userId && this.userGuideUpdateType) {
      this.subscription.add(
        this.projectService.updateUserGuide(this.userGuideUpdateType, "0", userForm, this.userId)
          .pipe(first()).subscribe((result) => {
            // navigates to confirm mail screen
            // this.router.navigate(['/account/confirm-email']);

            // Navigate to Return URL

            this.formSubmitted = true;
            this.updateInProgress = false;
            this.userGuideUpdateType = "new";
            this.resetUserGuideFormValue();
          },
            (error: string) => {
              this.error = error;
              this.updateInProgress = false;
            }));
    }

  }

  cancelUpdate() {
    // Show new tool form
    this.showForm = false;

    setTimeout(() => {
      this.scrollToElement("advancedList");
    }, 500);

    // Clear form
    this.resetUserGuideFormValue();
  }

  /**
  * convenience getter for easy access to form fields
  */
  get userGuideFormValues() { return this.userGuideForm.controls; }

  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }

  enlargeImage(content: TemplateRef<NgbModal>) {
    //Modal Large image
    if (this.gif) {
      this.pickedImage = this.gif;
      this.modalService.open(content, { size: "lg" });
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  deleteImage() {
    this.gif = "";
    this.userGuideForm.get("gif").setValue("");
  }

  cancelEdit() {
    this.resetUserGuideFormValue();
    // Scroll to List
    this.scrollToElement("advancedList");
  }

  scrollToElement(a: string) {
    // document.getElementById(a).scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start',
    //   inline: 'nearest',
    // });

    this.scroller.scrollToAnchor(a);

    setTimeout(() => {
      window.scrollBy(0, -75);
    }, 500);

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}



