import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, first } from 'rxjs/operators';

//AngularFireAuth
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import * as firebase from 'firebase/compat/app';
import { FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';

// types
import { empty, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { UserService } from './user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AppUser } from '../models-two/AppUser';
import { ToastrService } from 'ngx-toastr';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: Observable<firebase.default.User | null>;
    subscription: Subscription = new Subscription();
    returnUrl: any;
    constructor(
        private http: HttpClient,
        private userService: UserService,
        private afAuth: AngularFireAuth,
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private fns: AngularFireFunctions,
    ) {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.user = afAuth.authState;
    };

    get authStatus(): Observable<any> {
        return this.afAuth.authState;
    };

    /**
     * Returns the email verification state
     */

    get emailVerificationState() {
        return this.user
            .pipe(switchMap((user: any) => {

                if (user) {
                    return user.emailVerified;
                } else {
                    //Navigate to signup
                    return false;
                }
            }));
    };

    verifyEmail() {
        return this.user.pipe().subscribe((user) => {
            return user.sendEmailVerification();
        });
    };

    /**
     * Returns the current user
     */

    get currentUser(): Observable<AppUser | null> {
        return this.user
            .pipe(switchMap(user => {

                if (user) {
                    return this.userService.getUser(user.uid).valueChanges();
                } else {
                    //Navigate to signup
                    return empty();
                }
            }));
    };

    /**
     * Logout the user
     */
    logout(): void {
        // remove user from session storage to log user out
        this.afAuth.signOut();
        sessionStorage.removeItem('currentUser');
        this.user == null;
        //navigate to return url
        localStorage.getItem('returnUrl');

        //this.router.navigate(['/account/login']);
    };


    // Sign in with email/password
    signInWithEmailAndPassword(email: string, password: string, returnUrl: string) {
        return this.afAuth
            .signInWithEmailAndPassword(email, password)
            .then((result) => {

                console.log("signInWithEmailAndPassword - result:", result);

                // Save Data ????
                // this.setUserData(result.user);

                this.afAuth.authState.subscribe((user) => {
                    if (user) {

                        this.router.navigate([returnUrl]);

                        return "Success";
                    }
                });
            })
            .catch((error) => {

                // window.alert(error.message);
                // Send to page account/login error = error.message
                this.toastr.error("Invalid Username & Password!" + " " + error.message, "Error!");
                this.router.navigate(['account/login'], { queryParams: { returnUrl: returnUrl } });
            });
    }

    // Sign up with email/password
    signUp(
        firstName: string,
        otherName: string,
        surname: string,
        email: string,
        password: string,
        phoneNumber: string,
        profilePicture: any,
        returnUrl: string,
    ) {
        return this.afAuth
            .createUserWithEmailAndPassword(email, password)
            .then((result) => {

                // Email Verification
                this.sendVerificationMail(email,result.user.uid);

                // Save Data
                this.setUserData({
                    email: email ? email : "",
                    password: password ? password : "",
                    firstName: firstName ? firstName : "",
                    otherName: otherName ? otherName : "",
                    surname: surname ? surname : "",
                    phoneNumber: phoneNumber ? phoneNumber : "",
                    uid: result.user.uid ? result.user.uid : "",
                    profilePicture: profilePicture ? profilePicture : {},
                    createDate: (new Date(result.user.metadata.creationTime)[Symbol.toPrimitive]('number')) ? (new Date(result.user.metadata.creationTime)[Symbol.toPrimitive]('number')) : 0,
                    lastSignInDate: (new Date(result.user.metadata.lastSignInTime)[Symbol.toPrimitive]('number')) ? (new Date(result.user.metadata.lastSignInTime)[Symbol.toPrimitive]('number')) : 0,
                    role: "New User",
                });

                this.router.navigate([returnUrl]);
            })
            .catch((error) => {
                window.alert(error.message);

                this.toastr.error("Create User Failed!" + " " + error.message, "Error!");
                // this.router.navigate(['account/login'], { queryParams: { returnUrl: returnUrl} });
            });
    }

    // Send email verfificaiton when new user sign up
    sendVerificationMail(email:string,uid:string) {
        var actionCodeSettings = {
            url: `https://www.financialforecast.cloud/email?email=${email}&uid=${uid}`,
            handleCodeInApp: true
          };

        return this.afAuth.currentUser
            .then((u: any) => u.sendEmailVerification(actionCodeSettings))
            .then(() => {

                // Save Data
                this.setUserData({
                    emailVerificationSent: true,
                });

            });
    }

    // Reset Forggot password
    forgotPassword(passwordResetEmail: string) {
        return this.afAuth
            .sendPasswordResetEmail(passwordResetEmail)
            .then(() => {

                // Save Data via backend function
                let data = {
                    passwordReset: {
                        date: "",
                        ip: "",
                    },
                };

                // Toast
                window.alert('Password reset email sent, check your inbox.');
                this.logout();
                this.router.navigate(['account/login'], { queryParams: { m: "Password reset successful!", r: "password reset" } });
            })
            .catch((error) => {
                window.alert(error);

                this.router.navigate(['account/login'], { queryParams: { m: error.message, r: "password reset" } });
            });
    }

    /* Setting up user data when sign in with username/password, 
    sign up with username/password and sign in with social auth  
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
    setUserData(user: any) {
        if (user.uid) {
            this.userService.initAuthProfileSave(user);
            console.log("initAuthProfileSave: ");
        }
    }

    // Sign out
    signOut() {
        return this.afAuth.signOut().then(() => {
            localStorage.removeItem('user');

            // Save Data via backend function
            let data = {
                signOut: {
                    date: "",
                    ip: "",
                },
            };

            // Navigate Home
            this.router.navigate(['account/signout']);
        });
    }



}

