import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
//import { NgApexchartsModule } from 'ng-apexcharts';
import { SimplebarAngularModule } from 'simplebar-angular';

// components
import { PreloaderComponent } from './preloader/preloader.component';
import { CardTitleComponent } from './card-title/card-title.component';
import { FaqComponent } from './faq/faq.component';

@NgModule({
  declarations: [
    PreloaderComponent,
    CardTitleComponent,
    FaqComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbDropdownModule,
    SimplebarAngularModule,
    //NgApexchartsModule
  ],
  exports: [
    PreloaderComponent,
    CardTitleComponent,
    FaqComponent
  ],
})
export class WidgetModule { }
