import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';

import { User } from '../models/User';
import { FcmMessage } from '../models/FcmMessage';
import { AuthenticationService } from './auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnInit, OnDestroy {

  currentMessage = new BehaviorSubject(null);
  subscription: Subscription = new Subscription();
  options: { autoClose: boolean; keepAfterRouteChange: boolean; };
  subscription2: any;
  appUser: any;

  constructor(
    private fcm: AngularFireMessaging,
    private auth: AuthenticationService,
    private db: AngularFireDatabase,
    private toastr: ToastrService
    ) {

    this.options = {
      autoClose: true,
      keepAfterRouteChange: true
    };
    this.subscription.add(
      this.fcm.messages.subscribe(
        (messaging) => {
        }
      ))
    this.subscription.add(
      this.auth.currentUser.subscribe(appUser =>
        this.appUser = appUser))

  }
  ngOnInit(): void {

  }

  requestPermission() {
    this.subscription.add(
      this.fcm.requestToken.subscribe(
        (token) =>

        //update token to database,
        {
          if (this.appUser) {
            this.db.object(`/fcm-tokens/${this.appUser.uid}`)
              .update({
                clientToken: true,
                phone: this.appUser.phoneNumber,
                serverToken: false,
                shipperToken: false,
                token: token
              })
              .then(() => {
                return;
              })
              .catch((err) => {
                return err;
              })
          };
        },
        error => {
        }
      ))
  }

  listen() {
    //Sort Issue

    this.subscription.add(
      this.fcm.messages.subscribe(
        (message) => {

          // this.notificationAlertService.alert({
          //   id: "default-alert",
          //   type: AlertType.Success,
          //   title: message.notification.title,
          //   message: message.notification.body,
          //   autoClose: true,
          //   keepAfterRouteChange: true,
          //   fade: false
          // });

          this.toastr.info(message.notification.body,message.notification.title);
          this.currentMessage.next(message);
        }
      )
    )
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
