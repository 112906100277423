<div class="card bg-transparent">
    <app-ui-preloader [display]="loading"></app-ui-preloader>
    <div class="card">
        <div class="card-header">
            <div class="row flex-grow-1">
                <div class="col">
                    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                        <h1 class="display-4">Country</h1>
                        <p class="lead">List of country information available.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Logo -->
        <div class="card-body">
            <div class="row mb-2">
                <div class="col-sm-5">
                    <a (click)="createRecord()" class="btn btn-primary mb-2 me-1"><i
                            class="mdi mdi-plus-circle me-2"></i>
                        Add Country</a>
                </div>
                <div class="col-sm-7">
                    <div class="text-sm-end">
                    </div>
                </div><!-- end col-->
            </div>
            <!-- end row -->
            <!-- <app-ui-preloader [display]="loading"></app-ui-preloader> -->

            <!-- user table -->
            <app-advanced-table #advancedTable (handleTableLoad)="handleTableLoad()" [tableData]="countryArray"
                [columns]="columns" [pagination]="true" [isSearchable]="true" [pageSizeOptions]="pageSizeOptions"
                (search)="searchData($event)" [hasItemNumbering]="true" [isSortable]="true" (sort)="sort($event)"
                tableClasses="table-centered nowrap w-100" theadClasses="table-light">
            </app-advanced-table>
        </div> <!-- end card-body-->
    </div>
</div>

<div class="card bg-transparent" *ngIf="showForm">
    <div class="card-header">
        <!-- title-->
        <div class="row flex-grow-1">
            <div class="col">
                <div class="pricing-header px-3 py-3 pt-md-3 pb-md-2 mx-auto text-center">
                    <h1 class="display-6">Country</h1>
                    <p class="lead text-muted">Fill in the country details.</p>
                </div>
            </div>
        </div>

    </div>
    <div class="card-body">

        <div class="row" name="project-settings-edit-form" [formGroup]="countryForm">
            <div class="col-12 col-lg-6">
                <div class="table-responsive-sm">
                    <table class="table table-sm table-centered table-borderless mb-0">
                        <tbody>
                            <tr>
                                <td class="p-0">
                                    Country Name
                                </td>
                                <td class="p-0">
                                    <select class="form-select form-select-sm" name="id" formControlName="id"
                                        id="id" required
                                        [ngClass]="{'is-invalid': (formSubmitted && countryFormValues['id'].invalid)||(countryFormValues['id'].touched && countryFormValues['id'].invalid)}">
                                        <option *ngFor="let m of countryArray" [value]="m[0].id">
                                            {{m[0].name}}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0">
                                    USD Fx Rate
                                </td>
                                <td class="p-0">
                                    <div class="input-group input-group-sm input-group-merge">
                                        <input class="form-control form-control-sm " type="number"
                                            id="usdFxRate" formControlName="usdFxRate"
                                            [ngClass]="{'is-invalid': (formSubmitted && countryFormValues['usdFxRate'].invalid)||(countryFormValues['usdFxRate'].touched && countryFormValues['usdFxRate'].invalid)}">
                                        <div class="input-group-text">
                                            <span>KES/USD</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0">
                                    Tax Carry Over Allowed For
                                </td>
                                <td class="p-0">
                                    <div class="input-group input-group-sm input-group-merge">
                                        <input class="form-control form-control-sm " type="number"
                                            id="taxCarryOverPeriod" formControlName="taxCarryOverPeriod"
                                            [ngClass]="{'is-invalid': (formSubmitted && countryFormValues['taxCarryOverPeriod'].invalid)||(countryFormValues['taxCarryOverPeriod'].touched && countryFormValues['taxCarryOverPeriod'].invalid)}">
                                        <div class="input-group-text">
                                            <span>Years</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            

                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="table-responsive-sm">
                    <table class="table table-sm table-centered table-borderless mb-0">
                        <tbody>
                            <tr>
                                <td class="p-0">
                                    Inflation
                                </td>
                                <td class="p-0">
                                    <div class="input-group input-group-sm input-group-merge">
                                        <input class="form-control form-control-sm " type="number"
                                            id="inflation" formControlName="inflation"
                                            [ngClass]="{'is-invalid': (formSubmitted && countryFormValues['inflation'].invalid)||(countryFormValues['inflation'].touched && countryFormValues['inflation'].invalid)}">
                                        <div class="input-group-text">
                                            <span>%</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0">
                                    Interest Rate
                                </td>
                                <td class="p-0">
                                    <div class="input-group input-group-sm input-group-merge">
                                        <input class="form-control form-control-sm " type="number"
                                            id="interestRate" formControlName="interestRate"
                                            [ngClass]="{'is-invalid': (formSubmitted && countryFormValues['interestRate'].invalid)||(countryFormValues['interestRate'].touched && countryFormValues['interestRate'].invalid)}">
                                        <div class="input-group-text">
                                            <span>%</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0">
                                    Network Channel Fixed Costs
                                </td>
                                <td class="p-0">
                                    <div class="input-group input-group-sm input-group-merge">
                                        <input class="form-control form-control-sm" type="number"
                                            id="networkChannelFixedCosts"
                                            formControlName="networkChannelFixedCosts"
                                            [ngClass]="{'is-invalid': (formSubmitted && countryFormValues['networkChannelFixedCosts'].invalid)||(countryFormValues['networkChannelFixedCosts'].touched && countryFormValues['networkChannelFixedCosts'].invalid)}">
                                        <div class="input-group-text">
                                            <span>KES</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0">
                                    Discounted Rate For Net Present Value (NPV)
                                </td>
                                <td class="p-0">
                                    <div class="input-group input-group-sm input-group-merge">
                                        <input class="form-control form-control-sm" type="number"
                                            id="discountRateForNetPresentValue"
                                            formControlName="discountRateForNetPresentValue"
                                            [ngClass]="{'is-invalid': (formSubmitted && countryFormValues['discountRateForNetPresentValue'].invalid)||(countryFormValues['discountRateForNetPresentValue'].touched && countryFormValues['discountRateForNetPresentValue'].invalid)}">
                                        <div class="input-group-text">
                                            <span>%</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            
                            <tr>
                                <td class="p-0">
                                    Corporate Tax Rate
                                </td>
                                <td class="p-0">
                                    <div class="input-group input-group-sm input-group-merge">
                                        <input class="form-control form-control-sm" type="number"
                                            id="corporateTaxRate" formControlName="corporateTaxRate"
                                            [ngClass]="{'is-invalid': (formSubmitted && countryFormValues['corporateTaxRate'].invalid)||(countryFormValues['corporateTaxRate'].touched && countryFormValues['corporateTaxRate'].invalid)}">
                                        <div class="input-group-text">
                                            <span>%</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-12">
                <div class="d-grid">
                    <button class="btn btn-sm btn-outline-primary"
                        (click)="updateCountryDetails(countryForm.value)" type="button"
                        *ngIf="!updateInProgress">
                        <i class=""></i>Update</button>
                    <button class="btn btn-sm btn-outline-primary" type="button" disabled
                        *ngIf="updateInProgress">
                        <span class="spinner-border spinner-border-sm me-1" role="status"
                            aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card bg-transparent" *ngIf="showForm">

    <div class="card-body">
        <div class="row">
            <div class="card">
                <div class="card-header text-center mb-0">
                    <span>
                        Edit Product
                    </span>
                </div>
                <div class="card-body">

                    <form class="form-horizontal" name="product-form" [formGroup]="productForm">
                        <div class="row">
                            <div class="col-12">
                                <div class="row mb-0">
                                    <label for="productId" class="col-1 col-form-label col-form-label-sm">
                                        <span><span class="text-danger"></span></span>
                                    </label>
                                    <label for="productId" class="col-5 col-form-label col-form-label-sm">
                                        <span>Product<span class="text-danger"> *</span></span>
                                    </label>
                                    <div class="col-6">
                                        <select class="form-select form-select-sm" name="productId"
                                            formControlName="productId" id="productId" required
                                            [ngClass]="{'is-invalid': (formSubmitted && productFormValues['productId'].invalid)||(productFormValues['productId'].touched && productFormValues['productId'].invalid)}">
                                            <option *ngFor="let m of petroleumProductArray" [value]="m[0].id">
                                                {{m[0].name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div *ngIf="(formSubmitted && productFormValues['productId'].invalid)||(productFormValues['productId'].touched && productFormValues['productId'].invalid)"
                                        class="invalid-tooltip">
                                        <div *ngIf="productFormValues['productId'].errors?.['required']">
                                            Product is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label for="productCategory" class="col-1 col-form-label col-form-label-sm">
                                        <span><span class="text-danger"></span></span>
                                    </label>
                                    <label for="productCategory" class="col-5 col-form-label col-form-label-sm">
                                        <span>Category<span class="text-danger"></span></span>
                                    </label>
                                    <div class="col-6">
                                        <input class="form-control form-control-sm form-control-plaintext"
                                            type="text" id="productCategory" placeholder="Fuel" readonly
                                            formControlName="productCategory">
                                    </div>
                                    <div *ngIf="(formSubmitted && productFormValues['productCategory'].invalid)||(productFormValues['productCategory'].touched && productFormValues['productCategory'].invalid)"
                                        class="invalid-tooltip">
                                        <div *ngIf="productFormValues['productCategory'].errors?.['required']">
                                            Product Category is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <form class="form-horizontal" name="fuel-product-form" [formGroup]="fuelProductForm">

                        <div *ngIf="showPetroleumProductForm && (pickedPetroleumProductCategory === 'Fuel')">

                            <div class="row">
                                <div class="col-12">

                                    <div class="row mb-0">
                                        <label for="networkGlobalPrice"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span>i.</span>
                                        </label>
                                        <label for="networkGlobalPrice"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Global Price<span class="text-danger">
                                                    *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="1" id="networkGlobalPrice" placeholder="12.39"
                                                    formControlName="networkGlobalPrice">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/L
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && fuelProductFormValues['networkGlobalPrice'].invalid)||(fuelProductFormValues['networkGlobalPrice'].touched && fuelProductFormValues['networkGlobalPrice'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="fuelProductFormValues['networkGlobalPrice'].errors?.['required']">
                                                Global Price is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="networkEPRAMargin"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span></span>
                                        </label>
                                        <label for="networkEPRAMargin"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>EPRA Margin<span class="text-danger"></span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input
                                                    class="form-control form-control-sm form-control-plaintext"
                                                    type="number" min="1" id="networkEPRAMargin"
                                                    placeholder="8.25" readonly
                                                    formControlName="networkEPRAMargin">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/L
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && fuelProductFormValues['networkEPRAMargin'].invalid)||(fuelProductFormValues['networkEPRAMargin'].touched && fuelProductFormValues['networkEPRAMargin'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="fuelProductFormValues['networkEPRAMargin'].errors?.['required']">
                                                EPRA Margin is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="networkDODOMargin"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span>ii.</span>
                                        </label>
                                        <label for="networkDODOMargin"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>DODO Margin<span class="text-danger"> *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="1" id="networkDODOMargin" placeholder="12.39"
                                                    formControlName="networkDODOMargin">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/L
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && fuelProductFormValues['networkDODOMargin'].invalid)||(fuelProductFormValues['networkDODOMargin'].touched && fuelProductFormValues['networkDODOMargin'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="fuelProductFormValues['networkDODOMargin'].errors?.['required']">
                                                DODO Margin is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="networkDealerMarginL"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span>iii.</span>
                                        </label>
                                        <label for="networkDealerMarginL"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Dealer Margin<span class="text-danger"> *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="1" id="networkDealerMarginL" placeholder="12.39"
                                                    formControlName="networkDealerMarginL">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/L
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && fuelProductFormValues['networkDealerMarginL'].invalid)||(fuelProductFormValues['networkDealerMarginL'].touched && fuelProductFormValues['networkDealerMarginL'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="fuelProductFormValues['networkDealerMarginL'].errors?.['required']">
                                                Dealer Margin is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="networkDiscountGiven"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span>iv.</span>
                                        </label>
                                        <label for="networkDiscountGiven"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Discount Given<span class="text-danger"> *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input
                                                    class="form-control form-control-sm form-control-plaintext"
                                                    type="number" min="1" id="networkDiscountGiven"
                                                    placeholder="12.39" readonly
                                                    formControlName="networkDiscountGiven">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/L
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && fuelProductFormValues['networkDiscountGiven'].invalid)||(fuelProductFormValues['networkDiscountGiven'].touched && fuelProductFormValues['networkDiscountGiven'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="fuelProductFormValues['networkDiscountGiven'].errors?.['required']">
                                                Discount Given is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="networkDepotCost"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span>v.</span>
                                        </label>
                                        <label for="networkDepotCost"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Depot Cost<span class="text-danger"> *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="0" id="networkDepotCost" placeholder="12.39"
                                                    formControlName="networkDepotCost">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/L
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && fuelProductFormValues['networkDepotCost'].invalid)||(fuelProductFormValues['networkDepotCost'].touched && fuelProductFormValues['networkDepotCost'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="fuelProductFormValues['networkDepotCost'].errors?.['required']">
                                                Depot Cost is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="networkGalanaPro"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span>vi.</span>
                                        </label>
                                        <label for="networkGalanaPro"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Galana Pro<span class="text-danger"> *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="0" id="networkGalanaPro" placeholder="12.39"
                                                    formControlName="networkGalanaPro">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/L
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && fuelProductFormValues['networkGalanaPro'].invalid)||(fuelProductFormValues['networkGalanaPro'].touched && fuelProductFormValues['networkGalanaPro'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="fuelProductFormValues['networkGalanaPro'].errors?.['required']">
                                                Galana Pro is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="networkVarex"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span>vii.</span>
                                        </label>
                                        <label for="networkVarex"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Varex<span class="text-danger"> *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="0" id="networkVarex" placeholder="12.39"
                                                    formControlName="networkVarex">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/L
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && fuelProductFormValues['networkVarex'].invalid)||(fuelProductFormValues['networkVarex'].touched && fuelProductFormValues['networkVarex'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="fuelProductFormValues['networkVarex'].errors?.['required']">
                                                Varex is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="networkExpectedGMVE"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span></span>
                                        </label>
                                        <label for="networkExpectedGMVE"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Unit GMVE<span class="text-danger"></span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input
                                                    class="form-control form-control-sm form-control-plaintext"
                                                    type="number" min="1" id="networkExpectedGMVE"
                                                    placeholder="12.39" readonly
                                                    formControlName="networkExpectedGMVE">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/L
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && fuelProductFormValues['networkExpectedGMVE'].invalid)||(fuelProductFormValues['networkExpectedGMVE'].touched && fuelProductFormValues['networkExpectedGMVE'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="fuelProductFormValues['networkExpectedGMVE'].errors?.['required']">
                                                Unit GMVE is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="companyUnitMargin"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span></span>
                                        </label>
                                        <label for="companyUnitMargin"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Company Unit Margin<span class="text-danger">
                                                </span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input
                                                    class="form-control form-control-sm form-control-plaintext"
                                                    type="number" readonly min="1" id="companyUnitMargin"
                                                    placeholder="12.39" formControlName="companyUnitMargin">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && fuelProductFormValues['companyUnitMargin'].invalid)||(fuelProductFormValues['companyUnitMargin'].touched && fuelProductFormValues['companyUnitMargin'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="fuelProductFormValues['companyUnitMargin'].errors?.['required']">
                                                Company Unit Margin is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="unitSupplyPrice"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span>viii.</span>
                                        </label>
                                        <label for="unitSupplyPrice"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Unit Supply Price<span class="text-danger">
                                                    *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="1" id="unitSupplyPrice" placeholder="12.39"
                                                    formControlName="unitSupplyPrice">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && fuelProductFormValues['unitSupplyPrice'].invalid)||(fuelProductFormValues['unitSupplyPrice'].touched && fuelProductFormValues['unitSupplyPrice'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="fuelProductFormValues['unitSupplyPrice'].errors?.['required']">
                                                Unit Supply Price is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="unitaryTax" class="col-1 col-form-label col-form-label-sm">
                                            <span>ix.</span>
                                        </label>
                                        <label for="unitaryTax" class="col-5 col-form-label col-form-label-sm">
                                            <span>Unitary Tax<span class="text-danger">
                                                    *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="1" id="unitaryTax" placeholder="12.39"
                                                    formControlName="unitaryTax">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && fuelProductFormValues['unitaryTax'].invalid)||(fuelProductFormValues['unitaryTax'].touched && fuelProductFormValues['unitaryTax'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="fuelProductFormValues['unitaryTax'].errors?.['required']">
                                                Unitary Tax is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="unitRevenue" class="col-1 col-form-label col-form-label-sm">
                                            <span></span>
                                        </label>
                                        <label for="unitRevenue" class="col-5 col-form-label col-form-label-sm">
                                            <span>Unit Revenue<span class="text-danger"></span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input
                                                    class="form-control form-control-sm form-control-plaintext"
                                                    type="number" min="1" id="unitRevenue" placeholder="12.39"
                                                    readonly formControlName="unitRevenue">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && fuelProductFormValues['unitRevenue'].invalid)||(fuelProductFormValues['unitRevenue'].touched && fuelProductFormValues['unitRevenue'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="fuelProductFormValues['unitRevenue'].errors?.['required']">
                                                Unit Revenue is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="monthlyVolume"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span>x.</span>
                                        </label>
                                        <label for="monthlyVolume"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Monthly Volume<span class="text-danger"> *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="1" id="monthlyVolume" placeholder="12.39"
                                                    formControlName="monthlyVolume">
                                                <div class="input-group-text">
                                                    <span>
                                                        m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && fuelProductFormValues['monthlyVolume'].invalid)||(fuelProductFormValues['monthlyVolume'].touched && fuelProductFormValues['monthlyVolume'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="fuelProductFormValues['monthlyVolume'].errors?.['required']">
                                                Monthly Volume is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="yearlyVolume"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span></span>
                                        </label>
                                        <label for="yearlyVolume"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Yearly Volume<span class="text-danger"></span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input
                                                    class="form-control form-control-sm form-control-plaintext"
                                                    type="number" min="1" id="yearlyVolume" placeholder="12.39"
                                                    readonly formControlName="yearlyVolume">
                                                <div class="input-group-text">
                                                    <span>
                                                        m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && fuelProductFormValues['yearlyVolume'].invalid)||(fuelProductFormValues['yearlyVolume'].touched && fuelProductFormValues['yearlyVolume'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="fuelProductFormValues['yearlyVolume'].errors?.['required']">
                                                Yearly Volume is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <label for="gMVE" class="col-1 col-form-label col-form-label-sm">
                                            <span></span>
                                        </label>
                                        <label for="gMVE" class="col-5 col-form-label col-form-label-sm">
                                            <span>GMVE<span class="text-danger"></span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input
                                                    class="form-control form-control-sm form-control-plaintext"
                                                    type="number" min="1" id="gMVE" readonly
                                                    formControlName="gMVE">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && fuelProductFormValues['gMVE'].invalid)||(fuelProductFormValues['gMVE'].touched && fuelProductFormValues['gMVE'].invalid)"
                                            class="invalid-tooltip">
                                            <div *ngIf="fuelProductFormValues['gMVE'].errors?.['required']">
                                                GMVE is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="d-grid">
                                                <button class="btn btn-secondary mt-2"
                                                    (click)="hideProductMenu()" type="submit"><i
                                                        class=""></i>Cancel Edit</button>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="d-grid" *ngIf="productExists_Update">
                                                <button class="btn btn-success mt-2" *ngIf="!updateInProgress"
                                                    (click)="addProduct(fuelProductForm.value)" type="submit"><i
                                                        class=""></i>Update Product</button>
                                                <button class="btn btn-success mt-2" type="button" disabled
                                                    *ngIf="updateInProgress">
                                                    <span class="spinner-border spinner-border-sm me-1"
                                                        role="status" aria-hidden="true"></span>
                                                    Loading...
                                                </button>
                                            </div>
                                            <div class="d-grid" *ngIf="!productExists_Update">
                                                <button class="btn btn-success mt-2" *ngIf="!updateInProgress"
                                                    (click)="addProduct(fuelProductForm.value)" type="submit"><i
                                                        class=""></i>Add Product</button>
                                                <button class="btn btn-success mt-2" type="button" disabled
                                                    *ngIf="updateInProgress">
                                                    <span class="spinner-border spinner-border-sm me-1"
                                                        role="status" aria-hidden="true"></span>
                                                    Loading...
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>

                    <form class="form-horizontal" name="lubricant-product-form"
                        [formGroup]="lubricantProductForm">
                        <div
                            *ngIf="showPetroleumProductForm && (pickedPetroleumProductCategory === 'Lubricant')">

                            <div class="row">
                                <div class="col-12">

                                    <div class="row mb-0">
                                        <label for="networkDealerMarginM3"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span>i.</span>
                                        </label>
                                        <label for="networkDealerMarginM3"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Dealer Margin<span class="text-danger"> *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="1" id="networkDealerMarginM3" placeholder="40,000"
                                                    formControlName="networkDealerMarginM3">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && lubricantProductFormValues['networkDealerMarginM3'].invalid)||(lubricantProductFormValues['networkDealerMarginM3'].touched && lubricantProductFormValues['networkDealerMarginM3'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="lubricantProductFormValues['networkDealerMarginM3'].errors?.['required']">
                                                Dealer Margin is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="unitGMVE" class="col-1 col-form-label col-form-label-sm">
                                            <span>ii.</span>
                                        </label>
                                        <label for="unitGMVE" class="col-5 col-form-label col-form-label-sm">
                                            <span>Unit GMVE<span class="text-danger"> *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="1" id="unitGMVE" placeholder="166,021"
                                                    formControlName="unitGMVE">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && lubricantProductFormValues['unitGMVE'].invalid)||(lubricantProductFormValues['unitGMVE'].touched && lubricantProductFormValues['unitGMVE'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="lubricantProductFormValues['unitGMVE'].errors?.['required']">
                                                Unit GMVE is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="ompanyUnitMargin"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span></span>
                                        </label>
                                        <label for="companyUnitMargin"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Company Unit Margin<span class="text-danger"></span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input
                                                    class="form-control form-control-sm form-control-plaintext"
                                                    type="number" readonly min="1" id="companyUnitMargin"
                                                    formControlName="companyUnitMargin">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && lubricantProductFormValues['companyUnitMargin'].invalid)||(lubricantProductFormValues['companyUnitMargin'].touched && lubricantProductFormValues['companyUnitMargin'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="lubricantProductFormValues['companyUnitMargin'].errors?.['required']">
                                                Company Unit Margin is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="unitSupplyPrice"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span>iii.</span>
                                        </label>
                                        <label for="unitSupplyPrice"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Unit Supply Price<span class="text-danger"> *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="1" id="unitSupplyPrice" placeholder="175,325"
                                                    formControlName="unitSupplyPrice">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && lubricantProductFormValues['unitSupplyPrice'].invalid)||(lubricantProductFormValues['unitSupplyPrice'].touched && lubricantProductFormValues['unitSupplyPrice'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="lubricantProductFormValues['unitSupplyPrice'].errors?.['required']">
                                                Unit Supply Price is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="unitaryTax" class="col-1 col-form-label col-form-label-sm">
                                            <span>iv.</span>
                                        </label>
                                        <label for="unitaryTax" class="col-5 col-form-label col-form-label-sm">
                                            <span>Unitary Tax<span class="text-danger"> *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="1" id="unitaryTax" placeholder="21,039"
                                                    formControlName="unitaryTax">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && lubricantProductFormValues['unitaryTax'].invalid)||(lubricantProductFormValues['unitaryTax'].touched && lubricantProductFormValues['unitaryTax'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="lubricantProductFormValues['unitaryTax'].errors?.['required']">
                                                Unitary Tax is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="unitRevenue" class="col-1 col-form-label col-form-label-sm">
                                            <span></span>
                                        </label>
                                        <label for="unitRevenue" class="col-5 col-form-label col-form-label-sm">
                                            <span>Unit Revenue<span class="text-danger"></span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input
                                                    class="form-control form-control-sm form-control-plaintext"
                                                    type="number" min="1" id="unitRevenue" readonly
                                                    formControlName="unitRevenue">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && lubricantProductFormValues['unitRevenue'].invalid)||(lubricantProductFormValues['unitRevenue'].touched && lubricantProductFormValues['unitRevenue'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="lubricantProductFormValues['unitRevenue'].errors?.['required']">
                                                Unit Revenue is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="monthlyVolume"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span>v.</span>
                                        </label>
                                        <label for="monthlyVolume"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Monthly Volume<span class="text-danger"> *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="1" id="monthlyVolume" placeholder="175"
                                                    formControlName="monthlyVolume">
                                                <div class="input-group-text">
                                                    <span>
                                                        m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && lubricantProductFormValues['monthlyVolume'].invalid)||(lubricantProductFormValues['monthlyVolume'].touched && lubricantProductFormValues['monthlyVolume'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="lubricantProductFormValues['monthlyVolume'].errors?.['required']">
                                                Monthly Volume is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="yearlyVolume"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span></span>
                                        </label>
                                        <label for="yearlyVolume"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Yearly Volume<span class="text-danger"></span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input
                                                    class="form-control form-control-sm form-control-plaintext"
                                                    type="number" min="1" id="yearlyVolume" readonly
                                                    formControlName="yearlyVolume">
                                                <div class="input-group-text">
                                                    <span>
                                                        m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && lubricantProductFormValues['yearlyVolume'].invalid)||(lubricantProductFormValues['yearlyVolume'].touched && lubricantProductFormValues['yearlyVolume'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="lubricantProductFormValues['yearlyVolume'].errors?.['required']">
                                                Yearly Volume is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <label for="gMVE" class="col-1 col-form-label col-form-label-sm">
                                            <span></span>
                                        </label>
                                        <label for="gMVE" class="col-5 col-form-label col-form-label-sm">
                                            <span>GMVE<span class="text-danger"></span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input
                                                    class="form-control form-control-sm form-control-plaintext"
                                                    type="number" min="1" id="gMVE" readonly
                                                    formControlName="gMVE">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && lubricantProductFormValues['gMVE'].invalid)||(lubricantProductFormValues['gMVE'].touched && lubricantProductFormValues['gMVE'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="lubricantProductFormValues['gMVE'].errors?.['required']">
                                                GMVE is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="d-grid">
                                                <button class="btn btn-secondary mt-2"
                                                    (click)="hideProductMenu()" type="submit"><i
                                                        class=""></i>Cancel Edit</button>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="d-grid" *ngIf="productExists_Update">
                                                <button class="btn btn-success mt-2" *ngIf="!updateInProgress"
                                                    (click)="addProduct(lubricantProductForm.value)"
                                                    type="submit"><i class=""></i>Update Product</button>
                                                <button class="btn btn-success mt-2" type="button" disabled
                                                    *ngIf="updateInProgress">
                                                    <span class="spinner-border spinner-border-sm me-1"
                                                        role="status" aria-hidden="true"></span>
                                                    Loading...
                                                </button>
                                            </div>
                                            <div class="d-grid" *ngIf="!productExists_Update">
                                                <button class="btn btn-success mt-2" *ngIf="!updateInProgress"
                                                    (click)="addProduct(lubricantProductForm.value)"
                                                    type="submit"><i class=""></i>Add Product</button>
                                                <button class="btn btn-success mt-2" type="button" disabled
                                                    *ngIf="updateInProgress">
                                                    <span class="spinner-border spinner-border-sm me-1"
                                                        role="status" aria-hidden="true"></span>
                                                    Loading...
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>

                    <form class="form-horizontal" name="gas-product-form" [formGroup]="gasProductForm">

                        <div *ngIf="showPetroleumProductForm && (pickedPetroleumProductCategory === 'Gas')">

                            <div class="row">
                                <div class="col-12">

                                    <div class="row mb-0">
                                        <label for="networkDealerMarginM3"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span>i.</span>
                                        </label>
                                        <label for="networkDealerMarginM3"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Dealer Unit Margin<span class="text-danger"> *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="1" id="networkDealerMarginM3" placeholder="15,000"
                                                    formControlName="networkDealerMarginM3">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && gasProductFormValues['networkDealerMarginM3'].invalid)||(gasProductFormValues['networkDealerMarginM3'].touched && gasProductFormValues['networkDealerMarginM3'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="gasProductFormValues['networkDealerMarginM3'].errors?.['required']">
                                                Dealer Unit Margin is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="unitGMVE" class="col-1 col-form-label col-form-label-sm">
                                            <span>ii.</span>
                                        </label>
                                        <label for="unitGMVE" class="col-5 col-form-label col-form-label-sm">
                                            <span>Unit GMVE<span class="text-danger"> *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="1" id="unitGMVE" placeholder="54281.28"
                                                    formControlName="unitGMVE">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && gasProductFormValues['unitGMVE'].invalid)||(gasProductFormValues['unitGMVE'].touched && gasProductFormValues['unitGMVE'].invalid)"
                                            class="invalid-tooltip">
                                            <div *ngIf="gasProductFormValues['unitGMVE'].errors?.['required']">
                                                Unit GMVE is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="companyUnitMargin"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span></span>
                                        </label>
                                        <label for="companyUnitMargin"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Company Unit Margin<span class="text-danger">
                                                </span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input
                                                    class="form-control form-control-sm form-control-plaintext"
                                                    type="number" readonly min="1" id="companyUnitMargin"
                                                    formControlName="companyUnitMargin">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && gasProductFormValues['companyUnitMargin'].invalid)||(gasProductFormValues['companyUnitMargin'].touched && gasProductFormValues['companyUnitMargin'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="gasProductFormValues['companyUnitMargin'].errors?.['required']">
                                                Company Unit Margin is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="unitSupplyPrice"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span>iii.</span>
                                        </label>
                                        <label for="unitSupplyPrice"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Unit Supply Price<span class="text-danger">
                                                    *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="1" id="unitSupplyPrice" placeholder="82019.99"
                                                    formControlName="unitSupplyPrice">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && gasProductFormValues['unitSupplyPrice'].invalid)||(gasProductFormValues['unitSupplyPrice'].touched && gasProductFormValues['unitSupplyPrice'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="gasProductFormValues['unitSupplyPrice'].errors?.['required']">
                                                Unit Supply Price is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="unitaryTax" class="col-1 col-form-label col-form-label-sm">
                                            <span>iv.</span>
                                        </label>
                                        <label for="unitaryTax" class="col-5 col-form-label col-form-label-sm">
                                            <span>Unitary Tax<span class="text-danger">
                                                    *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="1" id="unitaryTax" placeholder="400"
                                                    formControlName="unitaryTax">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && gasProductFormValues['unitaryTax'].invalid)||(gasProductFormValues['unitaryTax'].touched && gasProductFormValues['unitaryTax'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="gasProductFormValues['unitaryTax'].errors?.['required']">
                                                Unitary Tax is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="unitRevenue" class="col-1 col-form-label col-form-label-sm">
                                            <span></span>
                                        </label>
                                        <label for="unitRevenue" class="col-5 col-form-label col-form-label-sm">
                                            <span>Unit Revenue<span class="text-danger">
                                                </span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input
                                                    class="form-control form-control-sm form-control-plaintext"
                                                    type="number" min="1" id="unitRevenue" readonly
                                                    formControlName="unitRevenue">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && gasProductFormValues['unitRevenue'].invalid)||(gasProductFormValues['unitRevenue'].touched && gasProductFormValues['unitRevenue'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="gasProductFormValues['unitRevenue'].errors?.['required']">
                                                Unit Revenue is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="monthlyVolume"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span>v.</span>
                                        </label>
                                        <label for="monthlyVolume"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Monthly Volume<span class="text-danger">
                                                    *</span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    min="1" id="monthlyVolume" placeholder="5"
                                                    formControlName="monthlyVolume">
                                                <div class="input-group-text">
                                                    <span>
                                                        m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && gasProductFormValues['monthlyVolume'].invalid)||(gasProductFormValues['monthlyVolume'].touched && gasProductFormValues['monthlyVolume'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="gasProductFormValues['monthlyVolume'].errors?.['required']">
                                                Monthly Volume is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0">
                                        <label for="yearlyVolume"
                                            class="col-1 col-form-label col-form-label-sm">
                                            <span></span>
                                        </label>
                                        <label for="yearlyVolume"
                                            class="col-5 col-form-label col-form-label-sm">
                                            <span>Yearly Volume<span class="text-danger"></span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input
                                                    class="form-control form-control-sm form-control-plaintext"
                                                    type="number" min="1" id="yearlyVolume" readonly
                                                    formControlName="yearlyVolume">
                                                <div class="input-group-text">
                                                    <span>
                                                        m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && gasProductFormValues['yearlyVolume'].invalid)||(gasProductFormValues['yearlyVolume'].touched && gasProductFormValues['yearlyVolume'].invalid)"
                                            class="invalid-tooltip">
                                            <div
                                                *ngIf="gasProductFormValues['yearlyVolume'].errors?.['required']">
                                                Yearly Volume is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <label for="gMVE" class="col-1 col-form-label col-form-label-sm">
                                            <span></span>
                                        </label>
                                        <label for="gMVE" class="col-5 col-form-label col-form-label-sm">
                                            <span>GMVE<span class="text-danger"></span></span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input
                                                    class="form-control form-control-sm form-control-plaintext"
                                                    type="number" min="1" id="gMVE" readonly
                                                    formControlName="gMVE">
                                                <div class="input-group-text">
                                                    <span *ngIf="localCurrencyISOCode">
                                                        {{localCurrencyISOCode}}/m3
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="(formSubmitted && gasProductFormValues['gMVE'].invalid)||(gasProductFormValues['gMVE'].touched && gasProductFormValues['gMVE'].invalid)"
                                            class="invalid-tooltip">
                                            <div *ngIf="gasProductFormValues['gMVE'].errors?.['required']">
                                                GMVE is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="d-grid">
                                                <button class="btn btn-secondary mt-2"
                                                    (click)="hideProductMenu()" type="submit"><i
                                                        class=""></i>Cancel Edit</button>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="d-grid" *ngIf="productExists_Update">
                                                <button class="btn btn-success mt-2" *ngIf="!updateInProgress"
                                                    (click)="addProduct(gasProductForm.value)" type="submit"><i
                                                        class=""></i>Update Product</button>
                                                <button class="btn btn-success mt-2" type="button" disabled
                                                    *ngIf="updateInProgress">
                                                    <span class="spinner-border spinner-border-sm me-1"
                                                        role="status" aria-hidden="true"></span>
                                                    Loading...
                                                </button>
                                            </div>
                                            <div class="d-grid" *ngIf="!productExists_Update">
                                                <button class="btn btn-success mt-2" *ngIf="!updateInProgress"
                                                    (click)="addProduct(gasProductForm.value)" type="submit"><i
                                                        class=""></i>Add Product</button>
                                                <button class="btn btn-success mt-2" type="button" disabled
                                                    *ngIf="updateInProgress">
                                                    <span class="spinner-border spinner-border-sm me-1"
                                                        role="status" aria-hidden="true"></span>
                                                    Loading...
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="card">
                <div class="card-header text-center mb-0">
                    <span>
                        Products Margin Summary
                    </span>
                </div>
                <div class="card-body">

                    <div class="row mb-3">
                        <div class="board">
                            <table class="table table-sm table-bordered table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0">
                                            Global Price
                                        </th>
                                        <th class="p-0">
                                            EPRA Margin
                                        </th>
                                        <th class="p-0">
                                            DODO Given
                                        </th>
                                        <th class="p-0">
                                            Dealer Margin
                                        </th>
                                        <th class="p-0">
                                            Discount Given
                                        </th>
                                        <th class="p-0">
                                            Depot Cost
                                        </th>
                                        <th class="p-0">
                                            Galana Pro
                                        </th>
                                        <th class="p-0">
                                            Varex
                                        </th>
                                        <th class="p-0">
                                            Yearly Volume
                                        </th>
                                        <th class="p-0">
                                            Unit Revenue
                                        </th>
                                        <th class="p-0">
                                            Unit Supply Price
                                        </th>
                                        <th class="p-0">
                                            Unitary Tax
                                        </th>
                                        <th class="p-0">
                                            Company Unit Margin
                                        </th>
                                        <th class="p-0">
                                            Unit GMVE
                                        </th>
                                        <th class="p-0">
                                            GMVE
                                        </th>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0">
                                            KES/L
                                        </th>
                                        <th class="p-0">
                                            KES/L
                                        </th>
                                        <th class="p-0">
                                            KES/L
                                        </th>
                                        <th class="p-0">
                                            KES/L
                                        </th>
                                        <th class="p-0">
                                            KES/L
                                        </th>
                                        <th class="p-0">
                                            KES/L
                                        </th>
                                        <th class="p-0">
                                            KES/L
                                        </th>
                                        <th class="p-0">
                                            KES/L
                                        </th>
                                        <th class="p-0">
                                            m3/year
                                        </th>
                                        <th class="p-0">
                                            KES/m3
                                        </th>
                                        <th class="p-0">
                                            KES/m3
                                        </th>
                                        <th class="p-0">
                                            KES/m3
                                        </th>
                                        <th class="p-0">
                                            KES/m3
                                        </th>
                                        <th class="p-0">
                                            KES/m3
                                        </th>
                                        <th class="p-0">
                                            KES
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr *ngFor="let m of fuelProductArray;let i =index;">
                                        <td class="p-0">
                                            <span>

                                            </span>
                                        </td>
                                        <td class="p-0 ps-1">
                                            <span *ngIf="m.name">
                                                {{m.name}}
                                                <span *ngIf="m.industryAbbreviation">
                                                    ({{m.industryAbbreviation}})
                                                </span>
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.networkGlobalPrice">
                                                {{m.networkGlobalPrice | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.networkEPRAMargin">
                                                {{m.networkEPRAMargin | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.networkDODOMargin">
                                                {{m.networkDODOMargin | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.networkDealerMarginL">
                                                {{m.networkDealerMarginL | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.networkDiscountGiven">
                                                {{m.networkDiscountGiven | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.networkDepotCost">
                                                {{m.networkDepotCost | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.networkGalanaPro">
                                                {{m.networkGalanaPro | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.networkVarex">
                                                {{m.networkVarex | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.yearlyVolume">
                                                {{m.yearlyVolume | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.unitRevenue">
                                                {{m.unitRevenue | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.unitSupplyPrice">
                                                {{m.unitSupplyPrice | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.unitaryTax">
                                                {{m.unitaryTax | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.companyUnitMargin">
                                                {{m.companyUnitMargin | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.companyUnitMargin">
                                                {{m.companyUnitMargin | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <!-- <td class="p-0">
                                            <span *ngIf="m.unitGMVE">
                                                {{m.unitGMVE | number : '1.2-2'}}
                                            </span>
                                        </td> -->
                                        <!-- <td class="p-0">
                                            <span *ngIf="m.yearlyVolume">
                                                {{m.yearlyVolume | number : '1.2-2'}}
                                            </span>
                                        </td> -->
                                        <td class="p-0">
                                            <span *ngIf="m.gMVE">
                                                {{m.gMVE | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class="table-group-divider" *ngIf="fuelProductTotal">
                                    <tr>
                                        <td class="p-0">
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                <p class="mb-0"><strong>Fuels</strong></p>
                                            </span>
                                        </td>
                                        <td class="p-0">
                                        </td>
                                        <td class="p-0">
                                        </td>
                                        <td class="p-0">
                                        </td>
                                        <td class="p-0">
                                        </td>
                                        <td class="p-0">
                                        </td>
                                        <td class="p-0">
                                        </td>
                                        <td class="p-0">
                                        </td>
                                        <td class="p-0">
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelProductTotal.yearlyVolume">
                                                {{fuelProductTotal.yearlyVolume | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelProductTotal.unitRevenue">
                                                {{fuelProductTotal.unitRevenue | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelProductTotal.unitSupplyPrice">
                                                {{fuelProductTotal.unitSupplyPrice | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelProductTotal.unitaryTax">
                                                {{fuelProductTotal.unitaryTax | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelProductTotal.companyUnitMargin">
                                                {{fuelProductTotal.companyUnitMargin | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelProductTotal.companyUnitMargin">
                                                {{fuelProductTotal.companyUnitMargin | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <!-- <td class="p-0">
                                            <span *ngIf="fuelProductTotal.unitGMVE">
                                                {{fuelProductTotal.unitGMVE | number : '1.2-2'}}
                                            </span>
                                        </td> -->
                                        <!-- <td class="p-0">
                                            <span *ngIf="fuelProductTotal.yearlyVolume">
                                                {{fuelProductTotal.yearlyVolume | number : '1.2-2'}}
                                            </span>
                                        </td> -->
                                        <td class="p-0">
                                            <span *ngIf="fuelProductTotal.gMVE">
                                                {{fuelProductTotal.gMVE | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class="table-group-divider" *ngIf="lubricantProductArray">
                                    <tr *ngFor="let m of lubricantProductArray;let i = index;">
                                        <td class="p-0">
                                            <span>

                                            </span>
                                        </td>
                                        <td class="p-0 ps-1">
                                            <span *ngIf="m.name">
                                                {{m.name}}
                                                <span *ngIf="m.industryAbbreviation">
                                                    ({{m.industryAbbreviation}})
                                                </span>
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0">
                                            <span *ngIf="m.yearlyVolume">
                                                {{m.yearlyVolume | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.unitRevenue">
                                                {{m.unitRevenue | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.unitSupplyPrice">
                                                {{m.unitSupplyPrice | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.unitaryTax">
                                                {{m.unitaryTax | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.companyUnitMargin">
                                                {{m.companyUnitMargin | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.companyUnitMargin">
                                                {{m.companyUnitMargin | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <!-- <td class="p-0">
                                            <span *ngIf="m.networkDealerMarginM3">
                                                {{m.networkDealerMarginM3 | number : '1.2-2'}}
                                            </span>
                                        </td> -->
                                        <!-- <td class="p-0">
                                            <span *ngIf="m.unitGMVE">
                                                {{m.unitGMVE | number : '1.2-2'}}
                                            </span>
                                        </td> -->
                                        <!-- <td class="p-0">
                                            <span *ngIf="m.yearlyVolume">
                                                {{m.yearlyVolume | number : '1.2-2'}}
                                            </span>
                                        </td> -->

                                        <td class="p-0">
                                            <span *ngIf="m.gMVE">
                                                {{m.gMVE | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class="table-group-divider" *ngIf="fuelAndLubricantProductTotal">
                                    <tr>
                                        <td class="p-0">
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                <p class="mb-0"><strong>Fuels & Lubs</strong></p>
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0">
                                            <span *ngIf="fuelAndLubricantProductTotal.yearlyVolume">
                                                {{fuelAndLubricantProductTotal.yearlyVolume | number :
                                                '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelAndLubricantProductTotal.unitRevenue">
                                                {{fuelAndLubricantProductTotal.unitRevenue | number :
                                                '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelAndLubricantProductTotal.unitSupplyPrice">
                                                {{fuelAndLubricantProductTotal.unitSupplyPrice | number :
                                                '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelAndLubricantProductTotal.unitaryTax">
                                                {{fuelAndLubricantProductTotal.unitaryTax | number :
                                                '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelAndLubricantProductTotal.companyUnitMargin">
                                                {{fuelAndLubricantProductTotal.companyUnitMargin | number :
                                                '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelAndLubricantProductTotal.companyUnitMargin">
                                                {{fuelAndLubricantProductTotal.companyUnitMargin | number :
                                                '1.2-2'}}
                                            </span>
                                        </td>
                                        <!-- <td class="p-0">
                                            <span *ngIf="fuelAndLubricantProductTotal.networkDealerMarginM3">
                                                {{fuelAndLubricantProductTotal.networkDealerMarginM3 | number : '1.2-2'}}
                                            </span>
                                        </td> -->
                                        <!-- <td class="p-0">
                                            <span *ngIf="fuelAndLubricantProductTotal.unitGMVE">
                                                {{fuelAndLubricantProductTotal.unitGMVE | number : '1.2-2'}}
                                            </span>
                                        </td> -->
                                        <!-- <td class="p-0">
                                            <span *ngIf="fuelAndLubricantProductTotal.yearlyVolume">
                                                {{fuelAndLubricantProductTotal.yearlyVolume | number : '1.2-2'}}
                                            </span>
                                        </td> -->

                                        <td class="p-0">
                                            <span *ngIf="fuelAndLubricantProductTotal.gMVE">
                                                {{fuelAndLubricantProductTotal.gMVE | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class="table-group-divider">
                                    <tr *ngFor="let m of gasProductArray;let i = index;">
                                        <td class="p-0">
                                            <span>

                                            </span>
                                        </td>
                                        <td class="p-0 ps-1">
                                            <span *ngIf="m.name">
                                                {{m.name}}
                                                <span *ngIf="m.industryAbbreviation">
                                                    ({{m.industryAbbreviation}})
                                                </span>
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0">
                                            <span *ngIf="m.yearlyVolume">
                                                {{m.yearlyVolume | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.unitRevenue">
                                                {{m.unitRevenue | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.unitSupplyPrice">
                                                {{m.unitSupplyPrice | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.unitaryTax">
                                                {{m.unitaryTax | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.companyUnitMargin">
                                                {{m.companyUnitMargin | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.companyUnitMargin">
                                                {{m.companyUnitMargin | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <!-- <td class="p-0">
                                            <span *ngIf="m.networkDealerMarginM3">
                                                {{m.networkDealerMarginM3 | number : '1.2-2'}}
                                            </span>
                                        </td> -->
                                        <!-- <td class="p-0">
                                            <span *ngIf="m.unitGMVE">
                                                {{m.unitGMVE | number : '1.2-2'}}
                                            </span>
                                        </td> -->
                                        <!-- <td class="p-0">
                                            <span *ngIf="m.yearlyVolume">
                                                {{m.yearlyVolume | number : '1.2-2'}}
                                            </span>
                                        </td> -->
                                        <td class="p-0">
                                            <span *ngIf="m.gMVE">
                                                {{m.gMVE | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
            <div class="card">
                <div class="card-header text-center mb-0">
                    <span>
                        Fuel Volume & Margin Overview (Before Bonus & Discount)
                    </span>
                </div>
                <div class="card-body">

                    <div class="row mb-3">
                        <div class="table-responsive-xxl">
                            <table class="table table-sm table-borderless table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                Fuel - Monthly Volume
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                :
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelProductTotal">
                                                <span *ngIf="fuelProductTotal.monthlyVolume">
                                                    {{fuelProductTotal.monthlyVolume | number : '1.2-2'}}
                                                </span>
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                m3/month
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                Fuel - Yearly Volume
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                :
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelProductTotal">
                                                <span *ngIf="fuelProductTotal.yearlyVolume">
                                                    {{fuelProductTotal.yearlyVolume | number : '1.2-2'}}
                                                </span>
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                m3/year
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                Fuel - Yearly Gross Margin
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                :
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelProductTotal">
                                                <span *ngIf="fuelProductTotal.yearlyGrossMargin">
                                                    {{fuelProductTotal.yearlyGrossMargin | number :
                                                    '1.2-2'}}
                                                </span>
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                KES
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                Fuel - Average Unit Margin
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                :
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelProductTotal">
                                                <span *ngIf="fuelProductTotal.averageUnitMargin">
                                                    {{fuelProductTotal.averageUnitMargin | number :
                                                    '1.2-2'}}
                                                </span>
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                KES/m3
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                Lubricants - Yearly Volume
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                :
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="lubricantProductTotal">
                                                <span *ngIf="lubricantProductTotal.yearlyVolume">
                                                    {{lubricantProductTotal.yearlyVolume | number :
                                                    '1.2-2'}}
                                                </span>
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                m3/year
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                Fuels & Lubs - Yearly Volume
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                :
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelAndLubricantProductTotal">
                                                <span *ngIf="fuelAndLubricantProductTotal.yearlyVolume">
                                                    {{fuelAndLubricantProductTotal.yearlyVolume | number :
                                                    '1.2-2'}}
                                                </span>
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                m3/year
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                Fuels & Lubs - Yearly Gross Margin
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                :
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelAndLubricantProductTotal">
                                                <span *ngIf="fuelAndLubricantProductTotal.yearlyGrossMargin">
                                                    {{fuelAndLubricantProductTotal.yearlyGrossMargin |
                                                    number :
                                                    '1.2-2'}}
                                                </span>
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                KES
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                Fuels & Lubs - Average Unit Margin
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                :
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="fuelAndLubricantProductTotal">
                                                <span *ngIf="fuelAndLubricantProductTotal.averageUnitMargin">
                                                    {{fuelAndLubricantProductTotal.averageUnitMargin |
                                                    number :
                                                    '1.2-2'}}
                                                </span>
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                KES/m3
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                Gas in Bottle - Yearly Gross Margin
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                :
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="gasProductTotal">
                                                <span *ngIf="gasProductTotal.yearlyGrossMargin">
                                                    {{gasProductTotal.yearlyGrossMargin | number : '1.2-2'}}
                                                </span>
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span>
                                                KES
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
            <div class="card">
                <div class="card-header text-center mb-0">
                    <span>
                        Fuel Volume Growth
                    </span>
                </div>
                <div class="card-body">
                    <div class="board">
                        <table class="table table-sm table-bordered table-centered mb-0">
                            <thead>
                                <tr>
                                    <th style="min-width:50px">

                                    </th>
                                    <th>
                                        Y - 0
                                    </th>
                                    <th>
                                        Y - 1
                                    </th>
                                    <th>
                                        Y - 2
                                    </th>
                                    <th>
                                        Y - 3
                                    </th>
                                    <th>
                                        Y - 4
                                    </th>
                                    <th>
                                        Y - 5
                                    </th>
                                    <th>
                                        Y - 6
                                    </th>
                                    <th>
                                        Y - 7
                                    </th>
                                    <th>
                                        Y - 8
                                    </th>
                                    <th>
                                        Y - 9
                                    </th>
                                    <th>
                                        Y - 10
                                    </th>
                                    <th>
                                        Y - 11
                                    </th>
                                    <th>
                                        Y - 12
                                    </th>
                                    <th>
                                        Y - 13
                                    </th>
                                    <th>
                                        Y - 14
                                    </th>
                                    <th>
                                        Y - 15
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel volume growth (% p.a)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of fuelVolumeGrowthArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m}}%</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>

                                    <td class="p-0">
                                        <span>
                                            Fuel volume ramp up in % of potential
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of fuelVolumeGrowthPotentialArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m}}%</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Months during the period
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of monthsDuringThePeriodArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Months before operations
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of monthsBeforeOperationArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel margin growth (%p.a)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of fuelMarginGrowthArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m}}%</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel margin growth Factor
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of fuelMarginGrowthFactorArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel margin growth Factor 2
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of fuelMarginGrowthFactorTwoArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel volume - Without Lubricants (m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of fuelVolumeWithoutLubricantArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel volume (m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of fuelVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel margin (KES/m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of fuelMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of gasMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel Margin (KES)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of fuelMarginTotalArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>