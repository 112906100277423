export const environment = {
  firebase: {
    apiKey: "AIzaSyCr7OSar1DPnLwF_5rK1E1A6tiTFdKqi3s",
    authDomain: "financial-forecast-v2.firebaseapp.com",
    projectId: "financial-forecast-v2",
    storageBucket: "financial-forecast-v2.appspot.com",
    messagingSenderId: "371163072833",
    appId: "1:371163072833:web:9f01d4cb4e78f4ad32b9c0"
  },
  production: true,
  demo: '', // other possible options are, saas creative and modern
  GOOGLE_MAPS_API_KEY: 'AIzaSyC5pcAkhXSo6-KqV9RtZdUAhdJno7r_8b0'
};
