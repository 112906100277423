import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { User } from '@firebase/auth-types';
import { NgbAlert, NgbAlertModule, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription, first } from 'rxjs';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { ClockService } from 'src/app/core/service/clock.service';
import { ProjectService } from 'src/app/core/service/project.service';
import { UserService } from 'src/app/core/service/user.service';
import { WidgetModule } from 'src/app/shared/widget/widget.module';

@Component({
  selector: 'app-operating-expenses',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    WidgetModule,
    NgbButtonsModule,
  ],
  templateUrl: './operating-expenses.component.html',
  styleUrls: ['./operating-expenses.component.scss']
})
export class OperatingExpensesComponent implements OnInit, OnDestroy {


  subscription: Subscription = new Subscription();
  today: number = Date.now();
  loading: boolean = false;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;

  salariesAndBonusItemForm!: FormGroup;
  employeeContributionItemForm!: FormGroup;
  electricityItemForm!: FormGroup;
  miscellaneousItemForm!: FormGroup;
  productLossItemForm!: FormGroup;
  operatingExpenseItemForm!: FormGroup;

  salariesAndBonusItemList: FormArray;
  employeeContributionItemList: FormArray;
  electricityItemList: FormArray;
  miscellaneousItemList: FormArray;
  productLossItemList: FormArray;

  formSubmitted: boolean = false;

  show_alert: boolean;
  alert_message: string;
  alert_type: string;
  loggedInUser: User;
  error: string;
  localCurrencyISOCode: any = "KES";
  userId: any;

  salariesAndBonusTotal: number = 0;
  employeeContributionTotal: number = 0;
  electricityTotal: number = 0;
  miscellaneousTotal: number = 0;
  productLossTotal: number = 0;
  operatingExpenseTotal: number = 0;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private clockService: ClockService,
  ) {
    this.loadingAnimationFourSeconds();
    this.subscription.add(
      authenticationService.currentUser.pipe().subscribe((res: any) => {
        if (res) {
          if (res) {
            this.loggedInUser = res;
            if (res.userId) {
              this.userId = res.userId;
            }
          } else {
          }
        }
      })
    );
    this.subscription.add(
      this.projectService.getOperatingExpenseObject().pipe().subscribe((res: any) => {
        
        if (res) {
          if (res.salariesAndBonus) {
            this.salariesAndBonusItemList.removeAt(0);
            let salariesAndBonusIndex = 0;
            this.salariesAndBonusItemForm.get("name").setValue("Salaries And Bonus");
            (res.salariesAndBonus).forEach(element => {
              this.salariesAndBonusItemList.push(this.createItem());
              this.salariesAndBonusItemList.get(`${salariesAndBonusIndex}`).get("name").setValue(element.name);
              this.salariesAndBonusItemList.get(`${salariesAndBonusIndex}`).get("cost").setValue(element.cost);
              salariesAndBonusIndex += 1;
            });
          }
          if (res.employeeContribution) {
            this.employeeContributionItemList.removeAt(0);
            let employeeContributionIndex = 0;
            this.employeeContributionItemForm.get("name").setValue("Employee Contribution");
            (res.employeeContribution).forEach(element => {
              this.employeeContributionItemList.push(this.createItem());
              this.employeeContributionItemList.get(`${employeeContributionIndex}`).get("name").setValue(element.name);
              this.employeeContributionItemList.get(`${employeeContributionIndex}`).get("cost").setValue(element.cost);
              employeeContributionIndex += 1;
            });
          }
          if (res.electricity) {
            this.electricityItemList.removeAt(0);
            let electricityIndex = 0;
            this.electricityItemForm.get("name").setValue("Electricity");
            (res.electricity).forEach(element => {
              this.electricityItemList.push(this.createItem());
              this.electricityItemList.get(`${electricityIndex}`).get("name").setValue(element.name);
              this.electricityItemList.get(`${electricityIndex}`).get("cost").setValue(element.cost);
              electricityIndex += 1;
            });
          }
          if (res.miscellaneous) {
            this.miscellaneousItemList.removeAt(0);
            let miscellaneousIndex = 0;
            this.miscellaneousItemForm.get("name").setValue("Miscellaneous");
            (res.miscellaneous).forEach(element => {
              this.miscellaneousItemList.push(this.createItem());
              this.miscellaneousItemList.get(`${miscellaneousIndex}`).get("name").setValue(element.name);
              this.miscellaneousItemList.get(`${miscellaneousIndex}`).get("cost").setValue(element.cost);
              miscellaneousIndex += 1;
            });
          }
          if (res.productLoss) {
            this.productLossItemList.removeAt(0);
            let productLossIndex = 0;
            this.productLossItemForm.get("name").setValue("Product Loss");
            (res.productLoss).forEach(element => {
              this.productLossItemList.push(this.createItem());
              this.productLossItemList.get(`${productLossIndex}`).get("name").setValue(element.name);
              this.productLossItemList.get(`${productLossIndex}`).get("cost").setValue(element.cost);
              productLossIndex += 1;
            });
          }
        }
      })
    );
  }

  // returns all form groups under items
  get salariesAndBonusItemFormGroup() {
    return this.salariesAndBonusItemForm.get('item') as FormArray;
  }
  // returns all form groups under items
  get employeeContributionItemFormGroup() {
    return this.employeeContributionItemForm.get('item') as FormArray;
  }
  // returns all form groups under items
  get electricityItemFormGroup() {
    return this.electricityItemForm.get('item') as FormArray;
  }
  // returns all form groups under items
  get productLossItemFormGroup() {
    return this.productLossItemForm.get('item') as FormArray;
  }

  get miscellaneousItemFormGroup() {
    return this.miscellaneousItemForm.get('item') as FormArray;
  }


  ngOnInit(): void {

    this.salariesAndBonusItemForm = this.fb.group({
      name: ['Salaries And Bonus', Validators.required],
      item: this.fb.array([this.createItem()]),
    });
    this.employeeContributionItemForm = this.fb.group({
      name: ['Employee Contribution', Validators.required],
      item: this.fb.array([this.createItem()]),
    });
    this.electricityItemForm = this.fb.group({
      name: ['Electricity', Validators.required],
      item: this.fb.array([this.createItem()]),
    });
    this.productLossItemForm = this.fb.group({
      name: ['Product Loss', Validators.required],
      item: this.fb.array([this.createItem()]),
    });
    this.miscellaneousItemForm = this.fb.group({
      name: ['Miscellaneous', Validators.required],
      item: this.fb.array([this.createItem()]),
    });

    this.operatingExpenseItemForm = this.fb.group({
      salariesAndBonusTotal: [0, Validators.required],
      employeeContributionTotal: [0, Validators.required],
      electricityTotal: [0, Validators.required],
      miscellaneousTotal: [0, Validators.required],
      productLossTotal: [0, Validators.required],
      total: [0, Validators.required],
    });

    this.salariesAndBonusItemList = this.salariesAndBonusItemForm.get('item') as FormArray;
    this.employeeContributionItemList = this.employeeContributionItemForm.get('item') as FormArray;
    this.electricityItemList = this.electricityItemForm.get('item') as FormArray;
    this.miscellaneousItemList = this.miscellaneousItemForm.get('item') as FormArray;
    this.productLossItemList = this.productLossItemForm.get('item') as FormArray;

    this.subscription.add(
      this.salariesAndBonusItemList.valueChanges.subscribe(x => {

        this.salariesAndBonusTotal = 0
        this.salariesAndBonusItemList.value.forEach(element => {
          this.salariesAndBonusTotal += element.cost;
        });

        this.operatingExpenseTotal = this.salariesAndBonusTotal + this.employeeContributionTotal + this.electricityTotal + this.miscellaneousTotal + this.productLossTotal;
        this.operatingExpenseItemForm.get("salariesAndBonusTotal").setValue(this.salariesAndBonusTotal);
        this.operatingExpenseItemForm.get("total").setValue(this.operatingExpenseTotal);
      }));

    this.subscription.add(
      this.employeeContributionItemList.valueChanges.subscribe(x => {

        this.employeeContributionTotal = 0
        this.employeeContributionItemList.value.forEach(element => {
          this.employeeContributionTotal += element.cost;
        });

        this.operatingExpenseTotal = this.salariesAndBonusTotal + this.employeeContributionTotal + this.electricityTotal + this.miscellaneousTotal + this.productLossTotal;
        this.operatingExpenseItemForm.get("employeeContributionTotal").setValue(this.employeeContributionTotal);
        this.operatingExpenseItemForm.get("total").setValue(this.operatingExpenseTotal);
      }));

    this.subscription.add(
      this.electricityItemList.valueChanges.subscribe(x => {

        this.electricityTotal = 0
        this.electricityItemList.value.forEach(element => {
          this.electricityTotal += element.cost;
        });

        this.operatingExpenseTotal = this.salariesAndBonusTotal + this.employeeContributionTotal + this.electricityTotal + this.miscellaneousTotal + this.productLossTotal;
        this.operatingExpenseItemForm.get("electricityTotal").setValue(this.electricityTotal);
        this.operatingExpenseItemForm.get("total").setValue(this.operatingExpenseTotal);
      }));

    this.subscription.add(
      this.miscellaneousItemList.valueChanges.subscribe(x => {

        this.miscellaneousTotal = 0
        this.miscellaneousItemList.value.forEach(element => {
          this.miscellaneousTotal += element.cost;
        });

        this.operatingExpenseTotal = this.salariesAndBonusTotal + this.employeeContributionTotal + this.electricityTotal + this.miscellaneousTotal + this.productLossTotal;
        this.operatingExpenseItemForm.get("miscellaneousTotal").setValue(this.miscellaneousTotal);
        this.operatingExpenseItemForm.get("total").setValue(this.operatingExpenseTotal);
      }));

      this.subscription.add(
        this.productLossItemList.valueChanges.subscribe(x => {
  
          this.productLossTotal = 0
          this.productLossItemList.value.forEach(element => {
            this.productLossTotal += element.cost;
          });
  
          this.operatingExpenseTotal = this.salariesAndBonusTotal + this.employeeContributionTotal + this.electricityTotal + this.miscellaneousTotal + this.productLossTotal;
          this.operatingExpenseItemForm.get("productLossTotal").setValue(this.productLossTotal);
          this.operatingExpenseItemForm.get("total").setValue(this.operatingExpenseTotal);
        }));
  }



  createItem(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      cost: [1, Validators.required],
    });
  }

  // add a item form group
  addSalariesAndBonusItem() {
    this.salariesAndBonusItemList.push(this.createItem());
  }

  addEmployeeContributionItem() {
    this.employeeContributionItemList.push(this.createItem());
  }

  addElectricityItem() {
    this.electricityItemList.push(this.createItem());
  }

  addMiscellaneousItem() {
    this.miscellaneousItemList.push(this.createItem());
  }

  addProductLossItem() {
    this.productLossItemList.push(this.createItem());
  }

  removeSalariesAndBonusItem(index) {
    // this.itemList = this.form.get('items') as FormArray;
    this.salariesAndBonusItemList.removeAt(index);
  }

  removeEmployeeContributionItem(index) {
    // this.itemList = this.form.get('items') as FormArray;
    this.employeeContributionItemList.removeAt(index);
  }

  removeElectricityItem(index) {
    // this.itemList = this.form.get('items') as FormArray;
    this.electricityItemList.removeAt(index);
  }

  removeMiscellaneousItem(index) {
    // this.itemList = this.form.get('items') as FormArray;
    this.miscellaneousItemList.removeAt(index);
  }

  removeProductLossItem(index) {
    // this.itemList = this.form.get('items') as FormArray;
    this.productLossItemList.removeAt(index);
  }

  ngAfterViewInit(): void {

  }

  /**
   * convenience getter for easy access to form fields
   */
  get salariesAndBonusItemFormValues() {
    return this.salariesAndBonusItemForm.controls;
  }

  get employeeContributionItemFormValues() {
    return this.employeeContributionItemForm.controls;
  }

  get electricityItemFormValues() {
    return this.electricityItemForm.controls;
  }

  get miscellaneousItemFormValues() {
    return this.miscellaneousItemForm.controls;
  }

  get productLossItemFormValues() {
    return this.productLossItemForm.controls;
  }

  /**
   * On form submit
   */

  saveOperatingExpensesItems(
    salariesAndBonusForm: any,
    employeeContributionForm: any,
    electricityForm: any,
    miscellaneousForm: any,
    productLossForm: any,
    operatingExpenseItemForm: any,
  ) {

    this.loading = true;
    this.subscription.add(
      this.projectService.updateDefaultDealerOperatingExpense(
        salariesAndBonusForm,
        employeeContributionForm,
        electricityForm,
        miscellaneousForm,
        productLossForm,
        operatingExpenseItemForm,
      )
        .pipe(first()).subscribe((result) => {
          // navigates to confirm mail screen
          // this.router.navigate(['/account/confirm-email']);
          this.loading = false;

          // Clear form
          // this.investmentItemForm.reset();

          // navigate via window to same page
          window.location.href = '/portal/operating-expenses';

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
          }));

  }

  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  loadingAnimationStart() {
    this.loading = true;
  }

  loadingAnimationStop() {
    this.loading = false;
  }

  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}


