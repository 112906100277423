import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../service/auth.service';
import { User } from '../models/User';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    subscription: any;
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        var currentUser: User;

        this.subscription = this.authenticationService.currentUser.subscribe((appUser: User | any) => {
            currentUser = appUser;
            if (currentUser && currentUser.userId) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentUser.userId}`
                    }
                });
            }
        });

        return next.handle(request);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
