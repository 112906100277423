import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root'
})
export class PetroleumProductsService {

  constructor(
    private db: AngularFireDatabase,
    private fns: AngularFireFunctions,
    private storage: AngularFireStorage,
  ) { }


  updatePetroleumProduct(load: any) {
    const callable = this.fns.httpsCallable('updatePetroleumProduct');
    return callable({
      load: load,
    });
  }

  getPetroleumProductArray(){
    return this.db.list('/petroleumProduct').valueChanges();
  }

  getPetroleumProductObject(){
    return this.db.object('/petroleumProduct').valueChanges();
  }

  deletePetroleumProduct(id: string) {
    const callable = this.fns.httpsCallable('deletePetroleumProduct');
    return callable({
      load: id,
    });
  }

  getPetroleumProductId() {
    const callable = this.fns.httpsCallable('petroleumProductIdGenerator');
    return callable({ type: '' });
  }


  // Product Category
  updatePetroleumProductCategory(load: any) {
    const callable = this.fns.httpsCallable('updatePetroleumProductCategory');
    return callable({
      load: load,
    });
  }

  getPetroleumProductCategoryArray(){
    return this.db.list('/petroleumProductCategory').valueChanges();
  }

  getPetroleumProductCategoryObject(){
    return this.db.object('/petroleumProductCategory').valueChanges();
  }

  deletePetroleumProductCategory(id: string) {
    const callable = this.fns.httpsCallable('deletePetroleumProductCategory');
    return callable({
      load: id,
    });
  }

  getPetroleumProductCategoryId() {
    const callable = this.fns.httpsCallable('petroleumProductIdGenerator');
    return callable({ type: '' });
  }

  // Country
  updateCountry(load: any) {
    const callable = this.fns.httpsCallable('updateCountry');
    return callable({
      load: load,
    });
  }

  updateProjectInflation(id:string, load: any) {
    const callable = this.fns.httpsCallable('updateProjectInflation');
    return callable({
      id: id,
      load: load,
    });
  }

  updateCountryInflation(id:string,load: any) {
    const callable = this.fns.httpsCallable('updateCountryInflation');
    return callable({
      id: id,
      load: load,
    });
  }

  getCountryArray(){
    return this.db.list('/country').valueChanges();
  }

  getCountryObject(){
    return this.db.object('/country').valueChanges();
  }

  deleteCountry(id: string) {
    const callable = this.fns.httpsCallable('deleteCountry');
    return callable({
      load: id,
    });
  }

  getCountryId() {
    const callable = this.fns.httpsCallable('countryIdGenerator');
    return callable({ type: '' });
  }
}
