import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { NgbAlertModule, NgbProgressbarModule, NgbButtonsModule, NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdvancedTableModule } from 'src/app/shared/advanced-table/advanced-table.module';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { Subscription, first } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@firebase/auth-types';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { ClockService } from 'src/app/core/service/clock.service';
import { UserService } from 'src/app/core/service/user.service';
import { PetroleumProductsService } from 'src/app/core/service/petroleum-products.service';
import { Column, AdvancedTableComponent } from 'src/app/shared/advanced-table/advanced-table.component';
import { SortEvent } from 'src/app/shared/advanced-table/sortable.directive';
import { BreadcrumbItem } from 'src/app/shared/page-title/page-title.model';
import { Country } from 'src/app/core/models-two/Country';
import { FuelLubricantGasProduct } from 'src/app/core/models-two/FuelLubricantGasProduct';
import { ProjectService } from 'src/app/core/service/project.service';
import { FuelProduct } from 'src/app/core/models-two/FuelProduct';
import { GasProduct } from 'src/app/core/models-two/GasProduct';
import { LubricantProduct } from 'src/app/core/models-two/LubricantProduct';

@Component({
  selector: 'app-country-admin',
  standalone: true,
  imports: [
    CommonModule,
    AdvancedTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbProgressbarModule,
    WidgetModule,
    NgbButtonsModule,
  ],
  templateUrl: './country-admin.component.html',
  styleUrls: ['./country-admin.component.scss']
})
export class CountryAdminComponent implements OnInit, OnDestroy {

  yearlyEscalationDefault: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  subscription: Subscription = new Subscription();
  today: number = Date.now();
  pageTitle: BreadcrumbItem[] = [];
  //countryArray!: Client[];
  pageSizeOptions: number[] = [5, 10, 20, 50];
  pageSize: number = 5;
  selectAll: boolean = false;
  loading: boolean = false;
  columns: Column[] = [];
  @ViewChild('profileModal', { static: false }) private profileModal: any;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  @ViewChild('advancedTable') advancedTable!: AdvancedTableComponent;

  countryForm!: FormGroup;
  productForm!: FormGroup;
  fuelProductForm!: FormGroup;
  lubricantProductForm!: FormGroup;
  gasProductForm!: FormGroup;

  fuelVolumeGrowthForm!: FormGroup;
  fuelVolumeGrowthPotentialForm!: FormGroup;

  formSubmitted: boolean = false;

  show_alert: boolean;
  alert_message: string;
  alert_type: string;

  loggedInUser: User;
  countryObject: Country;
  countryArray;
  userId: any;

  destinationLatitude: number;
  destinationLongitude: number;
  destinationZoom: number;
  googleLocationAddressLong: string;
  googleLocationAddressShort: any;
  profilePicture: any;
  invalidPhoneNumber: boolean;
  pickedImage: any;

  error: string;
  showForm: boolean = false;
  userCategoryViewChoice: number = 1;
  dateNow2: any;
  dateNow3: Date;
  newInviteId: any;
  defaultCountryArray: any[] = [];
  defaultCountryObject: any;
  importedCountryArray: Country[] = [];
  showImportPanel: boolean = false;
  disableDownloadButton: boolean = false;
  alphabeticalCountryArray: Country[] = [];
  workingCountryArray: Country[] = [];
  localCurrencyISOCode: any;
  unitsOfMeasureArray: string[];
  selectedCountry: any;
  petroleumProductArray: FuelLubricantGasProduct[];
  pickedPetroleumProductCategory: any;
  fuelProductArray: FuelProduct[];
  lubricantProductArray: LubricantProduct[];
  gasProductArray: GasProduct[];
  selectedCountry_id: any;
  productExists_Update: boolean = false;
  showPetroleumProductForm: boolean = false;
  fuelProductTotal: FuelLubricantGasProduct;
  lubricantProductTotal: FuelLubricantGasProduct;
  fuelAndLubricantProductTotal: any;
  gasProductTotal: any;
  gasProductTotalYearlyVolume: any;
  gasProductTotalCompanyUnitMargin: any;
  updateInProgress: boolean = false;

  fuelVolumeGrowthArray: number[] = this.yearlyEscalationDefault;
  fuelVolumeGrowthPotentialArray: number[] = this.yearlyEscalationDefault;
  monthsDuringThePeriodArray: number[] = this.yearlyEscalationDefault;
  fuelMarginGrowthArray: number[] = this.yearlyEscalationDefault;
  fuelMarginGrowthFactorArray: number[] = this.yearlyEscalationDefault;
  fuelMarginGrowthFactorTwoArray: number[] = this.yearlyEscalationDefault;
  monthsBeforeOperationArray: number[] = this.yearlyEscalationDefault;
  fuelVolumeWithoutLubricantArray: number[] = this.yearlyEscalationDefault;
  fuelVolumeArray: number[] = this.yearlyEscalationDefault;
  fuelMarginArray: number[] = this.yearlyEscalationDefault;
  fuelMarginTwoArray: number[] = this.yearlyEscalationDefault;
  gasMarginArray: number[] = this.yearlyEscalationDefault;
  fuelMarginTotalArray: number[] = this.yearlyEscalationDefault;
  fuelAndLubricantProductTotalAverageUnitMargin: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private projectService: ProjectService,
    private petroleumProductsService: PetroleumProductsService,
    private clockService: ClockService,
  ) {
    this.loadingAnimationFourSeconds();
    this.subscription.add(
      authenticationService.currentUser.pipe().subscribe((res: any) => {
        if (res) {
          if (res[0]) {
            this.loggedInUser = res[0];
            if (res[0].userId) {
              this.userId = res[0].userId;
            }
          } else {
          }
        }
      })
    );
    this.subscription.add(
      this.projectService.getPetroleumProductArray().pipe().subscribe((res: FuelLubricantGasProduct[]) => {
        if (res) {
          this.petroleumProductArray = res;
        }
      })
    );
  }

  ngOnInit(): void {

    // get user data
    this.getCountryList();
    // initialize advance table 
    this.initAdvancedTableData();

    this.unitsOfMeasureArray = [
      "Imperial",
      "Metric",
    ];

    this.countryForm = this.fb.group({
      name: ['', Validators.required],
      unitsOfMeasure: [''],
      localCurrencyName: ['', Validators.required],
      localCurrencyISOCode: ['', Validators.required],

      id: ['', Validators.required],
      usdFxRate: [0.68, Validators.required],
      taxCarryOverPeriod: [5, Validators.required],
      inflation: [5, Validators.required],
      interestRate: [5, Validators.required],
      networkChannelFixedCosts: ['', Validators.required],
      discountRateForNetPresentValue: ['', Validators.required],
      corporateTaxRate: [30, Validators.required],

      date: [''],
    });

    this.productForm = this.fb.group({
      productId: ['', Validators.required],
      productCategory: ['', Validators.required],
    });

    this.fuelProductForm = this.fb.group({
      productId: ['', Validators.required],
      productCategory: ['', Validators.required],
      networkGlobalPrice: [0, Validators.required],
      networkEPRAMargin: [0, Validators.required],
      networkDODOMargin: [0, Validators.required],
      networkDealerMarginM3: [0, Validators.required],
      networkDealerMarginL: [0, Validators.required],
      networkDiscountGiven: [0, Validators.required],
      networkDepotCost: [0, Validators.required],
      networkGalanaPro: [0, Validators.required],
      networkVarex: [0, Validators.required],
      networkExpectedGMVE: [0, Validators.required],

      unitSupplyPrice: ['', Validators.required],
      unitaryTax: ['', Validators.required],
      unitRevenue: ['', Validators.required],
      companyUnitMargin: ['', Validators.required],

      unitGMVE: ['', Validators.required],
      gMVE: ['', Validators.required],

      monthlyVolume: ['', Validators.required],
      yearlyVolume: ['', Validators.required],
    });

    this.lubricantProductForm = this.fb.group({
      productId: ['', Validators.required],
      productCategory: ['', Validators.required],
      networkDealerMarginM3: ['', Validators.required],

      unitSupplyPrice: ['', Validators.required],
      unitaryTax: ['', Validators.required],
      unitRevenue: ['', Validators.required],
      companyUnitMargin: ['', Validators.required],

      unitGMVE: ['', Validators.required],
      gMVE: ['', Validators.required],

      monthlyVolume: ['', Validators.required],
      yearlyVolume: ['', Validators.required],
    });

    this.gasProductForm = this.fb.group({
      productId: ['', Validators.required],
      productCategory: ['', Validators.required],
      networkDealerMarginM3: ['', Validators.required],

      unitSupplyPrice: ['', Validators.required],
      unitaryTax: ['', Validators.required],
      unitRevenue: ['', Validators.required],
      companyUnitMargin: ['', Validators.required],

      unitGMVE: ['', Validators.required],
      gMVE: ['', Validators.required],

      monthlyVolume: ['', Validators.required],
      yearlyVolume: ['', Validators.required],
    });

    this.fuelVolumeGrowthForm = this.fb.group({
      y0: [0],
      y1: [0, Validators.required],
      y2: [0, Validators.required],
      y3: [0, Validators.required],
      y4: [0, Validators.required],
      y5: [4, Validators.required],
      y6: [4, Validators.required],
      y7: [4, Validators.required],
      y8: [4, Validators.required],
      y9: [4, Validators.required],
      y10: [4, Validators.required],
      y11: [4, Validators.required],
      y12: [4, Validators.required],
      y13: [4, Validators.required],
      y14: [4, Validators.required],
      y15: [4, Validators.required],
    });

    this.fuelVolumeGrowthPotentialForm = this.fb.group({
      y0: [0],
      y1: [80],
      y2: [90],
      y3: [100],
      y4: [100],
      y5: [100],
      y6: [100],
      y7: [100],
      y8: [100],
      y9: [100],
      y10: [100],
      y11: [100],
      y12: [100],
      y13: [100],
      y14: [100],
      y15: [100],
    });

    this.monthsDuringThePeriodArray = [3, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12];
    this.monthsBeforeOperationArray = [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3];
    this.fuelMarginGrowthArray = [0, 2.5910931174089, 2.88082083662193, 11.3732259301879, 0.473566385396929, 1.42257262833148, 1.49556400506972, 2.47252747252746, 2.47252747252746, 2.47252747252746, 2.47252747252746, 2.47252747252746, 2.47252747252746, 2.47252747252746, 2.47252747252746, 2.47252747252746];
    this.fuelMarginGrowthFactorArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.fuelMarginGrowthFactorTwoArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.fuelMarginTwoArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    let index_A = 1;
    for (let index = 0; index < 16; index++) {
      if (index === 0) {
        this.fuelMarginGrowthFactorArray[index] = 1;
      } else {
        index_A = this.fuelMarginGrowthFactorArray[index] = index_A * (1 + ((this.fuelMarginGrowthArray[index]) / 100));
      }
    }

    for (let index = 0; index < 16; index++) {
      if (index === 0) {
        this.fuelMarginGrowthFactorTwoArray[index+1] = 1;
      } else {
        if(index < 15){
          this.fuelMarginGrowthFactorTwoArray[index+1] = this.fuelMarginGrowthFactorArray[index] * (1 + (((this.fuelMarginGrowthArray[index]) / 100) ** ((this.monthsDuringThePeriodArray[index]) / (this.monthsBeforeOperationArray[index]))));
        }
      }
    }

    for (let index = 0; index < 16; index++) {
      this.fuelMarginTwoArray[index] = ((this.fuelMarginGrowthFactorTwoArray[index]) * (this.fuelAndLubricantProductTotalAverageUnitMargin));
    }

    // Product
    this.subscription.add(
      this.productForm.get("productId").valueChanges.subscribe(x => {

        if (x && (this.productForm.get("productId").dirty)) {

          if (this.petroleumProductArray) {

            this.petroleumProductArray.forEach(element => {
              if (element[0].id === x) {
                if (element[0].petroleumProductCategory_name) {
                  this.showPetroleumProductForm = true;
                  this.pickedPetroleumProductCategory = element[0].petroleumProductCategory_name;
                  this.productForm.get("productCategory").setValue(element[0].petroleumProductCategory_name);

                  if (element[0].petroleumProductCategory_name === "Fuel") {
                    this.fuelProductForm.get("productId").setValue(x);
                    this.fuelProductForm.get("productCategory").setValue(element[0].petroleumProductCategory_name);
                  }

                  if (element[0].petroleumProductCategory_name === "Lubricant") {
                    this.lubricantProductForm.get("productId").setValue(x);
                    this.lubricantProductForm.get("productCategory").setValue(element[0].petroleumProductCategory_name);
                  }

                  if (element[0].petroleumProductCategory_name === "Gas") {
                    this.gasProductForm.get("productId").setValue(x);
                    this.gasProductForm.get("productCategory").setValue(element[0].petroleumProductCategory_name);
                  }

                  // Load Product
                  if (element[0].name) {
                    this.loadProductFormData(element[0].petroleumProductCategory_name, element[0].name);
                  }

                }
              }
            });
          };
        }
      }));

    this.subscription.add(
      this.fuelProductForm.get("networkDODOMargin").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkDODOMargin").dirty) && !(this.fuelProductForm.get("networkDODOMargin").pristine)) {
          // this.calculateFuelMarginFormItems();

          this.fuelProductForm.get("networkDiscountGiven").setValue(Number(this.fuelProductForm.get("networkDODOMargin").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value));
          this.fuelProductForm.get("networkEPRAMargin").setValue(Number(this.fuelProductForm.get("networkGlobalPrice").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value));
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value));
          this.fuelProductForm.get("unitGMVE").setValue(this.fuelProductForm.get("networkExpectedGMVE").value);
          this.fuelProductForm.get("companyUnitMargin").setValue((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000);
          this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("networkGlobalPrice").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkGlobalPrice").dirty) && !(this.fuelProductForm.get("networkGlobalPrice").pristine)) {
          this.fuelProductForm.get("networkDealerMarginL").setValue(Number(this.fuelProductForm.get("networkDODOMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value));
          this.fuelProductForm.get("networkEPRAMargin").setValue(Number(this.fuelProductForm.get("networkGlobalPrice").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value));
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value));
          this.fuelProductForm.get("unitGMVE").setValue(this.fuelProductForm.get("networkExpectedGMVE").value);
          this.fuelProductForm.get("companyUnitMargin").setValue((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000);
          this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("networkDealerMarginL").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkDealerMarginL").dirty) && !(this.fuelProductForm.get("networkDealerMarginL").pristine)) {
          this.fuelProductForm.get("networkDiscountGiven").setValue(Number(this.fuelProductForm.get("networkDODOMargin").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value));
          this.fuelProductForm.get("networkEPRAMargin").setValue(Number(this.fuelProductForm.get("networkGlobalPrice").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value));
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value));
          this.fuelProductForm.get("unitGMVE").setValue(this.fuelProductForm.get("networkExpectedGMVE").value);
          this.fuelProductForm.get("companyUnitMargin").setValue((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000);
          this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("networkDepotCost").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkDepotCost").dirty) && !(this.fuelProductForm.get("networkDepotCost").pristine)) {
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value));
          this.fuelProductForm.get("unitGMVE").setValue(this.fuelProductForm.get("networkExpectedGMVE").value);
          this.fuelProductForm.get("companyUnitMargin").setValue((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000);
          this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("networkGalanaPro").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkGalanaPro").dirty) && !(this.fuelProductForm.get("networkGalanaPro").pristine)) {
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value));
          this.fuelProductForm.get("unitGMVE").setValue(this.fuelProductForm.get("networkExpectedGMVE").value);
          this.fuelProductForm.get("companyUnitMargin").setValue((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000);
          this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("networkVarex").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkVarex").dirty) && !(this.fuelProductForm.get("networkVarex").pristine)) {
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value));
          this.fuelProductForm.get("unitGMVE").setValue(this.fuelProductForm.get("networkExpectedGMVE").value);
          this.fuelProductForm.get("companyUnitMargin").setValue((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000);
          this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("unitSupplyPrice").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("unitSupplyPrice").dirty) && !(this.fuelProductForm.get("unitSupplyPrice").pristine)) {
          this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("unitaryTax").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("unitaryTax").dirty) && !(this.fuelProductForm.get("unitaryTax").pristine)) {
          this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("monthlyVolume").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("monthlyVolume").dirty) && !(this.fuelProductForm.get("monthlyVolume").pristine)) {
          this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
          this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
        }
      })
    )

    // Lubricant
    this.subscription.add(
      this.lubricantProductForm.get("unitGMVE").valueChanges.subscribe(x => {
        if ((this.lubricantProductForm.get("unitGMVE").dirty) && !(this.lubricantProductForm.get("unitGMVE").pristine)) {
          this.calculateLubricantMarginFormItems();
        }
      }));

    this.subscription.add(
      this.lubricantProductForm.get("unitaryTax").valueChanges.subscribe(x => {
        if ((this.lubricantProductForm.get("unitaryTax").dirty) && !(this.lubricantProductForm.get("unitaryTax").pristine)) {
          this.calculateLubricantMarginFormItems();
        }
      }));

    this.subscription.add(
      this.lubricantProductForm.get("monthlyVolume").valueChanges.subscribe(x => {
        if ((this.lubricantProductForm.get("monthlyVolume").dirty) && !(this.lubricantProductForm.get("monthlyVolume").pristine)) {
          this.calculateLubricantMarginFormItems();
        }
      }));

    this.subscription.add(
      this.lubricantProductForm.get("unitSupplyPrice").valueChanges.subscribe(x => {
        if ((this.lubricantProductForm.get("unitSupplyPrice").dirty) && !(this.lubricantProductForm.get("unitSupplyPrice").pristine)) {
          this.calculateLubricantMarginFormItems();
        }
      }));

    // Gas
    this.subscription.add(
      this.gasProductForm.get("unitGMVE").valueChanges.subscribe(x => {
        if ((this.gasProductForm.get("unitGMVE").dirty) && !(this.gasProductForm.get("unitGMVE").pristine)) {
          this.calculateGasMarginFormItems();
        }
      }));

    this.subscription.add(
      this.gasProductForm.get("unitaryTax").valueChanges.subscribe(x => {
        if ((this.gasProductForm.get("unitaryTax").dirty) && !(this.gasProductForm.get("unitaryTax").pristine)) {
          this.calculateGasMarginFormItems();
        }
      }));

    this.subscription.add(
      this.gasProductForm.get("monthlyVolume").valueChanges.subscribe(x => {
        if ((this.gasProductForm.get("monthlyVolume").dirty) && !(this.gasProductForm.get("monthlyVolume").pristine)) {
          this.calculateGasMarginFormItems();
        }
      }));

    this.subscription.add(
      this.gasProductForm.get("unitSupplyPrice").valueChanges.subscribe(x => {
        if ((this.gasProductForm.get("unitSupplyPrice").dirty) && !(this.gasProductForm.get("unitSupplyPrice").pristine)) {
          this.calculateGasMarginFormItems();
        }
      }));

    // Fuel Margin Calculations
    // this.subscription.add(
    //   this.fuelVolumeForm.get("y1").valueChanges.subscribe(x => {
    //     if ((this.fuelVolumeForm.get("y1").value > 0) && (this.fuelMarginForm.get("y1").value > 0)) {
    //       this.fuelMarginTotalForm.get("y1").setValue(((Number(this.fuelVolumeForm.get("y1").value)) * (Number(this.fuelMarginForm.get("y1").value))) + (Number(this.gasMarginForm.get("y1").value)));
    //     }
    //   })
    // );
    // this.subscription.add(
    //   this.fuelVolumeForm.get("y2").valueChanges.subscribe(x => {
    //     if ((this.fuelVolumeForm.get("y2").value > 0) && (this.fuelMarginForm.get("y2").value > 0)) {
    //       this.fuelMarginTotalForm.get("y2").setValue(((Number(this.fuelVolumeForm.get("y2").value)) * (Number(this.fuelMarginForm.get("y2").value))) + (Number(this.gasMarginForm.get("y2").value)));
    //     }
    //   })
    // );
    // this.subscription.add(
    //   this.fuelVolumeForm.get("y3").valueChanges.subscribe(x => {
    //     if ((this.fuelVolumeForm.get("y3").value > 0) && (this.fuelMarginForm.get("y3").value > 0)) {
    //       this.fuelMarginTotalForm.get("y3").setValue(((Number(this.fuelVolumeForm.get("y3").value)) * (Number(this.fuelMarginForm.get("y3").value))) + (Number(this.gasMarginForm.get("y3").value)));
    //     }
    //   })
    // );
    // this.subscription.add(
    //   this.fuelVolumeForm.get("y4").valueChanges.subscribe(x => {
    //     if ((this.fuelVolumeForm.get("y4").value > 0) && (this.fuelMarginForm.get("y4").value > 0)) {
    //       this.fuelMarginTotalForm.get("y4").setValue(((Number(this.fuelVolumeForm.get("y4").value)) * (Number(this.fuelMarginForm.get("y4").value))) + (Number(this.gasMarginForm.get("y4").value)));
    //     }
    //   })
    // );
    // this.subscription.add(
    //   this.fuelVolumeForm.get("y5").valueChanges.subscribe(x => {
    //     if ((this.fuelVolumeForm.get("y5").value > 0) && (this.fuelMarginForm.get("y5").value > 0)) {
    //       this.fuelMarginTotalForm.get("y5").setValue(((Number(this.fuelVolumeForm.get("y5").value)) * (Number(this.fuelMarginForm.get("y5").value))) + (Number(this.gasMarginForm.get("y5").value)));
    //     }
    //   })
    // );
    // this.subscription.add(
    //   this.fuelVolumeForm.get("y6").valueChanges.subscribe(x => {
    //     if ((this.fuelVolumeForm.get("y6").value > 0) && (this.fuelMarginForm.get("y6").value > 0)) {
    //       this.fuelMarginTotalForm.get("y6").setValue(((Number(this.fuelVolumeForm.get("y6").value)) * (Number(this.fuelMarginForm.get("y6").value))) + (Number(this.gasMarginForm.get("y6").value)));
    //     }
    //   })
    // );
    // this.subscription.add(
    //   this.fuelVolumeForm.get("y7").valueChanges.subscribe(x => {
    //     if ((this.fuelVolumeForm.get("y7").value > 0) && (this.fuelMarginForm.get("y7").value > 0)) {
    //       this.fuelMarginTotalForm.get("y7").setValue(((Number(this.fuelVolumeForm.get("y7").value)) * (Number(this.fuelMarginForm.get("y7").value))) + (Number(this.gasMarginForm.get("y7").value)));
    //     }
    //   })
    // );
    // this.subscription.add(
    //   this.fuelVolumeForm.get("y8").valueChanges.subscribe(x => {
    //     if ((this.fuelVolumeForm.get("y8").value > 0) && (this.fuelMarginForm.get("y8").value > 0)) {
    //       this.fuelMarginTotalForm.get("y8").setValue(((Number(this.fuelVolumeForm.get("y8").value)) * (Number(this.fuelMarginForm.get("y8").value))) + (Number(this.gasMarginForm.get("y8").value)));
    //     }
    //   })
    // );
    // this.subscription.add(
    //   this.fuelVolumeForm.get("y9").valueChanges.subscribe(x => {
    //     if ((this.fuelVolumeForm.get("y9").value > 0) && (this.fuelMarginForm.get("y9").value > 0)) {
    //       this.fuelMarginTotalForm.get("y9").setValue(((Number(this.fuelVolumeForm.get("y9").value)) * (Number(this.fuelMarginForm.get("y9").value))) + (Number(this.gasMarginForm.get("y9").value)));
    //     }
    //   })
    // );
    // this.subscription.add(
    //   this.fuelVolumeForm.get("y10").valueChanges.subscribe(x => {
    //     if ((this.fuelVolumeForm.get("y10").value > 0) && (this.fuelMarginForm.get("y10").value > 0)) {
    //       this.fuelMarginTotalForm.get("y10").setValue(((Number(this.fuelVolumeForm.get("y10").value)) * (Number(this.fuelMarginForm.get("y10").value))) + (Number(this.gasMarginForm.get("y10").value)));
    //     }
    //   })
    // );
    // this.subscription.add(
    //   this.fuelVolumeForm.get("y11").valueChanges.subscribe(x => {
    //     if ((this.fuelVolumeForm.get("y11").value > 0) && (this.fuelMarginForm.get("y11").value > 0)) {
    //       this.fuelMarginTotalForm.get("y11").setValue(((Number(this.fuelVolumeForm.get("y11").value)) * (Number(this.fuelMarginForm.get("y11").value))) + (Number(this.gasMarginForm.get("y11").value)));
    //     }
    //   })
    // );
    // this.subscription.add(
    //   this.fuelVolumeForm.get("y12").valueChanges.subscribe(x => {
    //     if ((this.fuelVolumeForm.get("y12").value > 0) && (this.fuelMarginForm.get("y12").value > 0)) {
    //       this.fuelMarginTotalForm.get("y12").setValue(((Number(this.fuelVolumeForm.get("y12").value)) * (Number(this.fuelMarginForm.get("y12").value))) + (Number(this.gasMarginForm.get("y12").value)));
    //     }
    //   })
    // );
    // this.subscription.add(
    //   this.fuelVolumeForm.get("y13").valueChanges.subscribe(x => {
    //     if ((this.fuelVolumeForm.get("y13").value > 0) && (this.fuelMarginForm.get("y13").value > 0)) {
    //       this.fuelMarginTotalForm.get("y13").setValue(((Number(this.fuelVolumeForm.get("y13").value)) * (Number(this.fuelMarginForm.get("y13").value))) + (Number(this.gasMarginForm.get("y13").value)));
    //     }
    //   })
    // );
    // this.subscription.add(
    //   this.fuelVolumeForm.get("y14").valueChanges.subscribe(x => {
    //     if ((this.fuelVolumeForm.get("y14").value > 0) && (this.fuelMarginForm.get("y14").value > 0)) {
    //       this.fuelMarginTotalForm.get("y14").setValue(((Number(this.fuelVolumeForm.get("y14").value)) * (Number(this.fuelMarginForm.get("y14").value))) + (Number(this.gasMarginForm.get("y14").value)));
    //     }
    //   })
    // );
    // this.subscription.add(
    //   this.fuelVolumeForm.get("y15").valueChanges.subscribe(x => {
    //     if ((this.fuelVolumeForm.get("y15").value > 0) && (this.fuelMarginForm.get("y15").value > 0)) {
    //       this.fuelMarginTotalForm.get("y15").setValue(((Number(this.fuelVolumeForm.get("y15").value)) * (Number(this.fuelMarginForm.get("y15").value))) + (Number(this.gasMarginForm.get("y15").value)));
    //     }
    //   })
    // );
  }

  calculateLubricantMarginFormItems() {
    this.lubricantProductForm.get("companyUnitMargin").setValue((Number(this.lubricantProductForm.get("unitGMVE").value)));
    this.lubricantProductForm.get("unitRevenue").setValue(Number(this.lubricantProductForm.get("unitaryTax").value) + Number(this.lubricantProductForm.get("unitSupplyPrice").value) + Number(this.lubricantProductForm.get("companyUnitMargin").value));
    this.lubricantProductForm.get("yearlyVolume").setValue(Number(this.lubricantProductForm.get("monthlyVolume").value) * 12);
    this.lubricantProductForm.get("gMVE").setValue(Number(this.lubricantProductForm.get("unitRevenue").value) * Number(this.lubricantProductForm.get("yearlyVolume").value));
  }

  calculateGasMarginFormItems() {
    this.gasProductForm.get("companyUnitMargin").setValue((Number(this.gasProductForm.get("unitGMVE").value)));
    this.gasProductForm.get("unitRevenue").setValue(Number(this.gasProductForm.get("unitaryTax").value) + Number(this.gasProductForm.get("unitSupplyPrice").value) + Number(this.gasProductForm.get("companyUnitMargin").value));
    this.gasProductForm.get("yearlyVolume").setValue(Number(this.gasProductForm.get("monthlyVolume").value) * 12);
    this.gasProductForm.get("gMVE").setValue(Number(this.gasProductForm.get("unitRevenue").value) * Number(this.gasProductForm.get("yearlyVolume").value));
  }

  calculateFuelMarginFormItems() {
    this.fuelProductForm.get("networkDiscountGiven").setValue(Number(this.fuelProductForm.get("networkDODOMargin").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value));
    this.fuelProductForm.get("networkEPRAMargin").setValue(Number(this.fuelProductForm.get("networkGlobalPrice").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value));
    this.fuelProductForm.get("networkExpectedGMVE").setValue(Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value));
    this.fuelProductForm.get("unitGMVE").setValue(this.fuelProductForm.get("networkExpectedGMVE").value);
    this.fuelProductForm.get("companyUnitMargin").setValue((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000);
    this.fuelProductForm.get("unitRevenue").setValue((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value)));
    this.fuelProductForm.get("yearlyVolume").setValue(Number(this.fuelProductForm.get("monthlyVolume").value) * 12);
    this.fuelProductForm.get("gMVE").setValue(Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value));
  }

  ngAfterViewInit(): void {

  }

  loadProductFormData(productCategory: string, productName: string) {
    if (productCategory === "Fuel") {
      // Get Fuel Data
      if (this.selectedCountry) {
        if (this.selectedCountry.fuelProduct) {
          let load: any = this.selectedCountry.fuelProduct;

          load.forEach((element: FuelProduct) => {
            if (element.name === productName) {
              this.productExists_Update = true;
              if (element.id) { this.fuelProductForm.get("productId").setValue(element.id); };
              if (element.networkGlobalPrice) { this.fuelProductForm.get("networkGlobalPrice").setValue(element.networkGlobalPrice); };
              if (element.networkEPRAMargin) { this.fuelProductForm.get("networkEPRAMargin").setValue(element.networkEPRAMargin); };
              if (element.networkDODOMargin) { this.fuelProductForm.get("networkDODOMargin").setValue(element.networkDODOMargin); };
              if (element.networkDealerMarginL) { this.fuelProductForm.get("networkDealerMarginL").setValue(element.networkDealerMarginL); };
              if (element.networkDiscountGiven) { this.fuelProductForm.get("networkDiscountGiven").setValue(element.networkDiscountGiven); };
              if (element.networkDepotCost) { this.fuelProductForm.get("networkDepotCost").setValue(element.networkDepotCost); };
              if (element.networkGalanaPro) { this.fuelProductForm.get("networkGalanaPro").setValue(element.networkGalanaPro); };
              if (element.networkVarex) { this.fuelProductForm.get("networkVarex").setValue(element.networkVarex); };
              if (element.networkExpectedGMVE) { this.fuelProductForm.get("networkExpectedGMVE").setValue(element.networkExpectedGMVE); };
              if (element.unitSupplyPrice) { this.fuelProductForm.get("unitSupplyPrice").setValue(element.unitSupplyPrice); };
              if (element.unitaryTax) { this.fuelProductForm.get("unitaryTax").setValue(element.unitaryTax); };
              if (element.unitRevenue) { this.fuelProductForm.get("unitRevenue").setValue(element.unitRevenue); };
              if (element.companyUnitMargin) { this.fuelProductForm.get("companyUnitMargin").setValue(element.companyUnitMargin); };
              if (element.monthlyVolume) { this.fuelProductForm.get("monthlyVolume").setValue(element.monthlyVolume); };
              if (element.yearlyVolume) { this.fuelProductForm.get("yearlyVolume").setValue(element.yearlyVolume); };
              if (element.unitGMVE) { this.fuelProductForm.get("unitGMVE").setValue(element.unitGMVE ? element.unitGMVE : 0); };
              if (element.gMVE) { this.fuelProductForm.get("gMVE").setValue(element.gMVE); };
            }
          });

        }
      }

    }

    if (productCategory === "Lubricant") {
      // Get Fuel Data
      if (this.selectedCountry) {
        if (this.selectedCountry.lubricantProduct) {
          let load: any = this.selectedCountry.lubricantProduct;

          load.forEach((element: LubricantProduct) => {
            if (element.name === productName) {
              this.productExists_Update = true;
              if (element.id) { this.lubricantProductForm.get("productId").setValue(element.id); };
              if (element.networkDealerMarginM3) { this.lubricantProductForm.get("networkDealerMarginM3").setValue(element.networkDealerMarginM3); };
              if (element.unitSupplyPrice) { this.lubricantProductForm.get("unitSupplyPrice").setValue(element.unitSupplyPrice); };
              if (element.unitaryTax) { this.lubricantProductForm.get("unitaryTax").setValue(element.unitaryTax); };
              if (element.unitRevenue) { this.lubricantProductForm.get("unitRevenue").setValue(element.unitRevenue); };
              if (element.companyUnitMargin) { this.lubricantProductForm.get("companyUnitMargin").setValue(element.companyUnitMargin); };
              if (element.unitGMVE) { this.lubricantProductForm.get("unitGMVE").setValue(element.unitGMVE ? element.unitGMVE : 0); };
              if (element.gMVE) { this.lubricantProductForm.get("gMVE").setValue(element.gMVE); };
              if (element.monthlyVolume) { this.lubricantProductForm.get("monthlyVolume").setValue(element.monthlyVolume); };
              if (element.yearlyVolume) { this.lubricantProductForm.get("yearlyVolume").setValue(element.yearlyVolume); };
            }
          });

        }
      }

    }

    if (productCategory === "Gas") {
      // Get Fuel Data
      if (this.selectedCountry) {
        if (this.selectedCountry.gasProduct) {
          let load: any = this.selectedCountry.gasProduct;

          load.forEach((element: GasProduct) => {
            if (element.name === productName) {
              this.productExists_Update = true;
              if (element.id) { this.gasProductForm.get("productId").setValue(element.id); };
              if (element.networkDealerMarginM3) { this.gasProductForm.get("networkDealerMarginM3").setValue(element.networkDealerMarginM3); };
              if (element.unitSupplyPrice) { this.gasProductForm.get("unitSupplyPrice").setValue(element.unitSupplyPrice); };
              if (element.unitaryTax) { this.gasProductForm.get("unitaryTax").setValue(element.unitaryTax); };
              if (element.unitRevenue) { this.gasProductForm.get("unitRevenue").setValue(element.unitRevenue); };
              if (element.companyUnitMargin) { this.gasProductForm.get("companyUnitMargin").setValue(element.companyUnitMargin); };
              if (element.unitGMVE) { this.gasProductForm.get("unitGMVE").setValue(element.unitGMVE ? element.unitGMVE : 0); };
              if (element.gMVE) { this.gasProductForm.get("gMVE").setValue(element.gMVE); };
              if (element.monthlyVolume) { this.gasProductForm.get("monthlyVolume").setValue(element.monthlyVolume); };
              if (element.yearlyVolume) { this.gasProductForm.get("yearlyVolume").setValue(element.yearlyVolume); };
            }
          });

        }
      }

    }

    // Load Fuel Form


  }


  /**
   * convenience getter for easy access to form fields
   */
  get formValues() {
    return this.countryForm.controls;
  }



  /**
   * On form submit
   */

  updateCountryDetails(load: any): void {

    // Checks
    if (this.countryForm.controls['id'].invalid) {
      this.show_alert = true;
      this.alert_message = "Country Id is missing!";
      this.alert_type = "danger";

      this.toastr.error('Country Id is missing!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.controls['name'].invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the name!";
      this.alert_type = "danger";

      this.toastr.error('Provide the name!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("unitsOfMeasure").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the units of measurement!";
      this.alert_type = "danger";

      this.toastr.error('Provide the units of measurement!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("localCurrencyName").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the local currency name!";
      this.alert_type = "danger";

      this.toastr.error('Provide the local currency name!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("localCurrencyISOCode").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the local currency ISO Code!";
      this.alert_type = "danger";

      this.toastr.error('Provide the local currency ISO Code!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("corporateTaxRate").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the Corporate Tax Rate!";
      this.alert_type = "danger";

      this.toastr.error('Provide the Corporate Tax Rate!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("taxCarryOverPeriod").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the Tax Carry Over Period!";
      this.alert_type = "danger";

      this.toastr.error('Provide the Tax Carry Over Period!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("discountRateForNetPresentValue").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the Discount Rate For Net Present Value (NPV)!";
      this.alert_type = "danger";

      this.toastr.error('Provide the Discount Rate For Net Present Value (NPV)!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("usdFxRate").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the USD Fx Rate!";
      this.alert_type = "danger";

      this.toastr.error('Provide the USD Fx Rate!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("inflation").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the Inflation Rate!";
      this.alert_type = "danger";

      this.toastr.error('Provide the Inflation Rate!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.countryForm.get("interestRate").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the Inflation Rate!";
      this.alert_type = "danger";

      this.toastr.error('Provide the Inflation Rate!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    this.loading = true;
    this.subscription.add(
      this.petroleumProductsService.updateCountry(load)
        .pipe(first()).subscribe((result) => {
          // navigates to confirm mail screen
          // this.router.navigate(['/account/confirm-email']);
          this.formSubmitted = true;
          this.loading = false;
          this.showForm = false;

          // Clear form
          this.productForm.reset();
          this.countryForm.reset();
          this.fuelProductForm.reset();
          this.lubricantProductForm.reset();
          this.gasProductForm.reset();

          // navigate via window to same page
          window.location.href = '/portal/country-admin';

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
          }));

  }

  saveFuelVolumeGrowth(
    fuelVolumeGrowthForm: any,
    fuelVolumeGrowthPotentialForm: any,
  ) {
    this.loading = true;
    this.subscription.add(
      this.projectService.updateCountryFuelVolumeGrowth(
        fuelVolumeGrowthForm,
        fuelVolumeGrowthPotentialForm,
        this.monthsDuringThePeriodArray,
        this.fuelMarginGrowthArray,
        this.fuelVolumeArray,
        this.fuelMarginArray,
        this.gasMarginArray,
        this.fuelMarginTotalArray,
      )
        .pipe(first()).subscribe((result) => {
          // navigates to confirm mail screen
          // this.router.navigate(['/account/confirm-email']);
          this.formSubmitted = true;
          this.loading = false;
          // Clear form
        },
          (error: string) => {
            this.error = error;
            this.loading = false;
          }));
  }

  addProduct(load: any) {

    if (this.pickedPetroleumProductCategory === 'Fuel') {

      // Checks
      if (this.fuelProductForm.get("productId").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkGlobalPrice").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network global price!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network global price!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkDODOMargin").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network DODO margin!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network DODO margin!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkDealerMarginL").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network dealer margin!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network dealer margin!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkDiscountGiven").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network discount given!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network discount given!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkDepotCost").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network depot cost!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network depot cost!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkGalanaPro").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network galana pro!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network galana pro!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkVarex").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network varex!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network varex!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }


      // Checks
      if (this.fuelProductForm.get("networkExpectedGMVE").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network expected GMVE!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network expected GMVE!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("unitSupplyPrice").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product unit supply price!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unit supply price!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("unitaryTax").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product unitary tax!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unitary tax!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      this.loading = true;
      this.subscription.add(
        this.projectService.updateCountryPetroleumProduct(this.selectedCountry_id, load)
          .pipe(first()).subscribe((result) => {
            // navigates to confirm mail screen
            // this.router.navigate(['/account/confirm-email']);
            this.formSubmitted = true;
            this.loading = false;

            // Clear form
            this.showForm = false;
            this.showPetroleumProductForm = false;
            this.productForm.reset();
            this.countryForm.reset();
            this.fuelProductForm.reset();
            this.lubricantProductForm.reset();
            this.gasProductForm.reset();

          },
            (error: string) => {
              this.error = error;
              this.loading = false;
            }));
    };

    if (this.pickedPetroleumProductCategory === 'Lubricant') {

      // Checks
      if (this.lubricantProductForm.get("productId").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.lubricantProductForm.get("networkDealerMarginM3").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network dealer margin!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network dealer margin!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.lubricantProductForm.get("unitSupplyPrice").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product unit supply price!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unit supply price!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.lubricantProductForm.get("unitaryTax").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product unitary tax!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unitary tax!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      this.loading = true;
      this.subscription.add(
        this.projectService.updateCountryPetroleumProduct(
          this.selectedCountry_id, 
          load,
        )
          .pipe(first()).subscribe((result) => {
            // navigates to confirm mail screen
            // this.router.navigate(['/account/confirm-email']);
            this.formSubmitted = true;
            this.loading = false;

            // Clear form
            this.showForm = false;
            this.showPetroleumProductForm = false;
            this.productForm.reset();
            this.countryForm.reset();
            this.fuelProductForm.reset();
            this.lubricantProductForm.reset();
            this.gasProductForm.reset();

          },
            (error: string) => {
              this.error = error;
              this.loading = false;
            }));

    };

    if (this.pickedPetroleumProductCategory === 'Gas') {

      // Checks
      if (this.gasProductForm.get("productId").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.gasProductForm.get("networkDealerMarginM3").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product network dealer margin!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network dealer margin!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.gasProductForm.get("unitSupplyPrice").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product unit supply price!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unit supply price!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.gasProductForm.get("unitaryTax").invalid) {
        this.show_alert = true;
        this.alert_message = "Provide the product unitary tax!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unitary tax!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      this.loading = true;
      this.subscription.add(
        this.projectService.updateCountryPetroleumProduct(this.selectedCountry_id, load)
          .pipe(first()).subscribe((result) => {
            // navigates to confirm mail screen
            // this.router.navigate(['/account/confirm-email']);
            this.formSubmitted = true;
            this.loading = false;

            // Clear form
            this.showPetroleumProductForm = false;
            this.showForm = false;
            this.productForm.reset();
            this.countryForm.reset();
            this.fuelProductForm.reset();
            this.lubricantProductForm.reset();
            this.gasProductForm.reset();

          },
            (error: string) => {
              this.error = error;
              this.loading = false;
            }));

    };

  }

  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  loadingAnimationStart() {
    this.loading = true;
  }

  loadingAnimationStop() {
    this.loading = false;
  }

  getCountryList() {
    this.subscription.add(
      this.petroleumProductsService.getCountryArray().pipe().subscribe((res: any) => {
        if (res) {
          this.workingCountryArray = [];
          this.defaultCountryArray = this.countryArray = res;

          // Arranged Alphabetically
          res.forEach(element => {
            this.workingCountryArray.push(element[0]);
          });

          this.alphabeticalCountryArray = this.workingCountryArray.sort((a: any, b: any) => (a.firstName).localeCompare(b.firstName));

        }
      })
    );

    this.subscription.add(
      this.petroleumProductsService.getCountryObject().pipe().subscribe((res) => {
        if (res) {
          this.defaultCountryObject = this.countryObject = res;

          if (this.selectedCountry_id) {
            if (res[this.selectedCountry_id]) {
              if (res[this.selectedCountry_id][0]) {
                this.selectedCountry = res[this.selectedCountry_id][0];
                if (res[this.selectedCountry_id][0].id) { this.countryForm.get("id").setValue(res[this.selectedCountry_id][0].id); };
                if (res[this.selectedCountry_id][0].index) { this.countryForm.get("index").setValue(res[this.selectedCountry_id][0].index); };
                if (res[this.selectedCountry_id][0].name) { this.countryForm.get("name").setValue(res[this.selectedCountry_id][0].name); };

                if (res[this.selectedCountry_id][0].unitsOfMeasure) { this.countryForm.get("unitsOfMeasure").setValue(res[this.selectedCountry_id][0].unitsOfMeasure); };
                if (res[this.selectedCountry_id][0].localCurrencyName) { this.countryForm.get("localCurrencyName").setValue(res[this.selectedCountry_id][0].localCurrencyName); };
                if (res[this.selectedCountry_id][0].localCurrencyISOCode) {
                  this.countryForm.get("localCurrencyISOCode").setValue(res[this.selectedCountry_id][0].localCurrencyISOCode);
                  this.localCurrencyISOCode = res[this.selectedCountry_id][0].localCurrencyISOCode;
                };
                if (res[this.selectedCountry_id][0].corporateTaxRate) { this.countryForm.get("corporateTaxRate").setValue(res[this.selectedCountry_id][0].corporateTaxRate); };
                if (res[this.selectedCountry_id][0].taxCarryOverPeriod) { this.countryForm.get("taxCarryOverPeriod").setValue(res[this.selectedCountry_id][0].taxCarryOverPeriod); };
                if (res[this.selectedCountry_id][0].discountRateForNetPresentValue) { this.countryForm.get("discountRateForNetPresentValue").setValue(res[this.selectedCountry_id][0].discountRateForNetPresentValue); };
                if (res[this.selectedCountry_id][0].usdFxRate) { this.countryForm.get("usdFxRate").setValue(res[this.selectedCountry_id][0].usdFxRate); };
                if (res[this.selectedCountry_id][0].networkChannelFixedCosts) { this.countryForm.get("networkChannelFixedCosts").setValue(res[this.selectedCountry_id][0].networkChannelFixedCosts); };
                if (res[this.selectedCountry_id][0].inflation) { this.countryForm.get("inflation").setValue(res[this.selectedCountry_id][0].inflation); };
                if (res[this.selectedCountry_id][0].interestRate) { this.countryForm.get("interestRate").setValue(res[this.selectedCountry_id][0].interestRate); };
                if (res[this.selectedCountry_id][0].date) { this.countryForm.get("date").setValue(res[this.selectedCountry_id][0].date); };

                // New
                if (res[this.selectedCountry_id][0].fuelProduct) { this.fuelProductArray = res[this.selectedCountry_id][0].fuelProduct; };
                if (res[this.selectedCountry_id][0].lubricantProduct) { this.lubricantProductArray = res[this.selectedCountry_id][0].lubricantProduct };
                if (res[this.selectedCountry_id][0].gasProduct) { this.gasProductArray = res[this.selectedCountry_id][0].gasProduct };

                if (res[this.selectedCountry_id][0].fuelProductTotal) { this.fuelProductTotal = res[this.selectedCountry_id][0].fuelProductTotal; };
                if (res[this.selectedCountry_id][0].lubricantProductTotal) { this.lubricantProductTotal = res[this.selectedCountry_id][0].lubricantProductTotal };
                if (res[this.selectedCountry_id][0].gasProductTotal) {
                  this.gasProductTotal = res[this.selectedCountry_id][0].gasProductTotal;

                  if (res[this.selectedCountry_id][0].gasProductTotal.yearlyVolume) {
                    this.gasProductTotalYearlyVolume = res[this.selectedCountry_id][0].gasProductTotal.yearlyVolume
                  };
                  if (res[this.selectedCountry_id][0].gasProductTotal.companyUnitMargin) {
                    this.gasProductTotalCompanyUnitMargin = res[this.selectedCountry_id][0].gasProductTotal.companyUnitMargin
                  };

                  if (this.gasProductTotalYearlyVolume && this.gasProductTotalCompanyUnitMargin) {
                    let a = (Number(this.gasProductTotalYearlyVolume)) * (Number(this.gasProductTotalCompanyUnitMargin));
                    for (let index = 0; index < 16; index++) {
                      if (index > 0) {
                        this.gasMarginArray[index] = a;
                      } else {
                        this.gasMarginArray[index] = 0;
                      }
                    }
                  };
                };
                if (res[this.selectedCountry_id][0].fuelAndLubricantProductTotal) {
                  this.fuelAndLubricantProductTotal = res[this.selectedCountry_id][0].fuelAndLubricantProductTotal;
                  if (res[this.selectedCountry_id][0].fuelAndLubricantProductTotal.averageUnitMargin) {
                    // Calculate 
                    if (this.fuelMarginGrowthArray[1]) {
                      let a = this.fuelAndLubricantProductTotalAverageUnitMargin = res[this.selectedCountry_id][0].fuelAndLubricantProductTotal.averageUnitMargin;
                      for (let index = 0; index < 16; index++) {
                        if (index === 0) {
                          this.fuelMarginArray[index] = 0;
                        }
                        if (index === 1) {
                          this.fuelMarginArray[index] = a;
                        }
                        if (index > 1) {
                          this.fuelMarginArray[index] = ((100 + Number(this.fuelMarginGrowthArray[index - 1])) / 100) * Number(this.fuelMarginArray[index - 1]);
                        }
                      }
                      for (let index = 0; index < 16; index++) {
                        this.fuelMarginTwoArray[index] = ((this.fuelMarginGrowthFactorTwoArray[index]) * (a));
                      }
                    };
                  };

                  if (res[this.selectedCountry_id][0].fuelAndLubricantProductTotal.yearlyVolume) {
                    if (this.fuelVolumeGrowthPotentialForm.get("y1").value && this.fuelVolumeGrowthForm.get("y5").value) {
                      let a = res[this.selectedCountry_id][0].fuelAndLubricantProductTotal.yearlyVolume;
                      for (let index = 0; index < 16; index++) {
                        if (index === 0) {
                          this.fuelVolumeArray[index] = 0;
                        }
                        if (0 < index) {
                          if (index < 5) {
                            this.fuelVolumeArray[index] = ((this.fuelVolumeGrowthPotentialForm.get(`y${index}`).value) / 100) * Number(a);
                          } else {
                            this.fuelVolumeArray[index] = (((Number(this.fuelVolumeGrowthForm.get(`y${index}`).value) + Number(this.fuelVolumeGrowthPotentialForm.get(`y${index}`).value)) / 100) * Number(this.fuelVolumeArray[index - 1]));
                          }
                        }
                      }
                    };
                  };
                };
              }
            }
          }

        }
      })
    );
  }

  /**
   * initialize advance table columns
   */
  initAdvancedTableData(): void {
    this.columns = [
      {
        name: 'name',
        label: 'Name',
        formatter: (p: Country[]) => p[0].name,
      },
      {
        name: 'action',
        label: 'Action',
        formatter: this.userActionFormatter.bind(this),
        sort: false
      }
    ]
  }

  /**
   * handles operations that need to be performed after loading table
   */
  handleTableLoad(): void {
    // user cell
    document.querySelectorAll('.edit').forEach((e) => {
      e.addEventListener("click", () => {
        //Navigate to edit page
        this.editForm(e.id);

        // Navigate to /id
        this.router.navigate([`/portal/country/${e.id}`]);
      });
    })
    document.querySelectorAll('.delete').forEach((e) => {
      e.addEventListener("click", () => {

        if (confirm("Are you sure you want to delete this project?")) {
        // Move item to petroleumProduct/delete
        this.deleteCountry(e.id);
      }

      });
    })
  }


  deleteCountry(id: string) {
    this.subscription.add(
      this.petroleumProductsService.deleteCountry(id)
        .pipe(first()).subscribe((result) => {

          //Get User Data
          this.show_alert = true;
          this.alert_message = `Petroleum Product ${id} Deleted!`;
          this.alert_type = "success";

          this.toastr.success(`Petroleum Product ${id} Deleted!`, "Info!");

          setTimeout(() => {
            this.show_alert = false;
            this.selfClosingAlert.close();
          }, 3000);

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
          }));
  }


  // table user formatter
  userNameFormatter(user: Country): SafeHtml {
    let userCell: string = "";
    userCell += `<p class='m-0 d-inline-block align-middle font-16'>`
    if (user[0]) {
      if (user[0].name) {
        userCell += `${user[0].name} `
      }
      if (user[0].petroleumProductCategory_name) {
        userCell += `${user[0].petroleumProductCategory_name} `
      }
    }
    userCell = userCell + `</p>`
    return this.sanitizer.bypassSecurityTrustHtml(userCell);
  }

  codeFormatter(user: Country): SafeHtml {
    let userCell: string = "";
    //Category for each
    if (user[0]) {
      if (user[0].id) {
        userCell = `<p class='m-0 d-inline-block align-middle'>${user[0].id}</p>`
      } else {
        userCell = `<p class='m-0 d-inline-block align-middle text-danger">Missing</p>`
      };
    } else {
      userCell = `<p class='m-0 d-inline-block align-middle text-danger">Missing</p>`
    }
    return this.sanitizer.bypassSecurityTrustHtml(userCell);
  }

  // table action cell formatter
  userActionFormatter(user): SafeHtml {
    if (user[0]) {
      if (user[0].id) {
        return this.sanitizer.bypassSecurityTrustHtml(
          `<button id="${user[0].id}" class="btn btn-sm btn-outline-primary edit" type="button" *ngIf="!updateInProgress"> <i class=""></i>View/Edit</button>
            <button id="${user[0].id}" class="btn btn-sm btn-outline-danger delete" type="button" *ngIf="!updateInProgress"> <i class=""></i>X</button>`
        )
      }
    }
  }

  numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  // compares two cell valueuser?: Country;
  user_complete?: boolean;
  compare(v1: string | number | boolean, v2: string | number | boolean): number {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  /**
   * Sort the table data
   * @param event column name,sort direction
   */
  sort(event: SortEvent): void {
    if (event.direction === '') {
      this.countryArray = this.defaultCountryArray;
      //this.getCountryList();
    } else {
      this.countryArray = [...this.countryArray].sort((a, b) => {
        const res = this.compare(a["0"][event.column], b["0"][event.column]);
        return event.direction === 'asc' ? res : -res;
      });
    }
  }

  /**
 * Table Data Match with Search input
 * @param tableRow Table row
 * @param term Search the value
 */
  matches(tableRow: Country, term: string) {
    return tableRow[0].name?.toLowerCase().includes(term)
      || tableRow[0].petroleumProductCategory_name?.toLowerCase().includes(term);
  }

  /**
   * Search Method
  */
  searchData(searchTerm: string): void {
    if (searchTerm === '') {
      // this.getCountryList();
      this.countryArray = this.defaultCountryArray;
    }
    else {
      let updatedData = this.countryArray = this.defaultCountryArray;
      //  filter
      updatedData = updatedData.filter(user => this.matches(user, searchTerm));
      this.countryArray = updatedData;
    }

  }

  hasChanged($event: any) {
    // Clear all other address details?
  }

  generateId() {
    // Get new Id
    this.subscription.add(
      this.petroleumProductsService.getCountryId().pipe(first()).subscribe((result) => {
        if (result) {
          this.newInviteId = result;
          this.countryForm.get("id").setValue(result);
        }
      })
    );
  }

  createRecord() {
    // Show new tool form
    this.showForm = true;

    // Clear form

    this.countryForm.reset();
    this.productForm.reset();
    this.fuelProductForm.reset();
    this.lubricantProductForm.reset();
    this.gasProductForm.reset();

    // Generate ID
    this.generateId();
  }

  editForm(id: string) {

    this.selectedCountry_id = id;

    // Show new tool form
    this.showForm = true;

    // Clear form
    this.countryForm.reset();
    this.productForm.reset();
    this.fuelProductForm.reset();
    this.lubricantProductForm.reset();
    this.gasProductForm.reset();

    //Populate Form
    if (this.countryObject) {
      if (this.countryObject[id]) {
        if (this.countryObject[id][0]) {
          this.selectedCountry = this.countryObject[id][0];
          if (this.countryObject[id][0].id) { this.countryForm.get("id").setValue(this.countryObject[id][0].id); };
          if (this.countryObject[id][0].name) { this.countryForm.get("name").setValue(this.countryObject[id][0].name); };

          if (this.countryObject[id][0].unitsOfMeasure) { this.countryForm.get("unitsOfMeasure").setValue(this.countryObject[id][0].unitsOfMeasure); };
          if (this.countryObject[id][0].localCurrencyName) { this.countryForm.get("localCurrencyName").setValue(this.countryObject[id][0].localCurrencyName); };
          if (this.countryObject[id][0].localCurrencyISOCode) {
            this.countryForm.get("localCurrencyISOCode").setValue(this.countryObject[id][0].localCurrencyISOCode);
            this.localCurrencyISOCode = this.countryObject[id][0].localCurrencyISOCode;
          };
          if (this.countryObject[id][0].corporateTaxRate) { this.countryForm.get("corporateTaxRate").setValue(this.countryObject[id][0].corporateTaxRate); };
          if (this.countryObject[id][0].discountRateForNetPresentValue) { this.countryForm.get("discountRateForNetPresentValue").setValue(this.countryObject[id][0].discountRateForNetPresentValue); };
          if (this.countryObject[id][0].usdFxRate) { this.countryForm.get("usdFxRate").setValue(this.countryObject[id][0].usdFxRate); };
          if (this.countryObject[id][0].networkChannelFixedCosts) { this.countryForm.get("networkChannelFixedCosts").setValue(this.countryObject[id][0].networkChannelFixedCosts); };
          if (this.countryObject[id][0].inflation) { this.countryForm.get("inflation").setValue(this.countryObject[id][0].inflation); };
          if (this.countryObject[id][0].interestRate) { this.countryForm.get("interestRate").setValue(this.countryObject[id][0].interestRate); };
          if (this.countryObject[id][0].date) { this.countryForm.get("date").setValue(this.countryObject[id][0].date); };

          // New
          if (this.countryObject[id][0].fuelProduct) { this.fuelProductArray = this.countryObject[id][0].fuelProduct; };
          if (this.countryObject[id][0].lubricantProduct) { this.lubricantProductArray = this.countryObject[id][0].lubricantProduct };
          if (this.countryObject[id][0].gasProduct) { this.gasProductArray = this.countryObject[id][0].gasProduct };

          if (this.countryObject[id][0].fuelProductTotal) {
            this.fuelProductTotal = this.countryObject[id][0].fuelProductTotal;
          };
          if (this.countryObject[id][0].lubricantProductTotal) {
            this.lubricantProductTotal = this.countryObject[id][0].lubricantProductTotal;
          };
          if (this.countryObject[id][0].gasProductTotal) {
            this.gasProductTotal = this.countryObject[id][0].gasProductTotal;

            if (this.countryObject[id][0].gasProductTotal.yearlyVolume) {
              this.gasProductTotalYearlyVolume = this.countryObject[id][0].gasProductTotal.yearlyVolume
            };
            if (this.countryObject[id][0].gasProductTotal.companyUnitMargin) {
              this.gasProductTotalCompanyUnitMargin = this.countryObject[id][0].gasProductTotal.companyUnitMargin
            };

            if (this.gasProductTotalYearlyVolume && this.gasProductTotalCompanyUnitMargin) {
              let a = (Number(this.gasProductTotalYearlyVolume)) * (Number(this.gasProductTotalCompanyUnitMargin));
              for (let index = 0; index < 16; index++) {
                this.gasMarginArray[index] = a;
              };
            };
          };
          if (this.countryObject[id][0].fuelAndLubricantProductTotal) {
            this.fuelAndLubricantProductTotal = this.countryObject[id][0].fuelAndLubricantProductTotal;

            if (this.countryObject[id][0].fuelAndLubricantProductTotal.averageUnitMargin) {
              // Calculate 
              if (this.fuelMarginGrowthArray[1]) {
                let a = this.countryObject[id][0].fuelAndLubricantProductTotal.averageUnitMargin;
                for (let index = 0; index < 16; index++) {
                  if (index === 0) {
                    this.fuelMarginArray[index] = 0;
                  }
                  if (index === 1) {
                    this.fuelMarginArray[index] = a;
                  }
                  if (index > 1) {
                    this.fuelMarginArray[index] = ((100 + Number(this.fuelMarginGrowthArray[index - 1])) / 100) * Number(this.fuelMarginArray[index - 1]);
                  }
                }
                for (let index = 0; index < 16; index++) {
                  this.fuelMarginTwoArray[index] = ((this.fuelMarginGrowthFactorTwoArray[index]) * (a));
                }
              };
            };

            if (this.countryObject[id][0].fuelAndLubricantProductTotal.yearlyVolume) {
              if (this.fuelVolumeGrowthPotentialForm.get("y1").value && this.fuelVolumeGrowthForm.get("y5").value) {
                let a = this.countryObject[id][0].fuelAndLubricantProductTotal.yearlyVolume;
                for (let index = 0; index < 16; index++) {
                  if (index === 0) {
                    this.fuelVolumeArray[index] = 0;
                  }
                  if (0 < index) {
                    if (index < 5) {
                      this.fuelVolumeArray[index] = ((this.fuelVolumeGrowthPotentialForm.get(`y${index}`).value) / 100) * Number(a);
                    } else {
                      this.fuelVolumeArray[index] = (((Number(this.fuelVolumeGrowthForm.get(`y${index}`).value) + Number(this.fuelVolumeGrowthPotentialForm.get(`y${index}`).value)) / 100) * Number(this.fuelVolumeArray[index - 1]));
                    }
                  }
                }
              };
            };
          };

          if (this.countryObject[id][0].fuelVolumeAndMarginGrowth) {
            if (this.countryObject[id][0].fuelVolumeAndMarginGrowth.fuelVolumeGrowth) {
              this.fuelVolumeGrowthArray = this.countryObject[id][0].fuelVolumeAndMarginGrowth.fuelVolumeGrowth;
            }
            if (this.countryObject[id][0].fuelVolumeAndMarginGrowth.fuelVolumeGrowthPotential) {
              this.fuelVolumeGrowthPotentialArray = this.countryObject[id][0].fuelVolumeAndMarginGrowth.fuelVolumeGrowthPotential;
            }
            if (this.countryObject[id][0].fuelVolumeAndMarginGrowth.monthsDuringThePeriod) {
              this.monthsDuringThePeriodArray = this.countryObject[id][0].fuelVolumeAndMarginGrowth.monthsDuringThePeriod;
            }
            if (this.countryObject[id][0].fuelVolumeAndMarginGrowth.fuelMarginGrowth) {
              this.fuelMarginGrowthArray = this.countryObject[id][0].fuelVolumeAndMarginGrowth.fuelMarginGrowth;
            }
            if (this.countryObject[id][0].fuelVolumeAndMarginGrowth.fuelVolumeWithoutLubricant) {
              this.fuelVolumeWithoutLubricantArray = this.countryObject[id][0].fuelVolumeAndMarginGrowth.fuelVolumeWithoutLubricant;
            }
            if (this.countryObject[id][0].fuelVolumeAndMarginGrowth.fuelVolume) {
              this.fuelVolumeArray = this.countryObject[id][0].fuelVolumeAndMarginGrowth.fuelVolume;
            }
            if (this.countryObject[id][0].fuelVolumeAndMarginGrowth.fuelMargin) {
              this.fuelMarginArray = this.countryObject[id][0].fuelVolumeAndMarginGrowth.fuelMargin;
            }
            if (this.countryObject[id][0].fuelVolumeAndMarginGrowth.gasMargin) {
              this.gasMarginArray = this.countryObject[id][0].fuelVolumeAndMarginGrowth.gasMargin;
            }
            if (this.countryObject[id][0].fuelVolumeAndMarginGrowth.fuelMarginTotal) {
              this.fuelMarginTotalArray = this.countryObject[id][0].fuelVolumeAndMarginGrowth.fuelMarginTotal;
            }
          }
        }
      }
    }
  }

  edit(a: string) {

  }

  cancelUpdate() {
    // Show new tool form
    this.showForm = false;

    // Clear form
    this.productForm.reset();
    this.countryForm.reset();
    this.fuelProductForm.reset();
    this.lubricantProductForm.reset();
    this.gasProductForm.reset();

  }


  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }

  get countryFormValues() {
    return this.countryForm.controls;
  }
  get productFormValues() {
    return this.productForm.controls;
  }
  get gasProductFormValues() {
    return this.gasProductForm.controls;
  }
  get lubricantProductFormValues() {
    return this.lubricantProductForm.controls;
  }
  get fuelProductFormValues() {
    return this.fuelProductForm.controls;
  }

  hideProductMenu() {
    this.showPetroleumProductForm = false;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}

